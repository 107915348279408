import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AppBlockerService,
  AuthFactoryService,
  CodebookService,
  DmsService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
  TicketingService,
  UserInfo
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { CodebookDto, TicketDto, TicketNoteDto } from '@btl/order-bff';
import { CustomerService } from '@service/customer.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { TicketService } from '@service/ticket.service';
import { ModalFactoryService } from '@service/modal-factory.service';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PickerInputType } from '../../../form-field-modal-picker/form-field-modal-picker.component';
import { AbstractTicketComponent } from '../../tickets/abstract-ticket.component';
import {
  OpportunityDetailsComponent
} from '../../ecare-opportunities/opportunity-details/opportunity-details.component';

@Component({
  selector: 'app-contact-history-details',
  templateUrl: './contact-history-details.component.html',
  styleUrls: ['./contact-history-details.component.css'],
})
@EnableDynamicLoading({ customName: ContactHistoryDetailsComponent.PAGE_ID })
export class ContactHistoryDetailsComponent extends AbstractTicketComponent {
  public static readonly PAGE_ID = 'ContactHistoryDetailsComponent';
  public static readonly INTERACTION_TICKET_TYPE = 'CUSTOMER_CONTACT';

  pageId(): string {
    return ContactHistoryDetailsComponent.PAGE_ID;
  }

  navigationSubscription(navigation: NavigationEnd) {
    this.ticketingService
      .getTicketType('CUSTOMER_CONTACT')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(ticketType => {
        this.ticketType = ticketType;

        this.ticketType.parameters.forEach(param => {
          if (param.paramMetas.find(meta => meta.name === 'guiVisible' && meta.value === 'true')) {
            this.visibleParameters.set(param.name, param);
          }
          this.allParameters.set(param.name, param);
        });

        this.initRelatedTicketsByCode();

        if (this.isValidUrlByPattern()) {
          const ticketId = this.params.id;
          if (ticketId && ticketId !== 'newTicket') {
            this.loadTicket(ticketId);
          } else {
            this.newTicket = true;
            this.editMode = true;
            this.authService.getUserInfo().then(userInfo => {
              this.getNewInteraction(userInfo);
              this.reloadTicket(this.ticket);
            });
          }
        } else {
          this.ticket = undefined;
        }
      });
  }

  contactParamNames = ContactParamNames;
  editedNote: TicketNoteDto;
  customerAccountId = null;
  channels;
  directions;
  noteEmptyError;
  subjectEmptyError;

  form: FormGroup = this.formBuilder.group({
    subject: [null, Validators.required],
    assignedTo: [null],
    startFrom: [null],
    slaDueDate: [null],
    description: [],
    attachments: [],
    cuRefNo: [null, Validators.required],
    caRefNo: [null],
  });

  contactTypesCodebooks: CodebookDto[];
  contactTypes = [];
  areas = [];
  subAreas = [];
  listingComponentName: string = 'EcareContactHistoryComponent';
  supportEditMode = true;
  editMode = false;

  constructor(
    protected router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    protected appBlockerService: AppBlockerService,
    protected ticketingService: TicketingService,
    protected customerService: CustomerService,
    protected dmsService: DmsService,
    protected modalFactory: ModalFactoryService,
    protected customerLocalStorageService: CustomerLocalStorageService,
    public codebookService: CodebookService,
    private authFactoryService: AuthFactoryService,
    protected route: ActivatedRoute,
    protected ngbModal: NgbModal,
    protected stickyMessageService: StickyMessageService
  ) {
    super(router, route, ngbModal, appBlockerService, ticketingService, stickyMessageService, modalFactory, customerService, customerLocalStorageService, dmsService);
    this.authService = authFactoryService.getAuthService();

    this.codebookService
      .getCodebooks('TICKET_CONTACT_CHANNEL')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(cb => {
        this.channels = cb;
      });

    this.codebookService
      .getCodebooks('TICKET_CONTACT_DIRECTION')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(cb => {
        this.directions = cb;
      });

    this.codebookService
      .getCodebooks('TICKET_CONTACT_TYPE')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(cb => {
        this.contactTypesCodebooks = cb;
        this.contactTypes = this.contactTypesCodebooks.filter(codebook => !codebook.parentId);
      });
  }

  getNewInteraction(userInfo: UserInfo) {
    const references = [];
    if (!this.cuRefNo && this.customerLocalStorageService.getCurrentCustomer()) {
      references.push({
        refType: 'GENERAL',
        entityType: this.customerLocalStorageService.getCurrentCustomerAccount()
          ? 'com.emeldi.ecc.be.crm.dto.CustomerAccount'
          : 'com.emeldi.ecc.be.crm.dto.Customer',
        entityId: this.customerLocalStorageService.getCurrentCustomerAccount()
          ? this.customerLocalStorageService.getCurrentCustomerAccount()?.id
          : this.customerLocalStorageService.getCurrentCustomer()?.id,
      });
    }

    this.ticket = {
      id: null,
      type: {
        code: 'CUSTOMER_CONTACT',
        areaType: null,
      },
      subject: null,
      description: null,
      businessState: 'NOTE',
      assignmentState: userInfo ? 'ASSIGNED_TO_SOLVER' : 'NOT_ASSIGNED',
      assignedTo: userInfo ? userInfo.username : null,
      parameters: [
        {
          name: ContactParamNames.CONTACT_CHANNEL,
          value: this.channels?.length > 0 ? this.channels[0].code : null,
        },
        {
          name: ContactParamNames.CONTACT_DIRECTION,
          value: this.directions?.length > 0 ? this.directions[0].code : null,
        },
        {
          name: ContactParamNames.CONTACT_TYPE,
          value: this.contactTypes?.length > 0 ? this.contactTypes[0].code : null,
        },
      ],
      attachments: [],
      notes: [],
      references: references,
    };
    this.addQueryParametersConfiguration(this.ticket);
    this.setContactsAndAddresses();
  }

  loadTicket(ticketId) {
    this.ticketingService
      .getTicketById(ticketId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (this.relatedTicketId) {
          this.saveRelatedTicketReturn(result);
        } else {
          this.reloadTicket(result);
        }
      });
  }

  reloadTicket(ticketDto: TicketDto) {
    this.ticket = ticketDto;
    this.form.patchValue(this.ticket);
    this.editedNote = null;
    this.customer = null;
    this.relatedTasks = [];

    this.getCuAndCa();
    this.loadRelatedTasks();

    this.contactTypeChanged(TicketService.getParamValue(this.ticket, ContactParamNames.CONTACT_TYPE), null, null);
    this.areaChanged(TicketService.getParamValue(this.ticket, ContactParamNames.TYPE_AREA), null);
  }

  getParam(params, paramName) {
    let param = params.find(findParam => findParam.name === paramName);
    if (!param) {
      param = { name: paramName, value: null };
      params.push(param);
    }
    if (paramName === ContactParamNames.CONTACT_CHANNEL && !param.value && this.channels.length > 0) {
      param.value = this.channels[0].code;
    }
    if (paramName === ContactParamNames.CONTACT_DIRECTION && !param.value && this.directions.length > 0) {
      param.value = this.directions[0].code;
    }
    if (paramName === ContactParamNames.CONTACT_TYPE && !param.value && this.contactTypes.length > 0) {
      param.value = this.contactTypes[0].code;
      this.contactTypeChanged(this.contactTypes[0].code, null, null);
    }
    if (paramName === ContactParamNames.TYPE_AREA && !param.value && this.areas.length > 0) {
      param.value = this.areas[0].code;
      this.areaChanged(this.areas[0].code, null);
    }
    if (paramName === ContactParamNames.TYPE_SUBAREA && !param.value && this.subAreas.length > 0) {
      param.value = this.subAreas[0].code;
    }

    return param;
  }

  fieldChanged(field, event) {
    if (
      [
        ContactParamNames.CONTACT_CHANNEL,
        ContactParamNames.CONTACT_DIRECTION,
        ContactParamNames.CONTACT_TYPE,
        ContactParamNames.TYPE_SUBAREA,
        ContactParamNames.TYPE_AREA,
        ContactParamNames.REMINDER,
        TicketService.PREFERRED_CONTACT_PARAM_NAME,
        TicketService.PREFERRED_ADDRESS_PARAM_NAME,
      ].indexOf(field) > -1
    ) {
      const param = this.getParam(this.ticket.parameters, field);
      this.getParam(this.ticket.parameters, field).value = event;
    } else {
      this.ticket[field] = event;
    }

    if (field === 'Customer') {
      TicketService.replaceReferenceByType(this.ticket, 'com.emeldi.ecc.be.crm.dto.Customer', event?.id);
      this.form.controls.caRefNo.patchValue(null);
      TicketService.replaceReferenceByType(this.ticket, 'com.emeldi.ecc.be.crm.dto.CustomerAccount', null);
      this.customerChanged(event);
    }

    if (field === 'CustomerAccount') {
      TicketService.replaceReferenceByType(this.ticket, 'com.emeldi.ecc.be.crm.dto.CustomerAccount', event?.id);
    }

    if (field === 'subject') {
      this.checkSubjectEmpty();
    }

    this.updateIfNotEdit(field);
  }

  checkSubjectEmpty(): boolean {
    if (!this.ticket.subject || this.ticket.subject === '') {
      this.subjectEmptyError = true;
    } else {
      this.subjectEmptyError = false;
    }
    return this.subjectEmptyError;
  }

  saveChanges(skipFormValidation = false) {
    if (!skipFormValidation) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return;
      }
    }

    this.ticket.startFrom = this.form.value.startFrom;
    this.ticket.slaDueDate = this.form.value.slaDueDate;
    this.ticket.assignedTo = this.form.value.assignedTo;
    this.prepareTicketAttachmentsToSave();
    if (this.checkSubjectEmpty()) {
      return;
    } else {
      this.ticket.notes.forEach(note => {
        this.checkNotEmptyNote(note.note);
        note.created = null;
        note.createdBy = null;
        note.modified = null;
        note.modifiedBy = null;
      });

      if (!this.noteEmptyError) {
        if (this.ticket.recordVersion) {
          this.appBlockerService.block();
          this.saveTicket();
        } else {
          this.ticketingService
            .createTicket(this.ticket)
            .pipe(finalize(this.appBlockerService.unblock))
            .subscribe(result => this.saveFiles(result));
        }
      }
    }
  }

  checkNotEmpty(value: any) {
    return !value || value === '';
  }

  checkNotEmptySubject(value: any) {
    this.subjectEmptyError = this.checkNotEmpty(value);
  }

  checkNotEmptyNote(value: any) {
    this.noteEmptyError = this.checkNotEmpty(value);
  }

  contactTypeChanged($event: any, areaSelect, subAreaSelect) {
    if (this.contactTypesCodebooks) {
      const cb = this.contactTypesCodebooks.find(codebook => codebook.code === $event);
      this.areas.length = 0;
      if (cb) {
        this.contactTypesCodebooks
          .filter(codebook => codebook.parentId === cb.id)
          .forEach(area => {
            this.areas.push(area);
          });
      }
      if (areaSelect) {
        const areaCode = this.areas[0]?.code;
        this.getParam(this.ticket.parameters, ContactParamNames.TYPE_AREA).value = areaCode;
        this.areaChanged(areaCode, subAreaSelect);
      }
    }
  }

  areaChanged($event: any, subAreaSelect) {
    const cb = this.contactTypesCodebooks?.find(codebook => codebook?.code === $event);
    this.subAreas.length = 0;
    if (cb) {
      this.contactTypesCodebooks
        .filter(codebook => codebook.parentId === cb.id)
        .forEach(subArea => this.subAreas.push(subArea));
    }
    if (this.subAreas.length > 0 && subAreaSelect) {
      this.getParam(this.ticket.parameters, ContactParamNames.TYPE_SUBAREA).value = this.subAreas[0].code;
    } else {
      this.getParam(this.ticket.parameters, ContactParamNames.TYPE_SUBAREA).value = null;
    }
  }

  tasksDeleted() {
    this.reloadTicket(this.ticket);
  }

  protected readonly PickerInputType = PickerInputType;

  protected readonly TicketService = TicketService;
  protected readonly OpportunityDetailsComponent = OpportunityDetailsComponent;
}

export enum ContactParamNames {
  CONTACT_CHANNEL = 'CONTACT_CHANNEL',
  CONTACT_DIRECTION = 'CONTACT_DIRECTION',
  CONTACT_TYPE = 'CONTACT_TYPE',
  TYPE_AREA = 'TYPE_AREA',
  TYPE_SUBAREA = 'TYPE_SUBAREA',
  REMINDER = 'REMINDER',
}
