<form *ngIf="this.configuration?.columns" [formGroup]="form" class="configurable-form">
  <table>
    <thead>
      <tr>
        <th *ngFor="let column of configuration.columns">{{ column.labelKey ? (column.labelKey | translate) : column.label }}</th>
      </tr>
    </thead>
    <tbody formArrayName="rows">
      <tr *ngFor="let row of rows.controls; let i = index" [formGroupName]="i">
        <td *ngFor="let column of configuration.columns" [ngClass]="column.classes">
          <ng-container [ngSwitch]="column.type">
            <!-- Display as Label -->
            <span *ngSwitchCase="'label'">{{ row.get(column.name)?.value }}</span>

            <!-- Text Input -->
            <input *ngSwitchCase="'text'" formControlName="{{ column.name }}" type="text" [readonly]="column.name === 'service'">

            <!-- Number Input -->
            <input *ngSwitchCase="'number'" formControlName="{{ column.name }}" type="number">

            <!-- Dropdown (for options) -->
            <select *ngSwitchCase="'dropdown'" formControlName="{{ column.name }}">
              <option *ngFor="let option of column.options" [value]="option">{{ option }}</option>
            </select>

            <!-- Display calculated fields -->
            <span *ngSwitchCase="'calculated'">{{ row.get(column.name)?.value }}</span>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</form>
