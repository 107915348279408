<form *ngIf="formGenerated" class="generated-form" [formGroup]="form">
  <div *ngIf="customer && showCustomerData" class="primaryContact-box">
    <div class="primaryContact-box_leftSide">
      <div class="icon-container">
        <i [inlineSVG]="'assets/svg/login-benefits/manage-account.svg'"></i>
      </div>
      <div class="primaryContact-box_text">
        <div class="fullName">{{ customer.contact.firstName }} {{ customer.contact.lastName }}</div>
        <div>
          {{ customer.contact.email }}
        </div>
      </div>
    </div>
    <i
      placement="bottom"
      [ngbTooltip]="'wc.shopping.form.primaryContact.info.tooltip' | translate"
      [inlineSVG]="'assets/svg/awesome-info-circle.svg'"></i>
  </div>
  <ng-container *ngFor="let formAttribute of formAttributes">
    <div
      *ngIf="formAttribute.metaParameters['isInternal'] === 'true'"
      app-acl-internal-evaluate-component
      [ngClass]="generateColumn(formAttribute.name)"
      [hidden]="formAttribute.metaParameters['guiVisible'] !== 'true' || hide">
      <ng-template [ngTemplateOutlet]="entityGeneratedFormInput"></ng-template>
    </div>

    <div
      *ngIf="formAttribute.metaParameters['isInternal'] !== 'true' && !generatePrimaryContact(formAttribute.name)"
      [ngClass]="generateColumn(formAttribute.name)"
      [hidden]="formAttribute.metaParameters['guiVisible'] !== 'true' || hide || generatePrimaryContact(formAttribute.name)">
      <ng-template [ngTemplateOutlet]="entityGeneratedFormInput"></ng-template>
    </div>
    <ng-template #entityGeneratedFormInput>
      <app-entity-generated-form-input
        translationPrefix="wc.shopping.productAttributes"
        [name]="formAttribute.name"
        [label]="getAttributeLabel(formAttribute)"
        [metaParameters]="formAttribute.metaParameters"
        [form]="formAttribute.nativeAttribute ? form : getParametersForm()"
        [type]="formAttribute.type"
        [typeDetail]="formAttribute.typeDetail"
        [formIdPrefix]="formIdPrefix"
        [attributeOnChangeEvent]="attributeOnChangeEvent[formAttribute.name]"
        [externalValues]="externalValues[formAttribute.name]"
        [externalDefaultValue]="defaultValues[formAttribute.name]"
        [additionalValidators]="additionalValidators[formAttribute.name]"
        [additionalAsyncValidators]="additionalAsyncValidators[formAttribute.name]"
        [defaultValue]="formAttribute.defaultValue"
        [applyDefaultValues]="applyDefaultValues"
        [checkFormVisibility]="checkFormVisibility"
        [validationRegex]="formAttribute.validationRegex"
        [hide]="hide"
        [sourceName]="sourceName"
        [validate]="validate"
        [getAssignmentStateFromMinDate]="getAssignmentStateFromMinDate"
        [getAssignmentStateFromMaxDate]="getAssignmentStateFromMaxDate"/>
    </ng-template>
  </ng-container>
</form>
