<ng-container *ngIf="subscriptions.length > 0; else banner">
  <div class="widget--header">
    <div class="widget--header_title">
      <i [inlineSVG]="'/assets/svg/dashboard-widgets/mobile-tariff.svg'"></i>
      <h6>{{ 'wc.ecare.widgets.mobileTariff.header' | translate }}</h6>
    </div>

    <app-subscription-select-widget
      [subscription]="subscription"
      [subscriptionsToSelect]="subscriptionsToSelect"
      [tariffIdentification]="tariffIdentification"
      (subscriptionChanged)="changeSelectedTariff($event)">
    </app-subscription-select-widget>
  </div>
  <div class="widget--content content-in-row">
    <app-ecare-widget-main-info [label1]="customName" [label2]="tariffProduct.name" [price]="tariffProductPrice">
      <div class="details-wraper">
        <div class="content-in-row">
          <app-acl-internal-evaluate-component class="padding-5">
            <app-acl-evaluate privilege="DEACTIVATION_B2C_SUBS" class="btn-no-padding">
              <button
                *ngIf="subscription?.assets[0]['state'] !== 'DEACTIVATED'"
                id="008_subscription_detail"
                class="btn -btn-tariff-restriction"
                tooltipClass="ecare-settings-tooltip"
                placement="bottom"
                ngbTooltip="{{ 'wc.ecare.dashboard.tariffRestriction.label' | translate }}"
                (click)="restrictTariff()">
                <img src="../../../../assets/img/ecare/ant-design_stop-outlined.svg" />
              </button>
            </app-acl-evaluate>
          </app-acl-internal-evaluate-component>
          <app-acl-internal-evaluate-component class="padding-5">
            <app-acl-evaluate privilege="DEACTIVATION_B2C_SUBS" class="btn-no-padding">
              <button
                *ngIf="subscription?.assets[0]['state'] !== 'DEACTIVATED'"
                id="007_subscription_detail"
                class="btn -btn-tariff-deactivation"
                tooltipClass="ecare-settings-tooltip"
                placement="bottom"
                ngbTooltip="{{ 'wc.ecare.dashboard.tariffDeactivation.label' | translate }}"
                (click)="deactivateTariff()">
                <img src="../../../../assets/img/ecare/ionic-ios-power.svg" />
              </button>
            </app-acl-evaluate>
          </app-acl-internal-evaluate-component>
        </div>
      </div>

    </app-ecare-widget-main-info>

    <div class="details-wraper fullHeight">
      <ng-container *ngFor="let widgetElementInfo of widgetElementsInfos | keyvalue">
        <div class="detail-box">
          <div class="detail-box--label">
            {{ 'wc.ecare.widgets.' + widgetElementInfo.value.type + '.label' | translate }}
          </div>
          <div class="detail-box--icon">
            <i [inlineSVG]="'/assets/svg/dashboard-widgets/' + widgetElementInfo.value.type.toLowerCase() + '.svg'"></i>
          </div>
          <div class="detail-box--value">
            <ng-container *ngIf="widgetElementInfo.value.limit > 0">
              {{ widgetElementInfo.value.limit }}
              <ng-container *ngIf="codebookService.getCodebookTexts('FUOM_UNITS') | async as codebook">
                {{ codebook[widgetElementInfo.value.limitUnit] }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="widgetElementInfo.value.limit < 0">
              <span class="infinity">∞</span>
            </ng-container>
            <div *ngIf="widgetElementInfo.value.limit === 0" class="content-in-row">
              <div class="price" [innerHTML]="widgetElementInfo.value.priceOnTop | price"></div>
              {{ 'wc.ecare.widgets.unit.' + widgetElementInfo.value.limitUnit | translate }}
            </div>
          </div>
        </div>
      </ng-container>
      <div
        *ngIf="isPostpaid()"
        app-button
        class="change-tariff-btn"
        routerLink="/eshop/tariff-change"
        [queryParams]="{
          tariffSpaceId: subscription.id,
          tariffId: asset.id,
          customerAccountId: customerAccount?.id ? customerAccount.id : subscription.parentId,
          locationId: locationId,
          socketId: socketId
        }"
        [variant]="'primary'"
        [size]="'fullWidth'"
        [label]="'wc.ecare.dashboard.tariffChange.label' | translate"></div>
    </div>
  </div>
</ng-container>
<ng-template #banner>
  <div class="full-height" app-cms-widget-banner [bannerTargeter]="bannerTargetArea"></div>
</ng-template>
<app-button
  class="remove-widget"
  iconPath="/assets/img/ecare/feather-minus.svg"
  variant="whiteWithBorder"
  placement="top"
  ngbTooltip="Remove widget">
</app-button>
