import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MetaEntityAttributeDto } from '@btl/order-bff';
import { CodebookService, CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-parameter-data, [app-parameter-data]',
  templateUrl: './parameter-data.component.html',
})
export class ParameterDataComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }


  @Input() parameterValue: string;
  @Input() metaEntityAttributeDto: MetaEntityAttributeDto= null;
  @Input() onlyValues = false;

  label = null;
  parameterName: string;

  constructor(private currentLocaleService: CurrentLocaleService, public codebookService: CodebookService) {
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.label = this.getAttributeLabel(this.metaEntityAttributeDto);
    });
  }

  ngOnInit() {
    this.parameterName = this.metaEntityAttributeDto.name;
    this.label = this.getAttributeLabel(this.metaEntityAttributeDto);
  }

  getAttributeLabel(attribute: MetaEntityAttributeDto): string {
    const ret = attribute.localizedTexts?.find(text => text.locale === this.currentLocaleService.getCurrentLanguage())?.message;
    return ret ? ret : attribute.name;
  }
}
