import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-delivery-address-form',
  templateUrl: './delivery-address-form.component.html',
  styleUrls: ['./delivery-address-form.component.scss'],
})
export class DeliveryAddressFormComponent implements OnInit {
  @Input() parentGroup: FormGroup;
  @Input() addressType: string;

  deliveryAddressForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (this.parentGroup) {
      this.deliveryAddressForm = this.fb.group({
        type: [this.addressType ? this.addressType : null],
        city: [null],
        street: [null],
        streetNumber: [null],
        zipCode: [null],
        district: [null],
        country: [environment.country],
        id: null,
      });
      this.parentGroup.setControl('deliveryAddress', this.deliveryAddressForm);
    }
  }
}
