/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AclService,
  AppBlockerService,
  AuthFactoryService,
  CodebookService,
  CompareType,
  CurrentLocaleService,
  DmsService,
  ElasticsearchService,
  EnableDynamicLoading,
  FormUtils,
  ProductService,
  QuoteService,
  ServiceUtils,
  StickyMessageService,
  TicketingService
} from '@btl/btl-fe-wc-common';
import { finalize, map, takeUntil } from 'rxjs/operators';
import {
  AccountDto,
  AddressDto,
  ContactDto,
  CustomerDto,
  DynamicEnumService,
  OrderDto,
  OrderParamDto,
  ProductDetailDto,
  ProductParamMetadataDto,
  RoleDto,
  SocketService,
  TicketDto,
  TicketNoteDto,
  TicketTypeParamDto
} from '@btl/order-bff';
import { CustomerService } from '@service/customer.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { forkJoin } from 'rxjs';
import { WcOrderingService } from '@service/wc-ordering.service';
import { from } from 'rxjs/internal/observable/from';
import { OrderUtils, ScenarioStepTypeEnum, ScenarioTypeEnum } from 'app/helpers/order-utils';
import { TicketService } from '@service/ticket.service';
import { Location } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalFactoryService } from '@service/modal-factory.service';
import { CustomerFormComponent } from '../../../page/customer-form/customer-form.component';
import { CustomerPartyUtil } from '../../../../helpers/customer-party.util';
import {
  FormAddressSearchInputComponent
} from '../../../form-address-search-input/form-address-search-input.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import {
  GenerateDocumentEntityType
} from '../../../entity-generate-document-modal/entity-generate-document-modal.component';
import { QuoteDetailsPopupComponent } from './quote-details-popup/quote-details-popup.component';
import { PickerInputType } from '../../../form-field-modal-picker/form-field-modal-picker.component';
import { environment } from '../../../../../environments/environment';
import {
  CreateCustomerPopupComponent
} from '../../ecare-homepage/create-customer-popup/create-customer-popup.component';
import { TicketEditComponent } from '../../tickets/edit/ticket-edit.component';
import { AbstractTicketComponent } from '../../tickets/abstract-ticket.component';
import {
  ContactHistoryDetailsComponent
} from '../../ecare-contact-history/contact-history-details/contact-history-details.component';
import { ProductUtils } from '../../../../helpers/product-utils';
import { PrecalculatedShoppingCart } from '../../../../models/precalculated-shopping-cart';
import { ProductInShoppingCart } from '../../../../models/product-in-shopping-cart';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;
import QuoteStateDtoEnum = OrderDto.QuoteStateDtoEnum;

@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
})
@EnableDynamicLoading({ customName: OpportunityDetailsComponent.PAGE_ID })
export class OpportunityDetailsComponent extends AbstractTicketComponent {
  public static readonly PAGE_ID = 'OpportunityDetailsComponent';
  pickerInputType = PickerInputType;

  formParameters: TicketTypeParamDto[] = [];
  quotesMainProducts = new Map<string, ProductDetailDto>();
  quotesMainProductsMetaParams = new Map<string, ProductParamMetadataDto[]>();

  pageId(): string {
    return OpportunityDetailsComponent.PAGE_ID;
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const ticketId = this.params.id;

      const isNewOpportunity = !ticketId || ticketId === 'newTicket';

      this.authService = this.authFactoryService.getAuthService();
      this.ticketingService
        .getTicketType(OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(ticketType => {
          this.ticketType = ticketType;

          this.ticketType.parameters.forEach(param => {
            if (param.paramMetas.find(meta => meta.name === 'guiVisible' && meta.value === 'true')) {
              this.visibleParameters.set(param.name, param);
            }
            if (
              param.paramMetas.find(
                metaParam => metaParam.name === 'guiElementType' && metaParam.value === 'configurableForm'
              )
            ) {
              this.formParameters.push(param);
            }
            this.allParameters.set(param.name, param);
          });

          this.initRelatedTicketsByCode();

          if (isNewOpportunity) {
            this.newTicket = true;
            this.editMode = true;

            if (this.duplicationId) {
              this.ticketService
                .getTicketForDuplication(this.duplicationId, this.forDuplication)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(ticket => this.reloadTicket(ticket, true));
            } else {
              const references = [];
              if (!this.cuRefNo && this.customerLocalStorageService.getCurrentCustomer()) {
                references.push({
                  refType: 'GENERAL',
                  entityType: 'com.emeldi.ecc.be.crm.dto.Customer',
                  entityId: this.customerLocalStorageService.getCurrentCustomer()?.id,
                });

                if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
                  references.push({
                    refType: 'GENERAL',
                    entityType: 'com.emeldi.ecc.be.crm.dto.CustomerAccount',
                    entityId: this.customerLocalStorageService.getCurrentCustomerAccount()?.id,
                  });
                }
              }

              this.ticket = {
                id: null,
                type: {
                  code: OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE,
                },
                subject: null,
                description: null,
                businessState: 'NOTE',
                assignmentState: 'NOT_ASSIGNED',
                parameters: [],
                attachments: [],
                notes: [],
                references: references,
                priority: this.ticketType?.defaultPriority,
              };
              this.addQueryParametersConfiguration(this.ticket);
              this.setContactsAndAddresses();
              this.reloadTicket(this.ticket, true);
            }
          } else {
            this.loadTicket(ticketId, true);
          }
        });
    } else {
      this.ticket = undefined;
    }
  }

  public static readonly OPPORTUNITY_TICKET_TYPE = 'OPPORTUNITY';

  private readonly opportunityTicketParams: Set<string> = new Set<string>([
    TicketService.OPPORTUNITY_TYPE_PARAM_NAME,
    TicketService.OPPORTUNITY_STATE_PARAM_NAME,
    TicketService.OPPORTUNITY_PROBABILITY_PARAM_NAME,
    TicketService.EXPECTED_REVENUE_PARAM_NAME,
    TicketService.CURRENT_OPERATOR_PARAM_NAME,
    TicketService.REJECT_REASON_PARAM_NAME,
    TicketService.OPPORTUNITY_CUSTOMER_PARAM_NAME,
    TicketService.OPPORTUNITY_CONTACT_PARAM_NAME,
    TicketService.PREFERRED_CONTACT_PARAM_NAME,
    TicketService.TEAM_ASSIGNMENT_PARAM_NAME,
  ]);

  @ViewChild('formAddressSearchInputComponent', { static: true })
  formAddressSearchInputComponent: FormAddressSearchInputComponent;

  @ViewChild('customerForm', { static: false })
  customerFormComponent: CustomerFormComponent;

  quoteStateDtoEnum = QuoteStateDtoEnum;
  listingComponentName: string = 'EcareOpportunitiesComponent';
  supportEditMode = true;
  editedNote: TicketNoteDto;
  relatedOrders: Array<OrderDto> = [];
  relatedQuotes: Array<OrderDto> = [];
  form: FormGroup = this.formBuilder.group({
    subject: [null, Validators.required],
    opportunityType: [null, Validators.required],
    status: [null, Validators.required],
    assignedTo: [null],
    salesTeam: [null],
    slaDueDate: [null],
    probability: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
    priority: [null, Validators.required],
    expectedRevenue: [null],
    currentOperator: [null],
    description: [null, Validators.required],
    attachments: [],
    cuRefNo: [],
    caRefNo: [],
    address: [],
  });

  salesTeamRoles: RoleDto[];
  userSalesTeamRoles: RoleDto[] = [];
  selectedContactIndex = -1;
  phoneNumber = null;

  ticketEntityType = [];
  showAddressForm = false;
  editMode = false;

  constructor(
    protected router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    protected modalFactory: ModalFactoryService,
    private ngbModal: NgbModal,
    protected appBlockerService: AppBlockerService,
    protected ticketingService: TicketingService,
    private ticketService: TicketService,
    protected customerService: CustomerService,
    public codebookService: CodebookService,
    private aclService: AclService,
    protected dmsService: DmsService,
    private authFactoryService: AuthFactoryService,
    private orderingService: WcOrderingService,
    private quoteService: QuoteService,
    protected stickyMessageService: StickyMessageService,
    public currentLocaleService: CurrentLocaleService,
    protected customerLocalStorageService: CustomerLocalStorageService,
    protected route: ActivatedRoute,
    private propertyAccessorService: PropertyAccessorLocalService,
    private elasticSearchService: ElasticsearchService,
    private dynamicEnumService: DynamicEnumService,
    private productService: ProductService,
    private socketService: SocketService
  ) {
    super(
      router,
      route,
      ngbModal,
      appBlockerService,
      ticketingService,
      stickyMessageService,
      modalFactory,
      customerService,
      customerLocalStorageService,
      dmsService
    );
    this.dynamicEnumService
      .getEnumEntries(TicketEditComponent.SOURCE_NAME, TicketEditComponent.TICKET_EXTERNAL_ENTITY_TYPE_ENUM_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.ticketEntityType = result.data.map(value => value.name).sort();
        this.showAddressForm =
          this.ticketEntityType.filter(entityType => entityType === 'com.emeldi.ecc.be.address.dto.Address').length > 0;
      });
  }

  loadTicket(ticketId, buildForm = false) {
    this.ticketingService
      .getTicketById(ticketId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (this.relatedTicketId) {
          this.saveRelatedTicketReturn(result);
        } else if (this.duplicationId) {
          result.id = null;
          result.recordVersion = null;

          TicketService.clearFieldsBeforeUpdate(result, true);
          this.reloadTicket(result, buildForm);
        } else {
          this.reloadTicket(result, buildForm);
        }
      });
  }

  reloadTicket(ticketDto: TicketDto, buildForm = false) {
    this.ticket = ticketDto;
    if (buildForm) {
      this.buildForm();
    } else {
      this.patchFormWithTicketParams();
    }
    this.form.patchValue(this.ticket);

    this.editedNote = null;
    this.customer = null;
    this.relatedTasks = [];
    this.relatedOrders = [];
    this.relatedQuotes = [];

    this.loadCustomer();
    this.loadRelatedTasks();
    this.loadRelatedOrdersAndQuotes();
    this.addAddressToForm();
  }

  addAddressToForm() {
    const address = TicketService.getReferenceByType(this.ticket, 'com.emeldi.ecc.be.address.dto.Address');
    if (address) {
      const properties: Map<string, any> = new Map<string, any>();
      properties.set('_id', address.entityId);
      this.elasticSearchService
        .query(ElasticsearchService.ADDRESS_INDEX, null, null, properties, null, 1, 1)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(map(oph => oph.hits.hits))
        .subscribe(addresses => {
          if (addresses && addresses.length > 0) {
            this.form.get('address').patchValue(addresses[0]?._source);
          }
        });
    }
  }

  getOpportunityParamValue(paramName: string, defaultValue: string = ''): string {
    const opportunityParam = this.ticket?.parameters.find(param => param.name === paramName);
    return opportunityParam ? opportunityParam.value : defaultValue;
  }

  getOrderAttributeValue(order: OrderDto, attrName: string): string {
    return order.orderAttributes.find(findParam => findParam.attr === attrName)?.value;
  }

  getQuoteLabel(quote: OrderDto): string {
    return quote.orderItems
      ?.filter(oi => oi.parentOrderItemId === null)
      .map(oi => (this.quotesMainProducts.has(oi.productId) ? this.quotesMainProducts.get(oi.productId)['name'] : null))
      .join(', ');
  }

  fieldChanged(fieldName: string, value: any) {
    if (this.opportunityTicketParams.has(fieldName)) {
      this.setOpportunityTicketParameter(fieldName, value);
    } else {
      this.ticket[fieldName] = value;
    }
    this.updateIfNotEdit(fieldName);
  }

  saveChanges(skipFormValidation = false) {
    if (!skipFormValidation) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return;
      }
    }

    this.prepareTicketAttachmentsToSave();
    this.ticket.subject = this.getFormControlValue('subject');
    this.ticket.assignedTo = this.getFormControlValue('assignedTo');
    this.ticket.description = this.getFormControlValue('description');
    this.ticket.priority = this.getFormControlValue('priority');
    this.ticket.slaDueDate = this.getFormControlValue('slaDueDate');
    this.setOpportunityTicketParameter(TicketService.TEAM_ASSIGNMENT_PARAM_NAME, this.getFormControlValue('salesTeam'));
    this.setOpportunityTicketParameter(
      TicketService.OPPORTUNITY_TYPE_PARAM_NAME,
      this.getFormControlValue('opportunityType')
    );
    this.setOpportunityTicketParameter(TicketService.OPPORTUNITY_STATE_PARAM_NAME, this.getFormControlValue('status'));
    this.setOpportunityTicketParameter(
      TicketService.OPPORTUNITY_PROBABILITY_PARAM_NAME,
      this.getFormControlValue('probability')
    );
    this.setOpportunityTicketParameter(
      TicketService.EXPECTED_REVENUE_PARAM_NAME,
      this.getFormControlValue('expectedRevenue')
    );
    this.setOpportunityTicketParameter(
      TicketService.CURRENT_OPERATOR_PARAM_NAME,
      this.getFormControlValue('currentOperator')
    );
    this.setOpportunityTicketParameter(TicketService.VISITED_PAGE_URL_PARAM_NAME, null);

    this.formParameters.forEach(param => {
      return this.setOpportunityTicketParameter(param.name, JSON.stringify(this.getFormControlValue(param.name)));
    });

    const address = this.getFormControlValue('address');
    this.ticket.references = this.ticket.references.filter(
      ref => ref.entityType !== 'com.emeldi.ecc.be.address.dto.Address'
    );
    if (address) {
      this.ticket = TicketService.addReference(this.ticket, 'com.emeldi.ecc.be.address.dto.Address', address.adrRefNo);
    }
    if (this.newTicket) {
      this.setOpportunityTicketParameter(TicketService.REJECT_REASON_PARAM_NAME, null);
      this.setOpportunityTicketParameter(TicketService.OPPORTUNITY_CUSTOMER_PARAM_NAME, null);
    }
    TicketService.replaceReferenceByType(
      this.ticket,
      'com.emeldi.ecc.be.crm.dto.Customer',
      this.getFormControlValue('cuRefNo')?.id
    );
    TicketService.replaceReferenceByType(
      this.ticket,
      'com.emeldi.ecc.be.crm.dto.CustomerAccount',
      this.getFormControlValue('caRefNo')?.id
    );

    if (this.selectedContactIndex < 0 && this.form.get('newContact')) {
      const newContact = (this.form.get('newContact') as FormGroup).getRawValue();
      this.setPreferredContact(newContact);
      this.setOpportunityTicketParameter(TicketService.OPPORTUNITY_CONTACT_PARAM_NAME, JSON.stringify(newContact));
    } else {
      this.setOpportunityTicketParameter(TicketService.OPPORTUNITY_CONTACT_PARAM_NAME, null);
    }

    this.ticket.notes.forEach(note => {
      note.created = null;
      note.createdBy = null;
      note.modified = null;
      note.modifiedBy = null;
    });
    if (this.ticket.recordVersion) {
      this.appBlockerService.block();
      this.saveTicket();
    } else {
      this.ticketingService
        .createTicket(this.ticket)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => this.saveFiles(result));
    }
  }

  quoteToOrder(quote: OrderDto) {
    this.quoteService
      .quoteToOrder(quote.id, this.ticket.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.ticket = TicketService.addReference(this.ticket, 'com.emeldi.ecc.be.order.dto.Order', order.id);

        this.saveTicket();

        this.relatedOrders.push(order);
        this.orderingService.getOrderByRefNo(order.id).subscribe(order => {
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.opportunities.quoteToOrder.success');
        });
      });
  }

  private setOpportunityTicketParameter(paramName: string, paramValue: string = null) {
    const existingParam = this.ticket.parameters.find(param => param.name === paramName);
    if (existingParam) {
      existingParam.value = paramValue;
    } else {
      this.ticket.parameters.push({ name: paramName, value: paramValue });
    }
  }

  private loadSalesTeamRoles() {
    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push({
      column: 'parameters',
      compareType: null,
      value: [{ name: 'isSalesRole', value: true }],
    });
    this.aclService
      .filterRoles(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(roles => {
        this.salesTeamRoles = roles.data;
        const responseRolesIds = roles.data.map(role => role.id);
        const userSalesRole = this.authService.getUserRoles()?.find(role => responseRolesIds.includes(role));
        if (this.newTicket && userSalesRole) {
          this.form.get('salesTeam')?.setValue(userSalesRole);
        }
      });
  }

  private getFormControlValue(controlName: string): any {
    return this.form.controls[controlName].value;
  }

  private loadRelatedOrdersAndQuotes() {
    const relatedOrdersIds = this.ticket.references
      .filter(reference => reference.entityType === 'com.emeldi.ecc.be.order.dto.Order')
      .map(ref => ref.entityId);
    if (relatedOrdersIds?.length) {
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({
        column: 'id',
        compareType: CompareTypeDtoEnum.IN,
        value: relatedOrdersIds,
      });
      // todo: use getOrdersByFilter (BFF API must be changed)

      const relatedOrdersObservables = relatedOrdersIds.map(orderId => this.orderingService.searchOrders(orderId));
      forkJoin(relatedOrdersObservables)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(orders => {
          const preCalculatedShoppingCarts = [];
          orders.forEach(order => {
            if (order.orderItems.length > 0) {
              if (order.orderType === 'SALES') {
                this.relatedOrders.push(order);
              } else if (order.orderType === 'QUOTE') {
                const preCalculatedShoppingCart = new PrecalculatedShoppingCart(
                  order,
                  this.productService,
                  this.socketService
                );
                preCalculatedShoppingCarts.push(preCalculatedShoppingCart.loadProducts());
                order['preCalculatedShoppingCart'] = preCalculatedShoppingCart;
                this.relatedQuotes.push(order);
                order['description'] = this.getOrderAttributeValue(order, 'description');
                order['state'] = this.getQuoteState(order);
                order['stateIcon'] = this.getQuoteState(order, true);
                order['isExpired'] = this.isExpired(order);
              }
            }
          });

          forkJoin(preCalculatedShoppingCarts).subscribe(() => {
            this.relatedQuotes.forEach(quote => {
              quote['preCalculatedShoppingCart'].products.forEach((value: ProductInShoppingCart[], key: string) => {
                this.quotesMainProducts.set(key, value[0].productDetail);
              });
            });

            const productMetaParamsCalls = [];
            this.quotesMainProducts.forEach((value, key) => {
              this.quotesMainProductsMetaParams.set(value['categoryId'], null);
            });
            this.quotesMainProductsMetaParams.forEach((value, key) => {
              productMetaParamsCalls.push(this.productService.getProductParameterMetadata(key));
            });

            forkJoin(productMetaParamsCalls)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((categoriesMetaParams: ProductParamMetadataDto[][]) => {
                let i = 0;
                this.quotesMainProductsMetaParams.forEach((value, key) => {
                  this.quotesMainProductsMetaParams.set(key, categoriesMetaParams[i]);
                  i++;
                });

                this.relatedQuotes.forEach(quote => {
                  quote['label'] = this.getQuoteLabel(quote);
                  if (!quote['mainProducts']) {
                    quote['mainProducts'] = [];
                  }
                  quote.orderItems
                    ?.filter(oi => oi.parentOrderItemId === null)
                    .forEach(oi => {
                      const product = { ...this.quotesMainProducts.get(oi.productId) };
                      quote['mainProducts'].push(product);
                      this.quotesMainProductsMetaParams
                        .get(product['categoryId'])
                        .forEach((param: ProductParamMetadataDto) => {
                          if (param.metadata['guiVisibility'] === 'true' && param.metadata['quoteParam'] === 'true') {
                            let suffix = param.metadata['inputSuffix'] ? param.metadata['inputSuffix'] : '';
                            let prefix = param.metadata['inputPrefix'] ? param.metadata['inputPrefix'] : '';
                            prefix = ProductUtils.replaceCurrencyVariable(prefix);
                            suffix = ProductUtils.replaceCurrencyVariable(suffix);

                            if (!product['quoteParams']) {
                              product['quoteParams'] = {};
                            }
                            product['quoteParams'][param.name] = {
                              label: param.label,
                              value: prefix + oi.attributes.find(attr => attr.name === param.name)?.value + suffix,
                            };
                          }
                        });
                    });
                });
              });
          });
        });
    }
  }

  private loadCustomer() {
    const referenceCustomer = this.ticket.references.find(
      reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.Customer'
    );
    const paramCustomer = this.getOpportunityParamValue(TicketService.OPPORTUNITY_CUSTOMER_PARAM_NAME);
    // J.Faust confirmed that referenced Customer is preferred before Customer stored in ticket parameter
    if (referenceCustomer) {
      this.getCuAndCa();
    } else if (paramCustomer) {
      this.customer = JSON.parse(paramCustomer);
      this.checkNewContact();
    } else {
      this.checkNewContact();
    }

    if (!this.editMode) {
      this.form.disable();
    }
  }

  private buildForm() {
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(queryParams => {
      if (queryParams) {
        this.phoneNumber = queryParams['phoneNumber'];
      }

      this.form.controls.subject.setValue(this.ticket.subject);
      this.form.controls.description.patchValue(this.ticket.description);
      this.form.controls.priority.setValue(this.ticket.priority);
      this.form.controls.slaDueDate.setValue(this.ticket.slaDueDate);
      this.form.controls.assignedTo.setValue(this.ticket.assignedTo);

      this.form.addControl(
        'newContact',
        this.formBuilder.group({
          firstName: [null],
          lastName: [null],
          email: [null, Validators.email],
          phone1: [this.phoneNumber],
        })
      );
      this.form.controls.newContact.setValidators(this.phoneOrEmailRequires);

      if (this.ticketType) {
        this.form
          .get('priority')
          .setValidators([Validators.min(this.ticketType.minPriority), Validators.max(this.ticketType.maxPriority)]);
      }

      if (this.newTicket) {
        this.setAssignedToUser();
      }
      this.patchFormWithTicketParams();
      this.loadSalesTeamRoles();
      this.formParameters.forEach(param => {
        this.form.addControl(param.name, new FormControl(this.getOpportunityParamValue(param.name)));
      });
    });
  }

  phoneOrEmailRequires(formGroup: AbstractControl): any {
    const email = (formGroup as FormGroup).controls['email'].value;
    const phone1 = (formGroup as FormGroup).controls['phone1'].value;
    return !email && !phone1 ? { phoneOrEmailRequires: true } : null;
  }

  private patchFormWithTicketParams() {
    this.form
      .get('opportunityType')
      .setValue(this.getOpportunityParamValue(TicketService.OPPORTUNITY_TYPE_PARAM_NAME, null));
    this.form.get('status').setValue(this.getOpportunityParamValue(TicketService.OPPORTUNITY_STATE_PARAM_NAME, null));
    this.form
      .get('probability')
      .setValue(this.getOpportunityParamValue(TicketService.OPPORTUNITY_PROBABILITY_PARAM_NAME));
    this.form
      .get('expectedRevenue')
      .setValue(this.getOpportunityParamValue(TicketService.EXPECTED_REVENUE_PARAM_NAME, null));
    this.form.get('currentOperator').setValue(this.getOpportunityParamValue(TicketService.CURRENT_OPERATOR_PARAM_NAME));
    this.form.get('salesTeam').setValue(this.getOpportunityParamValue(TicketService.TEAM_ASSIGNMENT_PARAM_NAME, null));
  }

  addQuote() {
    const newOrder = OrderUtils.getInitOrder(
      ScenarioStepTypeEnum.SALES_PRODUCT_LISTING,
      ScenarioTypeEnum.QUOTE_MANAGEMENT
    );
    newOrder.orderType = 'QUOTE';
    newOrder.quoteState = QuoteStateDtoEnum.InProgress;
    OrderUtils.addAttributeToOrder(newOrder, 'quoteVersion', '1');
    OrderUtils.addAttributeToOrder(
      newOrder,
      'quoteValidFor',
      JSON.stringify({ startDateTime: null, endDateTime: null })
    );
    OrderUtils.addAttributeToOrder(newOrder, 'qEffectiveQuoteCompletionDate', null);
    if (!this.customer?.address?.id) {
      const address = this.form.controls.address.value;
      if (address) {
        this.customer.address = CustomerPartyUtil.getDefaultAddress(AddressDto.TypeDtoEnum.REGISTRATION);
        this.customer.address.city = address.city;
        this.customer.address.street = address.street;
        this.customer.address.streetNumber = address.streetNo;
        this.customer.address.buildingNumber = address.buildingNo;
        this.customer.address.zipCode = address.zip;
        this.customer.address.district = address.district;
        this.customer.address.country = address.countryCode;
      }
    }

    if (this.customer?.contact?.id) {
      let contactJson = TicketService.getParamValue(this.ticket, TicketService.PREFERRED_CONTACT_PARAM_NAME);
      if (!contactJson) {
        contactJson = TicketService.getParamValue(this.ticket, TicketService.OPPORTUNITY_CONTACT_PARAM_NAME);
      }

      const contact = JSON.parse(contactJson);
      this.customer.contact = CustomerPartyUtil.getDefaultContact(ContactDto.TypeDtoEnum.PRIMARY);
      this.customer.contact.firstName = contact.firstName;
      this.customer.contact.lastName = contact.lastName;
      this.customer.contact.email = contact.email;
      this.customer.contact.phone1 = contact.phone1;
    }
    OrderUtils.addCustomerAttributeToOrder(newOrder, this.customer);

    this.orderingService.createOrderObservable = null;
    this.orderingService.removeCurrentOrderFromContext();
    this.orderingService.createOrder(newOrder, false).subscribe(newOrder => {
      if (this.customer?.id) {
        this.customerLocalStorageService.setCurrentCustomer(this.customer, false);
      }
      this.router.navigateByUrl('/');
    });

    TicketService.setLocalOpportunity(this.ticket);
  }

  handleCurrentOperatorChange(operatorCode: string) {
    if (typeof operatorCode !== 'string') {
      this.form.get('currentOperator').setValue(null);
    }
  }

  private setAssignedToUser() {
    from(this.authService.getUserInfo()).subscribe(user => this.form.get('assignedTo').setValue(user.username));
  }

  continueQuote(quote: OrderDto) {
    if (this.isQuoteClickable(quote)) {
      TicketService.setLocalOpportunity(this.ticket);
      this.router.navigate([`/eshop/shopping-cart/id/${quote.id}`]);
    }
  }

  getDefaultEmailList() {
    let contact = null;
    let contactJson = this.getOpportunityParamValue(TicketService.PREFERRED_CONTACT_PARAM_NAME);
    if (contactJson) {
      contact = JSON.parse(contactJson);
      if (!contact || !contact.email) {
        contactJson = this.getOpportunityParamValue(TicketService.OPPORTUNITY_CONTACT_PARAM_NAME);
        if (contactJson) {
          contact = JSON.parse(contactJson);
        }
      }
    }

    return contact ? contact.email : null;
  }

  getOpportunityPriorities(): number[] {
    return this.ticketingService.getTicketPriorities(this.ticketType?.minPriority, this.ticketType?.maxPriority);
  }

  generateQuoteDocument(quote: OrderDto) {
    const formatTimestampPart = t => (t < 10 ? '0' : '') + t;

    const now = new Date();
    const timestamp =
      now.getFullYear() +
      formatTimestampPart(now.getMonth() + 1) +
      formatTimestampPart(now.getDate()) +
      formatTimestampPart(now.getHours()) +
      formatTimestampPart(now.getMinutes());

    const documentName = `Quote#${quote.id}_${timestamp}.pdf`;
    const locale = this.currentLocaleService.getCurrentLanguage();
    from(this.authService.getUserInfo()).subscribe(user => {
      this.modalFactory.entityGenerateDocumentModal(
        GenerateDocumentEntityType.QUOTE,
        documentName,
        this.quoteService.getGenerateQuoteDocumentUrl(locale, quote.id, user.username),
        (dialogRef, documentName) => this.attachQuoteDocument(quote, documentName, dialogRef)
      );
    });
  }

  attachQuoteDocument(quote: OrderDto, documentName: string, dialogRef: NgbModalRef) {
    from(this.authService.getUserInfo()).subscribe(user => {
      const locale = this.currentLocaleService.getCurrentLanguage();

      this.quoteService
        .attachQuoteDocument(documentName, quote.id, this.ticket.id, user.username, locale)
        .subscribe(updatedTicket => {
          this.reloadTicket(updatedTicket);
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.opportunities.documentAttached');
          dialogRef.close();
        });
    });
  }

  getQuoteState(quote: OrderDto, icon?: boolean): string {
    return OpportunityDetailsComponent.getQuoteState(
      quote.quoteState,
      this.getOrderAttributeValue(quote, 'quoteValidFor'),
      icon
    );
  }

  public static getQuoteState(quoteState: QuoteStateDtoEnum, quoteValidFor: string, icon?: boolean): string {
    const processingStates = [QuoteStateDtoEnum.InProgress, QuoteStateDtoEnum.Pending, QuoteStateDtoEnum.Approved];
    if (quoteState && processingStates.includes(quoteState)) {
      if (!quoteValidFor) {
        return 'processing';
      }

      const validFor = JSON.parse(quoteValidFor);
      if (!validFor['endDateTime']) {
        return 'processing';
      }

      if (OpportunityDetailsComponent.dateNotExpired(new Date(validFor.endDateTime))) {
        return 'processing';
      }

      if (icon) return 'cancelled';
    }

    return quoteState;
  }

  isExpired(quote: OrderDto) {
    const processingStates = [QuoteStateDtoEnum.InProgress, QuoteStateDtoEnum.Pending, QuoteStateDtoEnum.Approved];
    if (quote.quoteState && processingStates.includes(quote.quoteState)) {
      const quoteValidFor = this.getOrderAttributeValue(quote, 'quoteValidFor');
      if (!quoteValidFor) {
        return false;
      }

      const validFor = JSON.parse(quoteValidFor);
      if (!validFor['endDateTime']) {
        return false;
      }

      if (OpportunityDetailsComponent.dateNotExpired(new Date(validFor.endDateTime))) {
        return false;
      }

      return true;
    }
    return false;
  }

  public static dateNotExpired(date: Date) {
    const now = new Date();
    return (
      date.getFullYear() > now.getFullYear() ||
      (date.getFullYear() === now.getFullYear() && date.getMonth() > now.getMonth()) ||
      (date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() >= now.getDate())
    );
  }

  approveQuote(quote: OrderDto) {
    this.propertyAccessorService.getQuotesDefaultValidityPeriod().subscribe(defaultValidityPeriod => {
      const startDate = new Date();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + defaultValidityPeriod);

      quote.quoteState = QuoteStateDtoEnum.Approved;
      const orderParamsDto: Array<OrderParamDto> = [];
      OrderUtils.updateOrderAttr(
        orderParamsDto,
        'quoteValidFor',
        JSON.stringify({
          startDateTime: startDate,
          endDateTime: endDate,
        })
      );
      this.patchQuote(quote.id, {
        quoteState: quote.quoteState,
        orderAttributes: orderParamsDto,
        recordVersion: quote.recordVersion,
      });
    });
  }

  rejectQuote(quote: OrderDto) {
    quote.quoteState = QuoteStateDtoEnum.Rejected;
    const orderParamsDto: Array<OrderParamDto> = [];
    OrderUtils.updateOrderAttr(orderParamsDto, 'qEffectiveQuoteCompletionDate', new Date());
    this.patchQuote(quote.id, {
      quoteState: quote.quoteState,
      orderAttributes: orderParamsDto,
      recordVersion: quote.recordVersion,
    });
  }

  reviseQuote(quote: OrderDto) {
    const quoteVersionAttribute = this.getOrderAttributeValue(quote, 'quoteVersion');
    const quoteVersionValue = quoteVersionAttribute ? Number(quoteVersionAttribute) + 1 : 1;

    quote.quoteState = QuoteStateDtoEnum.InProgress;
    const orderParamsDto: Array<OrderParamDto> = [];
    OrderUtils.updateOrderAttr(orderParamsDto, 'quoteVersion', quoteVersionValue);
    OrderUtils.updateOrderAttr(
      orderParamsDto,
      'quoteValidFor',
      JSON.stringify({ startDateTime: null, endDateTime: null })
    );
    OrderUtils.updateOrderAttr(orderParamsDto, 'qEffectiveQuoteCompletionDate', null);
    this.patchQuote(quote.id, {
      quoteState: quote.quoteState,
      orderAttributes: orderParamsDto,
      recordVersion: quote.recordVersion,
    });
  }

  cancelQuote(quote: OrderDto) {
    quote.quoteState = QuoteStateDtoEnum.Cancelled;
    const orderParamsDto: Array<OrderParamDto> = [];
    OrderUtils.updateOrderAttr(orderParamsDto, 'qEffectiveQuoteCompletionDate', new Date());
    this.patchQuote(quote.id, {
      quoteState: quote.quoteState,
      orderAttributes: orderParamsDto,
      recordVersion: quote.recordVersion,
    });
  }

  private patchQuote(quoteId: string, quoteAsMap: any) {
    this.orderingService.patchOrder(quoteId, quoteAsMap, true).subscribe(quote => {
      CustomerPartyUtil.copyWithExclude(
        quote,
        this.relatedQuotes.find(relQuote => relQuote.id === quote.id)
      );
    });
  }

  isQuoteToOrderVisible(quote: OrderDto): boolean {
    const isStateValid = quote.quoteState === QuoteStateDtoEnum.Approved;
    if (!isStateValid) {
      return false;
    }

    const validForJson = this.getOrderAttributeValue(quote, 'quoteValidFor');
    if (validForJson) {
      const validFor = JSON.parse(validForJson);
      if (!validFor['endDateTime'] || OpportunityDetailsComponent.dateNotExpired(new Date(validFor.endDateTime))) {
        return true;
      }
    }
    return false;
  }

  isQuoteClickable(quote: OrderDto): boolean {
    if (quote.quoteState === QuoteStateDtoEnum.InProgress || quote.quoteState === QuoteStateDtoEnum.Pending) {
      const validForJson = this.getOrderAttributeValue(quote, 'quoteValidFor');
      if (validForJson) {
        const validFor = JSON.parse(validForJson);
        if (!validFor['endDateTime'] || OpportunityDetailsComponent.dateNotExpired(new Date(validFor.endDateTime))) {
          return true;
        }
      }
    }
    return false;
  }

  showQuoteDetailsPopup(quote: OrderDto) {
    const dialogReference = this.ngbModal.open(QuoteDetailsPopupComponent, {
      size: 'md',
      windowClass: QuoteDetailsPopupComponent.POPUP_WINDOW_CSS_CLASSES,
    });
    const quoteDetailsPopup = <QuoteDetailsPopupComponent>dialogReference.componentInstance;
    quoteDetailsPopup.dialogReference = dialogReference;
    quoteDetailsPopup.quote = quote;
    quoteDetailsPopup.saveChangesHandler = (quote: OrderDto) => {
      CustomerPartyUtil.copyWithExclude(
        quote,
        this.relatedQuotes.find(relQuote => relQuote.id === quote.id)
      );
    };
  }

  tasksDeleted() {
    this.reloadTicket(this.ticket);
  }

  accountChanged(account: AccountDto) {
    this.userSalesTeamRoles = [];
    if (account) {
      this.userSalesTeamRoles.push(
        ...this.salesTeamRoles?.filter(role =>
          account.roles.find(
            accountRole => accountRole.roleName === role.id || accountRole.roleName.indexOf('ADMIN') > 0
          )
        )
      );

      if (this.userSalesTeamRoles.length === 0) {
        this.form.controls.salesTeam.patchValue(null);
      } else {
        if (
          this.form.controls.salesTeam.value &&
          !this.userSalesTeamRoles.find(role => role.id === this.form.controls.salesTeam.value)
        ) {
          this.form.controls.salesTeam.patchValue(this.userSalesTeamRoles[0].id);
        }
      }
    } else {
      this.userSalesTeamRoles.push(...this.salesTeamRoles);
    }
    if (this.userSalesTeamRoles.length > 0) {
      this.form.controls.salesTeam.enable();
    } else {
      this.form.controls.salesTeam.disable();
    }
  }

  get appCurrency(): string {
    return environment.currency;
  }

  duplicate() {
    this.ticketService
      .getDuplicateParameters(this.ticket)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(duplicateParamters => {
        if (duplicateParamters) {
          this.navigateSelf(duplicateParamters);
        }
      });
  }

  createCustomerPopup() {
    const modalRef = this.ngbModal.open(CreateCustomerPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const createCustomerComponent = <CreateCustomerPopupComponent>modalRef.componentInstance;
    createCustomerComponent.dialogRef = modalRef;
    createCustomerComponent.handler = (customer: CustomerDto) => {
      this.form.controls.cuRefNo.patchValue(customer);
      this.customerChanged(customer);
    };
  }

  public readonly ContactHistoryDetailsComponent = ContactHistoryDetailsComponent;
}
