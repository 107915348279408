import { SocketService } from '@btl/order-bff';
// TODO: Fix Dto´s

import { EventEmitter, Injectable } from '@angular/core';
import { PrecalculatedShoppingCart } from '../models/precalculated-shopping-cart';
import { OrderDto } from '@btl/order-bff';
import { CurrentLocaleService, ProductService } from '@btl/btl-fe-wc-common';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

/**
 * Service for managing shopping cart
 */
@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  /**
   * A precalculated shopping cart for the current order.
   */
  preCalculatedShoppingCart: PrecalculatedShoppingCart;

  /**
   * An event emitter activated when a change in order happens.
   */
  readonly onChange: EventEmitter<PrecalculatedShoppingCart> = new EventEmitter<PrecalculatedShoppingCart>();

  /**
   * A flag specifying if this service is loading some data at th moment.
   */
  loading = false;

  /**
   * Store current order.
   */
  currentOrder;

  readOnlyOrder: PrecalculatedShoppingCart;

  constructor(private productService: ProductService, private currentLocaleService: CurrentLocaleService, private socketService: SocketService) {
    this.currentLocaleService.currentLocaleChange.subscribe(() => {
      if (this.preCalculatedShoppingCart) {
        this.handleOrderChange(this.currentOrder).subscribe(preCalculatedShoppingCart => {
          this.preCalculatedShoppingCart = preCalculatedShoppingCart;
        });
      }
    });
  }

  //region Operations:

  /**
   * Handle change of the given order. Every change invokes firing an event of the onChange event emitter.
   *
   * @param {OrderDto} order The changed order.
   */
  handleOrderChange(order: OrderDto): Observable<PrecalculatedShoppingCart> {
    this.loading = true;
    return this.createNewPreCalculatedShoppingCart(order).pipe(
      tap((preCalculatedShoppingCart: PrecalculatedShoppingCart) => {
        this.currentOrder = order;
        this.preCalculatedShoppingCart = preCalculatedShoppingCart;
        this.loading = false;
        this.onChange.emit(this.preCalculatedShoppingCart);
      })
    );
  }

  createNewPreCalculatedShoppingCart(order: OrderDto): Observable<PrecalculatedShoppingCart> {
    const preCalculatedShoppingCart = new PrecalculatedShoppingCart(order, this.productService, this.socketService);
    this.readOnlyOrder = preCalculatedShoppingCart;
    return preCalculatedShoppingCart.loadProducts().pipe(map(() => preCalculatedShoppingCart));
  }

  continue(actualStep) {
    if (actualStep === 'shoppingCart') {
      //this.shoppingCartItemsComponent.validate();
    }
  }
}
