import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupDto } from '@btl/order-bff';
import { CodebookService, CurrentLocaleService, GroupTreeNode, ProductGroupService } from '@btl/btl-fe-wc-common';
import { AvailabilityEnum } from '../../../../models/availability.enum';
import { FilterService } from '@service/filter.service';
import { UrlParamsService } from '@service/url-params.service';
import { ProductElasticFilter } from '../../../../models/product-elastic-filter';
import { FilteringGroup, GeneralFilter, RangeFilter } from '../../../../models/filtering-group';

@Component({
  selector: 'app-filters, [app-filters]',
  templateUrl: './filters.component.html',
})
export class FiltersComponent implements OnInit, OnDestroy {
  public static readonly MIN_TEXT_LENGTH_FOR_SEARCH: number = 3;
  private static readonly AVAILABILITY_GROUP = '1';

  private onDestroy$: Subject<void> = new Subject<void>();
  private searchTerms = new Subject<string>();
  private filter: ProductElasticFilter;
  public productGroupsTree: Array<GroupTreeNode>;
  private currentProductGroup: GroupTreeNode;
  private static allFilterData: FilteringGroup[];
  private static activatedCheckFilters = new Array<ActivatedCheckFilter>();
  public subGroups$: ReplaySubject<GroupTreeNode[]>;

  @Input()
  enableTariffFilter: boolean;

  constructor(
    private filterService: FilterService,
    private productGroupService: ProductGroupService,
    private router: Router,
    private route: ActivatedRoute,
    private urlParamsService: UrlParamsService,
    private currentLocaleService: CurrentLocaleService,
    public codebookService: CodebookService
  ) {}

  ngOnInit() {
    this.filter = this.filterService.getProductFilter();
    this.loadGroups();
    const checkboxFilters$ = this.productGroupService.getCurrentProductGroup().pipe(
      debounceTime(200),
      switchMap(group => this.filterService.getCheckboxFilters(group?.group.id))
    );

    combineLatest([this.productGroupService.getCurrentProductGroup(), this.route.params, this.productGroupService.getProductGroups(), checkboxFilters$,])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([currentProductGroup, params, allProductGroups, generalFilteringGroups]) => {
        if (
          !this.currentProductGroup ||
          !currentProductGroup ||
          this.currentProductGroup.group.id !== currentProductGroup.group.id
        ) {
          this.currentProductGroup = currentProductGroup;
          this.cancelAllCheckFilters();
          if (currentProductGroup && !this.urlParamsService.getSearchText(params)) {
            this.filterService.getProductFilter().attributes.text = null;
            this.searchChange('');
          }

          //Default filtering group type is 0
          const filteringGroupType = this.getFilteringGroupType(this.currentProductGroup, allProductGroups);
          const generalFilteringGroupsCopy = JSON.parse(JSON.stringify(generalFilteringGroups));
          for (const generalFilter of generalFilteringGroupsCopy) {
            generalFilter.filters = generalFilter.filters
              ? generalFilter.filters.filter(f => f.types.indexOf(filteringGroupType) !== -1)
              : generalFilter.filters;
          }
          this.customFilterTranslations(generalFilteringGroupsCopy);
          const finalFilteringGroups: FilteringGroup[] = generalFilteringGroupsCopy.filter(
            gf => gf.filters && gf.filters.length > 0
          );
          FiltersComponent.allFilterData = finalFilteringGroups;
        }
      });

    this.currentLocaleService.currentLocaleChange
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(locale => this.loadGroups());
  }

  private loadGroups() {
    const allProductGroups$ = this.productGroupService.getProductGroups();
    const currentProductGroup$ = this.productGroupService.getCurrentProductGroup();
    combineLatest([allProductGroups$, currentProductGroup$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([allProductGroups, currentProductGroup]: [GroupTreeNode[], GroupTreeNode]) => {
        //init groups and sub-groups
        if (currentProductGroup) {
          //this.productGroupsTree = allProductGroups;
          if (currentProductGroup.children.length !== 0) {
            this.productGroupsTree = currentProductGroup.children;
          } else {
            this.productGroupsTree = [];
          }
        }
      });
  }

  private customFilterTranslations(finalFilteringGroups: FilteringGroup[]) {
    //Translate the availability
    const availabilityGroup = finalFilteringGroups.find(gf => gf.code === FiltersComponent.AVAILABILITY_GROUP);
    if (availabilityGroup) {
      availabilityGroup.filters = availabilityGroup.filters.filter(f => f.code !== AvailabilityEnum.none);
      availabilityGroup.filters
        .filter(f => AvailabilityEnum.getOnStockTypes().includes(f.code))
        .forEach(f => (f.translateKey = AvailabilityEnum.getTranslationKey(AvailabilityEnum[f.code])));
    }
  }

  private getFilteringGroupType(currentGroup, allProductGroups) {
    let filteringGroupType = 0;
    if (currentGroup && currentGroup.group.groupParams.filteringType) {
      filteringGroupType = parseInt(currentGroup.group.groupParams.filteringType);
    } else if (currentGroup && currentGroup.group.parentId) {
      const productGroupById = this.productGroupService.getProductGroupById(
        allProductGroups,
        currentGroup.group.parentId
      );
      return this.getFilteringGroupType(productGroupById, allProductGroups);
    }
    return filteringGroupType;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  /**
   * Called from GUI when search input changes
   * @param text
   */
  private searchChange(text: string) {
    this.searchTerms.next(text);
  }

  /**
   * Sets text filter in filtering service
   * @param text Text to set
   */
  private setFilteringText(text: string) {
    if (text && text.length >= FiltersComponent.MIN_TEXT_LENGTH_FOR_SEARCH) {
      this.cancelAllCheckFilters();
      this.router.navigate(['eshop/search', text]);
    }
    if (!text) {
      this.productGroupService
        .getDefaultGroup()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(defaultGroup => {
          this.filterService.setText(null);
          this.router.navigate(['eshop/product-listing', defaultGroup.group.seoUrl]);
        });
    }
  }

  checkFilter(filteringGroup: FilteringGroup, generalFilter: GeneralFilter | RangeFilter, hwCheck: HTMLInputElement) {
    if (hwCheck.checked) {
      this.activateFilter(generalFilter, filteringGroup, hwCheck);
    } else {
      this.deactivateFilter(filteringGroup, generalFilter);
    }
  }

  private activateFilter(
    generalFilter: GeneralFilter | RangeFilter,
    filteringGroup: FilteringGroup,
    hwCheck: HTMLInputElement
  ) {
    const value = this.getFilterValueFromGeneralOrRangeFilter(generalFilter);
    FiltersComponent.activatedCheckFilters.push({
      filteringGroup: filteringGroup,
      generalFilter: generalFilter,
    });
    this.filterService.addProductAttributeValue(
      this.filterService.getAttributeNameByCodebookCode(filteringGroup.code),
      value
    );
  }

  private deactivateFilter(filteringGroup: FilteringGroup, generalFilter: GeneralFilter | RangeFilter) {
    const value = this.getFilterValueFromGeneralOrRangeFilter(generalFilter);
    const indexToRemove = FiltersComponent.activatedCheckFilters.findIndex(
      obj => obj.filteringGroup.code === filteringGroup.code && obj.generalFilter.code === generalFilter.code
    );
    FiltersComponent.activatedCheckFilters.splice(indexToRemove, 1);
    this.filterService.removeProductAttributeValue(
      this.filterService.getAttributeNameByCodebookCode(filteringGroup.code),
      value
    );
  }

  private getFilterValueFromGeneralOrRangeFilter(generalFilter: GeneralFilter | RangeFilter) {
    let value = null;
    if (generalFilter.hasOwnProperty('value')) {
      value = (generalFilter as GeneralFilter).value;
    }
    if (generalFilter.hasOwnProperty('min') || generalFilter.hasOwnProperty('max')) {
      const rangeFilter = generalFilter as RangeFilter;
      value = { min: rangeFilter.min, max: rangeFilter.max };
    }
    return value;
  }

  cancelCheckFilter(activatedCheckFilter: ActivatedCheckFilter) {
    this.deactivateFilter(activatedCheckFilter.filteringGroup, activatedCheckFilter.generalFilter);
  }

  cancelAllCheckFilters() {
    const activatedCheckFiltersCopy = new Array<ActivatedCheckFilter>(...FiltersComponent.activatedCheckFilters);
    for (const activatedCheckFilter of activatedCheckFiltersCopy) {
      this.cancelCheckFilter(activatedCheckFilter);
    }
  }

  private getProductsAggregationsNumber(groupCode, filterCode) {
    return this.filterService.getAggregatedNumber(groupCode, filterCode);
  }

  private get activatedCheckFilters() {
    return FiltersComponent.activatedCheckFilters;
  }

  private get allFilterData(): FilteringGroup[] {
    return FiltersComponent.allFilterData;
  }

  isChecked(filterGroupCode: string, filterCode: string): boolean {
    return !!FiltersComponent.activatedCheckFilters.find(
      acf => acf.filteringGroup.code === filterGroupCode && acf.generalFilter.code === filterCode
    );
  }

  public autocompleteFocusOut(autocomplete: HTMLDivElement) {
    setTimeout(() => (autocomplete.hidden = true), 500);
  }

  public getGroupName(group: GroupDto): string {
    return this.productGroupService.getGroupName(group);
  }
}

interface ActivatedCheckFilter {
  filteringGroup: FilteringGroup;
  generalFilter: GeneralFilter | RangeFilter;
}
