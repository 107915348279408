import { CategoryTypeEnum } from 'app/models/product-filter';
import { CustomerPartyUtil } from 'app/helpers/customer-party.util';
import { ProductDetailDto } from '@btl/order-bff';

export class SubscriptionUtils {
  public static isPostpaid(tariffProduct: ProductDetailDto): boolean {
    if (tariffProduct && tariffProduct.categoryId === CategoryTypeEnum.PRODC_SU_TARIFF) {
      return true;
    } else if (tariffProduct.parametersStatic && tariffProduct.parametersStatic['serviceType'] === 'postpaid') {
      return true;
    } else {
      return false;
    }
  }

  public static getMsisdn(coreProduct): string {
    return CustomerPartyUtil.getAssetProductCharacteristicValue(coreProduct, 'phoneNumber');
  }
}
