<ng-container *ngIf="subscriptions.length > 0; else banner">
  <ng-container *ngIf="tariffProduct">
    <div class="widget--header">
      <div class="widget--header_title">
        <i [inlineSVG]="'/assets/svg/dashboard-widgets/mobile-data-usage.svg'"></i>
        <h6>{{ 'wc.ecare.widgets.mobileInternetTariff.header' | translate }}</h6>
      </div>
      <app-subscription-select-widget
        [subscription]="subscription"
        [subscriptionsToSelect]="subscriptionsToSelect"
        [tariffIdentification]="tariffIdentification"
        (subscriptionChanged)="changeSelectedTariff($event)">
      </app-subscription-select-widget>
    </div>

    <div class="widget--content content-in-row">
      <div
        app-ecare-widget-main-info
        class="widget--content_mainInfo"
        [label1]="customName"
        [label2]="mainTariffProduct ? mainTariffProduct.name : tariffProduct.name"
        [price]="monthPrice"
        [period]="'wc.ecare.widgets.thisMonth' | translate"
        [price2]="tariffProductPrice"
        [period2]="'wc.shopping.confirmation.price.RC.label' | translate"
        [priceOnTop]="amountOnTop"
        [periodOnTop]="'wc.ecare.widgets.amountOnTop' | translate">
        <div class="details-wraper">
          <div class="content-in-row">
            <app-acl-internal-evaluate-component class="padding-5">
              <app-acl-evaluate privilege="DEACTIVATION_B2C_SUBS" class="btn-no-padding">
                <button
                  *ngIf="subscription?.assets[0]['state'] !== 'DEACTIVATED'"
                  id="008_subscription_detail"
                  class="btn -btn-tariff-restriction"
                  tooltipClass="ecare-settings-tooltip"
                  placement="bottom"
                  ngbTooltip="{{ 'wc.ecare.dashboard.tariffRestriction.label' | translate }}"
                  (click)="restrictTariff()">
                  <img src="../../../../assets/img/ecare/ant-design_stop-outlined.svg" />
                </button>
              </app-acl-evaluate>
            </app-acl-internal-evaluate-component>
            <app-acl-internal-evaluate-component class="padding-5">
              <app-acl-evaluate privilege="DEACTIVATION_B2C_SUBS" class="btn-no-padding">
                <button
                  *ngIf="subscription?.assets[0]['state'] !== 'DEACTIVATED'"
                  id="007_subscription_detail"
                  class="btn -btn-tariff-deactivation"
                  tooltipClass="ecare-settings-tooltip"
                  placement="bottom"
                  ngbTooltip="{{ 'wc.ecare.dashboard.tariffDeactivation.label' | translate }}"
                  (click)="deactivateTariff()">
                  <img src="../../../../assets/img/ecare/ionic-ios-power.svg" />
                </button>
              </app-acl-evaluate>
            </app-acl-internal-evaluate-component>
          </div>
        </div>
      </div>
      <!--Change <input type="range" to divs as it is not possible to use scss variables here-->
      <div class="details-wraper fullHeight" *ngIf="dataWidgetInfo">
        <div class="widget--content wide">
          <div
            app-ecare-widget-main-info
            class="detail-box--label"
            [price]="dataWidgetInfo.leftUsage"
            [priceFormat]="false"
            [period]="
            dataWidgetInfo.limitUnit +
            ' ' +
            ('wc.ecare.widgets.mobileInternetTariff.leftOf' | translate) +
            ' ' +
            dataWidgetInfo.limit +
            dataWidgetInfo.limitUnit
          "></div>
          <mat-slider min="1" max="100" step="1" thumbLabel><input matSliderThumb [value]="dataWidgetInfo.usedPercentage" /></mat-slider>
        </div>
        <div class="detail-box">
          <div class="detail-box--label">{{ 'wc.ecare.widgets.dataOnTop.label' | translate }}</div>
          <div class="detail-box--icon"><i [inlineSVG]="'/assets/svg/dashboard-widgets/data.svg'"></i></div>
          <div class="detail-box--value">{{ dataWidgetInfo.onTop }}
            <ng-container *ngIf="codebookService.getCodebookTexts('FUOM_UNITS') | async as codebook">
              {{ codebook[dataWidgetInfo.limitUnit] }}
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="isPostpaid"
          app-button
          class="change-tariff-btn"
          routerLink="/eshop/tariff-change"
          [queryParams]="{
          tariffSpaceId: subscription.id,
          tariffId: asset.id,
          customerAccountId: customerAccount?.id ? customerAccount.id : subscription.parentId,
          locationId: locationId,
          socketId: socketId
        }"
          [variant]="'primary'"
          [size]="'fullWidth'"
          [label]="'wc.ecare.dashboard.tariffChange.label' | translate"></div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #banner>
  <div class="full-height" app-cms-widget-banner [bannerTargeter]="bannerTargetArea"></div>
</ng-template>


<app-button
  *ngIf="editMode"
  class="remove-widget"
  iconPath="/assets/img/ecare/feather-minus.svg"
  variant="whiteWithBorder"
  placement="top"
  ngbTooltip="Remove widget">
</app-button>
