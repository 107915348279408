import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProductInShoppingCart } from '../../../models/product-in-shopping-cart';
import { ShoppingCartSocket } from '../shopping-cart-item/shopping-cart-item.component';
import { PrecalculatedShoppingCart } from 'app/models/precalculated-shopping-cart';
import { OrderDto, SocketDto } from '@btl/order-bff';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductAddingToCart } from '@service/product-listing.service';
import { Subject, takeUntil } from 'rxjs';
import { WcOrderingService } from '@service/wc-ordering.service';

@Component({
  selector: 'app-shopping-cart-add-product-to-socket',
  templateUrl: './shopping-cart-add-product-to-socket.component.html',
})
export class ShoppingCartAddProductToSocketComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input() shoppingCartSocket: ShoppingCartSocket;
  @Input() socketChilds: ShoppingCartSocket[];
  @Input() precalculatedShoppingCart: PrecalculatedShoppingCart;
  @Input() mainProductInShoppingCart: ProductInShoppingCart;

  socket: SocketDto;
  socketChildsProductOfferings = null;
  addProductForm: FormGroup = this.formBuilder.group({
    product: [null, [Validators.required]],
  });

  constructor(private formBuilder: FormBuilder, private orderingService: WcOrderingService) {}

  ngOnInit() {
    if (this.socketChilds.length > 0) {
      this.socket = this.socketChilds[0].socket;
    }

    if (this.precalculatedShoppingCart.promotionConfiguration.get(this.mainProductInShoppingCart.productDetail.productCode)) {
      this.socketChildsProductOfferings = this.precalculatedShoppingCart.promotionConfiguration
        .get(this.mainProductInShoppingCart.productDetail.productCode)
        .filter(productOffering => productOffering.socket.code === this.socket?.code)
        .filter(
          productOffering =>
            productOffering.socket.socketContentQty.max >
            this.socketChilds.find(
              socket => socket.product.productDetail.productCode === productOffering.product.productCode
            )?.currentContentQty
        )
        .map(productOffering => {
          return {
            value: productOffering.product,
            label: productOffering.product.name,
          };
        });
    }
  }

  addProduct() {
    const addOrderItem = (currentOrder: OrderDto) => {
      this.orderingService
        .addProductToShoppingCartWithParent(
          currentOrder,
          this.addProductForm.value.product as ProductAddingToCart,
          this.shoppingCartSocket.product.productDetail.id,
          this.shoppingCartSocket.product.orderItems[0].id,
          null,
          null,
          false
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(addOrderItem);
  }
}
