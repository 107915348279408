<ng-container
  *ngIf="socket && socket.contentQty.max > socketChilds.length && socketChildsProductOfferings"
  [formGroup]="addProductForm">
  <div class="generated-form">
      <app-input-select-form-field
        valueOption="value"
        labelOption="label"
        formControlName="product"
        [noLabel]="true"
        [options]="socketChildsProductOfferings"
        [doNotShowInternalErrors]="true"
        [showEmptyOption]="false" />

      <app-button
        label="{{ 'wc.shopping.cart.addToService.text' | translate }}"
        (click)="addProduct()" />
  </div>
</ng-container>
