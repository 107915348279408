import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Product } from '../../../../models/product';
import { ProductsHolder } from '../../../../models/products-holder';
import { ProductCustomService } from '@service/product-custom.service';
import { FilterService } from '@service/filter.service';
import { UrlParamsService } from '@service/url-params.service';
import {
  BlockTemplateComponent,
  CurrentLocaleService,
  GroupTreeNode,
  ProductGroupService
} from '@btl/btl-fe-wc-common';
import { GroupDto, ProductDetailDto } from '@btl/order-bff';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { ProductElasticFilter } from '../../../../models/product-elastic-filter';
import { Constants } from '../../../../constants';
import { BlockUI, BlockUIService, NgBlockUI } from 'ng-block-ui';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import { ProductListingService } from '@service/product-listing.service';
import { NavigationThirdMenuComponent } from '../../../page/navigation-third-menu/navigation-third-menu.component';

@Component({
  selector: 'app-listing, [app-listing]',
  templateUrl: './listing.component.html',
})
export class ListingComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  private static readonly filterStorageName = 'sorting';
  productsHolders: Array<ProductsHolder>;
  products: Array<Product>;
  productsLoadingError: boolean = false;
  moreProductsAvailable: boolean = false;
  private disableReload: boolean = false;
  private filterChangeSubscription: Subscription;
  private seoUrlSubscription: Subscription;
  private reloadProductsSubscription: Subscription;
  private loadNextPageSubscription: Subscription;
  public loadMorePageSize: number;
  private loadMorePageSizeSubscription: Subscription;
  private aggregationJson: any;
  private aggrEnrichSubscr: Subscription;
  private currentProductGroupSubscription: Subscription;
  public compareAvailable: boolean;
  private subscription: Subscription;
  public totalValue: number;

  public allProductGroups: Array<GroupTreeNode>;

  constructor(
    private productService: ProductCustomService,
    private filterService: FilterService,
    private route: ActivatedRoute,
    private urlParamsService: UrlParamsService,
    private productGroupService: ProductGroupService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private currentLocaleService: CurrentLocaleService,
    private orderingServiceWizard: OrderingWizardService,
    private blockUIService: BlockUIService,
    private productListingService: ProductListingService
  ) {
    this.loadProductGroups();

    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentProductGroup = null;
      this.loadProductGroups();
    });
  }

  loadProductGroups() {
    this.productGroupService
      .getProductGroups()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
          this.allProductGroups = result;
          this.getCurrentProductGroup();
        }
      );
  }

  /**
   * A flag specifying if list should contain sorting possibilities
   */
  @Input()
  enableSorting: boolean = true;

  @Input()
  tariffListing: boolean;

  @Input()
  currentGroup;

  @Input()
  isSmartCityOffer: boolean;

  /**
   * A flag specifying if list should contain header
   */
  @Input()
  enableHeader: boolean = true;

  /**
   * A flag specifying if list should contain pagination possibilities
   */
  @Input()
  enablePaging: boolean = true;

  @Input()
  parentInstanceId: Array<string>;

  @Input()
  parentProductCode: string;

  @Input()
  parentProductId: string;

  @Input()
  partyId: string;

  /**
   * The ID of the party of the original product. This is only relevant for tariff change.
   */
  @Input()
  originalProductPartyId: string;

  /**
   * The original product. This is only relevant for tariff change.
   */
  @Input()
  originalProduct: ProductDetailDto;

  @Input()
  customerAccountId: string;

  reloadListing;

  @Output()
  readonly selectionChanged: EventEmitter<String> = new EventEmitter();

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  public currentProductGroup: GroupTreeNode;
  public loading: boolean = true;

  ngOnInit() {
    this.filterChangeSubscription = combineLatest([
      this.filterService.filterChange.pipe(debounceTime(Constants.DEBOUNCE_TIME)),
      this.propertyAccessorLocalService.getPageSize(),
    ])
      .pipe(
        switchMap(([filteringGroup, pageSize]: [any, number]) => this.loadProducts(false, filteringGroup, pageSize))
      )
      .subscribe();

    this.seoUrlSubscription = this.route.params
      .pipe(
        switchMap(params => {
          if (!this.urlParamsService.getSearchText(params)) {
            return this.filterService.setCurrentProductGroupBySeoUrl(this.urlParamsService.getSeoUrl(params));
          } else {
            this.productGroupService.setCurrentProductGroup(null);
            this.filterService.setText(this.urlParamsService.getSearchText(params));
            return of(params);
          }
        })
      )
      .subscribe();
    this.loadMorePageSizeSubscription = this.propertyAccessorLocalService
      .getLoadMorePageSize()
      .subscribe(pageSize => (this.loadMorePageSize = pageSize));

    //this.getCurrentProductGroup();
  }

  getCurrentProductGroup() {
    if (this.currentProductGroupSubscription) {
      this.currentProductGroupSubscription.unsubscribe();
    }
    const currentProductGroup$ = this.productGroupService.getCurrentProductGroup();
    this.currentProductGroupSubscription = currentProductGroup$
      .pipe(debounceTime(Constants.DEBOUNCE_TIME))
      .subscribe(currentGroupTreeNode => {
        this.currentProductGroup = currentGroupTreeNode;
        if (
          this.currentProductGroup &&
          this.currentProductGroup.group.groupParams &&
          this.currentProductGroup.group.groupParams['isCompareAvailable'] === 'true'
        ) {
          this.compareAvailable = true;
        } else {
          this.compareAvailable = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.seoUrlSubscription.unsubscribe();
    this.filterChangeSubscription.unsubscribe();
    this.loadMorePageSizeSubscription.unsubscribe();
    if (this.reloadProductsSubscription) {
      this.reloadProductsSubscription.unsubscribe();
    }
    if (this.loadNextPageSubscription) {
      this.loadNextPageSubscription.unsubscribe();
    }
    if (this.aggrEnrichSubscr) {
      this.aggrEnrichSubscr.unsubscribe();
    }
    this.onDestroy$.next();
  }

  public getGroupName(group: GroupDto): string {
    return this.productGroupService.getGroupName(group);
  }

  public getGroupDescription(group: GroupDto): string {
    return this.productGroupService.getGroupDescription(group);
  }

  /**
   * Load products from elasticsearch filtered by product filter FilterService
   * @param append If true, appends new hits after already loaded, otherwise always load first page
   */
  public loadProducts(
    append: boolean = false,
    filteringGroup?: string,
    pageSize?: number,
    localeChange?: boolean
  ): Observable<any> {
    this.blockUIElement.start();
    const oldSorting = window.localStorage.getItem(ListingComponent.filterStorageName);
    const newSorting = this.filterService.getProductFilter().sorting;
    const activeFilter = this.filterService.getActiveFilteringGroups();
    if (oldSorting !== newSorting || activeFilter.length > 0 || append || localeChange) {
      window.localStorage.setItem(ListingComponent.filterStorageName, newSorting);
      this.reloadListing = false;
    } else {
      this.reloadListing = true;
    }
    if ((!this.currentGroup || this.currentGroup.group.parentId === null) && this.reloadListing) {
      this.loading = true;
      this.blockUIService.start('main');
    }
    if (!append) {
      this.filterService.getProductFilter().paging.page = 1;
      if (pageSize) {
        this.filterService.getProductFilter().paging.firstPageSize = pageSize;
      }
    }
    if (pageSize) {
      this.filterService.getProductFilter().paging.pageSize = pageSize;
    }
    const defaultProductFilter = this.filterService.getProductFilter();
    this.subscription = this.productService.getProductsByFilter(defaultProductFilter).subscribe(response => {
      this.totalValue = response.hits.total.value;
    });
    return this.filterService.getAggregationJson().pipe(
      switchMap(aggregationJson => {
        this.aggregationJson = aggregationJson;
        return this.productService
          .getProductsByFilter(this.filterService.getProductFilter(), aggregationJson, false)
          .pipe(
            switchMap(response => {
              this.totalValue = response.hits.total.value;
              return of(response);
            })
          )
          .pipe(
            finalize(() => {
              if ((this.currentGroup === null || this.currentGroup.group.parentId === null) && this.reloadListing) {
                this.blockUIService.stop('main');
              }
            })
          );
      }),
      tap(response => {
        if (append) {
          this.productsHolders.push(...response.hits.hits);
        } else {
          this.productsHolders = response.hits.hits;
        }
        this.products = this.productsHolders?.map(ph => ph._source) ?? [];
        this.moreProductsAvailable = response.hits.total.value > this.productsHolders.length;
        this.enrichAggregationsAndSetToFilter(response.aggregations, this.aggregationJson, filteringGroup);
        this.productsLoadingError = false;
      }),
      finalize(() => {
        this.loading = false;
        this.blockUIElement.stop();
      }),
      catchError(error => {
        console.error(error);
        this.productsLoadingError = true;
        this.blockUIElement.stop();
        return of([]);
      })
    );
  }

  /**
   * Reload products, can be triggered from GUI in case of loading error
   */
  public reloadProducts() {
    if (!this.disableReload) {
      this.disableReload = true;
      if (this.reloadProductsSubscription) {
        this.reloadProductsSubscription.unsubscribe();
      }
      this.reloadProductsSubscription = this.loadProducts().subscribe(() => {
        this.disableReload = false;
      });
    }
  }

  getGroupClass() {
    let clazz = '';
    if (this.currentProductGroup && this.allProductGroups) {
      const parentGroup = NavigationThirdMenuComponent.getProductGroupByGroupId(
        this.currentProductGroup.group.parentId,
        this.allProductGroups
      );
      clazz =
        this.currentProductGroup.group.groupParams.cssStyle || (parentGroup && parentGroup.group.groupParams.cssStyle);
    }

    return clazz;
  }

  /**
   * Loads next page
   */
  loadNextPage() {
    this.blockUIElement.start();
    const productFilter = this.filterService.getProductFilter();
    productFilter.paging.page++;
    if (this.loadNextPageSubscription) {
      this.loadNextPageSubscription.unsubscribe();
    }
    this.loadNextPageSubscription = this.loadProducts(true, null, this.loadMorePageSize)
      .pipe(
        finalize(() => {
          this.blockUIElement.stop();
        })
      )
      .subscribe();
  }

  private enrichAggregationsAndSetToFilter(aggregations: any, aggregationJson: any, filteringGroup?: string) {
    const activeFilteringGroups = this.filterService.getActiveFilteringGroups();
    const productFilter = this.filterService.getProductFilter();
    if (filteringGroup && productFilter.attributes[filteringGroup].length === 0 && activeFilteringGroups.length) {
      const activeGroupsObservables = activeFilteringGroups.map(activeFilteringGroup => {
        const productFilterClone: ProductElasticFilter = JSON.parse(JSON.stringify(productFilter));
        productFilterClone.paging.pageSize = 0;
        productFilterClone.paging.page = 0;
        productFilterClone.attributes[activeFilteringGroup] = [];
        return this.productService.getProductsByFilter(productFilterClone, aggregationJson);
      });
      if (this.aggrEnrichSubscr) {
        this.aggrEnrichSubscr.unsubscribe();
      }
      this.aggrEnrichSubscr = forkJoin(activeGroupsObservables).subscribe(result => {
        result.forEach((elasticResult, index) => {
          const groupName = activeFilteringGroups[index];
          aggregations[groupName] = elasticResult.aggregations[groupName];
        });
        this.filterService.setProductAggregations(aggregations, filteringGroup);
      });
    } else {
      this.filterService.setProductAggregations(aggregations, filteringGroup);
    }
  }

  buySmartCity(product: Product) {
    this.productListingService.handleProductAddingToCart(
      ProductListingService.asProductAddingToCart(product),
      this.originalProductPartyId,
      this.partyId,
      this.parentInstanceId,
      this.parentProductId
    );
  }

  changeGroup(navigationGroupNode: GroupTreeNode) {
    this.currentProductGroup = navigationGroupNode;
    this.reloadProducts();
  }

  getProductGroup(currentProductGroup: GroupTreeNode) {
    return NavigationThirdMenuComponent.getProductGroup(currentProductGroup, this.allProductGroups);
  }
}
