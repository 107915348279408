import { Injectable } from '@angular/core';
import { ElasticsearchService, Search, ServiceUtils, TicketingService } from '@btl/btl-fe-wc-common';
import { Keys } from '@service/keys';
import { SearchFrontendService, SearchOptionsDto, TicketDto } from '@btl/order-bff';
import { TicketRefDto } from '@btl/order-bff/model/ticketRefDto';
import { map, Observable } from 'rxjs';
import {
  DuplicateTicketModalComponent
} from '../components/wizard/tickets/duplicate-ticket-modal/duplicate-ticket-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketsHits } from '../models/tickets-holder';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  public static OPPORTUNITY_TYPE_PARAM_NAME: string = 'OPPORTUNITY_TYPE';
  public static OPPORTUNITY_STATE_PARAM_NAME: string = 'OPPORTUNITY_STATE';
  public static OPPORTUNITY_PROBABILITY_PARAM_NAME: string = 'OPPORTUNITY_PROBABILITY';
  public static EXPECTED_REVENUE_PARAM_NAME: string = 'EXPECTED_REVENUE';
  public static CURRENT_OPERATOR_PARAM_NAME: string = 'CURRENT_OPERATOR';
  public static REJECT_REASON_PARAM_NAME: string = 'REJECT_REASON';
  public static OPPORTUNITY_CUSTOMER_PARAM_NAME: string = 'OPPORTUNITY_CUSTOMER';
  public static OPPORTUNITY_CONTACT_PARAM_NAME: string = 'OPPORTUNITY_CONTACT';
  public static PREFERRED_CONTACT_PARAM_NAME: string = 'PREFERRED_CONTACT';
  public static PREFERRED_ADDRESS_PARAM_NAME: string = 'PREFERRED_ADDRESS';
  public static VISITED_PAGE_URL_PARAM_NAME: string = 'VISITED_PAGE_URL';
  public static REFERENCES_TYPE_GENERAL: string = 'GENERAL';
  public static TEAM_ASSIGNMENT_PARAM_NAME: string = 'TEAM_ASSIGNMENT';
  public static RELATED_TASK_TYPES_PARAM_NAME: string = 'RELATED_TASK_TYPES';

  constructor(private ticketingService: TicketingService,
              private searchFrontendService: SearchFrontendService,
              private ngModal: NgbModal) {}

  public static getLocalOpportunity() {
    return JSON.parse(window.sessionStorage.getItem(Keys.KEY_CURRENT_OPPORTUNITY));
  }

  public static setLocalOpportunity(opportunity) {
    window.sessionStorage.setItem(Keys.KEY_CURRENT_OPPORTUNITY, JSON.stringify(opportunity));
  }

  public static removeLocalOpportunity() {
    window.sessionStorage.removeItem(Keys.KEY_CURRENT_OPPORTUNITY);
  }

  public static addReference(ticket: TicketDto, type: string, id: string) {
    if (!ticket.references) {
      ticket.references = [];
    }
    const existingRef = ticket.references.find(
      reference =>
        reference.refType === TicketService.REFERENCES_TYPE_GENERAL &&
        reference.entityType === type &&
        reference.entityId === id
    );
    if (!existingRef) {
      ticket.references.push({
        refType: TicketService.REFERENCES_TYPE_GENERAL,
        entityType: type,
        entityId: id,
      });
    }

    return ticket;
  }

  public static getReferenceByType(ticket: TicketDto, type): TicketRefDto {
    if (ticket && ticket.references) {
      return ticket.references.find(ref => ref.entityType === type);
    }

    return null;
  }

  public static replaceReferenceByType(ticket: TicketDto, type: string, id: string) {
    if (ticket && ticket.references) {
      if (!id) {
        ticket.references = ticket.references.filter(filterRef => filterRef.entityType != type);
      } else {
        const ref: TicketRefDto = TicketService.getReferenceByType(ticket, type);
        if (ref) {
          ref.entityId = id;
        } else {
          TicketService.addReference(ticket, type, id);
        }
      }
    }

    if (!ticket) {
      ticket.references = [];
      TicketService.addReference(ticket, type, id);
    }
    return null;
  }

  public static getParamValue(ticket, paramName): any {
    return ticket?.parameters?.find(findParam => findParam.name === paramName)?.value;
  }

  public static getJsonParamValue(ticket: TicketDto, paramName: string): any {
    const jsonParam = TicketService.getParamValue(ticket, paramName);
    return jsonParam ? JSON.parse(jsonParam) : null;
  }

  public static getReferenceValue(ticket, entityType: string): string {
    return ticket.references?.find(ref => ref.entityType === entityType)?.entityId;
  }

  public static clearFieldsBeforeUpdate(ticket: TicketDto, create = false) {
    ticket.assignmentStateFrom = null;
    ticket.businessStateFrom = null;

    if (!create) {
      ticket.type = null;
    } else {
      ticket.type.areaType = null;
      ticket.type.minPriority = null;
      ticket.type.maxPriority = null;
      ticket.type['defaultPriority'] = null;
    }

    ticket.created = null;
    ticket.createdBy = null;
    ticket.modified = null;
    ticket.modifiedBy = null;

    ticket.steps?.forEach(step => {
      step.config = null;
      step.created = null;
      step.createdBy = null;
      step.modified = null;
      step.modifiedBy = null;
    });

    ticket.notes?.forEach(note => {
      note.created = null;
      note.createdBy = null;
      note.modified = null;
      note.modifiedBy = null;
    });

    return ticket;
  }

  public getDuplicateParameters(ticket: TicketDto): any {
    return new Observable<any>(observable => {
      const modalRef = this.ngModal.open(DuplicateTicketModalComponent);
      const createTicketModalComponent = <DuplicateTicketModalComponent>modalRef.componentInstance;
      createTicketModalComponent.dialogRef = modalRef;
      createTicketModalComponent.selectHandler = (forDuplication: string[]) => {
        if (forDuplication) {
          observable.next({
            id: 'newTicket',
            duplicationId: ticket.id,
            forDuplication: forDuplication,
          });
        } else {
          observable.next(null);
        }
      };
    });
  }

  public getTicketForDuplication(ticketId: string, forDuplication: string[]) {
    return new Observable<TicketDto>(observable => {
      this.ticketingService.getTicketById(ticketId).subscribe(ticket => {
        this.ticketingService.getTicketSkeletonByTypeCode(ticket.type.code).subscribe(ticketSkeleton => {
          TicketService.clearFieldsBeforeUpdate(ticket, true);
          if (forDuplication.indexOf('coreAttributes') === -1) {
            ticket.businessState = ticketSkeleton.businessState;
            ticket.assignmentState = ticketSkeleton.assignmentState;
            ticket.assignmentStateFrom = ticketSkeleton.assignmentStateFrom;
            ticket.assignedTo = ticketSkeleton.assignedTo;
            ticket.owner = ticketSkeleton.owner;
            ticket.priority = ticketSkeleton.priority;
            ticket.extId = ticketSkeleton.extId;
            ticket.followUpDate = ticketSkeleton.followUpDate;
            ticket.slaDueDate = ticketSkeleton.slaDueDate;
            ticket.startFrom = ticketSkeleton.startFrom;
          }

          if (forDuplication.indexOf('dynamicParameters') === -1) {
            ticket.parameters?.forEach(param => (param.value = null));
          }

          if (forDuplication.indexOf('steps') === -1) {
            ticket.steps?.forEach(step => {
              step.remark = null;
              step.workStartDate = null;
              step.workEndDate = null;
              step.expectedDelay = null;
            });
          }

          if (forDuplication.indexOf('attachments') === -1) {
            if (ticket.attachments) {
              ticket.attachments.length = 0;
            }
          }

          if (forDuplication.indexOf('references') === -1) {
            if (ticket.references) {
              ticket.references.length = 0;
            }
          }

          if (forDuplication.indexOf('notes') === -1) {
            if (ticket.notes) {
              ticket.notes.length = 0;
            }
          }

          ticket.id = null;
          ticket.recordVersion = null;
          observable.next(ticket);
        });
      });
    });
  }

  /**
   * Search elasticsearch for tickets by given text
   * @param term Text to search by
   * @param size
   * @param from
   * @param ticketType
   */
  public searchByText(search: Search, size: number, from?: number, ticketType?: string): Observable<TicketsHits> {
    const searchOptions: SearchOptionsDto = {
      criteria: [],
      sort: [],
      size: size,
      from: from,
    };
    searchOptions.criteria.push(ServiceUtils.getSearchCriteria('typeId', ticketType ? ticketType : 'TASK'));
    search.filtering.forEach(filter => {
      if (filter.column != 'type') {
        searchOptions.criteria.push(ServiceUtils.getSearchCriteria(filter.column, filter.value));
      }
    });

    search.sorting.forEach(sort => {
      searchOptions.sort.push(ServiceUtils.getSearchSort(sort.column, sort.sortOrder));
    });

    return this.searchFrontendService
      .search(ElasticsearchService.TICKETS_INDEX, searchOptions)
      .pipe(map(oph => JSON.parse(oph).hits));
  }
}
