import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import _ from 'lodash';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize, map, switchMap } from 'rxjs/operators';
import { OrderDto, PagedOrdersDto, PartyDto, ProductDetailDto } from '@btl/order-bff';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import {
  AbstractPageComponent,
  AuthorizationService,
  BlockTemplateComponent,
  CompareType,
  EnableDynamicLoading,
  Filter,
  OrderingService,
  Search
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { EcareOrdersFilterComponent } from './ecare-orders-filter/ecare-orders-filter.component';
import { forkJoin, of } from 'rxjs';
import { CategoryTypeEnum } from '../../../models/product-filter';
import { ProductUtils } from '../../../helpers/product-utils';
import { Observable } from 'rxjs/internal/Observable';
import { OrderSortingObject, ThumbnailMap } from 'app/models/orders-dtos';
import { ThumbnailHelperService } from '@service/thumbnail-helper.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ecare-orders',
  templateUrl: './ecare-orders.component.html',
})
@EnableDynamicLoading({ customName: EcareOrdersComponent.PAGE_ID })
export class EcareOrdersComponent extends AbstractPageComponent implements OnInit, OnChanges {
  public static readonly PAGE_ID = 'EcareOrdersComponent';

  public DEFAULT_SELECTED_STATE = 'ALL';
  DISALLOWED_ORDER_ITEM_CATEGORIES: string[] = [CategoryTypeEnum.PRODC_GE_DELIVERY, CategoryTypeEnum.PRODC_GE_PAYMENT];

  pageId(): string {
    return EcareOrdersComponent.PAGE_ID;
  }

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  orders: OrderDto[] = [];
  pagedDto: PagedOrdersDto;
  canDisplayNoOrdersText = false;
  ordersByState: { orderState: string; count: number }[] = [];
  selectedOrderState: string = this.DEFAULT_SELECTED_STATE;
  rewriteOrderStateCounts: boolean = true;
  currentSortOrder: OrderSortingObject[] = [{ column: 'created', sortOrder: 'desc' }];
  thumbnailMap: ThumbnailMap[];
  columns = [
    { column: 'extId', label: 'wc.ecare.orders.table.orderNumber.header' },
    { column: 'creator', label: 'wc.ecare.orders.table.creator.header' },
    { column: 'created', label: 'wc.ecare.orders.table.created.header' },
    { column: 'stateHappened', label: 'wc.ecare.ordersFilter.stateDatePeriod' },
  ]

  @Input() constantFilter: Array<Filter> = [];
  @Input() party: PartyDto;
  @Input() selectMode = false;
  @Input() ecareSearch = false;
  @Input() isModuleBffCrmEnabled = true;
  @Input() showStatusBar = true;
  @Input() cacheFilterKey = null;
  @Input() maxThumbnailsToShow = 4;
  @Input() readonly searchChangedEmitter = new EventEmitter<Search>();

  @ViewChild('filterComponent') filterComponent: EcareOrdersFilterComponent;
  @ViewChild('tabIndicator', { static: false }) tabIndicator: ElementRef<HTMLDivElement>;
  @ViewChild('tabContainer', { static: false }) tabContainer: ElementRef<HTMLDivElement>;

  @Output() selectedOrder = new EventEmitter<OrderDto>();

  search: Search = {
    filtering: [
      {
        column: 'orderType',
        compareType: 'EQUAL',
        value: 'SALES',
      },
      {
        column: 'orderCalcStateType',
        compareType: CompareTypeDtoEnum.DIFFERENT,
        value: 'EMPTY',
      }
    ],
    sorting: this.currentSortOrder,
    paging: {
      page: 1,
      pageSize: -1,
    },
  };

  cuRefNo = null;
  caRefNo = null;
  queryParams = null;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private renderer: Renderer2,
    private readonly orderingService: OrderingService,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private authorizationService: AuthorizationService,
    private thumbnailHelperService: ThumbnailHelperService
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');
  }

  ngOnInit(): void {
    if (this.searchChangedEmitter) {
      this.searchChangedEmitter.subscribe(search => {
        this.search = search;
        this.handleFilterChange();
      });
    }

    this.route.queryParams.subscribe(queryParams => {
      this.cuRefNo = queryParams['cuRefNo'];
      this.caRefNo = queryParams['caRefNo'];
      this.queryParams = queryParams;

      this.party = this.party ? this.party : this.customerLocalStorageService.getCurrentCustomer();
      if (this.selectMode) {
        this.search.paging.pageSize = 20;
      } else if (this.constantFilter && this.constantFilter.length > 0) {
        this.search.filtering.length = 0;
        this.constantFilter.forEach(filter =>
          this.search.filtering.push({
            column: filter.column,
            compareType: filter.compareType,
            value: filter.value,
          })
        );
      } else if (this.customerLocalStorageService.getCurrentCustomer()) {
        this.search.filtering.push({
          column: 'partyRefNo',
          compareType: 'IN',
          value: this.customerLocalStorageService.getCurrentFilterPartyIds(),
        });
      }
    });
  }

  public handleFilterChange(): void {
    if (!this.search['keepPaging']) {
      this.search.paging.page = 1;
    } else {
      delete this.search['keepPaging'];
    }

    this.loadData();
  }

  private loadData() {
    this.blockUIElement.start();
    this.constantFilter.forEach(constFilter => {
      const existingFilter = this.search.filtering.find(
        filter => filter.column === constFilter.column && filter.compareType === constFilter.compareType
      );
      if (existingFilter) {
        existingFilter.value = constFilter.value;
      } else {
        this.search.filtering.push(constFilter);
      }
    });
    if (this.authorizationService.isInternal) {
      this.search.filtering.push({
        column: 'orderCalcStateType',
        compareType: CompareTypeDtoEnum.DIFFERENT,
        value: 'EMPTY'
      });
    }
    if (this.cacheFilterKey) {
      sessionStorage.setItem(this.cacheFilterKey, JSON.stringify(this.search));
    }
    this.orderingService
      .filterOrders(this.search, null)
      .pipe(
        switchMap(orders => {
          let assembledCodesConcatenated = [];
          if (!this.authorizationService.isInternal) {
            assembledCodesConcatenated = this.thumbnailHelperService.assembleUniqueProductIds(orders.data);
            if (!this.thumbnailMap) {
              this.thumbnailMap = [];
            } else {
              const existingIds = this.thumbnailMap.map(mapElement => mapElement.productId);
              existingIds.forEach(productId => {
                const existingIndex = assembledCodesConcatenated.findIndex(assembledId => assembledId === productId);
                if (existingIndex !== -1) {
                  // product already exists in thumbnail map, no need to call product service again
                  assembledCodesConcatenated.splice(existingIndex, 1);
                }
              });
            }
          }
          const productObservables: Array<Observable<ProductDetailDto>> =
            this.thumbnailHelperService.getProductDetailObservableList(assembledCodesConcatenated);
          if (productObservables.length > 0) {
            return forkJoin(productObservables).pipe(
              map((productDtos: ProductDetailDto[]) => {
                productDtos.forEach((productDto: ProductDetailDto) => {
                  this.thumbnailMap.push(this.thumbnailHelperService.getThumbnailMapRecord(productDto));
                });
                return orders;
              })
            );
          } else {
            return of(orders);
          }
        }),
        finalize(() => {
          this.canDisplayNoOrdersText = _.isEmpty(this.orders);
          this.blockUIElement.stop();
        })
      )
      .subscribe(pagedOrders => {
        if (this.selectMode || this.ecareSearch) {
          this.pagedDto = pagedOrders;
          this.orders = pagedOrders.data;
        } else {
          this.orders = pagedOrders.data.filter(order => order.orderItems.length > 0);
        }

        if (this.rewriteOrderStateCounts) {
          this.prepareOrdersByStatus();
        }
        this.rewriteOrderStateCounts = true;
      });
  }

  private getProductThumbnail(productDto: ProductDetailDto) {
    let thumbnail = null;
    for (const picture of productDto.pictures) {
      if (picture.pictureMap['PRODUCT_GENERAL_THUMBNAIL']) {
        thumbnail = picture.pictureMap['PRODUCT_GENERAL_THUMBNAIL'].href;
        break;
      }
    }
    return thumbnail;
  }

  isItemThumbnailAllowed(product: ProductDetailDto) {
    return (
      product.parametersStatic['orderSummary'] == 'true' &&
      !ProductUtils.isOfAnyCategories(product, this.DISALLOWED_ORDER_ITEM_CATEGORIES) &&
      (ProductUtils.isOfCategory(product, CategoryTypeEnum.PRODC) ||
        ProductUtils.isOfCategory(product, CategoryTypeEnum.PRD_L))
    );
  }

  prepareOrdersByStatus() {
    this.ordersByState = [];
    this.ordersByState.push({ orderState: this.DEFAULT_SELECTED_STATE, count: 0 });
    this.orders.forEach(order => {
      const statusIndex = this.ordersByState.findIndex(stateObject => stateObject.orderState === order.orderCalcStateType);
      if (statusIndex !== -1) {
        this.ordersByState[statusIndex].count = this.ordersByState[statusIndex].count + 1;
      } else {
        this.ordersByState.push({ orderState: order.orderCalcStateType, count: 1 });
      }
    });
    this.ordersByState[0].count = this.orders.length;
  }

  changeSelectedOrderState(orderState: string) {
    const changedStatus = orderState !== this.DEFAULT_SELECTED_STATE ? orderState : '';
    this.selectedOrderState = orderState;
    this.filterComponent.filterForm.value.status = changedStatus;
    this.filterComponent.filterForm.controls['status'].setValue(changedStatus);
    this.rewriteOrderStateCounts = false;
    this.filterComponent.handleChange();
  }

  itemSelected(orderDto: OrderDto): void {
    this.selectedOrder.emit(orderDto);
  }

  onPrevPage() {
    this.search.paging.page--;
    this.loadData();
  }

  onNextPage() {
    this.search.paging.page++;
    this.loadData();
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.page = 1;
    this.search.paging.pageSize = pageSize;
    this.loadData();
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.loadData();
  }

  onSortChange() {
    this.loadData();
  }

  orderByColumn($event: OrderSortingObject) {
    this.currentSortOrder = [];
    this.currentSortOrder.push({ column: $event.column, sortOrder: $event.sortOrder });
    this.search.sorting = this.currentSortOrder;
    this.rewriteOrderStateCounts = false;
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showStatusBar']) {
      this.showStatusBar = changes['showStatusBar'].currentValue;
    }
  }
}
