import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PageAccessErrorHandler } from '@service/errors/page-access-error-handler';
import { LoggerModule, NGXLogger } from 'ngx-logger';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  CmsWidgetBannerDetailSecHopTopComponent
} from './components/cms-templates/widgets/banner-detail-sec/target-hp_top/cms-widget-banner-detail-sec-hop-top.component';
import {
  CmsWidgetBannerFullImageHpHeroComponent
} from './components/cms-templates/widgets/banner-full-image/target-hp_hero/cms-widget-banner-full-image-hp-hero.component';
import {
  CmsWidgetBannerHalfImageHpHalfImagesComponent
} from './components/cms-templates/widgets/banner-half-image/target-hp_half_images/cms-widget-banner-half-image-hp-half-images.component';
import {
  CmsWidgetBannerOverlayHopTopComponent
} from './components/cms-templates/widgets/banner-overlay/target-hp_top/cms-widget-banner-overlay-hop-top.component';
import {
  CmsWidgetBannerIconListFirstComponent
} from './components/cms-templates/widgets/banner-icon-list/banner-first/cms-widget-banner-icon-list-first.component';
import {
  CmsWidgetBannerIconListSecondComponent
} from './components/cms-templates/widgets/banner-icon-list/banner-second/cms-widget-banner-icon-list-second.component';
import {
  CmsWidgetBannerBrandListComponent
} from './components/cms-templates/widgets/banner-brand-list/cms-widget-banner-brand-list.component';
import {
  EcareCustomerAccountCreateComponent
} from './components/wizard/ecare-dashboard/ecare-customer-acccount-create/ecare-customer-account-create.component';
import {
  ContentShareIconsComponent
} from './components/wizard/detail/product-detail/content-share/content-share-icons.component';
import {
  EmailContentSharingModalComponent
} from './components/wizard/detail/product-detail/content-share/email-content-sharing/email-content-sharing-modal.component';
import {
  ViberContentSharingModalComponent
} from './components/wizard/detail/product-detail/content-share/viber-content-sharing/viber-content-sharing-modal.component';
import { HomepageComponent } from './components/wizard/homepage/homepage.component';
import { ProductListingOldComponent } from './components/wizard/product-listing-old/product-listing-old.component';
import { ProductListingComponent } from './components/wizard/product-listing/product-listing.component';
import { ListingComponent } from './components/wizard/product-listing/listing/listing.component';
import { FiltersComponent } from './components/wizard/product-listing/filters/filters.component';
import { SortingComponent } from './components/wizard/product-listing/sorting/sorting.component';
import {
  PhoneModelSelectComponent
} from './components/wizard/product-listing/phone-model-select/phone-model-select.component';
import { ProductDetailComponent } from './components/wizard/detail/product-detail/product-detail.component';
import { ShoppingCartComponent } from './components/wizard/shopping-cart/shopping-cart.component';
import { CheckoutPageComponent } from './components/wizard/checkout-page/checkout-page.component';
import {
  CheckoutAccountSelectionComponent
} from './components/wizard/checkout-account-selection/checkout-account-selection.component';
import { LookForCustomerComponent } from './components/wizard/look-for-customer/look-for-customer.component';
import { CreateCustomerComponent } from './components/wizard/create-customer/create-customer.component';
import { ThankYouPageComponent } from './components/wizard/thank-you-page/thank-you-page.component';
import { ProductFilterComponent } from './components/page/product-filter/product-filter.component';
import { BreadcrumbComponent } from './components/page/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/page/footer/footer.component';
import { NavigationMenuComponent } from './components/page/navigation-menu/navigation-menu.component';
import { OrderingWizardComponent } from './components/page/ordering-wizard/ordering-wizard.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { ErrorPageModalComponent } from './components/error-page/error-page-modal/error-page-modal.component';
import { SummaryPageComponent } from './components/wizard/summary-page/summary-page.component';
import {
  AclEvaluateComponent,
  AclService,
  APP_PROJECT_CONFIG_RUNTIME,
  APP_TENANT_CONFIG,
  AppAuthGuard,
  AppBlockerService,
  AuthFactoryService,
  AuthorizationHttpInterceptor,
  BlockTemplateComponent,
  CacheHttpInterceptor,
  CacheHttpInterceptorConfig,
  CodebookSelectionComponent,
  CodebookService,
  ContentService,
  CurrentLocaleService,
  DefaultErrorHandler,
  DefaultModule,
  DynamicComponentRegistry,
  DynamicSelectboxComponent,
  ForwardedHostHttpInterceptor,
  getCurrentHost,
  HTTP_CACHE_ALLOWED_ROUTES,
  HttpLoaderFactory,
  InputErrorsComponent,
  loadLocaleData,
  LocalizationService,
  LogErrorHandler,
  LogInfoHandler,
  MissingTranslationHandlerFactory,
  MultiTenancyInterceptor,
  PhoneNumberPipe,
  ProductGroupService,
  SafeHtmlPipe,
  SafeUrlPipe,
  WatchdogSubscriptionService
} from '@btl/btl-fe-wc-common';
import { environment } from '../environments/environment';
import * as orderBff from '@btl/order-bff';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import localeSl from '@angular/common/locales/sl';
import localeSq from '@angular/common/locales/sq';
import localeSr from '@angular/common/locales/sr';
import { ProductListingItemComponent } from './components/page/product-listing-item/product-listing-item.component';
import { OneTimePriceComponent } from './components/page/price/one-time-price.component';
import { ProductRatingComponent } from './components/page/product-rating/product-rating.component';
import { WcOrderingService } from '@service/wc-ordering.service';
import { InputRowComponent } from './components/page/input-row/input-row.component';
import { AddressFormComponent } from './components/page/address-form/address-form.component';
import { SummaryProductRowComponent } from './components/page/summary-product-row/summary-product-row.component';
import { ShoppingCartProductComponent } from './components/page/shopping-cart-product/shopping-cart-product.component';
import { ShoppingCartItemsComponent } from './components/page/shopping-cart-items/shopping-cart-items.component';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import { HwStoreMaterialManagementService } from '@service/hw-store-material-management.service';
import { NavigationMenuService } from '@service/navigation-menu.service';
import { PaginationComponent } from 'app/components/page/pagination/pagination.component';
import { OrderErrorHandler } from 'app/services/errors/order-error-handler';
import { NouisliderModule } from 'ng2-nouislider';
import { KeycloakService } from 'keycloak-angular'; //TODO REMOVE
import { ContextEntityRefResolverImpl } from './helpers/context-entity-ref-resolver-impl';
import { ClickOutsideDirective } from './helpers/click-outside.directive';
import { StickerTypeClassPipe } from './pipes/sticker-type-class.pipe';
import { RgbPipe } from './pipes/rgb.pipe';
import { DeliveryDatePipe } from './pipes/delivery-date.pipe';
import { initializer } from './app-init';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { ButtonComponent } from './components/button/button.component';
import { StickerComponent } from './components/sticker/sticker.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SectionTabsComponent } from './components/section-tabs/section-tabs.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { EcarePageLayoutComponent } from './components/ecare-page-layout/ecare-page-layout.component';
import { InfoBannerComponent } from './components/wizard/homepage/info-banner/info-banner.component';
import { CategoriesBannerComponent } from './components/wizard/homepage/categories-banner/categories-banner.component';
import { HalfImageBannerComponent } from './components/wizard/homepage/half-image-banner/half-image-banner.component';
import { TariffListingComponent } from './components/wizard/homepage/tariff-listing/tariff-listing.component';
import { BrandListingComponent } from './components/wizard/homepage/brand-listing/brand-listing.component';
import { HeroBannerComponent } from './components/wizard/homepage/hero-banner/hero-banner.component';
import { AlertComponent } from './components/alert/alert.component';
import { LazyLoadImageHooks } from './helpers/lazyLoad-image-hooks';
/* E-care components */
import { EcareLoginComponent } from './components/wizard/ecare-login/ecare-login.component';
import { EcareDashboardComponent } from './components/wizard/ecare-dashboard/ecare-dashboard.component';
import { EcareSearchComponent } from './components/wizard/ecare-search/ecare-search.component';
import { EcareOrdersComponent } from './components/wizard/ecare-orders/ecare-orders.component';
import { EcareTariffsComponent } from './components/wizard/ecare-tariffs/ecare-tariffs.component';
import { ContactFormComponent } from 'app/components/page/contact-form/contact-form.component';
import {
  CustomerAccountFormComponent
} from 'app/components/page/customer-account-form/customer-account-form.component';
import { CustomerFormComponent } from 'app/components/page/customer-form/customer-form.component';
import {
  IccidAttributeConfigurationComponent
} from './components/page/iccid-attribute-configuration/iccid-attribute-configuration.component';
import { CancelOrderComponent } from './components/page/cancel-order/cancel-order.component';
import { RecurrentPriceComponent } from './components/page/price/recurrent-price.component';
import { PaymentComponent } from './components/wizard/payment/payment.component';
import { ScoringService } from 'app/services/scoring.service';
import { ScoringTimeoutInterceptor } from 'app/helpers/scoring-timeout-interceptor';
import { MessageDialogComponent } from 'app/components/page/message-dialog/message-dialog.component';
import { PaymentService } from '@service/payment.service';
import { BlockUIModule } from 'ng-block-ui';
import { EllipsisModule } from 'ngx-ellipsis';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { EcareCustomerGuard } from 'app/helpers/ecare-customer-guard';
import { CustomerService } from 'app/services/customer.service';
import {
  ProductCommitmentInfoComponent
} from './components/page/product-commitment-info/product-commitment-info.component';
import { CustomerTileComponent } from 'app/components/page/customer-tile/customer-tile.component';
import {
  CustomerAccountTileComponent
} from 'app/components/page/customer-account-tile/customer-account-tile.component';
import { SubscriptionTileComponent } from 'app/components/page/subscription-tile/subscription-tile.component';
import { StandaloneTileComponent } from 'app/components/page/standalone-tile/standalone-tile.component';
import { PrepaidRechargeComponent } from 'app/components/page/prepaid-recharge/prepaid-recharge.component';
import { SimChangeComponent } from './components/page/sim-change/sim-change.component';
import { EcareOrderRowComponent } from './components/wizard/ecare-orders/components/ecare-order-row.component';
import { TariffChangeComponent } from './components/wizard/tariff-change/tariff-change.component';
import { InvoicePayComponent } from 'app/components/page/invoice-pay/invoice-pay.component';
import { PaymentResponseComponent } from 'app/components/page/payment-response/payment-response.component';
import {
  CustomerAccountDetailComponent
} from './components/page/customer-account-detail/customer-account-detail.component';
import { OrderBlockUiInterceptor } from 'app/helpers/order-block-ui-interceptor';
import { TariffDeactivationComponent } from './components/page/tariff-deactivation/tariff-deactivation.component';
import { TariffRestrictionComponent } from './components/page/tariff-restriction/tariff-restriction.component';
import {
  EcareBusinessDashboardComponent
} from './components/wizard/ecare-dashboard/ecare-business-dashboard.component';
import {
  EcareDashBoardSubscriptionComponent
} from './components/wizard/ecare-dashboard/ecare-dashboard-subscription.component';
import {
  EcareBusinessCustomerAccountComponent
} from './components/wizard/ecare-dashboard/ecare-business-customer-account.component';
import { EcareBusinessTariffComponent } from './components/wizard/ecare-dashboard/ecare-business-tariff.component';
import { SubscriptionDetailComponent } from 'app/components/page/subscription-detail/subscription-detail.component';
import {
  TariffSpaceConsumptionInfoComponent
} from 'app/components/page/tariff-space-consumption-info/tariff-space-consumption-info.component';
import { ProductComparisonComponent } from './components/page/product-comparison/product-comparison.component';
import {
  ComparedProductParameterComponent
} from './components/page/product-comparison/compared-product-parameter/compared-product-parameter.component';
import {
  ComparedProductAvailabilityComponent
} from './components/page/product-comparison/compared-product-availability/compared-product-availability.component';
import {
  ComparedProductPriceComponent
} from './components/page/product-comparison/compared-product-price/compared-product-price.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ProductGalleryComponent
} from './components/wizard/detail/product-detail/product-gallery/product-gallery.component';
import {
  WatchdogSubscriptionComponent
} from './components/wizard/detail/product-detail/watchdog-subscribe/watchdog-subscription.component';
import { KeyFeaturesComponent } from './components/wizard/detail/product-detail/key-features/key-features.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { ServiceDetailComponent } from './components/wizard/detail/service-detail/service-detail.component';
import { RatingStarsComponent } from './components/wizard/detail/product-detail/rating-stars/rating-stars.component';
import {
  RatingStarInfoComponent
} from './components/wizard/detail/product-detail/product-ratings-and-reviews/rating-star-info/rating-star-info.component';
import {
  ProductRatingsAndReviewsComponent
} from './components/wizard/detail/product-detail/product-ratings-and-reviews/product-ratings-and-reviews.component';
import {
  ReviewComponent
} from './components/wizard/detail/product-detail/product-ratings-and-reviews/review/review.component';
import { ProductCarouselComponent } from './components/page/product-carousel/product-carousel.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { PriorityPricePipe } from './pipes/priority-price.pipe';
import { PricePipe } from './pipes/price-pipe';
import { StripHtmlTagsPipe } from './pipes/strip-html.pipe';
import { StickyBarComponent } from './components/page/sticky-bar/sticky-bar.component';
import {
  ComparedProductsBarComponent
} from './components/page/sticky-bar/compared-products-bar/compared-products-bar.component';
import { SeoService } from '@service/seo.service';
import { HomepageListingComponent } from './components/page/homepage-listing/homepage-listing.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { DeliveryComponent } from './components/wizard/delivery/delivery.component';
import { CheckoutScoringComponent } from './components/wizard/checkout-scoring/checkout-scoring.component';
import { TechnologyCheckComponent } from './components/wizard/technology-check/technology-check.component';
import { InstallationTermsComponent } from './components/page/installation-terms/installation-terms.component';
import { TimeSlotComponent } from './components/page/time-slot/time-slot.component';
import {
  CheckoutDepositItemsComponent
} from './components/page/checkout-deposit-items/checkout-deposit-items.component';
import { CheckoutDepositItemComponent } from './components/page/checkout-deposit-item/checkout-deposit-item.component';
import { LocationFormComponent } from './components/page/location-form/location-form.component';
import { DeliveryContactFormComponent } from './components/page/delivery-contact-form/delivery-contact-form.component';
import { DeliveryAddressFormComponent } from './components/page/delivery-address-form/delivery-address-form.component';
import { DisplayAddressPipe } from './pipes/display-address.pipe';
import {
  EcareFinancialDocumentsComponent
} from './components/wizard/ecare-financial-documents/ecare-financial-documents.component';
import {
  FinancialDocumentFilterComponent
} from './components/wizard/ecare-financial-documents/financial-document-filter/financial-document-filter.component';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule } from 'ng-lazyload-image';
import { MostSoldComponent } from './components/page/most-sold/most-sold.component';
import { BoxItemComponent } from './components/page/product-listing-item/box-item/box-item.component';
import {
  AddonActivationGroupsComponent
} from './components/page/addon-activation-groups/addon-activation-groups.component';
import {
  AddonActivationAddonComponent
} from './components/page/addon-activation-addon/addon-activation-addon.component';
import {
  AclInternalEvaluateComponentComponent
} from './components/acl-internal-evaluate-component/acl-internal-evaluate-component.component';
import { SuspendOrderComponent } from './components/wizard/checkout-page/suspend-order/suspend-order.component';
import {
  OrderCollisionPopupComponent
} from './components/page/ordering-wizard/order-collision-popup/order-collision-popup.component';
import { WishListPopupComponent } from './components/wizard/wish-list/wish-list-popup/wish-list-popup.component';
import { WishListDetailComponent } from './components/wizard/wish-list/wish-list-detail/wish-list-detail.component';
import {
  ShareWishListPopupComponent
} from './components/wizard/wish-list/share-wish-list-popup/share-wish-list-popup.component';
import { DisplayContactPipe } from './pipes/display-contact.pipe';
import { SystemIdInterceptor } from './helpers/system-id-interceptor';
import { CookieService } from 'ngx-cookie-service';
import {
  OrderingWizardActionsComponent
} from './components/page/ordering-wizard-actions/ordering-wizard-actions.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  WishListItemPopupComponent
} from './components/wizard/wish-list/wish-list-popup/wish-list-item-popup/wish-list-item-popup.component';
import { EcareSettingsComponent } from './components/wizard/ecare-settings/ecare-settings.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DynamicModule } from 'ng-dynamic-component';
import { AppPageTemplateComponent } from './components/page/app-page-template/app-page-template.component';
import {
  CmsWidgetCikCakPhoneComponent
} from './components/cms-templates/widgets/cik-cak-phone/cms-widget-cik-cak-phone.component';
import { CmsWidgetHeadlineComponent } from './components/cms-templates/widgets/headline/cms-widget-headline.component';
import {
  CmsWidgetPageImageComponent
} from './components/cms-templates/widgets/page-image/cms-widget-page-image.component';
import {
  CmsWidgetProductsInRowComponent
} from './components/cms-templates/widgets/products-in-row/cms-widget-products-in-row.component';
import { CmsPageTemplateBlankComponent } from './components/cms-templates/page/blank/cms-page-template-blank.component';
import {
  CmsWidgetIconsListComponent
} from './components/cms-templates/widgets/icons-list/cms-widget-icons-list.component';
import {
  CmsWidgetBoxImagesListComponent
} from './components/cms-templates/widgets/box-images-list/cms-widget-box-images-list.component';
import { CmsWidgetVideoComponent } from './components/cms-templates/widgets/video/cms-widget-video.component';
import {
  CmsWidgetVideoListComponent
} from './components/cms-templates/widgets/video-list/cms-widget-video-list.component';
import {
  CmsWidgetTipImagesListComponent
} from './components/cms-templates/widgets/tip-images-list/cms-widget-tip-images-list.component';
import {
  EcareCustomerAccountCardComponent
} from 'app/components/wizard/ecare-dashboard/ecare-customer-account-card/ecare-customer-account-card.component';
import {
  EcareCustomerAccountBoxComponent
} from './components/wizard/ecare-dashboard/ecare-customer-account-box/ecare-customer-account-box.component';
import {
  EcareResidentCostumerAccountComponent
} from 'app/components/wizard/ecare-dashboard/ecare-resident-costumer-account.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { CountryCode } from 'libphonenumber-js';
import { ProductConfigurationComponent } from './components/page/product-configuration/product-configuration.component';
import {
  SimpleContactControlComponent
} from './components/page/product-configuration/simple-contact-control/simple-contact-control.component';
import {
  MultiIpControlComponent
} from './components/page/product-configuration/multi-ip-control/multi-ip-control.component';
import {
  AddressSearchControlComponent
} from './components/page/product-configuration/address-search-control/address-search-control.component';
import { EcareSwiperComponent } from 'app/components/wizard/ecare-dashboard/ecare-swiper/ecare-swiper.component';
import { SwiperModule } from 'swiper/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { EcareOrderTableComponent } from './components/wizard/ecare-orders/components/ecare-order-table.component';
import {
  EcareOrdersFilterComponent
} from './components/wizard/ecare-orders/ecare-orders-filter/ecare-orders-filter.component';
import {
  EcareTariffSharedUnitsTileComponent
} from 'app/components/wizard/ecare-dashboard/ecare-tariff-shared-units-swiper/ecare-tariff-shared-units-tile/ecare-tariff-shared-units-tile.component';
import {
  EcareTariffSharedUnitsSwiperComponent
} from 'app/components/wizard/ecare-dashboard/ecare-tariff-shared-units-swiper/ecare-tariff-shared-units-swiper.component';
import {
  EcareServicesSwiperComponent
} from 'app/components/wizard/ecare-dashboard/ecare-services-swiper/ecare-services-swiper.component';
import {
  EcareContactHistoryComponent
} from './components/wizard/ecare-contact-history/ecare-contact-history.component';
import {
  EcareContactHistoryMobileComponent
} from './components/wizard/ecare-contact-history/ecare-contact-history-mobile.component';
import {
  ContactHistoryFilterComponent
} from './components/wizard/ecare-contact-history/contact-history-filter/contact-history-filter.component';
import {
  ContactHistoryDetailsComponent
} from './components/wizard/ecare-contact-history/contact-history-details/contact-history-details.component';
import { FilesAddModalComponent } from './components/files-add-modal/files-add-modal.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import {
  EcareSearchFilterComponent
} from './components/wizard/ecare-search/ecare-search-filter/ecare-search-filter.component';
import { EcareHomepageComponent } from './components/wizard/ecare-homepage/ecare-homepage.component';
import { EcareTasksComponent } from './components/wizard/ecare-tasks/ecare-tasks.component';
import { EcareNotificationsComponent } from './components/wizard/ecare-notifications/ecare-notifications.component';
import {
  NotificationsFilterComponent
} from './components/wizard/ecare-notifications/notification-filter/notifications-filter.component';
import {
  NotificationDetailComponent
} from './components/wizard/ecare-notifications/notification-detail/notification-detail.component';
import { FormAttachmentsComponent } from './components/form-attachments/form-attachments.component';
import { FormRecipientsComponent } from './components/form-recipients/form-recipients.component';
import {
  AddRecipientModalComponent
} from './components/form-recipients/add-recipient-modal/add-recipient-modal.component';
import { CustomerContactsComponent } from './components/wizard/customer-contacts/customer-contacts.component';
import {
  CustomerContactsMobileComponent
} from './components/wizard/customer-contacts/customer-contacts-mobile.component';
import { EcareListingNoResultsComponent } from './components/wizard/ecare-listing/ecare-listing-no-results.component';
import { EcareOpportunitiesComponent } from './components/wizard/ecare-opportunities/ecare-opportunities.component';
import {
  EcareOpportunitiesMobileComponent
} from './components/wizard/ecare-opportunities/ecare-opportunities-mobile.component';
import {
  OpportunityFilterComponent
} from './components/wizard/ecare-opportunities/opportunity-filter/opportunity-filter.component';
import {
  OpportunityDetailsComponent
} from './components/wizard/ecare-opportunities/opportunity-details/opportunity-details.component';
import {
  EcareTasksTableComponent
} from './components/wizard/ecare-tasks/ecare-tasks-table/ecare-tasks-table.component';
import {
  EcareTasksTableMobileComponent
} from './components/wizard/ecare-tasks/ecare-tasks-table/ecare-tasks-table-mobile.component';
import { EcareReportsComponent } from './components/wizard/ecare-reports/ecare-reports.component';
import { EcareReportsMobileComponent } from './components/wizard/ecare-reports/ecare-reports-mobile.component';
import { ReportDetailsComponent } from './components/wizard/ecare-reports/report-details/report-details.component';
import { ReportFilterComponent } from './components/wizard/ecare-reports/report-filter/report-filter.component';
import { CallMeBackModalComponent } from './components/call-me-back-modal/call-me-back-modal.component';
import { TicketsComponent } from './components/wizard/tickets/tickets.component';
import { TicketFilterComponent } from './components/wizard/tickets/filter/ticket-filter.component';
import { TicketEditComponent } from './components/wizard/tickets/edit/ticket-edit.component';
import {
  CreateTicketModalComponent
} from './components/wizard/tickets/create-ticket-modal/create-ticket-modal.component';
import {
  DuplicateTicketModalComponent
} from './components/wizard/tickets/duplicate-ticket-modal/duplicate-ticket-modal.component';
import {
  EcareConfirmationDialogComponent
} from './components/page/ecare-confirmation-dialog/ecare-confirmation-dialog.component';
import { ModalFactoryService } from '@service/modal-factory.service';
import {
  CmsWidgetFullImageComponent
} from './components/cms-templates/widgets/full-image/cms-widget-full-image.component';
import { FormSelectInput } from './components/form-select-input/form-select-input.component';
import {
  FormAddressSearchInputComponent
} from './components/form-address-search-input/form-address-search-input.component';
import {
  EcareUserAccountAclComponent
} from './components/wizard/ecare-user-account/user-account-acl/ecare-user-account-acl.component';
import {
  EcareUserAccountAppearanceComponent
} from './components/wizard/ecare-user-account/user-account-appearance/ecare-user-account-appearance.component';
import {
  EcareUserAccountDetailsComponent
} from './components/wizard/ecare-user-account/user-account-details/ecare-user-account-details.component';
import {
  EcareUserAccountNotificationsComponent
} from './components/wizard/ecare-user-account/user-account-notifications/ecare-user-account-notifications.component';
import {
  EcareUserAccountPaymentsComponent
} from './components/wizard/ecare-user-account/user-account-payments/ecare-user-account-payments.component';
import {
  EcareUserAccountSecurityComponent
} from './components/wizard/ecare-user-account/user-account-security/ecare-user-account-security.component';
import {
  EcareUserAccountSkillsComponent
} from './components/wizard/ecare-user-account/user-account-skills/ecare-user-account-skills.component';
import { EcareUserAccountComponent } from './components/wizard/ecare-user-account/ecare-user-account.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {
  UserAccountDeletePopupComponent
} from './components/wizard/ecare-user-account/user-account-delete-popup/user-account-delete-popup.component';
import {
  EntityGenerateDocumentModalComponent
} from './components/entity-generate-document-modal/entity-generate-document-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
  QuoteDetailsPopupComponent
} from './components/wizard/ecare-opportunities/opportunity-details/quote-details-popup/quote-details-popup.component';
import {
  EcareUserAccountLinkedAccountsComponent
} from './components/wizard/ecare-user-account/user-account-linked-account/ecare-user-account-linked-accounts.component';
import { CmsWidgetBannerComponent } from './components/cms-templates/widgets/banner/cms-widget-banner.component';
import { WishListsComponent } from './components/wizard/wish-list/wish-lists/wish-lists.component';
import {
  DeleteWishListPopupComponent
} from './components/wizard/wish-list/delete-wish-list-popup/delete-wish-list-popup.component';
import {
  FormPhoneNumberComponent
} from './components/wizard/ecare-settings/form-phone-number/form-phone-number.component';
import { EcareDocumentRowComponent } from './components/wizard/ecare-documents/ecare-document-row.component';
import { EcareDocumentsComponent } from './components/wizard/ecare-documents/ecare-documents.component';
import {
  EcareDocumentsFilterComponent
} from './components/wizard/ecare-documents/ecare-documents-filter/ecare-documents-filter.component';
import { DocumentsPopupComponent } from './components/wizard/ecare-documents/documents-popup/documents-popup.component';
import { EcareBreadcrumbsComponent } from './components/wizard/ecare-breadcrumbs/ecare-breadcrumbs.component';
import { NavigationThirdMenuComponent } from './components/page/navigation-third-menu/navigation-third-menu.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import {
  AccountEntityDetailsComponent
} from './components/wizard/ecare-account-entity/account-entity-details/account-entity-details.component';
import { EcareExtHomepageComponent } from './components/wizard/ecare-ext-homepage/ecare-ext-homepage.component';
import {
  EcareCustomerCardComponent
} from './components/wizard/ecare-ext-homepage/ecare-customer-card/ecare-customer-card.component';
import {
  EcareAccountEntityFilterComponent
} from './components/wizard/ecare-account-entity/ecare-account-entity-filter/ecare-account-entity-filter.component';
import { EcareAccountEntityComponent } from './components/wizard/ecare-account-entity/ecare-account-entity.component';
import {
  EcareAccountEntityTableComponent
} from './components/wizard/ecare-account-entity/ecare-account-entity-table/ecare-account-entity-table.component';
import {
  EcareAccountEntityTableMobileComponent
} from './components/wizard/ecare-account-entity/ecare-account-entity-table/ecare-account-entity-table-mobile.component';
import { CmsWidgetButtonComponent } from './components/cms-templates/widgets/button/cms-widget-button.component';
import {
  CmsWidgetAccordionComponent
} from './components/cms-templates/widgets/accordion/cms-widget-accordion.component';
import {
  EcareOrdersDetailComponent
} from './components/wizard/ecare-orders/ecare-orders-detail/ecare-orders-detail.component';
import { GoogleMapsModule } from '@angular/google-maps';
import {
  FormPickUpPointInputComponent
} from './components/form-pick-up-point-input/form-pick-up-point-input.component';
import {
  FormPickUpPointModalComponent
} from './components/form-pick-up-point-input/form-pick-up-point-modal/form-pick-up-point-modal.component';
import { GoogleMapComponent } from './components/form-pick-up-point-input/google-map/google-map.component';
import { OpenLayerMapComponent } from './components/form-pick-up-point-input/open-layer-map/open-layer-map.component';
import { EntityGeneratedFormComponent } from './components/entity-generated-form/entity-generated-form.component';
import {
  FormPickUpPointInputWithModalComponent
} from './components/form-pick-up-point-input/form-pick-up-point-input-with-modal.component';
import {
  DelegateModalComponent
} from './components/wizard/ecare-account-entity/delegate-modal/delegate-modal.component';
import { FormAccountInputComponent } from './components/form-account-input/form-account-input.component';
import { BusinessEventsComponent } from './components/events/business-events.component';
import { EventsFilterComponent } from './components/events/events-filter/events-filter.component';
import { BusinessEventRowComponent } from './components/events/business-event-row.component';
import { EcareCustomersComponent } from './components/wizard/ecare-customers/ecare-customers.component';
import { EcareCustomerRowComponent } from './components/wizard/ecare-customers/ecare-customer-row.component';
import {
  EcareCustomersFilterComponent
} from './components/wizard/ecare-customers/ecare-customers-filter/ecare-customers-filter.component';
import {
  CustomerSelectionPopupComponent
} from './components/wizard/ecare-customers/customer-selection-popup/customer-selection-popup.component';
import {
  EcareCustomerAccountsComponent
} from './components/wizard/ecare-customer-accounts/ecare-customer-accounts.component';
import {
  EcareCustomerAccountRowComponent
} from './components/wizard/ecare-customer-accounts/ecare-customer-account-row.component';
import {
  EcareCustomerAccountsFilterComponent
} from './components/wizard/ecare-customer-accounts/ecare-customer-accounts-filter/ecare-customer-accounts-filter.component';
import {
  CustomerAccountSelectionPopupComponent
} from './components/wizard/ecare-customer-accounts/customer-account-selection-popup/customer-account-selection-popup.component';
import { FormFieldModalPicker } from './components/form-field-modal-picker/form-field-modal-picker.component';
import { EcareUsersComponent } from './components/wizard/ecare-users/ecare-users.component';
import { EcareUserRowComponent } from './components/wizard/ecare-users/ecare-user-row.component';
import {
  UserSelectionPopupComponent
} from './components/wizard/ecare-users/user-selection-popup/user-selection-popup.component';
import {
  EcareUsersFilterComponent
} from './components/wizard/ecare-users/ecare-users-filter/ecare-users-filter.component';
import {
  OrderSelectionPopupComponent
} from './components/wizard/ecare-orders/order-selection-popup/order-selection-popup.component';
import { FormAgreementComponent } from './components/form-agreements/form-agreement/form-agreement.component';
import {
  EcareUserAccountAgreementsComponent
} from './components/wizard/ecare-user-account/user-account-agreements/ecare-user-account-agreements.component';
import { FormAgreementsComponent } from './components/form-agreements/form-agreements.component';
import { ShopSelectionPopupComponent } from './components/page/shop-selection-popup/shop-selection-popup.component';
import {
  FormShopSelectionInputWithModalComponent
} from './components/page/shop-selection-popup/form-shop-selection-input-with-modal.component';
import { ShopSelectionRowComponent } from './components/page/shop-selection-popup/shop-selection-row.component';
import {
  EcareUserAccountContactsComponent
} from './components/wizard/ecare-user-account/user-account-contacts/ecare-user-account-contacts.component';
import {
  ContactEditPopupComponent
} from './components/wizard/ecare-user-account/user-account-contacts/contact-edit-popup/contact-edit-popup.component';
import {
  UserContactsComponent
} from './components/wizard/ecare-user-account/user-account-contacts/user-contacts/user-contacts.component';
import {
  FacebookCommentsComponent
} from './components/wizard/detail/product-detail/facebook-comments/facebook-comments.component';
import { SocialNetworksService } from '@service/social-networks.service';
import { CookiePolicyBarComponent } from './components/page/footer/cookie-policy/cookie-policy-bar.component';
import {
  CookiePolicySettingsModalComponent
} from './components/page/footer/cookie-policy/cookie-policy-settings/cookie-policy-settings-modal.component';
import {
  ConfirmationDialogBorderComponent
} from './components/confirmation-dialog/confirmation-dialog-border.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import {
  StickyPriceBarComponent
} from './components/wizard/detail/product-detail/sticky-price-bar/sticky-price-bar/sticky-price-bar.component';
import {
  OrdersDetailSideBoxComponent
} from './components/wizard/ecare-orders/ecare-orders-detail/orders-detail-side-box/orders-detail-side-box.component';
import { SideboxRowComponent } from './components/wizard/ecare-dashboard/sidebox-row/sidebox-row.component';
import { StickyBarService } from '@service/sticky-bar.service';
import { NewInvoiceDetailsComponent } from './components/wizard/new-invoice-details/new-invoice-details.component';
import { DeliveryMethodComponent } from './components/wizard/delivery/delivery-method/delivery-method.component';
import { PaymentMethodComponent } from './components/wizard/payment-method/payment-method.component';
import {
  CheckoutShoppingCartComponent
} from './components/wizard/checkout-shopping-cart/checkout-shopping-cart.component';
import {
  CheckoutShoppingCartSecondaryActionsComponent
} from './components/wizard/checkout-shopping-cart/checkout-shopping-cart-secondary-actions.component';
import {
  EcareMobileRowGeneralComponent
} from './components/wizard/ecare-common-components/ecare-mobile-row-general/ecare-mobile-row-general.component';
import {
  EcareOrderRowMobileComponent
} from './components/wizard/ecare-orders/components/ecare-order-row-mobile.component';
import {
  EcareOrderTableMobileComponent
} from './components/wizard/ecare-orders/components/ecare-order-table-mobile.component';
import {
  ThumbnailContainerComponent
} from './components/wizard/ecare-common-components/thumbnail-container/thumbnail-container.component';
import {
  ReservationErrorModalComponent
} from './components/page/payment-response/reservation-error-modal/reservation-error-modal.component';
import { TimeSlotChipsComponent } from './components/time-slot-chips/time-slot-chips.component';
import {
  DynamicComponentRegistryInitComponent
} from './components/dynamic-component-registry-prod-init/dynamic-component-registry-init.component';
import {
  EcareWidgetMainInfoComponent
} from './components/wizard/ecare-dashboard/ecare-widget-main-info/ecare-widget-main-info.component';
import {
  EcareSectionComponent
} from './components/wizard/ecare-general-components/ecare-section/ecare-section.component';
import {
  EcareFixInternetTariffWidgetComponent
} from './components/wizard/ecare-dashboard/ecare-fix-internet-tariff-widget/ecare-fix-internet-tariff-widget.component';
import {
  EcareTvTariffWidgetComponent
} from './components/wizard/ecare-dashboard/ecare-tv-tariff-widget/ecare-tv-tariff-widget.component';
import {
  EcareFilterAutocompleteBoxComponent
} from './components/wizard/ecare-general-components/ecare-filter-autocomplete-box/ecare-filter-autocomplete-box.component';
import {
  AdvancedFilterFieldComponent
} from './components/wizard/ecare-general-components/advanced-filter/advanced-filter-field.component';
import {
  AdvancedFilterComponent
} from './components/wizard/ecare-general-components/advanced-filter/advanced-filter.component';
import { LastTicketsWidgetComponent } from './components/last-tickets-widget/last-tickets-widget.component';
import { LastInvoicesWidgetComponent } from './components/last-invoices-widget/last-invoices-widget.component';
import {
  EcareLastPaymentWidgetComponent
} from './components/wizard/ecare-dashboard/ecare-last-payment-widget/ecare-last-payment-widget.component';
import { LastOrdersWidgetComponent } from './components/last-orders-widget/last-orders-widget.component';
import { HelpAndSupportWidgetComponent } from './components/help-and-support-widget/help-and-support-widget.component';
import {
  MobileTariffWidgetComponent
} from './components/wizard/ecare-dashboard/mobile-tariff-widget/mobile-tariff-widget.component';
import {
  EcareCustomerAccountWidgetComponent
} from './components/wizard/ecare-dashboard/ecare-customer-account-widget/ecare-customer-account-widget.component';
import { EcareSearchTableMobileComponent } from './components/wizard/ecare-search/ecare-search-table-mobile.component';
import {
  EcareSearchTableMobileRowComponent
} from './components/wizard/ecare-search/ecare-search-table-mobile-row.component';
import {
  CreateCustomerPopupComponent
} from './components/wizard/ecare-homepage/create-customer-popup/create-customer-popup.component';
import {
  ShoppingCartSideMenuComponent
} from './components/page/navigation-menu/shopping-cart-side-menu/shopping-cart-side-menu.component';
import { TopMenuComponent } from './components/page/navigation-menu/top-menu/top-menu.component';
import { CustomerMenuComponent } from './components/page/navigation-menu/customer-menu/customer-menu.component';
import { MobileMenuComponent } from './components/page/navigation-menu/mobile-menu/mobile-menu.component';
import {
  TopMenuActionComponent
} from './components/page/navigation-menu/top-menu/top-menu-action/top-menu-action.component';
import {
  EcareSearchSelectDropdownComponent
} from './components/wizard/ecare-search/ecare-search-select-dropdown/ecare-search-select-dropdown.component';
import {
  EcareMobileInternetTariffWidgetComponent
} from './components/wizard/ecare-dashboard/ecare-mobile-internet-tariff-widget/ecare-mobile-internet-tariff-widget.component';
import {
  EcareFixVoiceTariffWidgetComponent
} from './components/wizard/ecare-dashboard/ecare-fix-voice-tariff-widget/ecare-fix-voice-tariff-widget.component';
import {
  EcareMobileUsageTariffWidgetComponent
} from './components/wizard/ecare-dashboard/ecare-mobile-usage-tariff-widget/ecare-mobile-usage-tariff-widget.component';
import {
  EcareUsageAlertsWidgetComponent
} from './components/wizard/ecare-dashboard/ecare-usage-alerts-widget/ecare-usage-alerts-widget.component';
import {
  EcareBannerWidgetComponent
} from './components/wizard/ecare-dashboard/ecare-banner-widget/ecare-banner-widget.component';
import {
  CmsWidgetBannerFullImageComponent
} from './components/cms-templates/widgets/banner-full-image/default/cms-widget-banner-full-image.component';
import {
  SubscriptionSelectWidgetComponent
} from './components/wizard/ecare-dashboard/subscription-select-widget/subscription-select-widget.component';
import {
  EcareContactHistoryExpandedRowComponent
} from './components/wizard/ecare-contact-history/ecare-contact-history-expanded-row/ecare-contact-history-expanded-row.component';
import {
  EcareOpportunityExpandedRowComponent
} from './components/wizard/ecare-opportunities/ecare-opportunity-expanded-row/ecare-opportunity-expanded-row.component';
import { TicketNotesFormComponent } from './components/wizard/tickets/ticket-notes-form/ticket-notes-form.component';
import { EcareBreadcrumbComponent } from './components/page/ecare-breadcrumb/ecare-breadcrumb.component';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {
  EcareTaskExpandedRowComponent
} from './components/wizard/ecare-opportunities/ecare-tasks-expanded-row/ecare-task-expanded-row.component';
import { ShoppingCartItemComponent } from './components/page/shopping-cart-item/shopping-cart-item.component';
import {
  ShoppingCartAddProductToSocketComponent
} from './components/page/shopping-cart-add-product-to-socket/shopping-cart-add-product-to-socket.component';
import { ConfigurableFormComponent } from './components/configurable-form/configurable-form.component';
import {
  EntityGeneratedFormInputComponent
} from './components/entity-generated-form/entity-generated-form-input/entity-generated-form-input.component';
import {
  AddressEditPopupComponent
} from './components/wizard/ecare-user-account/user-account-contacts/address-edit-popup/address-edit-popup.component';
import { ShoppingCartTotalsComponent } from './components/page/shopping-cart-totals/shopping-cart-totals.component';
import { ParameterDataComponent } from './components/wizard/parameters-data/parameter-data.component';

loadLocaleData(environment.localization.localeData);

/* default routes */
@NgModule({
  declarations: [
    AddressFormComponent,
    AppComponent,
    BreadcrumbComponent,
    CheckoutAccountSelectionComponent,
    CheckoutPageComponent,
    CheckoutShoppingCartComponent,
    CheckoutShoppingCartSecondaryActionsComponent,
    CreateCustomerComponent,
    EcareDashboardComponent,
    EcareLoginComponent,
    FooterComponent,
    HomepageComponent,
    InputRowComponent,
    LookForCustomerComponent,
    NavigationMenuComponent,
    OneTimePriceComponent,
    OrderingWizardComponent,
    PaginationComponent,
    ProductDetailComponent,
    ProductFilterComponent,
    ProductListingComponent,
    ProductListingItemComponent,
    ProductListingOldComponent,
    ProductRatingComponent,
    RecurrentPriceComponent,
    ShoppingCartComponent,
    ShoppingCartItemComponent,
    ShoppingCartItemsComponent,
    SummaryPageComponent,
    SummaryProductRowComponent,
    ThankYouPageComponent,
    EcareSearchComponent,
    EcareSearchFilterComponent,
    EcareOrdersComponent,
    EcareOrdersFilterComponent,
    EcareTariffsComponent,
    EcareFinancialDocumentsComponent,
    ContactFormComponent,
    CustomerAccountFormComponent,
    CustomerFormComponent,
    IccidAttributeConfigurationComponent,
    CancelOrderComponent,
    PaymentComponent,
    MessageDialogComponent,
    EcareConfirmationDialogComponent,
    UserAccountDeletePopupComponent,
    ProductCommitmentInfoComponent,
    CustomerTileComponent,
    CustomerAccountTileComponent,
    SubscriptionTileComponent,
    StandaloneTileComponent,
    PrepaidRechargeComponent,
    SimChangeComponent,
    EcareOrderRowComponent,
    EcareOrderTableComponent,
    TariffChangeComponent,
    InvoicePayComponent,
    PaymentResponseComponent,
    CustomerAccountDetailComponent,
    EcareCustomerAccountCreateComponent,
    TariffDeactivationComponent,
    TariffRestrictionComponent,
    EcareBusinessDashboardComponent,
    EcareDashBoardSubscriptionComponent,
    EcareBusinessCustomerAccountComponent,
    EcareBusinessTariffComponent,
    SubscriptionDetailComponent,
    TariffSpaceConsumptionInfoComponent,
    ProductComparisonComponent,
    ComparedProductParameterComponent,
    ComparedProductAvailabilityComponent,
    ComparedProductPriceComponent,
    ProductGalleryComponent,
    PhoneModelSelectComponent,
    FiltersComponent,
    SortingComponent,
    ListingComponent,
    PriorityPricePipe,
    RgbPipe,
    StickerTypeClassPipe,
    DeliveryDatePipe,
    HighlightPipe,
    WatchdogSubscriptionComponent,
    KeyFeaturesComponent,
    PricePipe,
    StripHtmlTagsPipe,
    ServiceDetailComponent,
    RatingStarsComponent,
    RatingStarInfoComponent,
    ProductRatingsAndReviewsComponent,
    ReviewComponent,
    ProductCarouselComponent,
    StickyBarComponent,
    ComparedProductsBarComponent,
    HomepageListingComponent,
    DeliveryComponent,
    DeliveryMethodComponent,
    PaymentMethodComponent,
    HomepageListingComponent,
    CheckoutScoringComponent,
    TechnologyCheckComponent,
    InstallationTermsComponent,
    TimeSlotComponent,
    TimeSlotChipsComponent,
    CheckoutDepositItemsComponent,
    CheckoutDepositItemComponent,
    LocationFormComponent,
    DeliveryContactFormComponent,
    DeliveryAddressFormComponent,
    DisplayAddressPipe,
    FinancialDocumentFilterComponent,
    MostSoldComponent,
    BoxItemComponent,
    AddonActivationGroupsComponent,
    AddonActivationAddonComponent,
    AclInternalEvaluateComponentComponent,
    SuspendOrderComponent,
    OrderCollisionPopupComponent,
    DisplayContactPipe,
    OrderingWizardActionsComponent,
    WishListPopupComponent,
    WishListDetailComponent,
    NewInvoiceDetailsComponent,
    ShareWishListPopupComponent,
    WishListItemPopupComponent,
    EcareSettingsComponent,
    EcareCustomerAccountCardComponent,
    EcareResidentCostumerAccountComponent,
    AppPageTemplateComponent,
    CmsPageTemplateBlankComponent,
    CmsWidgetCikCakPhoneComponent,
    CmsWidgetHeadlineComponent,
    CmsWidgetPageImageComponent,
    CmsWidgetFullImageComponent,
    CmsWidgetProductsInRowComponent,
    CmsWidgetIconsListComponent,
    CmsWidgetBoxImagesListComponent,
    CmsWidgetVideoComponent,
    CmsWidgetVideoListComponent,
    CmsWidgetTipImagesListComponent,
    CmsWidgetBannerComponent,
    CmsWidgetBannerFullImageComponent,
    CmsWidgetBannerFullImageHpHeroComponent,
    CmsWidgetBannerHalfImageHpHalfImagesComponent,
    CmsWidgetBannerDetailSecHopTopComponent,
    CmsWidgetBannerOverlayHopTopComponent,
    CmsWidgetBannerIconListFirstComponent,
    CmsWidgetBannerIconListSecondComponent,
    CmsWidgetBannerBrandListComponent,
    CmsWidgetButtonComponent,
    CmsWidgetAccordionComponent,
    ProductConfigurationComponent,
    SimpleContactControlComponent,
    MultiIpControlComponent,
    AddressSearchControlComponent,
    EcareSwiperComponent,
    EcareTariffSharedUnitsTileComponent,
    EcareTariffSharedUnitsSwiperComponent,
    EcareServicesSwiperComponent,
    EcareSwiperComponent,
    EcareContactHistoryComponent,
    EcareContactHistoryMobileComponent,
    ContactHistoryFilterComponent,
    ContactHistoryDetailsComponent,
    FilesAddModalComponent,
    EcareHomepageComponent,
    EcareTasksComponent,
    EcareTasksTableComponent,
    EcareTasksTableMobileComponent,
    EcareHomepageComponent,
    EmailContentSharingModalComponent,
    ViberContentSharingModalComponent,
    EcareNotificationsComponent,
    NotificationsFilterComponent,
    NotificationDetailComponent,
    FormAttachmentsComponent,
    FormRecipientsComponent,
    AddRecipientModalComponent,
    CustomerContactsComponent,
    CustomerContactsMobileComponent,
    EcareListingNoResultsComponent,
    EcareOpportunitiesComponent,
    EcareOpportunitiesMobileComponent,
    OpportunityFilterComponent,
    OpportunityDetailsComponent,
    EcareReportsComponent,
    EcareReportsMobileComponent,
    ReportDetailsComponent,
    ReportFilterComponent,
    CallMeBackModalComponent,
    TicketsComponent,
    TicketFilterComponent,
    TicketEditComponent,
    CreateTicketModalComponent,
    DuplicateTicketModalComponent,
    FormSelectInput,
    DuplicateTicketModalComponent,
    FormAddressSearchInputComponent,
    EcareUserAccountComponent,
    EcareUserAccountAclComponent,
    EcareUserAccountAppearanceComponent,
    EcareUserAccountDetailsComponent,
    EcareUserAccountNotificationsComponent,
    EcareUserAccountPaymentsComponent,
    EcareUserAccountSecurityComponent,
    EcareUserAccountSkillsComponent,
    EntityGenerateDocumentModalComponent,
    QuoteDetailsPopupComponent,
    EcareUserAccountLinkedAccountsComponent,
    ButtonComponent,
    StickerComponent,
    InputTextComponent,
    ToggleSwitchComponent,
    SectionHeaderComponent,
    SectionTabsComponent,
    PageHeaderComponent,
    EcarePageLayoutComponent,
    InfoBannerComponent,
    CategoriesBannerComponent,
    HalfImageBannerComponent,
    TariffListingComponent,
    BrandListingComponent,
    HeroBannerComponent,
    AlertComponent,
    WishListsComponent,
    DeleteWishListPopupComponent,
    HeroBannerComponent,
    FormPhoneNumberComponent,
    EcareDocumentRowComponent,
    EcareDocumentsComponent,
    EcareDocumentsFilterComponent,
    DocumentsPopupComponent,
    EcareBreadcrumbsComponent,
    NavigationThirdMenuComponent,
    CopyToClipboardComponent,
    EcareExtHomepageComponent,
    EcareCustomerCardComponent,
    AccountEntityDetailsComponent,
    EcareAccountEntityComponent,
    EcareAccountEntityTableComponent,
    EcareAccountEntityTableMobileComponent,
    EcareAccountEntityFilterComponent,
    EcareOrdersDetailComponent,
    FormPickUpPointInputComponent,
    FormPickUpPointModalComponent,
    GoogleMapComponent,
    OpenLayerMapComponent,
    FormPickUpPointInputWithModalComponent,
    EntityGeneratedFormComponent,
    DelegateModalComponent,
    FormAccountInputComponent,
    ClickOutsideDirective,
    ContentShareIconsComponent,
    BusinessEventsComponent,
    EventsFilterComponent,
    BusinessEventRowComponent,
    EcareCustomersComponent,
    EcareCustomerRowComponent,
    EcareCustomersFilterComponent,
    CustomerSelectionPopupComponent,
    EcareCustomerAccountsComponent,
    EcareCustomerAccountRowComponent,
    EcareCustomerAccountsFilterComponent,
    CustomerAccountSelectionPopupComponent,
    FormFieldModalPicker,
    EcareUsersComponent,
    EcareUserRowComponent,
    EcareUsersFilterComponent,
    UserSelectionPopupComponent,
    OrderSelectionPopupComponent,
    FormAgreementsComponent,
    FormAgreementComponent,
    EcareUserAccountAgreementsComponent,
    ShopSelectionPopupComponent,
    FormShopSelectionInputWithModalComponent,
    ShopSelectionRowComponent,
    EcareUserAccountContactsComponent,
    ContactEditPopupComponent,
    AddressEditPopupComponent,
    UserContactsComponent,
    FacebookCommentsComponent,
    CookiePolicyBarComponent,
    CookiePolicySettingsModalComponent,
    ConfirmationDialogBorderComponent,
    ConfirmationDialogComponent,
    StickyPriceBarComponent,
    OrdersDetailSideBoxComponent,
    SideboxRowComponent,
    EcareMobileRowGeneralComponent,
    EcareOrderRowMobileComponent,
    EcareOrderTableMobileComponent,
    ThumbnailContainerComponent,
    ReservationErrorModalComponent,
    ErrorPageComponent,
    ErrorPageModalComponent,
    DynamicComponentRegistryInitComponent,
    EcareCustomerAccountBoxComponent,
    EcareWidgetMainInfoComponent,
    EcareSearchTableMobileComponent,
    EcareSearchTableMobileRowComponent,
    EcareSectionComponent,
    EcareFixInternetTariffWidgetComponent,
    EcareTvTariffWidgetComponent,
    EcareFilterAutocompleteBoxComponent,
    AdvancedFilterFieldComponent,
    AdvancedFilterComponent,
    CreateCustomerPopupComponent,
    LastTicketsWidgetComponent,
    LastInvoicesWidgetComponent,
    LastOrdersWidgetComponent,
    HelpAndSupportWidgetComponent,
    MobileTariffWidgetComponent,
    EcareLastPaymentWidgetComponent,
    EcareCustomerAccountWidgetComponent,
    ShoppingCartSideMenuComponent,
    TopMenuComponent,
    CustomerMenuComponent,
    MobileMenuComponent,
    TopMenuActionComponent,
    EcareSearchSelectDropdownComponent,
    EcareMobileInternetTariffWidgetComponent,
    EcareFixVoiceTariffWidgetComponent,
    EcareMobileUsageTariffWidgetComponent,
    EcareUsageAlertsWidgetComponent,
    EcareBannerWidgetComponent,
    SubscriptionSelectWidgetComponent,
    EcareContactHistoryExpandedRowComponent,
    EcareOpportunityExpandedRowComponent,
    TicketNotesFormComponent,
    EcareBreadcrumbComponent,
    EcareTaskExpandedRowComponent,
    ShoppingCartProductComponent,
    ShoppingCartAddProductToSocketComponent,
    ConfigurableFormComponent,
    EntityGeneratedFormInputComponent,
    ShoppingCartTotalsComponent,
    ParameterDataComponent,
  ],
  imports: [
    DefaultModule.forRoot({
      defaultLanguage: environment.localization.defaultLocale,
      localization: environment.localization,
      currency: environment.currency
    }),
    AngularEditorModule,
    AngularResizeEventModule,
    BrowserModule.withServerTransition({
      appId: 'serverApp'
    }),
    AppRoutingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ClipboardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [LocalizationService]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: MissingTranslationHandlerFactory,
        deps: [NGXLogger]
      },
      useDefaultLang: environment.useDefaultLanguageForMissingTranslation
    }),
    DynamicModule,
    LoggerModule.forRoot({
      level: environment.browserLoggingLevel,
      serverLogLevel: environment.remoteLoggingLevel
    }),
    orderBff.ApiModule,
    EllipsisModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    }),
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    LazyLoadImageModule,
    MatDatepickerModule,
    InlineSVGModule.forRoot(),
    MatNativeDateModule,
    NgbModule,
    NgImageSliderModule,
    NgSelectModule,
    NouisliderModule,
    PdfViewerModule,
    ReactiveFormsModule,
    SwiperModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatFormFieldModule,
    MatCardModule,
    GalleryModule,
    LightboxModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatCheckboxModule,
    MatSliderModule,
    MatButtonModule,
    MatGridListModule,
    MatListModule,
    MatSelectModule,
    MatRadioModule,
    MatTooltipModule,
  ],
  exports: [],
  providers: [
    AclEvaluateComponent,
    AclService,
    AppAuthGuard,
    AppBlockerService,
    AuthFactoryService,
    CodebookService,
    ConfirmationDialogComponent,
    CurrentLocaleService,
    CodebookSelectionComponent,
    CustomerLocalStorageService,
    CustomerService,
    DefaultErrorHandler,
    DynamicSelectboxComponent,
    EcareCustomerGuard,
    EcareSettingsComponent,
    InputErrorsComponent,
    KeycloakService,
    LocalizationService,
    OrderErrorHandler,
    DynamicSelectboxComponent,
    OrderingWizardService,
    PageAccessErrorHandler,
    PaymentService,
    ScoringService,
    ShoppingCartService,
    WcOrderingService,
    HwStoreMaterialManagementService,
    WatchdogSubscriptionService,
    ProductGroupService,
    SeoService,
    CookieService,
    SystemIdInterceptor,
    SafeHtmlPipe,
    SafeUrlPipe,
    DisplayAddressPipe,
    DisplayContactPipe,
    PhoneNumberPipe,
    ModalFactoryService,
    ContextEntityRefResolverImpl,
    ContentShareIconsComponent,
    LogErrorHandler,
    LogInfoHandler,
    NavigationMenuService,
    SocialNetworksService,
    StickyBarService,
    DatePipe,
    {
      provide: AppAuthGuard.PAGE_ACCESS_ERROR_HANDLER_INJECTION_NAME,
      useClass: PageAccessErrorHandler,
    },
    {
      provide: 'groupType',
      useValue: 'GUI_CATEGORY',
    },
    {
      provide: orderBff.BASE_PATH,
      useValue: getCurrentHost(),
    },
    {
      provide: LOCALE_ID,
      useValue: environment.localization.defaultLocale,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForwardedHostHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MultiTenancyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ScoringTimeoutInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrderBlockUiInterceptor,
      multi: true,
    },
    /*{
      provide: HTTP_INTERCEPTORS,
      useClass: SystemIdInterceptor,
      multi: true
    },*/
    {
      provide: HTTP_CACHE_ALLOWED_ROUTES,
      useValue: new CacheHttpInterceptorConfig()
        .addUrlPathCacheConfig('/api/bff/order/v1/contents/routes', 60 * 60 * 1000) // 3600 seconds = 1 hour
        .addUrlPathCacheConfig('/api/bff/order/v1/ticket-types', 60 * 60 * 1000) // 3600 seconds = 1 hour
        .addUrlPathCacheConfig('/api/bff/order/v1/codebooks/MODULE_PAGE', 60 * 60 * 1000), // 3600 seconds = 1 hour
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheHttpInterceptor,
      multi: true,
    },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [
        AuthFactoryService,
        SystemIdInterceptor,
        ActivatedRoute,
        WcOrderingService,
        CookieService,
        DynamicComponentRegistry,
        ContentService,
        CodebookService,
        Router,
      ],
    },
    {
      provide: APP_PROJECT_CONFIG_RUNTIME,
      useValue: environment.projectConfigRuntime,
    },
    {
      provide: APP_TENANT_CONFIG,
      useValue: environment.projectConfigRuntime?.tenant,
    },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private codebookService: CodebookService,
    private propertyAccessorLocalService: PropertyAccessorLocalService
  ) {
    registerLocaleData(localeCs, 'cs');
    registerLocaleData(localeSl, 'sl');
    registerLocaleData(localeSq, 'sq');
    registerLocaleData(localeSr, 'sr');
    PricePipe.setCurrency(environment.currency);
    this.propertyAccessorLocalService
      .getPhoneNumberFormatCountry()
      .subscribe(formatCountry => PhoneNumberPipe.setCountryCode(formatCountry as CountryCode));
  }
}
