<div *ngIf="childProduct && childProduct.display" class="shoppingCart-child">
  <div class="shoppingCart-child-inner">
    <div class="shoppingCart-parent-name">{{ childProduct.productDetail.name }}:</div>
    <form *ngIf="dynamicParametersForm" class="generated-form" [formGroup]="dynamicParametersForm">
      <ng-container *ngFor="let dynamicParamEntry of dynamicParameters | keyvalue">
        <div *ngFor="let dynamicParam of dynamicParamEntry.value" class="col4">
          <app-entity-generated-form-input
            guiStyleClasses=""
            translationPrefix="wc.shopping.productAttributes"
            [name]="dynamicParam.name"
            [label]="dynamicParam.label"
            [metaParameters]="dynamicParam.metadata"
            [formGroup]="dynamicParametersForm.get(dynamicParamEntry.key)"
            [parentForm]="dynamicParametersForm"
            [form]="dynamicParametersForm.get(dynamicParamEntry.key)"
            [type]="dynamicParam.type"
            [typeDetail]="dynamicParam.typeDetail" />
        </div>
      </ng-container>
    </form>
  </div>
  <div class="child-prices" [ngClass]="childProduct.priceMode">
    <ng-container
      *ngIf="childProduct.hasProductPrice && (childProduct.rcPrice > 0 || childProduct.ocPrice > 0); else noPrice">
      <ng-container *ngIf="showVat; else noVat">
        <div *ngIf="childProduct.ocPrice != null" class="inner-prices">
          <div class="price-amount one-time">
            <one-time-price [price]="childProduct.ocPrice" />
          </div>
        </div>
        <div *ngIf="childProduct.rcPrice != null" class="inner-prices">
          <div class="price-amount">
            <div class="price-name">
              {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
            </div>
            <app-recurrent-price [disableTexts]="true" [price]="childProduct.rcPrice" />
          </div>
        </div>
      </ng-container>
      <ng-template #noVat>
        <div *ngIf="childProduct.ocPriceTax != null" class="inner-prices">
          <div class="price-amount one-time">
            <one-time-price [price]="childProduct.ocPriceTax" />
          </div>
        </div>
        <div *ngIf="childProduct.rcPriceTax != null" class="inner-prices">
          <div class="price-amount">
            <div class="price-name">
              {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
            </div>
            <app-recurrent-price [disableTexts]="true" [price]="childProduct.rcPriceTax" />
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #noPrice>
      <div class="noPrice">
        {{ 'wc.shopping.confirmation.summaryRow.freePrice.label' | translate }}
      </div>
    </ng-template>
  </div>
</div>
<ng-container *ngIf="shoppingCartSocket">
  <ng-container *ngFor="let socketChildren of shoppingCartSocket.childSocketsBySocketId?.values(); let i = index">
    <ng-container *ngFor="let child of socketChildren; let j = index">
      <div class="shoppingCart-child-line"  [class.display]="child.product.display">
        <app-shopping-cart-product
          [shoppingCartSocket]="child"
          [precalculatedShoppingCart]="precalculatedShoppingCart"
          [orderItemsFormsValues]="orderItemsFormsValues"
          [showVat]="showVat"
          [showRemoveBtn]="showRemoveBtn"
          [displayMode]="displayMode" />
        <div
          *ngIf="showRemoveBtn && child.product.display && shoppingCartSocket.socket &&
            shoppingCartSocket.socket.contentQty.min < socketChildren.length &&
            child.socket.socketContentQty?.min < child.currentContentQty
          "
          class="parent-action">
          <a id="008_shopping_cart_item" (click)="remove(child.product)">
            <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
          </a>
        </div>
      </div>
    </ng-container>
    <app-shopping-cart-add-product-to-socket
      [shoppingCartSocket]="shoppingCartSocket"
      [precalculatedShoppingCart]="precalculatedShoppingCart"
      [socketChildren]="socketChildren" />
  </ng-container>
</ng-container>
