<app-ecare-page-layout
  *ngIf="account && !ticketForRelatedTask && (ticketType || externalSearchMode)"
  columnTemplate="1fr"
  [showBreadcrumbs]="!externalSearchMode && !staticSearch"
  [title]="
    !externalSearchMode && !staticSearch
      ? (caRefNo
          ? customerLocalStorageService.getCurrentCustomerAccount().displayName +
            ' ' +
            ('wc.ecare.dashboard.account.header' | translate) +
            ' '
          : (cuRefNo ? customerLocalStorageService.getCurrentCustomer().displayName + ' ' : '') + ' ') +
        ('wc.ecare.opportunity.headline' | translate)
      : null
  ">
  <ng-container *ngIf="!externalSearchMode && !staticSearch" page-actions>
    <app-button
      variant="primary"
      iconPath="/assets/svg/add.svg"
      label="{{ 'wc.ecare.opportunity.newOpportunity' | translate }}"
      (click)="new()" />
    <app-button
      *ngIf="anyTicketsSelected"
      variant="secondary"
      iconPath="/assets/svg/delete.svg"
      label="{{ 'wc.ecare.ticket.delete.button' | translate }}"
      (click)="deleteTasks()" />
  </ng-container>
  <div>
    <div class="ecare-filtering-wrapper">
      <app-section-tabs *ngIf="!externalSearchMode && !staticSearch" #nav="ngbNav" ngbNav>
        <li [ngbNavItem]="1">
          <a ngbNavLink (click)="sectionTabsChange('MY')">
            {{ 'wc.ecare.opportunity.myOpportunities' | translate }}
          </a>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink (click)="sectionTabsChange('TEAM')">
            {{ 'wc.ecare.opportunity.teamOpportunities' | translate }}
          </a>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink (click)="sectionTabsChange('ALL')">
            {{ 'wc.ecare.opportunity.allOpportunities' | translate }}
          </a>
        </li>
      </app-section-tabs>
      <div>
        <div>
          <app-opportunity-filter
            *ngIf="!this.externalSearchMode && !staticSearch"
            #opportunityFilterComponent
            [filtering]="search.filtering"
            (filterChanged)="handleFilterChange()" />
        </div>
        <div *ngIf="search?.sorting" class="mobile-sorting-container">
          <app-sortable-dropdown [columns]="columns" [sorting]="search.sorting" (sortChanged)="onSortChange()" />
        </div>
      </div>
    </div>
    <div class="dashboard">
      <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
        <div class="dashboard_search">
          <div class="ecare-responsive-table">
            <ng-container *ngIf="tickets.length > 0; else no_results">
              <table class="ecare_listing-table opportunities-table responsive-table tasks-table table">
                <thead>
                  <tr>
                    <th *ngIf="!smallTable">
                      <input
                        class="checkbox"
                        type="checkbox"
                        [id]="'ticket-all'"
                        [checked]="allTicketsSelected"
                        (change)="selectDeselectAll()" />
                      <label
                        style="vertical-align: super"
                        class="{{ anyTicketsSelected ? 'partial-selection' : '' }}"
                        [for]="'ticket-all'">
                      </label>
                    </th>
                    <th
                      class="with-icon"
                      app-sortable-column
                      column="subject"
                      localizationKey="wc.ecare.ticket.subject"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()"></th>
                    <th
                      class="with-icon"
                      app-sortable-column
                      column="priority"
                      localizationKey="wc.ecare.ticket.priority"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()"></th>
                    <th
                      class="with-icon"
                      app-sortable-column
                      localizationKey="wc.ecare.ticket.state"
                      column="opportunityState"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()">
                      {{ 'wc.ecare.opportunity.state' | translate }}
                    </th>
                    <th
                      *ngIf="!smallTable"
                      class="with-icon"
                      localizationKey="wc.ecare.ticket.expectedRevenue"
                      app-sortable-column
                      column="expectedRevenue"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()">
                      {{ 'wc.ecare.ticket.expectedRevenue' | translate }}
                    </th>
                    <th
                      *ngIf="!cuRefNo && !smallTable"
                      class="with-icon"
                      app-sortable-column
                      column="customerDisplayName"
                      localizationKey="wc.ecare.opportunity.customersName"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()">
                      {{ 'wc.ecare.opportunity.customersName' | translate }}
                    </th>
                    <th
                      class="with-icon"
                      app-sortable-column
                      column="slaDueDate"
                      localizationKey="wc.ecare.ticket.dueDate"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()">
                      {{ 'wc.ecare.ticket.dueDate' | translate }}
                    </th>
                    <th *ngIf="!smallTable"
                      class="with-icon"
                      app-sortable-column
                      column="teamAssignment"
                      localizationKey="wc.ecare.ticket.salesTeam"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()">
                      {{ 'wc.ecare.ticket.salesTeam' | translate }}
                    </th>
                    <th
                      class="with-icon"
                      app-sortable-column
                      column="assignedTo"
                      localizationKey="wc.ecare.ticket.assignedTo"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()">
                      {{ 'wc.ecare.opportunity.assignedTo' | translate }}
                    </th>
                    <th
                      *ngIf="!smallTable"
                      class="with-icon"
                      title="{{ 'wc.ecare.ticket.attachmentsCount.tootip' | translate }}">
                      <i class="fa fa-paperclip" aria-hidden="true"> </i>
                    </th>
                    <th
                      *ngIf="!smallTable"
                      class="with-icon"
                      title="{{ 'wc.ecare.ticket.notesCount.tootip' | translate }}">
                      <i class="fa fa-sticky-note-o" aria-hidden="true"> </i>
                    </th>
                    <th
                      *ngIf="!smallTable"
                      class="with-icon"
                      title="{{ 'wc.ecare.ticket.reatedTasksCount.tootip' | translate }}">
                      <i class="fa fa-check-square-o" aria-hidden="true"> </i>
                    </th>
                    <th
                      *ngIf="!smallTable"
                      class="with-icon"
                      title="{{ 'wc.ecare.ticket.ordersCount.tootip' | translate }}">
                      <i class="fa fa-cart-plus" aria-hidden="true"> </i>
                    </th>
                    <th
                      *ngIf="!smallTable"
                      class="with-icon"
                      title="{{ 'wc.ecare.ticket.quotesCount.tootip' | translate }}">
                      <i class="fa fa-quote-left" aria-hidden="true"> </i>
                    </th>
                    <th>{{ 'wc.ecare.opportunity.action' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let ticket of tickets; let i = index">
                    <tr
                      class="ecare_listing-table-row pointer {{ selected === ticket ? 'selected' : '' }}"
                      (click)="rowClicked(ticket)"
                      (dblclick)="showDetails(ticket)">
                      <td *ngIf="!smallTable">
                        <input
                          class="checkbox"
                          type="checkbox"
                          [id]="'ticket-' + i"
                          [formControl]="selectedFormArray.at(i)" />
                        <label style="vertical-align: super" [for]="'ticket-' + i"> </label>
                      </td>
                      <td>
                        <span class="information semi-bold">{{ ticket.subject }}</span>
                        <span *ngIf="ticket['percentageToDueDate']" class="bold probability">
                          <div>
                            <div class="progress-bar_container">
                              <div
                                class="progress-bar_value-bar probability-bar-{{
                                  getOpportunityProbabilityType(ticket['percentageToDueDate'])
                                }}"
                                [style.width.%]="ticket['percentageToDueDate']"></div>
                            </div>
                          </div>

                          <div class="progress-bar_value-label no-wrap">{{ ticket['percentageToDueDate'].toFixed(0) }} %</div>
                        </span>
                      </td>
                      <td>
                        <div class="wrapped">
                          <i class="task-priority" [inlineSVG]="'/assets/svg/task-' + ticket.priority + '.svg'"></i>
                          <span>{{ 'wc.ecare.ticket.task.priorities.' + ticket.priority | translate }}</span>
                        </div>
                      </td>
                      <td>
                        <span
                          *ngIf="codebookService.getCodebookTexts('TICKET_OPPORTUNITY_STATE') | async as codebook"
                          class="-opportunity-state {{ ticket['state'] }}">
                          {{ codebook[ticket['state']] ? codebook[ticket['state']] : '-' }}
                        </span>
                      </td>
                      <td *ngIf="!smallTable" class="-expected-revenue">
                        <div *ngIf="ticket['expectedRevenue']">{{ ticket['expectedRevenue'] }} {{ appCurrency }}</div>
                      </td>
                      <td *ngIf="!cuRefNo && !smallTable">
                        <span class="bold">{{ ticket['customer'] }}</span>
                      </td>
                      <td>
                        <span class="bold">{{ ticket.slaDueDate | date : 'dd.MM.yyyy HH:mm' }}</span>
                      </td>
                      <td *ngIf="!smallTable">
                        <span class="bold">{{ ticket['salesTeam'] }}</span>
                      </td>
                      <td>
                        <span *ngIf="!smallTable; else smallTableTemplate" class="bold">{{ ticket.assignedTo }}</span>
                        <ng-template #smallTableTemplate>
                          <span class="bold">{{ !ticket.assignedTo ? ticket['salesTeam'] : ticket.assignedTo }}</span>
                        </ng-template>
                      </td>
                      <td *ngIf="!smallTable">
                        {{ ticket.attachments.length ? ticket.attachments.length : '-' }}
                      </td>
                      <td *ngIf="!smallTable">
                        {{ ticket.notes.length ? ticket.notes.length : '-' }}
                      </td>
                      <td *ngIf="!smallTable">
                        {{ ticket['tasksIds']?.length ? ticket['tasksIds'].length : '-' }}
                      </td>
                      <td *ngIf="!smallTable">
                        {{ ticket['orders']?.length ? ticket['orders'].length : '-' }}
                      </td>
                      <td *ngIf="!smallTable">
                        {{ ticket['quotes']?.length ? ticket['quotes'].length : '-' }}
                      </td>
                      <td class="no-wrap mobile-right">
                        <ng-container>
                          <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
                            <span
                              class="fa-stack cursor-pointer action-icon-wrapper"
                              ngbDropdownToggle
                              (click)="$event.stopPropagation()">
                              <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
                            </span>

                            <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ ticket.id }}_popover-content">
                              <div>
                                <button
                                  class="btn btn-link action-list-more-actions-button"
                                  type="button"
                                  (click)="showDetails(ticket); $event.stopPropagation()">
                                  {{ 'wc.ecare.opportunity.showDetails.button' | translate }}
                                </button>
                                <button
                                  class="btn btn-link action-list-more-actions-button"
                                  type="button"
                                  (click)="showDetails(ticket, true); $event.stopPropagation()">
                                  {{ 'wc.common.showDetailsInNewTab.button' | translate }}
                                </button>
                                <button
                                  *ngIf="showDuplicateBtn"
                                  class="btn btn-link action-list-more-actions-button"
                                  type="button"
                                  (click)="duplicate(ticket); $event.stopPropagation()">
                                  {{ 'wc.common.duplicate.button' | translate }}
                                </button>
                                <button
                                  *ngIf="showDeleteBtn"
                                  class="btn btn-link action-list-more-actions-button"
                                  type="button"
                                  (click)="delete(ticket); $event.stopPropagation()">
                                  {{ 'wc.ecare.opportunity.delete.button' | translate }}
                                </button>
                                <button
                                  *ngIf="removeRelationCallback"
                                  class="btn btn-link action-list-more-actions-button"
                                  type="button"
                                  (click)="removeRelation(ticket); $event.stopPropagation()">
                                  {{ 'wc.ecare.ticket.deleteRelation.button' | translate }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </td>
                    </tr>
                    <tr *ngIf="selected === ticket" class="notification-window">
                      <td colspan="15">
                        <app-opportunity-expanded-row-component
                          [ticket]="ticket"
                          (openTicketRelatedTask)="openRelatedTask($event)"
                          (addTicketRelatedTask)="addTicketRelatedTask($event)" />
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <app-ecare-opportunities-mobile class="ecare-responsive-mobile tasks-table" [tickets]="tickets" />
            </ng-container>
          </div>
          <ng-template #no_results [ngIf]="canDisplayNoTicketsText">
            <app-ecare-listing-no-results />
          </ng-template>
          <div *ngIf="moreRecordsExists">
            {{ 'wc.ecare.notifications.scrollDownForMoreRecords.text' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-ecare-page-layout>
<app-ticket-edit
  *ngIf="newRelatedTask"
  [ticket]="newRelatedTask"
  [cancelCallback]="newRelatedTaskCanceled"
  [saveCallback]="newRelatedTaskSaved"
  [caRefNo]="newRelatedTaskCaRef"
  [cuRefNo]="newRelatedTaskCuRef"
  [relatedTicketId]="ticketForRelatedTask.id"
  [ticketTypeCode]="newRelatedTaskCode" />
