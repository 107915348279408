import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecare-mobile-row-general',
  templateUrl: './ecare-mobile-row-general.component.html'
})
export class EcareMobileRowGeneralComponent implements OnInit {

  @Input() 
  additionalClass: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
