import { Component, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CompareType, ComplexFilter, ControlFilterMiner } from '@btl/btl-fe-wc-common';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-contact-history-filter',
  templateUrl: './contact-history-filter.component.html',
})
export class ContactHistoryFilterComponent extends ComplexFilter {
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  protected advancedFilterFields = [
    'createdDateFrom',
    'createdDateTo',
    'startFrom',
    'startTo',
    'slaDueDateFrom',
    'slaDueDateTo'
  ];

  filterForm = this.formBuilder.group({
    searchText: [],
    createdDateFrom: [],
    createdDateTo: [],
    startFrom: [],
    startTo: [],
    slaDueDateFrom: [],
    slaDueDateTo: [],
    opportunityState: '',
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.searchText, 'searchText'),

    new ControlFilterMiner(this.filterForm.controls.createdDateFrom, 'created.from', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.createdDateTo, 'created.to', CompareTypeDtoEnum.SMALLEREQ),

    new ControlFilterMiner(this.filterForm.controls.startFrom, 'startFrom.from', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.startTo, 'startFrom.to', CompareTypeDtoEnum.SMALLEREQ),

    new ControlFilterMiner(this.filterForm.controls.slaDueDateFrom, 'slaDueDate.from', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.slaDueDateTo, 'slaDueDate.to', CompareTypeDtoEnum.SMALLEREQ),

    new ControlFilterMiner(this.filterForm.controls.opportunityState, 'opportunityState')
  ];

  constructor(
    private formBuilder: FormBuilder,
    public readonly customerLocalStorageService: CustomerLocalStorageService
  ) {
    super();
  }

  protected getDefaultFilterValues() {
    return {
      searchText: null,
      createdDateFrom: null,
      createdDateTo: null,
      startFrom: null,
      startTo: null,
      slaDueDateFrom: null,
      slaDueDateTo: null,
    };
  }
}
