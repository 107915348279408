<ng-template #innerPart>
  <div class="homepage-search">
    <div class="dashboard">
      <div class="dashboard_wrapper">
        <div
          app-ecare-search-filter
          [isHomepage]="isHomepage"
          [clearFilterEmitter]="clearFilterEmitter"
          (handleSearch)="handleSearch($event)"
          (handleChangeEntity)="changeSearchEntity($event)"></div>
        <div class="dashboard_search">
          <div *ngIf="isSearchCustomers()" class="table-result">
            <app-ecare-customers
              #ecareCustomersComponent
              [showFilter]="false"
              [clearFilterEmitter]="clearFilterEmitter"
              (selectedCustomer)="selectCustomer($event.id)" />
          </div>

          <div *ngIf="isSearchOrders()" class="table-result">
            <ng-container *ngIf="foundOrders && foundOrders.length">
              <app-ecare-order-table
                [canDisplayNoOrdersText]="false"
                [orders]="foundOrders"
                (selectCustomer)="selectCustomer($event)" />
              <app-ecare-order-table-mobile
                [orders]="foundOrders"
                [canDisplayNoOrdersText]="false"
                (selectCustomer)="selectCustomer($event)" />
              <app-button
                *ngIf="isMore"
                variant="secondary"
                label="{{ 'wc.ecare.homepage.loadMoreResults.button' | translate }}" />
            </ng-container>
          </div>

          <div *ngIf="isSearchOpportunities()" class="table-result">
            <ng-container *ngIf="foundOpportunities && foundOpportunities.length; else no_results">
              <app-opportunities-component
                [externalSearchMode]="true"
                [canDisplayNoTicketsText]="false"
                [smallTable]="true"
                [inputTickets]="foundOpportunities" />
              <app-button
                *ngIf="isMore"
                variant="secondary"
                label="{{ 'wc.ecare.homepage.loadMoreResults.button' | translate }}" />
            </ng-container>
          </div>

          <!-- TODO: Disconnect no result style from dashboard_wrapper & dashboard_search; connect it with theme  -->
          <ng-template #no_results>
            <app-ecare-listing-no-results *ngIf="!isSearchOrders()" [clearFilterEmitter]="clearFilterEmitter" [isHomepage]="true" />
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="isHomepage; else isPage">
  <ng-container *ngTemplateOutlet="innerPart" />
</ng-container>
<ng-template #isPage>
  <app-ecare-page-layout
    columnTemplate="1fr"
    title="{{
      (isModuleBffCrmEnabled ? 'wc.ecare.homepage.search.header' : 'wc.ecare.homepage.orderSearch.header') | translate
    }}">
    <div class="dashboard">
      <div class="dashboard_wrapper">
        <div class="dashboard_search">
          <ng-container *ngTemplateOutlet="innerPart" />
        </div>
      </div>
    </div>
  </app-ecare-page-layout>
</ng-template>
