/* eslint-disable @angular-eslint/prefer-standalone-component */
import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { CurrentLocaleService, MetaDescriptionService } from '@btl/btl-fe-wc-common';
import { EntityMetaDescriptionDto, EntityMetaDescriptionInputDto, MetaEntityAttributeDto } from '@btl/order-bff';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-entity-generated-form',
  templateUrl: './entity-generated-form.component.html',
})
export class EntityGeneratedFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();

    this.currentNativeAttributes.forEach(oldAttr => {
      this.form.removeControl(oldAttr);
    });

    this.currentNoNativeAttributes.forEach(oldAttr => {
      this.getParametersForm().removeControl(oldAttr);
    });
  }

  @Input() formName: string;
  @Input() sourceName: string;
  @Input() entityType: string;
  @Input() entityId: string;
  @Input() attributeType: EntityAttributeTypeEnum = EntityAttributeTypeEnum.ALL;
  @Input() resourceIdentification: { [key: string]: string; };
  @Input() externalValues: { [key: string]: Array<ExternalValue>; } = {};
  @Input() defaultValues: { [key: string]: any; } = {};
  @Input() attributeOnChangeEvent: { [key: string]: EventEmitter<any>; } = {};
  @Input() additionalValidators: { [key: string]: Array<ValidatorFn>; } = {};
  @Input() additionalAsyncValidators: { [key: string]: Array<AsyncValidatorFn>; } = {};
  @Input() columnsCount: number = 2;
  @Input() context;
  @Input() applyDefaultValues: boolean = true;
  @Input() nonNativeAttributesFieldName = 'parameters';
  @Input() form = this.formBuilder.group({});
  @Input() validate = true;
  @Input() checkFormVisibility = true;
  @Input() formIdPrefix = '';
  @Input() customer?;
  @Input() showCustomerData? = false;
  @Input() hide = false;

  @Output()
  readonly formGeneratedEmitter = new EventEmitter<void>();

  metaDescription: EntityMetaDescriptionDto;
  formAttributes: Array<MetaEntityAttributeDto> = [];
  currentNativeAttributes = [];
  currentNoNativeAttributes = [];
  formGenerated = false;
  formGeneratedEmitted = false;
  currentLocale: string;
  formVisibility = false;

  constructor(
    private formBuilder: FormBuilder,
    private currentLocaleService: CurrentLocaleService,
    private metaDescriptionService: MetaDescriptionService,
  ) {
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLocale = this.currentLocaleService.getCurrentLanguage();
      this.rebuildForm();
    });
  }

  ngAfterViewChecked(): void {
    if (this.formGenerated && !this.formGeneratedEmitted) {
      this.formGeneratedEmitter.emit();
      this.formGeneratedEmitted = true;
    }
  }

  ngOnInit(): void {
    this.rebuildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.formGenerated) {
      const resourceIdentificationChanges = changes['resourceIdentification'];
      if (
        resourceIdentificationChanges &&
        resourceIdentificationChanges.currentValue !== resourceIdentificationChanges.previousValue &&
        !resourceIdentificationChanges.firstChange
      ) {
        this.rebuildForm();
      }
    }
  }

  overrideFormParameters(attributes: { [key: string]: string }) {
    Object.keys(attributes).forEach(key => {
      if (attributes[key + this.formName] != null) {
        attributes[key] = attributes[key + this.formName];
      }
    });
  }

  rebuildForm() {
    this.formGenerated = false;
    this.formGeneratedEmitted = false;
    const entityMetaDescriptionInputDto: EntityMetaDescriptionInputDto = {
      resourceIdentification: this.resourceIdentification,
      context: this.context,
    };
    this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    this.metaDescriptionService
      .getEntityMetaDescriptions(this.sourceName, this.entityType, entityMetaDescriptionInputDto)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.metaDescription = result;
        this.overrideFormParameters(this.metaDescription.metaParameters);
        if (this.form && (this.metaDescription.metaParameters['guiVisible'] === 'true' || !this.checkFormVisibility)) {
          this.formVisibility = !this.hide;
          this.formAttributes.length = 0;
          this.metaDescription.attributes.forEach(attr => this.overrideFormParameters(attr.metaParameters));
          this.formAttributes = this.metaDescription.attributes
            .filter(attr => {
              if (!attr.metaParameters['guiVisible']) {
                if (this.form.contains(attr.name)) {
                  this.form.removeControl(attr.name);
                }
                return false;
              }
              if (this.attributeType === EntityAttributeTypeEnum.NATIVE && attr.nativeAttribute) {
                return true;
              }
              if (this.attributeType === EntityAttributeTypeEnum.NON_NATIVE && !attr.nativeAttribute) {
                return true;
              }
              return this.attributeType === EntityAttributeTypeEnum.ALL;
            })
            .sort((a, b) =>
              Number(a.metaParameters['guiSortOrder']) > Number(b.metaParameters['guiSortOrder']) ? 1 : -1
            );

          if (!this.form.contains(this.nonNativeAttributesFieldName)) {
            this.form.addControl(this.nonNativeAttributesFieldName, this.formBuilder.group([]));
          }

          const oldNativeAttributes = this.currentNativeAttributes;
          const oldNoNativeAttributes = this.currentNoNativeAttributes;
          this.currentNativeAttributes = [];
          this.currentNoNativeAttributes = [];

          this.formAttributes.forEach(attr => {
            if (attr.nativeAttribute) {
              this.currentNativeAttributes.push(attr.name);
            } else {
              this.currentNoNativeAttributes.push(attr.name);
            }
          });

          oldNativeAttributes.forEach(oldAttr => {
            if (!this.currentNativeAttributes.find(currentAttr => oldAttr === currentAttr)) {
              this.form.removeControl(oldAttr);
            }
          });

          oldNoNativeAttributes.forEach(oldAttr => {
            if (!this.currentNoNativeAttributes.find(currentAttr => oldAttr === currentAttr)) {
              this.getParametersForm().removeControl(oldAttr);
            }
          });
        } else {
          this.formVisibility = false;
        }
        this.formGenerated = true;
      });
  }

  resetForm() {
    this.form.reset();
    this.rebuildForm();
  }

  getParametersForm(): FormGroup {
    return this.form.get(this.nonNativeAttributesFieldName) as FormGroup;
  }

  getAttributeLabel(attribute: MetaEntityAttributeDto): string {
    const ret = attribute.localizedTexts?.find(text => text.locale === this.currentLocale)?.message;
    return ret ? ret : attribute.name;
  }

  generatePrimaryContact(attributeName: string) {
    if (!this.showCustomerData) return false;
    switch (attributeName) {
      case 'firstName':
      case 'lastName':
      case 'email':
        return true;
      default:
        return false;
    }
  }

  generateColumn(formName: string) {
    return `col${12 / this.columnsCount}`;
  }

  public getAssignmentStateFromMinDate(): Date {
    return new Date(1920, 1, 1);
  }

  public getAssignmentStateFromMaxDate(): Date {
    return new Date(2040, 1, 1);
  }
}

export class ExternalValue {
  value: any;
  labelKey?: string;
  label?: string;
}

export enum EntityAttributeTypeEnum {
  NATIVE,
  NON_NATIVE,
  ALL,
}

export enum GuiElementTypeEnum {
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATE_TIME = 'datetime',
  SELECT = 'select',
  PHONE = 'phone',
  EMAIL = 'email',
  BUILDING_NUMBER = 'buildingNumber',
  STREET_NUMBER = 'streetNumber',
  ZIP_CODE = 'zipCode',
}

export enum AttributeTypeEnum {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  CODEBOOK = 'CODEBOOK',
  ENUM = 'ENUM',
}
