<app-ecare-page-layout
  *ngIf="ticket && ticketType"
  showBackButton="true"
  [columnTemplate]="'2fr 1fr'"
  [title]="
    !editMode
      ? ('wc.ecare.interactions.heading.detail.text' | translate)
      : ((newTicket ? 'wc.ecare.interactions.heading.edit.text' : 'wc.ecare.interactions.heading.edit.text')
        | translate)
  ">
  <ng-container page-actions>
    <ng-container *ngIf="!editMode; else editActions">
      <app-button
        variant="secondary"
        iconPath="/assets/svg/edit.svg"
        [label]="'wc.common.edit' | translate"
        (click)="edit()" />
      <app-button
        *ngIf="!newTicket"
        variant="secondary"
        [label]="'wc.common.cancel.button' | translate"
        (click)="cancel()" />
    </ng-container>
    <ng-template #editActions>
      <app-button
        iconPath="/assets/svg/save.svg"
        [label]="'wc.ecare.accountSettings.saveChanges' | translate"
        (click)="saveChanges()" />
      <app-button variant="secondary" label="{{ 'wc.common.cancel.button' | translate }}" (click)="cancel()" />
      <app-button
        *ngIf="!newTicket"
        variant="secondary"
        [label]="'wc.ecare.contactHistory.discardChanges' | translate"
        (click)="discardChanges()" />
    </ng-template>
  </ng-container>
  <div *blockUI="blockUIElement.name; template: blockTemplate" left-side>
    <app-ecare-section [sectionTitle]="'wc.ecare.interactions.heading.interactionInfo.text' | translate">
      <div class="left-panel generated-form">
        <div class="generated-input-container col12">
          <app-custom-input
            [parentGroup]="form"
            [controlName]="'subject'"
            [inputType]="'textarea'"
            [disabled]="!editMode"
            [translationPrefix]="'wc.ecare.ticket'"
            (valueChanged)="fieldChanged('subject', $event.target.value)" />
        </div>
        <div class="generated-input-container col12">
          <app-custom-input
            [parentGroup]="form"
            [controlName]="'description'"
            [inputType]="'textarea'"
            [disabled]="!editMode"
            [translationPrefix]="'wc.ecare.ticket'"
            (valueChanged)="fieldChanged('description', $event.target.value)" />
        </div>
      </div>
    </app-ecare-section>

    <app-ecare-section [sectionTitle]="'wc.ecare.ticket.notes' | translate">
      <app-ticket-notes-form [ticket]="ticket" (notesChanged)="updateIfNotEdit()" />
    </app-ecare-section>

    <app-ecare-section [sectionTitle]="'wc.ecare.ticket.attachments' | translate">
      <div *ngIf="!form.get('attachments').value?.length" [ngTemplateOutlet]="noDataMessage"></div>
      <ng-container [formGroup]="form">
        <app-form-attachments
          extIdPrefix="TICKET_"
          dmsType="TICKET_GENERAL"
          formControlName="attachments"
          [control]="form.get('attachments')"
          [types]="['GENERAL']"
          (onChange)="fieldChanged('attachments', $event)" />
      </ng-container>
    </app-ecare-section>

    <ng-container *ngIf="!newTicket">
      <ng-container *ngIf="relatedTicketsByCode.size === 0; else relatedTaskByCodeMode">
        <app-ecare-section [sectionTitle]="'wc.ecare.contactHistory.relatedTasks' | translate">
          <div *ngIf="relatedTasks?.length; else noDataMessage">
            <app-ecare-tasks
              [backUrl]="router.url"
              [tickets]="relatedTasks"
              [editCallback]="goToRelatedTicket"
              [deleteCallback]="deleteTicketRelation"
              (tasksDeletedEmitter)="tasksDeleted()" />
          </div>
          <div class="buttons">
            <app-button
              variant="secondary"
              iconPath="/assets/svg/add.svg"
              [label]="'wc.ecare.contactHistory.addRelatedTask' | translate"
              (click)="addRelatedTask()" />
          </div>
        </app-ecare-section>
      </ng-container>
      <ng-template #relatedTaskByCodeMode>
        <app-ecare-section
          *ngFor="let entry of relatedTicketsByCode | keyvalue"
          [sectionTitle]="'wc.ecare.ticket.related.' + entry.key | translate">
          <div *ngIf="entry.value?.length; else noDataMessage">
            <ng-container *ngIf="entry.key === OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE">
              <app-opportunities-component
                [inputTickets]="entry.value"
                [externalSearchMode]="true"
                [editCallback]="goToRelatedTicket"
                [removeRelationCallback]="deleteTicketRelation"
                [showDeleteBtn]="false"
                [showDuplicateBtn]="false"
                [smallTable]="true" />
            </ng-container>
          </div>
          <div class="buttons">
            <app-button
              variant="secondary"
              iconPath="/assets/svg/add.svg"
              [label]="'wc.ecare.ticket.add.related.' + entry.key | translate"
              (click)="addRelatedTask(entry.key)" />
          </div>
        </app-ecare-section>
      </ng-template>
    </ng-container>
  </div>
  <div right-side>
    <app-ecare-section [sectionTitle]="'wc.ecare.interactions.interactionDetails.label' | translate">
      <div *ngIf="contactTypes">
        <app-sidebox-row [label]="'wc.ecare.ticket.startFrom' | translate">
          <div *ngIf="!editMode">{{ ticket.startFrom | date : 'dd.MM.yyyy HH:mm' }}</div>
          <app-custom-input
            translationPrefix="wc.ecare.ticket"
            [hidden]="!editMode"
            [parentGroup]="form"
            [controlName]="'startFrom'"
            [inputType]="'datetime'"
            [noLabel]="true" />
        </app-sidebox-row>

        <app-sidebox-row [label]="'wc.ecare.ticket.slaDueDate' | translate">
          <div *ngIf="!editMode">{{ ticket.slaDueDate | date : 'dd.MM.yyyy HH:mm' }}</div>
          <app-custom-input
            translationPrefix="wc.ecare.ticket"
            [hidden]="!editMode"
            [noLabel]="true"
            [parentGroup]="form"
            [controlName]="'slaDueDate'"
            [inputType]="'datetime'" />
        </app-sidebox-row>

        <app-sidebox-row
          *ngIf="visibleParameters.get(contactParamNames.REMINDER)"
          [label]="'wc.ecare.ticket.reminder' | translate">
          <div *ngIf="!editMode && getParam(ticket.parameters, contactParamNames.TYPE_AREA).value">
            {{ getParam(ticket.parameters, contactParamNames.TYPE_AREA).value | date : 'dd.MM.yyyy HH:mm' }}
          </div>
          <app-date-picker
            class="date-picker"
            [hidden]="!editMode"
            [showSeconds]="true"
            [showTime]="true"
            [(ngModel)]="getParam(ticket.parameters, contactParamNames.TYPE_AREA).value"
            (ngModelChange)="fieldChanged(contactParamNames.TYPE_AREA, $event)" />
        </app-sidebox-row>

        <app-sidebox-row [label]="'wc.ecare.ticket.assignedTo' | translate">
          <div *ngIf="!editMode">{{ ticket.assignedTo }}</div>
          <app-form-field-modal-picker
            valueField="login"
            labelField="login"
            controlName="assignedTo"
            [hidden]="!editMode"
            [disabled]="!editMode"
            [parentGroup]="form"
            [pickerType]="pickerInputType.USER" />
        </app-sidebox-row>

        <app-sidebox-row
          *ngIf="visibleParameters.get(contactParamNames.CONTACT_CHANNEL)"
          [label]="'wc.ecare.ticket.channel' | translate">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_CONTACT_CHANNEL') | async as codebook">
            {{ codebook[getParam(ticket.parameters, contactParamNames.CONTACT_CHANNEL).value] }}
          </div>
          <div [hidden]="!editMode">
            <select
              *ngIf="channels"
              class="full"
              id="ticket-edit-history-channel"
              [disabled]="!editMode"
              [(ngModel)]="getParam(ticket.parameters, contactParamNames.CONTACT_CHANNEL).value"
              (ngModelChange)="fieldChanged(contactParamNames.CONTACT_CHANNEL, $event)">
              <option *ngFor="let channel of channels" [value]="channel.code">
                <ng-container *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_CHANNEL') | async as codebook">
                  {{ codebook[channel.code] }}
                </ng-container>
              </option>
            </select>
          </div>
        </app-sidebox-row>
        <app-sidebox-row
          *ngIf="visibleParameters.get(contactParamNames.CONTACT_DIRECTION)"
          [label]="'wc.ecare.ticket.direction' | translate">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_CONTACT_DIRECTION') | async as codebook">
            {{ codebook[getParam(ticket.parameters, contactParamNames.CONTACT_DIRECTION).value] }}
          </div>
          <div [hidden]="!editMode">
            <select
              *ngIf="directions"
              class="full"
              id="ticket-edit-history-direction"
              [disabled]="!editMode"
              [(ngModel)]="getParam(ticket.parameters, contactParamNames.CONTACT_DIRECTION).value"
              (ngModelChange)="fieldChanged(contactParamNames.CONTACT_DIRECTION, $event)">
              <option *ngFor="let direction of directions" [value]="direction.code">
                <ng-container *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_DIRECTION') | async as codebook">
                  {{ codebook[direction.code] }}
                </ng-container>
              </option>
            </select>
          </div>
        </app-sidebox-row>
        <app-sidebox-row
          *ngIf="visibleParameters.get(contactParamNames.CONTACT_TYPE)"
          [label]="'wc.ecare.ticket.contactType' | translate">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
            {{ codebook[getParam(ticket.parameters, contactParamNames.CONTACT_TYPE).value] }}
          </div>
          <div [hidden]="!editMode">
            <select
              class="full"
              id="ticket-edit-history-contactType"
              [disabled]="!editMode"
              [(ngModel)]="getParam(ticket.parameters, contactParamNames.CONTACT_TYPE).value"
              (ngModelChange)="
                fieldChanged(contactParamNames.CONTACT_TYPE, $event);
                contactTypeChanged($event, areaSelect2, subAreaSelect2)
              ">
              <option *ngFor="let contactType of contactTypes" [value]="contactType.code">
                <ng-container *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
                  {{ codebook[contactType.code] }}
                </ng-container>
              </option>
            </select>
          </div>
        </app-sidebox-row>
        <app-sidebox-row
          *ngIf="visibleParameters.get(contactParamNames.TYPE_AREA)"
          [label]="'wc.ecare.ticket.area' | translate">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
            {{
              getParam(ticket.parameters, contactParamNames.TYPE_AREA).value
                ? codebook[getParam(ticket.parameters, contactParamNames.TYPE_AREA).value]
                : '-'
            }}
          </div>
          <div [hidden]="!editMode">
            <select
              #areaSelect2
              class="full"
              id="ticket-edit-history-area"
              [disabled]="!editMode"
              [(ngModel)]="getParam(ticket.parameters, contactParamNames.TYPE_AREA).value"
              (ngModelChange)="fieldChanged(contactParamNames.TYPE_AREA, $event); areaChanged($event, subAreaSelect2)">
              <option *ngFor="let area of areas" [value]="area.code">
                <ng-container *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
                  {{ codebook[area.code] }}
                </ng-container>
              </option>
            </select>
          </div>
        </app-sidebox-row>

        <app-sidebox-row
          *ngIf="visibleParameters.get(contactParamNames.TYPE_SUBAREA)"
          [label]="'wc.ecare.ticket.subArea' | translate">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
            {{
              getParam(ticket.parameters, contactParamNames.TYPE_SUBAREA).value
                ? codebook[getParam(ticket.parameters, contactParamNames.TYPE_SUBAREA).value]
                : '-'
            }}
          </div>
          <div [hidden]="!editMode">
            <select
              #subAreaSelect2
              class="full"
              id="ticket-edit-history-subArea"
              [disabled]="!editMode"
              [(ngModel)]="getParam(ticket.parameters, contactParamNames.TYPE_SUBAREA).value"
              (ngModelChange)="fieldChanged(contactParamNames.TYPE_SUBAREA, $event)">
              <option *ngFor="let subArea of subAreas" [value]="subArea.code">
                <ng-container *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
                  {{ codebook[subArea.code] }}
                </ng-container>
              </option>
            </select>
          </div>
        </app-sidebox-row>
        <app-sidebox-row *ngIf="ticket.recordVersion" label="{{ 'wc.ecare.ticket.created' | translate }}"
        >{{ ticket.createdBy }}
          <span class="-ticket-date">({{ ticket.created | date : 'dd.MM.yyyy HH:mm' }})</span>
        </app-sidebox-row>
        <app-sidebox-row *ngIf="ticket.recordVersion" label="{{ 'wc.ecare.ticket.modified' | translate }}"
        >{{ ticket.modifiedBy }}
          <span class="-ticket-date">({{ ticket.modified | date : 'dd.MM.yyyy HH:mm' }})</span>
        </app-sidebox-row>
      </div>
    </app-ecare-section>

    <app-ecare-section [sectionTitle]="'wc.ecare.ticket.customer' | translate">
      <div class="generated-form" [formGroup]="form">
        <div class="generated-input-container col12">
          <label>
            {{ 'wc.ecare.ticket.customer' | translate }}
            <span class="required">*</span>
          </label>
          <!--                  <app-ecare-filter-autocomplete-box-->
          <!--                    #customerSearch-->
          <!--                    [searchedEntity]="'Customer'"-->
          <!--                    (handleChangeEvent)="fieldChanged('customer', $event)">-->
          <!--                  </app-ecare-filter-autocomplete-box>-->
          <app-form-field-modal-picker
            labelField="displayName"
            controlName="cuRefNo"
            [disabled]="!editMode || (newTicket && customerLocalStorageService.getCurrentCustomer())"
            [parentGroup]="form"
            [translationPrefix]="'wc.ecare.ticket'"
            [pickerType]="pickerInputType.CUSTOMER"
            (selectionChanged)="fieldChanged('Customer', $event)" />
        </div>
        <div *ngIf="form.controls.cuRefNo.value" class="generated-input-container col4">
          <label>
            {{ 'wc.ecare.ticket.id' | translate }}
          </label>
          <input disabled [value]="form.controls.cuRefNo.value?.id" />
        </div>
        <div *ngIf="form.controls.cuRefNo.value" class="generated-input-container col8">
          <label>
            {{ 'wc.ecare.dashboard.subjectType.label' | translate }}
          </label>
          <input disabled [value]="form.controls.cuRefNo.value?.subject.subjectType" />
        </div>
        <div *ngIf="form.controls.cuRefNo.value" class="generated-input-container col12">
          <label>
            {{ 'wc.ecare.dashboard.primaryContact.label' | translate }}
          </label>
          <input disabled [value]="form.controls.cuRefNo.value?.contact | displayContact" />
        </div>
        <div *ngIf="form.controls.cuRefNo.value" class="generated-input-container col12">
          <label>
            {{ 'wc.ecare.dashboard.customerAccount.header' | translate }}
          </label>
          <app-form-field-modal-picker
            labelField="displayName"
            controlName="caRefNo"
            [disabled]="!editMode"
            [parentGroup]="form"
            [customerId]="form.controls.cuRefNo.value?.id"
            [pickerType]="pickerInputType.CUSTOMER_ACCOUNT"
            (selectionChanged)="fieldChanged('CustomerAccount', $event)" />
        </div>
      </div>
    </app-ecare-section>

    <app-ecare-section
      *ngIf="form.controls.cuRefNo.value && visibleParameters.get(TicketService.PREFERRED_CONTACT_PARAM_NAME)"
      [sectionTitle]="'wc.ecare.opportunities.contact' | translate">
      <div class="generated-form">
        <div *ngIf="contacts?.length" class="generated-input-container col12">
          <ng-container *ngFor="let contact of contacts; let i = index">
            <div
              *ngIf="(!editMode && i === selectedContactIndex) || editMode"
              class="{{ editMode ? 'section-widget' : '' }} contact-card full-width {{
                !editMode ? 'notEditable' : ''
              }} {{ i === selectedContactIndex ? 'selected' : '' }}"
              (click)="editMode ? setPreferredContact(contact, i) : null">
              <div class="radio">
                <input type="radio" name="contact-radio" [checked]="i === selectedContactIndex" />
                <div class="radio-label">
                  <div class="contact-info">
                    <div class="-name">{{ contact?.firstName }} {{ contact.middleName }} {{ contact.lastName }}</div>
                    <div class="-email">{{ contact?.email }}</div>
                    <div class="-phone-no">{{ contact?.phone1 }}</div>
                    <!-- todo: use phone number pipe <div class="-phone-no">{{contact.phone1 | phoneNumber}}</div>-->
                  </div>
                  <div
                    *ngIf="editMode && contact.type === contactTypesEnum.OPPORTUNITY"
                    ngbDropdown
                    class="edit-button-wrapper"
                    (click)="$event.stopPropagation()">
                    <button class="edit-button cursor-pointer" ngbDropdownToggle>
                      <i [inlineSVG]="'/assets/svg/feather-vertical-dots.svg'"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="shoppingCartDropdown">
                      <button
                        class="dropdown-item filled"
                        ngbTooltip="'wc.common.edit' | translate"
                        (click)="editContact(contact, i)">
                        <i [inlineSVG]="'/assets/svg/feather-edit.svg'"></i>{{ 'wc.common.edit' | translate }}
                      </button>
                      <button
                        class="dropdown-item"
                        ngbTooltip="'wc.common.delete.button' | translate"
                        (click)="removeContact(contact)">
                        <i [inlineSVG]="'/assets/svg/remove.svg'"></i>{{ 'wc.common.delete.button' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="editMode && form.controls.cuRefNo.value">
          <div
            class="buttons"
            app-button
            iconPath="/assets/svg/feather-plus-circle.svg"
            [label]="'wc.ecare.opportunity.newContact.text' | translate"
            (click)="addContact()"></div>
        </ng-container>
      </div>
    </app-ecare-section>

    <app-ecare-section
      *ngIf="form.controls.cuRefNo.value && visibleParameters.get(TicketService.PREFERRED_CONTACT_PARAM_NAME)"
      [sectionTitle]="'wc.ecare.opportunities.address' | translate">
      <div class="generated-form">
        <div *ngIf="addresses?.length" class="generated-input-container col12">
          <ng-container *ngFor="let address of addresses; let i = index">
            <div
              *ngIf="(!editMode && i === selectedAddressIndex) || editMode"
              class="{{ editMode ? 'section-widget' : '' }} contact-card full-width {{
                !editMode ? 'notEditable' : ''
              }} {{ i === selectedAddressIndex ? 'selected' : '' }}"
              (click)="editMode ? setPreferredAddress(address, i) : null">
              <div class="radio">
                <input type="radio" name="address-radio" [checked]="i === selectedAddressIndex" />
                <div class="radio-label">
                  <div class="contact-info">
                    <div>{{ address | displayAddress }}</div>
                  </div>
                  <div
                    *ngIf="editMode && address.type === addressTypesEnum.OPPORTUNITY"
                    ngbDropdown
                    class="edit-button-wrapper"
                    (click)="$event.stopPropagation()">
                    <button class="edit-button cursor-pointer" ngbDropdownToggle>
                      <i [inlineSVG]="'/assets/svg/feather-vertical-dots.svg'"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="shoppingCartDropdown">
                      <button
                        class="dropdown-item filled"
                        ngbTooltip="'wc.common.edit' | translate"
                        (click)="editAddress(address, i)">
                        <i [inlineSVG]="'/assets/svg/feather-edit.svg'"></i>{{ 'wc.common.edit' | translate }}
                      </button>
                      <button
                        class="dropdown-item"
                        ngbTooltip="'wc.common.delete.button' | translate"
                        (click)="removeAddress(address)">
                        <i [inlineSVG]="'/assets/svg/remove.svg'"></i>{{ 'wc.common.delete.button' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="editMode && form.controls.cuRefNo.value">
          <div
            class="buttons"
            app-button
            iconPath="/assets/svg/feather-plus-circle.svg"
            [label]="'wc.ecare.opportunity.newContact.text' | translate"
            (click)="addAddress()"></div>
        </ng-container>
      </div>
    </app-ecare-section>
  </div>
</app-ecare-page-layout>

<ng-template #noDataMessage>
  <div class="no-data">{{ 'wc.ecare.ticket.noData' | translate }}</div>
</ng-template>
