import { Component, Input } from '@angular/core';
import { DmsFileDto, PictureTypeDto } from '@btl/admin-bff';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-files-add-modal',
  templateUrl: './files-add-modal.component.html',
  styleUrls: ['./files-add-modal.component.scss'],
})
export class FilesAddModalComponent {
  @Input() dialogRef;
  @Input() uploadStep = true;
  @Input() pictureTypes = [];
  @Input() files = [];
  @Input() fileExtensions = [];
  @Input() uploadedHandler = (newFile: DmsFileDto) => {};

  uploadedImage;
  selectedType;
  multiple = true;
  wrongFileFormat = false;
  toBigFile = false;
  maxBodySizeMb = Number.parseInt(environment.maxBodySizeMb);

  select(pictureType: PictureTypeDto) {
    this.selectedType = pictureType;
    this.uploadStep = false;
  }

  public static readFile(fileToUpload: File, uploadedHandler: (newFile: DmsFileDto) => void) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      let uploadedImage = new Image();
      uploadedImage.src = reader.result as string;
      uploadedImage.onload = () => {};

      const base64Content = event.target.result.split(',');
      const mimeType = base64Content[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];

      const newFile: DmsFileDto = {
        content: event.target.result.split('base64,')[1],
        contentHref: uploadedImage.src,
        name: fileToUpload.name,
        mediaType: mimeType,
        size: fileToUpload.size,
        created: new Date(),
      };
      uploadedHandler(newFile);
    };
    reader.readAsDataURL(fileToUpload);
  }

  fileBrowseHandler(files) {
    for (const fileToUpload of files) {
      if ((fileToUpload.size / 1024) / 1024 > this.maxBodySizeMb) {
        this.toBigFile = true;
        return;
      } else {
        this.toBigFile = false;
      }
      if (this.isCorrectFileFormat(fileToUpload.name)) {
        for (const item of files) {
          item.progress = 0;
          this.files.push(item);
        }
        this.uploadFilesSimulator(0);
        this.wrongFileFormat = false;
        FilesAddModalComponent.readFile(fileToUpload, this.uploadedHandler);
      } else {
        this.wrongFileFormat = true;
      }
    }
  }

  isCorrectFileFormat(fileName: string) {
    if (this.fileExtensions.length > 0) {
      const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
      if (this.fileExtensions.includes(fileFormat)) {
        return true;
      }
      return false;
    }
    return true;
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }
}
