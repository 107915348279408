import { Component, Input, OnInit } from '@angular/core';
import { NavigationTypeEnum } from "../navigation-menu.component";
import { NavigationMenuService } from "@service/navigation-menu.service";
import { WcOrderingService } from '@service/wc-ordering.service';
import { ScenarioTypeEnum } from 'app/helpers/order-utils';

@Component({
  selector: 'app-shopping-cart-side-menu',
  templateUrl: './shopping-cart-side-menu.component.html',
  styleUrls: ['./shopping-cart-side-menu.component.scss']
})
export class ShoppingCartSideMenuComponent implements OnInit {
  readonly NavigationTypeEnum = NavigationTypeEnum;

  isCollapsed: boolean = false;

  @Input()
  preCalculatedShoppingCart;

  popupTimeout;
  showPopup: 'ShoppingCart' | 'WishList' | '';
  isQuoteOrder: boolean;

  @Input()
  navigationType: NavigationTypeEnum = NavigationTypeEnum.eshop;

  constructor(private navigationMenuService: NavigationMenuService, private wcOrderingService: WcOrderingService) {
  }

  ngOnInit(): void {
    this.navigationMenuService.openShoppingCartPopup.subscribe(() => {
      this.isCollapsed = true;
      clearTimeout(this.popupTimeout);
      this.showPopup = 'ShoppingCart';
    });
    this.wcOrderingService.getCurrentOrder().subscribe((currentOrder) => {
      if (currentOrder) {
        this.isQuoteOrder = currentOrder.scenarioType === ScenarioTypeEnum.QUOTE_MANAGEMENT;
      } else {
        throw new Error('There is no current order!');
      }
    });
  }

  closeCollapse(): void {
    this.isCollapsed = false;
    this.popupTimeout = setTimeout(() => {
      this.showPopup = '';
    }, 170);
  }

}
