import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchEntityType } from '../../ecare-search/ecare-search-filter/ecare-search-filter.component';

@Component({
  selector: 'app-customers-filter',
  templateUrl: './ecare-customers-filter.component.html',
})
export class EcareCustomersFilterComponent implements OnInit {

  @Input() clearFilterEmitter: EventEmitter<boolean>;
  @Output() readonly filterChanged = new EventEmitter<string>();

  entitySearchForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    if (this.clearFilterEmitter) {
      this.clearFilterEmitter.subscribe(() => {
        this.entitySearchForm.reset();
        this.handleChange();
      });
    }
    this.buildSearchForm({
      searchValue: '',
      searchEntity: SearchEntityType.CUSTOMER,
    });
  }

  buildSearchForm(searchForm) {
    this.entitySearchForm = this.formBuilder.group(searchForm);
  }

  handleChange() {
    this.filterChanged.emit(this.entitySearchForm.value.searchValue);
  }
}
