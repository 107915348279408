import { Component, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AuthFactoryService,
  CodebookService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  CurrentLocaleService,
  TicketingService
} from '@btl/btl-fe-wc-common';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { takeUntil } from 'rxjs/operators';
import { CodebookDto, TicketTypeDto, TicketTypeParamDto } from '@btl/order-bff';
import { OpportunityDetailsComponent } from '../opportunity-details/opportunity-details.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-opportunity-filter',
  templateUrl: './opportunity-filter.component.html',
})
export class OpportunityFilterComponent extends ComplexFilter {
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    this.closeAdvancedFilterOnGlobalClick(event);
  }

  protected advancedFilterFields = [
    'createdDateFrom',
    'createdDateTo',
    'startFrom',
    'startTo',
    'slaDueDateFrom',
    'slaDueDateTo',
    'opportunityState',
  ];

  filterForm = this.formBuilder.group({
    searchText: [],
    createdDateFrom: [],
    createdDateTo: [],
    startFrom: [],
    startTo: [],
    slaDueDateFrom: [],
    slaDueDateTo: [],
    opportunityState: '',
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.searchText, 'searchText'),

    new ControlFilterMiner(this.filterForm.controls.createdDateFrom, 'created.from', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.createdDateTo, 'created.to', CompareTypeDtoEnum.SMALLEREQ),

    new ControlFilterMiner(this.filterForm.controls.startFrom, 'startFrom.from', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.startTo, 'startFrom.to', CompareTypeDtoEnum.SMALLEREQ),

    new ControlFilterMiner(this.filterForm.controls.slaDueDateFrom, 'slaDueDate.from', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.slaDueDateTo, 'slaDueDate.to', CompareTypeDtoEnum.SMALLEREQ),

    new ControlFilterMiner(this.filterForm.controls.opportunityState, 'opportunityState'),
  ];

  opportunityStates: CodebookDto[];
  opportunityConfiguration: TicketTypeDto;
  parametersOptions = [];

  selectedParameter: TicketTypeParamDto;

  constructor(
    private formBuilder: FormBuilder,
    private codebookService: CodebookService,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private authFactoryService: AuthFactoryService,
    private ticketingService: TicketingService,
    private currentLocaleService: CurrentLocaleService
  ) {
    super();

    this.codebookService
      .getCodebooks('TICKET_OPPORTUNITY_STATE')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(cb => (this.opportunityStates = cb));

    this.ticketingService
      .getTicketTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.opportunityConfiguration = result.find(
          type => type.code === OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE
        );
        this.setParameterOptions();
        this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
          this.setParameterOptions();
        });
      });
  }

  ngOnInit() {
    super.ngOnInit(true);
  }

  changeFilterField(fieldName: string, value: any): void {
    super.changeFilterField(fieldName, value);
    if (fieldName === 'paramName') {
      this.selectedParameter = this.opportunityConfiguration?.parameters.find(param => param.name === value);
    }
  }

  setParameterOptions() {
    const paramOptions = [];
    if (this.opportunityConfiguration) {
      this.opportunityConfiguration.parameters.forEach((parameter: TicketTypeParamDto) =>
        paramOptions.push({
          value: parameter.name,
          label: parameter.localizedNames[this.currentLocaleService.getCurrentLanguage()]
        })
      );
    }
    this.parametersOptions = paramOptions;
  }

  protected getDefaultFilterValues() {
    return {
      searchText: null,
      createdDateFrom: null,
      createdDateTo: null,
      startFrom: null,
      startTo: null,
      slaDueDateFrom: null,
      slaDueDateTo: null,
      opportunityState: '',
    };
  }
}
