<form class="ecare-filter dashboard-search_filter opportunity-form" [formGroup]="filterForm">
  <div class="main-search-wrapper">
    <div class="-filter-fields -filter-fields-group">
      <input
        id="002_contact_history_filter"
        type="text"
        formControlName="searchText"
        class="filter-input-id"
        placeholder="{{ 'wc.common.navigation.search.button' | translate }}..."
        (keydown.enter)="$event.preventDefault(); handleChange()" />
      <app-advanced-filter
        clearHistoryTextLocalizedKey="wc.ecare.ordersFilter.clearFilters"
        [form]="filterForm"
        [isAdvancedFilterOpen]="isAdvancedFilterOpen"
        [changedFilterFieldsCount]="changedFilterFieldsCount"
        (openFilterEvent)="changeAdvancedFilterOpenToEvent($event)"
        (resetFieldsEmitter)="resetFilterFields()">
        <app-advanced-filter-field
          labelLocalizedKey="wc.ecare.ticket.startFrom"
          controlName="startFrom"
          startOfTheDay="true"
          placeholderLocalizedKey="wc.ecare.contactHistory.createdDateFrom.placeholder"
          [fieldType]="'date'"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
        </app-advanced-filter-field>
        <app-advanced-filter-field
          labelLocalizedKey="wc.ecare.ticket.startFrom"
          controlName="startTo"
          endOfTheDay="true"
          placeholderLocalizedKey="wc.ecare.contactHistory.createdDateTo.placeholder"
          [fieldType]="'date'"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
        </app-advanced-filter-field>
        <app-advanced-filter-field
          labelLocalizedKey="wc.ecare.ticket.slaDueDate"
          controlName="slaDueDateFrom"
          startOfTheDay="true"
          placeholderLocalizedKey="wc.ecare.contactHistory.createdDateFrom.placeholder"
          [fieldType]="'date'"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
        </app-advanced-filter-field>
        <app-advanced-filter-field
          labelLocalizedKey="wc.ecare.ticket.slaDueDate"
          controlName="slaDueDateTo"
          endOfTheDay="true"
          placeholderLocalizedKey="wc.ecare.contactHistory.createdDateTo.placeholder"
          [fieldType]="'date'"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
        </app-advanced-filter-field>
        <app-advanced-filter-field
          labelLocalizedKey="wc.ecare.contactHistory.createdDate"
          controlName="createdDateFrom"
          startOfTheDay="true"
          placeholderLocalizedKey="wc.ecare.contactHistory.createdDateFrom.placeholder"
          [fieldType]="'date'"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
        </app-advanced-filter-field>
        <app-advanced-filter-field
          labelLocalizedKey="wc.ecare.contactHistory.createdDate"
          controlName="createdDateTo"
          endOfTheDay="true"
          placeholderLocalizedKey="wc.ecare.contactHistory.createdDateTo.placeholder"
          [fieldType]="'date'"
          (changeFilterFieldAction)="changeFilterField($event.controlName, $event)">
        </app-advanced-filter-field>
      </app-advanced-filter>
    </div>
    <app-button
      variant="primary"
      id="004_opportunity_filter"
      iconPath="/assets/svg/search-icon.svg"
      label="{{ 'wc.common.search.button' | translate }}"
      (click)="handleChange()">
    </app-button>
  </div>
</form>
