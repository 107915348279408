import { Component, OnInit } from '@angular/core';
import { EnableDynamicLoading, ServiceUtils } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { CompareType } from '../../../../../mvn_modules/@btl/btl-fe-wc-common';
import { ContactParamNames } from './contact-history-details/contact-history-details.component';
import { TicketService } from '@service/ticket.service';
import { AbstractTicketTableComponent } from '../tickets/abstract-ticket-table.component';
import { TicketElse } from '../../../models/tickets-holder';
import { SortDto } from '@btl/order-bff';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-contact-history-component',
  templateUrl: './ecare-contact-history.component.html',
})
@EnableDynamicLoading({ customName: EcareContactHistoryComponent.PAGE_ID })
export class EcareContactHistoryComponent extends AbstractTicketTableComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareContactHistoryComponent';

  pageId(): string {
    return EcareContactHistoryComponent.PAGE_ID;
  }

  defaultSortOrdering: SortDto = { column: 'startFrom', sortOrder: 'desc' };

  canDisplayNoTicketsText = false;
  contactParamNames = ContactParamNames;

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.cuRefNo = queryParams['cuRefNo'];
      this.caRefNo = queryParams['caRefNo'];
      this.queryParams = queryParams;
    });

    this.initSearch('CUSTOMER_CONTACT', 'CUSTOMER')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {});
  }

  loadData() {
    this.ticketService
      .searchByText(this.search, this.pageSize, this.from, 'CUSTOMER_CONTACT')
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.totalItems = result.total.value;
        this.extendTickets(result?.hits.map(ticketHolder => ticketHolder._source));
      });
  }

  extendTickets(resultTickets: any[]) {
    const tickets = [];
    const customers: string[] = [];

    if (resultTickets) {
      resultTickets.forEach(ticket => {
        const tasks: string[] = [];
        ticket['area'] = TicketService.getParamValue(ticket, ContactParamNames.TYPE_AREA);
        ticket['subArea'] = TicketService.getParamValue(ticket, ContactParamNames.TYPE_SUBAREA);
        ticket['contactType'] = TicketService.getParamValue(ticket, ContactParamNames.CONTACT_TYPE);
        ticket['contactChannel'] = TicketService.getParamValue(ticket, ContactParamNames.CONTACT_CHANNEL);
        ticket['contactDirection'] = TicketService.getParamValue(ticket, ContactParamNames.CONTACT_DIRECTION);

        ticket['customer'] = ticket?.parties?.find(party => party.partyRole === 'Customer')?.displayName;
        ticket['customerAccount'] = ticket.parties?.find(party => party.partyRole === 'CustomerAccount')?.displayName;
        if (!ticket['customer']) {
          ticket['customer'] = TicketService.getReferenceValue(ticket, 'com.emeldi.ecc.be.crm.dto.Customer');
          if (ticket['customer']) {
            customers.push(ticket['customer']);
          }
        }

        if (!ticket['customerAccount']) {
          ticket['customerAccount'] = TicketService.getReferenceValue(
            ticket,
            'com.emeldi.ecc.be.crm.dto.CustomerAccount'
          );
        }
        ticket.references.forEach(reference => {
          if (reference.entityType === 'com.emeldi.ecc.be.ticket.dto.Ticket') {
            tasks.push(reference.entityId);
          }
        });
        ticket['tasksIds'] = tasks;
        ticket['tasks'] = [];
        const contact = TicketService.getParamValue(ticket, TicketService.PREFERRED_CONTACT_PARAM_NAME);
        if (contact) {
          ticket['contact'] = JSON.parse(contact);
        }
        tickets.push(ticket);
      });

      if (customers.length > 0) {
        const search = ServiceUtils.getUnlimitedSearch();
        search.filtering.push({
          column: 'id',
          compareType: CompareType.CompareTypeDtoEnum.IN,
          value: customers,
        });
        this.customerService
          .getCustomers(search)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(customers => {
            tickets.forEach(ticket => {
              if (ticket['customer']) {
                const customer = customers.data.find(loopCustomer => loopCustomer.id === ticket['customer']);
                if (customer) {
                  ticket['customer'] = customer.displayName;
                  if (ticket['customerAccount']) {
                    const ca = this.customerLocalStorageService.getPartyByIdRecursive(
                      customer.childParties,
                      ticket['customerAccount']
                    );
                    if (ca) {
                      ticket['customerAccount'] = ca.displayName;
                    }
                  }
                }
              }
            });
          });
      }
      tickets.forEach(contactHistory => this.tickets.push(contactHistory));
      this.loading = false;
      if (this.tickets.length > 0 && this.tickets.length < this.totalItems) {
        this.moreRecordsExists = true;
      } else {
        this.moreRecordsExists = false;
      }
    }
  }

  showDetails(ticketDto: TicketElse, inNewTab: boolean = false) {
    if (this.editCallback) {
      this.editCallback(ticketDto, inNewTab);
    } else {
      if (inNewTab) {
        window.open(
          this.router.serializeUrl(this.siblingUrlTree('ContactHistoryDetailsComponent', { id: ticketDto.id })),
          '_blank'
        );
      } else {
        this.navigateSibling('ContactHistoryDetailsComponent', { id: ticketDto.id });
      }
    }
  }

  newInteraction() {
    const urlTree = this.router.createUrlTree(['newTicket'], { relativeTo: this.route, queryParams: this.queryParams });
    this.router.navigateByUrl(urlTree);
  }

  getSVGIcon(iconName: any): string {
    switch (iconName) {
      case 'OUT':
        return '/assets/svg/phone-outgoing.svg';
      case 'IN':
        return '/assets/svg/phone-incoming.svg';
    }
  }

  getSVGIconTooltip(iconName: any): string {
    return `wc.ecare.contactHistory.icon.${iconName}`;
  }

  sectionTabsChange(tab: any) {
    if (tab === 'USER_CREATED') {
      this.search.filtering.push({
        column: 'createdBy',
        compareType: CompareTypeDtoEnum.EQUAL,
        value: this.account.login,
      });
    } else {
      this.search.filtering = this.search.filtering.filter(filter => filter.column != 'createdBy');
    }
    this.tickets.length = 0;
    this.from = 0;
    this.loadData();
  }

  protected readonly TicketService = TicketService;
}
