import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WcOrderingService } from '@service/wc-ordering.service';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import {
  AssetDto,
  CustomerAccountDto,
  CustomerBillDto,
  CustomerDto,
  GroupDto,
  OrderDto,
  PartyDto,
  UsageInformationDto,
  UsageService
} from '@btl/order-bff';
import { CustomerPartyUtil } from '../../../helpers/customer-party.util';
import { PaymentUtils } from '../../../helpers/payment-utils';
import {
  AbstractPageComponent,
  CompareType,
  EnableDynamicLoading,
  GroupFilter,
  LogErrorHandler,
  OrderingService,
  ProductService,
  Search
} from '@btl/btl-fe-wc-common';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { CustomerService } from '@service/customer.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OrderStateTypeEnum } from '../../../models/orderStateTypeEnum';
import { OrderUtils } from '../../../helpers/order-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, of } from 'rxjs';
import { CrmPartyRoleEnum } from '../../../models/CrmPartyRoleEnum';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ecare-dashboard',
  templateUrl: './ecare-dashboard.component.html',
})
@EnableDynamicLoading({ customName: EcareDashboardComponent.PAGE_ID })
export class EcareDashboardComponent extends AbstractPageComponent implements OnInit {
  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  public static readonly PAGE_ID = 'EcareDashboardComponent';

  pageId(): string {
    return EcareDashboardComponent.PAGE_ID;
  }

  customer: CustomerDto;
  customerAccounts = new Array<CustomerAccountDto>();
  selectedCustomerAccount: CustomerAccountDto;
  isBusiness: boolean;
  standaloneServices: Map<CustomerAccountDto, AssetDto[]> = new Map<CustomerAccountDto, AssetDto[]>();

  editingWidgets: boolean = false;
  groups: GroupDto[];
  widgetElementsGroups: GroupDto[] = [];

  widgets = new Map<GroupDto, PartyDto[]>();

  /**
   * The URL query parameters.
   */
  urlQueryParameters;

  /**
   * The current order or null if there is no current order.
   */
  order: OrderDto;

  lastOrders: OrderDto[] = [];
  lastInDeliveryOrders: OrderDto[] = [];

  invoices: CustomerBillDto[];
  oldestNotPayedInvoice: CustomerBillDto;

  subscriptionsUsages = new Map<string, UsageInformationDto>();
  subscriptionsUsagesLoaded = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private renderer: Renderer2,
    private wcOrderingService: WcOrderingService,
    private orderingService: OrderingService,
    public customerLocalStorageService: CustomerLocalStorageService,
    public ngbModal: NgbModal,
    private readonly customerService: CustomerService,
    private productService: ProductService,
    private usageService: UsageService,
    private logErrorHandler: LogErrorHandler
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');

    // The component will reinitialize on every URL change:
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.refreshContextCustomer();

    const filter: GroupFilter = {
      attributes: {
        isInternal: false,
        type: 'DASHBOARD_WIDGET',
        onlyEnabled: true,
      },
      sorting: [{ column: 'priority', sortOrder: 'asc' }],
      paging: { page: 1, pageSize: 200 },
    };

    this.productService
      .getProductGroups(filter)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(pagedGroups => {
        this.groups = pagedGroups.data;
        if (this.customerLocalStorageService.getCurrentCustomer().childParties.length === 0) {
          //this.router.navigateByUrl('ecare/settings');
        }
        this.customerLocalStorageService.contextChanged.subscribe(() => {
          this.loadPartiesAndProducts();
        });
        this.handlePayment();
        this.loadPartiesAndProducts();
        this.loadInvoices();
        this.loadOrders();
        this.loadOldestNotPayedInvoice();
      });
  }

  private loadOrders = () => {
    const search: Search = {
      filtering: [
        {
          column: 'partyRefNo',
          compareType: 'IN',
          value: this.customerLocalStorageService.getCurrentFilterPartyIds(),
        },
        {
          column: 'orderCalcStateType',
          compareType: CompareTypeDtoEnum.DIFFERENT,
          value: 'EMPTY',
        },
        {
          column: 'orderType',
          compareType: 'EQUAL',
          value: 'SALES',
        },
      ],
      sorting: [{ column: 'created', sortOrder: 'desc' }],
      paging: {
        page: 1,
        pageSize: -1,
      },
    };

    this.blockUIElement.start();
    this.orderingService
      .filterOrders(search, null)
      .pipe(
        finalize(() => {
          this.blockUIElement.stop();
        })
      )
      .subscribe(pagedOrders => {
        const orders = pagedOrders.data.filter(order => order.orderItems.length > 0);
        if (orders.length > 0) {
          this.lastOrders = orders.slice(0, 3);
          this.lastInDeliveryOrders = orders
            .filter(
              order =>
                order.orderStateType === OrderStateTypeEnum.CONFIRMED &&
                OrderUtils.getOrderAttributeValue(order, 'paymentStatus') === 'COMPLETED' &&
                OrderUtils.getOrderAttributeValue(order, 'isProcessingError') === 'false'
            )
            .slice(0, 3);
        }
      });
  };

  private loadInvoices() {
    const search: Search = {
      filtering: [
        {
          column: 'billingAccount.id',
          compareType: 'IN',
          value: this.customerLocalStorageService.getCurrentFilterPartyIds(),
        },
      ],
      sorting: [{ column: 'billDate', sortOrder: 'desc' }],
      paging: {
        page: 1,
        pageSize: 20,
      },
    };

    this.customerService
      .getFinancialDocumentsByFilter(search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          this.invoices = result.customerBills;
        }
      });
  }

  private loadOldestNotPayedInvoice() {
    this.customerService
      .getFinancialDocumentsByFilter({
        filtering: [
          {
            column: 'remainingAmount.amount',
            compareType: 'BIGGER',
            value: '0',
          },
          {
            column: 'billingAccount.id',
            compareType: 'IN',
            value: this.customerLocalStorageService.getCurrentFilterPartyIds(),
          },
        ],
        sorting: [{ column: 'billDate', sortOrder: 'asc' }],
        paging: {
          page: 1,
          pageSize: 1,
        },
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result && result.customerBills.length > 0) {
          this.oldestNotPayedInvoice = result.customerBills[0];
        }
      });
  }

  /**
   * Load parties (customer, customer accounts) and products to display.
   */
  private loadPartiesAndProducts(): void {
    this.customerAccounts = [];
    this.widgetElementsGroups = [];

    this.widgets = new Map<GroupDto, PartyDto[]>();
    this.standaloneServices.clear();

    this.customer = this.customerLocalStorageService.getCurrentCustomer();
    this.selectedCustomerAccount = this.customerLocalStorageService.getCurrentCustomerAccount();
    if (!this.customer) {
      return;
    }

    let allSubscriptions = [];
    if (!this.selectedCustomerAccount) {
      if (this.customer.childParties.length > 0) {
        allSubscriptions = CustomerPartyUtil.getChildPartiesByRole(
          this.customer.childParties,
          CrmPartyRoleEnum.Subscription
        );
      }
    } else {
      allSubscriptions = this.selectedCustomerAccount.childParties;
    }

    let subscriptionIds: Array<string> = [];
    allSubscriptions = allSubscriptions.filter(subscription => subscription.state != 'CANCELED');

    this.groups.forEach(group => {
      const subscriptions = allSubscriptions.filter(childParty =>
        childParty.assets.find(asset => asset.product.groupInfos?.find(productGroup => group.id === productGroup.id))
      );

      this.widgets.set(group, subscriptions);
      if (subscriptions?.length > 0) {
        subscriptionIds = subscriptionIds.concat(
          subscriptions
            .filter(subscription => !subscriptionIds.find(id => id === subscription.id))
            .map(subscription => subscription.id)
        );
      }

      if (group.groupParams['widget_ElementType']) {
        this.widgetElementsGroups.push(group);
      }
    });

    const calls = [];
    subscriptionIds.forEach(id =>
      calls.push(
        this.usageService.getServiceUsage(id).pipe(
          catchError(error => {
            this.logErrorHandler.handleError(`Error when getting usage for service with id: ${id}`, error);
            return of(null);
          })
        )
      )
    );

    forkJoin(calls)
      .pipe(takeUntil(this.onDestroy$))

      .subscribe((responses: UsageInformationDto[]) => {
        let i = 0;
        responses.forEach(response => {
          if (response) {
            this.subscriptionsUsages.set(subscriptionIds[i], response);
            i++;
          }
        });
        this.subscriptionsUsagesLoaded = true;
      });

    if (calls.length === 0) {
      this.subscriptionsUsagesLoaded = true;
    }
  }

  /**
   * Checks whether the Customer has a simple structure, typically Resident customer.
   * I.e. Cu -> 1x CA -> 0-n Subscription
   * @private
   */
  private isSimpleCustomerStructure(): boolean {
    const customerAccounts: CustomerAccountDto[] = CustomerPartyUtil.getChildCustomerAccounts(
      this.customer.childParties
    );
    return (
      customerAccounts?.length === 1 &&
      !CustomerPartyUtil.getChildCustomerAccounts(customerAccounts[0].childParties)?.length
    );
  }

  /**
   * Handle payment - prepare information to display, confirm the order.
   */
  private handlePayment(): void {
    this.route.queryParams.subscribe(urlQueryParameters => {
      this.urlQueryParameters = urlQueryParameters;

      const successCallback = (order: OrderDto) => {
        this.order = order;
      };
      PaymentUtils.confirmOrderInstantly(this.wcOrderingService, this.urlQueryParameters, successCallback);
    });
  }

  refreshContextCustomer(): void {
    this.customerLocalStorageService.refreshCustomerContext().subscribe(() => {});
  }
}
