<form class="ecare-filter dashboard-search_filter full-width" [formGroup]="entitySearchForm">
  <div class="-filter-fields -filter-fields-group full-width">
    <input
      class="filter-input-id full-width"
      formControlName="searchValue"
      type="text"
      autocomplete="off"
      placeholder="{{ 'wc.ecare.search.customerSearch.placeholder' | translate }}" />
  </div>

  <app-button
    variant="primary"
    id="004_opportunity_filter"
    iconPath="/assets/svg/search-icon.svg"
    label="{{ 'wc.common.search.button' | translate }}"
    (click)="handleChange()" />
</form>
