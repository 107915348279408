<div class="form-attachments-wrapper" [formGroup]="form">
  <div class="attachments">
    <ng-container *ngFor="let attachment of this.getFormArray().controls; let i = index">
      <div *ngIf="!attachment.value.delete && !attachment.value.inline" class="attachment section-widget">
        <div class="left-panel">
          <div *ngIf="allowedNotifSending" class="checkbox">
            <input type="checkbox" [id]="'select' + i" (change)="selectUnselectAttachment(attachment.value)" />
            <label [for]="'select' + i"></label>
          </div>
          <div class="attachment-icon">
            <i [inlineSVG]="'/assets/svg/fileUploader/' + getTypeIcon(attachment.value.dmsFile) + '.svg'"></i>
          </div>
          <div class="attachment-description">
            <div class="name" [title]="attachment.value.dmsFile?.name">
              {{ attachment.value.dmsFile?.name }}
            </div>
            <div class="file-info">
              {{ attachment.value.dmsFile?.created | date : 'dd.MM.yyyy HH:mm' }}
              - {{ formatBytes(attachment.get('dmsFile').get('size').value) }}
            </div>
          </div>
        </div>

        <ng-container>
          <div ngbDropdown placement="bottom" class="listing-row-actions-entry">
            <span class="fa-stack cursor-pointer">
              <i class="fa fa-circle fa-stack-2x order-list-more-actions"></i>
              <i id="002_ecare_order_row" class="fa fa-ellipsis-v fa-stack-1x" ngbDropdownToggle></i>
            </span>

            <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="shoppingCartDropdown">
              <div>
                <button
                  class="dropdown-item"
                  ngbDropdownItem
                  style="min-width: 185px"
                  (click)="download(attachment)">
                  <i [inlineSVG]="'/assets/svg/download.svg'"></i
                  >{{ 'wc.ecare.notifications.downloadAttachment' | translate }}
                </button>
                <button
                  *ngIf="control"
                  class="dropdown-item"
                  ngbDropdownItem
                  style="min-width: 185px"
                  (click)="remove(attachment)">
                  <i [inlineSVG]="'/assets/svg/remove.svg'"></i
                  >{{ 'wc.ecare.notifications.deleteAttachment' | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <app-button
      *ngIf="control && !uploadButtonBelow"
      variant="secondary"
      [iconPath]="'/assets/svg/' + attachmentType + '.svg'"
      (click)="add()">
    </app-button>
  </div>
  <div *ngIf="control && uploadButtonBelow" class="buttons">
    <app-button
      variant="secondary"
      size="large"
      [iconPath]="'/assets/svg/' + attachmentType + '.svg'"
      label="{{ 'wc.ecare.attachments.' + attachmentType | translate }}"
      (click)="add()">
    </app-button>
    <app-button
      *ngIf="allowedNotifSending"
      variant="secondary"
      size="large"
      [iconPath]="'/assets/svg/' + attachmentType + '.svg'"
      label="{{ 'wc.ecare.attachments.sendNotification' | translate }}"
      (click)="sendNotification()">
    </app-button>
  </div>
</div>
