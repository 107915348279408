import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  OrderDto,
  ProductDetailDto,
  ProductInfoDto,
  ProductParamMetadataDto,
  ProductSortAttributeDto,
  SocketDto
} from '@btl/order-bff';
import { BlockTemplateComponent, ProductService, StickyMessageService } from '@btl/btl-fe-wc-common';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ProductInShoppingCart } from '../../../models/product-in-shopping-cart';
import { OneTimePriceComponent } from 'app/components/page/price/one-time-price.component';
import { PrecalculatedShoppingCart } from '../../../models/precalculated-shopping-cart';
import { CategoryTypeEnum, ProductFilter } from '../../../models/product-filter';
import _ from 'lodash';
import { ProductUtils } from '../../../helpers/product-utils';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductConfigurationComponent } from '../product-configuration/product-configuration.component';
import { ProductCustomService } from '@service/product-custom.service';
import { GroupDto } from '@btl/order-bff/model/groupDto';
import { ProductAddingToCart } from '@service/product-listing.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WishListPopupComponent } from 'app/components/wizard/wish-list/wish-list-popup/wish-list-popup.component';
import { ShoppingCartProductComponent } from '../shopping-cart-product/shopping-cart-product.component';

/**
 * {@code ShoppingCartItemComponent} is a component responsible for displaying one product in {@link
 * ShoppingCartComponent shopping cart}.
 *
 * Clients of shopping cart item can subscribe changes via {@link onChange}.
 */
@Component({
  selector: 'app-shopping-cart-item',
  templateUrl: './shopping-cart-item.component.html',
})
export class ShoppingCartItemComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @ViewChild('priceOc', { static: false }) priceOcComponent: OneTimePriceComponent;
  @ViewChild('priceRc', { static: false }) priceRcComponent: OneTimePriceComponent;
  @ViewChildren(ShoppingCartProductComponent)
  private shoppingCartProductComponents: QueryList<ShoppingCartProductComponent>;

  @Input() precalculatedShoppingCart: PrecalculatedShoppingCart;
  @Input() productInShoppingCart: ProductInShoppingCart;
  @Input() addon: ProductInfoDto;
  @Input() showQuantityChange = true;
  @Input() showRemoveBtn = true;
  @Input() showConfigurationBtn = true;
  @Input() displayMode: 'FULL' | 'COMPACT' | 'ROLLOUT';
  @Input() showSpecialOffer = false;
  @Input() showDetails: boolean = false;
  @Input() showImg = false;
  @Input() showQuantity: boolean;
  @Input() showAddons = false;
  @Input() readOnlyOrder: boolean = false;
  @Input() orderItemsFormsValues = {};

  product: ProductDetailDto;
  canDisplayQuantity = true;
  canDisplayProduct = false;
  delete;
  modify;
  blockTemplate = BlockTemplateComponent;
  showVat: boolean = true;
  activeAddon: boolean = false;
  childProducts: ProductInShoppingCart[] = [];
  childProductsToDisplay: ProductInShoppingCart[] = [];
  addonsInGroups: Map<GroupDto, Array<ProductInfoDto>>;
  totalOcPriceForActiveAddons = 0;
  totalOcPriceTaxForActiveAddons = 0;
  totalRcPriceForActiveAddons = 0;
  totalRcPriceTaxForActiveAddons = 0;
  dynamicProductParameters: Array<ProductParamMetadataDto>;
  showActiveAddons = true;

  private get blockUIElementName(): string {
    return this.productInShoppingCart.productDetail.id;
  }

  constructor(
    private productService: ProductService,
    private orderingService: WcOrderingService,
    private productCustomService: ProductCustomService,
    private router: Router,
    private ngbModal: NgbModal,
    private stickyMessageService: StickyMessageService,
    private orderingWizardService: OrderingWizardService
  ) {
    this.orderingWizardService.showVat.pipe(takeUntil(this.onDestroy$)).subscribe(showVat => {
      if (showVat) {
        this.showVat = showVat === 'vat';
      }
    });
  }

  getAddons() {
    const assetIds = [this.productInShoppingCart, ...this.productInShoppingCart.children]
      .filter(product =>
        ProductUtils.isOfAnyCategories(product.productDetail, [
          CategoryTypeEnum.PRODC_SU_TARIFF,
          CategoryTypeEnum.PRODC_SU_TP,
          CategoryTypeEnum.PRODC_SU_CORE,
        ])
      )
      .map(product => product.orderItems[0].id);
    if (!assetIds.length) {
      return;
    }

    const filter: ProductFilter = {
      attributes: { categoryId: 'PROD' },
      sorting: { column: ProductSortAttributeDto.NAME, sortOrder: 'desc' },
      paging: { page: 1, pageSize: undefined },
    };
    this.productService
      .getProductsByFilter(filter, assetIds, null, null, 'GUI_ADDON_CATEGORY')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        responseAddons => {
          this.addonsInGroups = new Map<GroupDto, Array<ProductInfoDto>>();

          responseAddons.data.forEach(product => {
            product.groups.forEach(group => {
              let groupProducts = null;
              for (const entry of this.addonsInGroups) {
                if (entry[0].id === group.id) {
                  groupProducts = entry[1];
                }
              }

              if (groupProducts) {
                groupProducts.push(product);
              } else {
                this.addonsInGroups.set(group, [product]);
              }
            });
          });
        },
        errorResponse => {
          if (errorResponse instanceof HttpErrorResponse) {
            this.router.navigate([this.router.url]);
            this.stickyMessageService.addStickyWarningMessage(
              errorResponse.error.failures.pop().key,
              null,
              errorResponse
            );
          }
        }
      );
  }

  shoppingCartSocket: ShoppingCartSocket;
  isHw = false;

  ngOnInit() {
    if (!this.productInShoppingCart && this.addon) {
      this.productInShoppingCart = new ProductInShoppingCart(this.addon);
    }
    this.isHw = PrecalculatedShoppingCart.isProductWithShipment(this.productInShoppingCart);
    if (this.productInShoppingCart.productDetail.parametersStatic['showActiveAddons'] === 'false') {
      this.showActiveAddons = false;
    }
    this.loadProduct();
    if (this.showActiveAddons && this.showAddons && this.productInShoppingCart.orderItems && this.productInShoppingCart.orderItems.length > 0) {
      this.getAddons();
    }
    this.getActiveAddons();
  }

  private loadProduct(): void {
    this.delete = this.productInShoppingCart.deleteAction;
    this.modify = this.productInShoppingCart.modifyAction;
    this.product = this.productInShoppingCart.productDetail;
    if (this.productInShoppingCart.children.length > 0) {
      this.findProductToDisplay(this.productInShoppingCart.children);
      this.shoppingCartSocket = new ShoppingCartSocket();
      this.shoppingCartSocket.product = this.productInShoppingCart;
      this.shoppingCartSocket.socket = {
        name: 'Main',
        id: 'Main',
        code: 'Main',
        contentQty: {
          min: 0,
          max: 0
        }
      };
      this.setChildrenBaseOnSocketTree(this.shoppingCartSocket);
      this.getActiveAddonsPrices(this.productInShoppingCart.children);
    }
    this.canDisplayQuantity = this.displayQuantity();
    this.canDisplayProduct = this.canDisplayProductCheck(this.productInShoppingCart);
    this.productCustomService
      .getVisibleProductParameterMetadata(this.product.categoryId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: Array<ProductParamMetadataDto>) => {
        this.dynamicProductParameters = result;
      });
  }

  setChildrenBaseOnSocketTree(shoppingCartSocket: ShoppingCartSocket) {
    let contentQtyAddedSockets = [];
    shoppingCartSocket.product.children.forEach(child => {
      const childShoppingCartSocket = new ShoppingCartSocket();
      childShoppingCartSocket.parent = shoppingCartSocket;
      childShoppingCartSocket.product = child;
      childShoppingCartSocket.socket = child.socket;
      if (shoppingCartSocket.socket?.id === 'Main' && child.socket && !contentQtyAddedSockets.includes(child.socket.id)) {
        shoppingCartSocket.socket.contentQty.min += child.socket.contentQty.min;
        shoppingCartSocket.socket.contentQty.max += child.socket.contentQty.max;
        contentQtyAddedSockets.push(child.socket.id);
      }
      this.setChildrenBaseOnSocketTree(childShoppingCartSocket);
      const childSocketsBySocketId = shoppingCartSocket.childSocketsBySocketId.get(childShoppingCartSocket.socket?.id);
      if (childSocketsBySocketId) {
        childSocketsBySocketId.push(childShoppingCartSocket);
      } else {
        shoppingCartSocket.childSocketsBySocketId.set(childShoppingCartSocket.socket?.id, [childShoppingCartSocket]);
      }
    });

    Array.from(shoppingCartSocket.childSocketsBySocketId.values()).forEach(childSockets => {
      const productsQtyById = new Map<string, number>();

      childSockets.forEach(child => {
        let qty = productsQtyById.get(child.product.productDetail.productCode);
        if (qty) {
          qty++;
        } else {
          qty = 1;
        }
        productsQtyById.set(child.product.productDetail.productCode, qty);
      });
      childSockets.forEach(child => {
        child.currentContentQty = productsQtyById.get(child.product.productDetail.productCode);
      });
    });
  }

  getActiveAddons() {
    const shoppingCartContainer = document.querySelector('#shoppingCart-container');
    const shoppingCartActiveAddons = document.querySelector('.active-addons_container');
    shoppingCartContainer === null ? shoppingCartActiveAddons?.remove() : undefined;
  }

  getActiveAddonsPrices(childrenProduct) {
    if (childrenProduct) {
      childrenProduct.forEach(child => {
        child.children.forEach(item => {
          if (ProductUtils.isOfCategory(item.productDetail, 'PRODC_GE')) {
            this.totalOcPriceForActiveAddons += item.ocPrice;
            this.totalOcPriceTaxForActiveAddons += item.ocPriceTax;
            this.totalRcPriceForActiveAddons += item.rcPrice;
            this.totalRcPriceTaxForActiveAddons += item.rcPriceTax;
          }
        });
      });
    }
  }

  //region Event handling:

  /**
   * Increase quantity of the product in the shoping cart by 1.
   */
  increaseQuantity(): void {
    const addOrderItem = (currentOrder: OrderDto) => {
      this.orderingService
        .addProductToShoppingCartWithParent(currentOrder, this.product as ProductAddingToCart)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(addOrderItem);
  }

  displayQuantity(): boolean {
    let canDisplay: boolean = true;
    for (const categoryId of PrecalculatedShoppingCart.categoriesNotGroupedByQuantity) {
      if (ProductUtils.isOfCategory(this.product, categoryId)) {
        canDisplay = false;
        break;
      }
    }
    return canDisplay;
  }

  /**
   * Decrease quantity of the product in the shoping cart by 1.
   */
  decreaseQuantity(): void {
    const removeOrderItem = (currentOrder: OrderDto) => {
      const orderItemIndex = this.productInShoppingCart.orderItems.length - 1;
      const orderItem = this.productInShoppingCart.orderItems[orderItemIndex];

      this.orderingService.removeOrderItem(currentOrder, orderItem).pipe(takeUntil(this.onDestroy$)).subscribe();
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(removeOrderItem);
  }

  /**
   * Remove the order item from the cart.
   */
  remove(product?): void {
    if (!product) {
      product = this.productInShoppingCart;
    }
    const removeOrderItems = (currentOrder: OrderDto) => {
      this.orderingService
        .removeProductInShoppingCartFromCart(currentOrder, product)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(null, errorResponse => {
          if (errorResponse instanceof HttpErrorResponse) {
            errorResponse.error.failures.forEach(failure => {
              if (failure.code === 15150) {
                this.router.navigate([this.router.url]);
                this.stickyMessageService.addStickyWarningMessage(failure.key, null, errorResponse);
              }
            });
          }
        });
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(removeOrderItems);
  }

  /**
   * Perform validate() on all child instances of ProductAttributesConfigurationComponent.
   *
   * @return true if everything is valid, false otherwise.
   */
  validate(): boolean {
    let valid = true;
    if (this.shoppingCartProductComponents) {
      this.shoppingCartProductComponents.forEach(shoppingCartProductComponent => {
        if (!shoppingCartProductComponent.validate()) {
          valid = false;
        }
      });
    }

    return valid;
  }

  /**
   * Perform save() on all child instances of ProductAttributesConfigurationComponent.
   *
   * @param order The order to save the data in.
   */
  save(order: OrderDto): void {
    if (this.shoppingCartProductComponents) {
      this.shoppingCartProductComponents.forEach(shoppingCartProductComponent => {
        shoppingCartProductComponent.save(order);
      });
    }
  }

  canDisplayProductCheck(productInShoppingCart: ProductInShoppingCart): boolean {
    return (
      !_.isNil(productInShoppingCart.productDetail) &&
      PrecalculatedShoppingCart.canDisplayProductInShoppingCart(productInShoppingCart) &&
      (!this.readOnlyOrder || !ProductUtils.isOfCategory(productInShoppingCart.productDetail, CategoryTypeEnum.PRODC_GE_PAYMENT))
    );
  }

  findProductToDisplay(childrenProduct) {
    if (childrenProduct) {
      childrenProduct.forEach(product => {
        this.childProducts.push(product);
        if (this.canDisplayProductCheck(product)) {
          if (product.productDetail.name) {
            product.display = true;
            this.childProductsToDisplay.push(product);
          }
        } else {
          this.findProductToDisplay(product.children);
        }
      });
    }
  }

  canDisplayCommitmentInfo(): boolean {
    return !_.isNil(this.getChildProductWithCommitmentCategory()) && this.showDetails === true;
  }

  getChildProductWithCommitmentCategory(): ProductDetailDto {
    return ProductUtils.getProductInCartByCategoryId(this.productInShoppingCart, CategoryTypeEnum.PRODC_SU_COMMIT);
  }

  hasLinkToDetail(): boolean {
    return ProductUtils.hasLinkToDetail(this.product);
  }

  getPriceType(product) {
    return product.orderItems[0].prices[0].priceAppType;
  }

  configure() {
    this.orderingService
      .getCurrentOrder()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        const modalRef = this.ngbModal.open(ProductConfigurationComponent, {
          size: 'sm',
          backdrop: 'static',
          windowClass: 'dialog dialog-input',
        });

        const productConfigurationComponent = <ProductConfigurationComponent>modalRef.componentInstance;
        productConfigurationComponent.dialogRef = modalRef;
        productConfigurationComponent.order = order;
        productConfigurationComponent.productInShoppingCart = this.productInShoppingCart;
        productConfigurationComponent.orderItem = this.productInShoppingCart.orderItems[0];
        productConfigurationComponent.product = this.product;
        productConfigurationComponent.dynamicProductParameters = this.dynamicProductParameters;
      });
  }

  getAddonParent(productInShoppingCart): ProductInShoppingCart {
    if (productInShoppingCart.orderItems[0].id === this.addon.parentInstanceId) {
      return productInShoppingCart;
    } else if (productInShoppingCart.children) {
      let ret: ProductInShoppingCart = null;
      productInShoppingCart.children.forEach(child => {
        ret = this.getAddonParent(child);
      });

      return ret;
    }
    return null;
  }

  selectAddon() {
    if (!this.activeAddon) {
      const activeAddon = (currentOrder: OrderDto) => {
        this.orderingService
          .addProductToShoppingCartWithParent(
            currentOrder,
            this.product as ProductAddingToCart,
            null,
            this.addon.parentInstanceId,
            null,
            null,
            this.isHw ? true : false
          )
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(order => {});
      };
      this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(activeAddon);
      this.activeAddon = true;
    } else {
      this.activeAddon = false;
    }
  }

  wishListPopup() {
    const modalRef = this.ngbModal.open(WishListPopupComponent, {
      size: 'sm',
    });
    const wishListPopupComponent = <WishListPopupComponent>modalRef.componentInstance;
    wishListPopupComponent.dialogRef = modalRef;
    wishListPopupComponent.productCode = this.product.productCode;
  }
}

export class ShoppingCartSocket {
  socket: SocketDto;
  product: ProductInShoppingCart;
  childSocketsBySocketId = new Map<string, ShoppingCartSocket[]>();
  currentContentQty = 0;
  parent: ShoppingCartSocket;
}
