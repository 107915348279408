<app-ecare-page-layout
  *ngIf="account && !ticketForRelatedTask && (ticketType || externalSearchMode)"
  columnTemplate="1fr"
  [showBreadcrumbs]="!externalSearchMode && !staticSearch"
  [title]="
    !externalSearchMode && !staticSearch
      ? (caRefNo
          ? customerLocalStorageService.getCurrentCustomerAccount().displayName +
            ' ' +
            ('wc.ecare.dashboard.account.header' | translate) +
            ' '
          : (cuRefNo ? customerLocalStorageService.getCurrentCustomer().displayName + ' ' : '') + ' ') +
        ('wc.ecare.interactions.heading.list.text' | translate)
      : null
  ">
  <ng-container *ngIf="!externalSearchMode && !staticSearch" page-actions>
    <app-acl-internal-evaluate-component class="padding-10">
      <app-button
        *ngIf="!account.external"
        variant="primary"
        iconPath="/assets/svg/add.svg"
        label="{{ 'wc.ecare.contactHistory.newInteraction' | translate }}"
        (click)="newInteraction()" />
    </app-acl-internal-evaluate-component>
  </ng-container>
  <div>
    <div
      *ngIf="!externalSearchMode && !staticSearch"
      class="ecare-filtering-wrapper {{ caRefNo || cuRefNo ? 'flex-end' : '' }}">
      <app-section-tabs *ngIf="!caRefNo && !cuRefNo" #nav="ngbNav" ngbNav>
        <li [ngbNavItem]="1">
          <a ngbNavLink (click)="sectionTabsChange('ALL')">
            {{ 'wc.ecare.contactHistory.all' | translate }}
          </a>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink (click)="sectionTabsChange('USER_CREATED')">
            {{ 'wc.ecare.contactHistory.createdByUser' | translate }}
          </a>
        </li>
      </app-section-tabs>
      <div>
        <app-contact-history-filter [filtering]="search.filtering" (filterChanged)="handleFilterChange()" />
        <div *ngIf="search?.sorting" class="mobile-sorting-container">
          <app-sortable-dropdown [columns]="columns" [sorting]="search.sorting" (sortChanged)="onSortChange()" />
        </div>
      </div>
    </div>

    <div class="dashboard">
      <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
        <div class="dashboard_search">
          <div class="ecare-responsive-table">
            <ng-container *ngIf="tickets.length > 0; else no_results">
              <table class="ecare_listing-table responsive-table contact-history-table invoices-table table">
                <thead>
                  <tr>
                    <th *ngIf="visibleParameters.get(contactParamNames.CONTACT_DIRECTION)"></th>
                    <th
                      class="with-icon"
                      app-sortable-column
                      column="subject"
                      localizationKey="wc.ecare.ticket.subject"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()"></th>
                    <th>{{ 'wc.ecare.ticket.contactType' | translate }}</th>
                    <th
                      *ngIf="!smallTable"
                      class="with-icon"
                      app-sortable-column
                      column="id"
                      localizationKey="wc.ecare.ticket.id"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()">
                      {{ 'wc.ecare.ticket.id' | translate }}
                    </th>
                    <th
                      class="with-icon"
                      app-sortable-column
                      column="startFrom"
                      localizationKey="wc.ecare.ticket.startFrom"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()">
                      {{ 'wc.ecare.ticket.startFrom' | translate }}
                    </th>
                    <th
                      class="with-icon"
                      app-sortable-column
                      column="slaDueDate"
                      localizationKey="wc.ecare.ticket.slaDueDate"
                      [sorting]="search?.sorting"
                      (sortChanged)="onSortChange()">
                      {{ 'wc.ecare.ticket.slaDueDate' | translate }}
                    </th>
                    <th *ngIf="!smallTable">
                      {{
                        (!account.external ? 'wc.ecare.ticket.customer' : 'wc.ecare.ticket.customerAccount') | translate
                      }}
                    </th>
                    <th *ngIf="!smallTable && visibleParameters.get(TicketService.PREFERRED_CONTACT_PARAM_NAME)">
                      {{ 'wc.ecare.dashboard.contact.label' | translate }}
                    </th>
                    <th *ngIf="visibleParameters.get(contactParamNames.CONTACT_CHANNEL)">
                      {{ 'wc.ecare.ticket.channel' | translate }}
                    </th>
                    <th
                      *ngIf="!smallTable"
                      app-acl-internal-evaluate-component
                      class="with-icon"
                      title="{{ 'wc.ecare.ticket.attachmentsCount.tootip' | translate }}">
                      <i class="fa fa-paperclip" aria-hidden="true"> </i>
                    </th>
                    <th
                      *ngIf="!smallTable"
                      app-acl-internal-evaluate-component
                      class="with-icon"
                      title="{{ 'wc.ecare.ticket.notesCount.tootip' | translate }}">
                      <i class="fa fa-sticky-note-o" aria-hidden="true"> </i>
                    </th>
                    <th
                      *ngIf="!smallTable"
                      app-acl-internal-evaluate-component
                      class="with-icon"
                      title="{{ 'wc.ecare.ticket.reatedTasksCount.tootip' | translate }}">
                      <i class="fa fa-check-square-o" aria-hidden="true"> </i>
                    </th>
                    <th app-acl-internal-evaluate-component>{{ 'wc.ecare.ticket.action' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let ticket of tickets">
                    <tr
                      class="ecare_listing-table-row pointer {{ selected === ticket ? 'selected' : '' }}"
                      (click)="rowClicked(ticket)"
                      (dblclick)="showDetails(ticket)">
                      <td *ngIf="visibleParameters.get(contactParamNames.CONTACT_DIRECTION)">
                        <span *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_DIRECTION') | async as codebook"
                          ><i
                            class="menu-icon"
                            ngbTooltip="{{ codebook[ticket['contactDirection']] }}"
                            [inlineSVG]="getSVGIcon(ticket['contactDirection'])"></i
                        ></span>
                      </td>
                      <td>
                        <span class="-sm-td-title">{{ 'wc.ecare.ticket.subject.header' | translate }}</span>
                        <span class="information semi-bold">{{ ticket.subject }}</span>
                      </td>
                      <td>
                        <div *ngIf="ticket['contactType']">
                          <span *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_TYPE') | async as codebook">
                            {{ codebook[ticket['contactType']] }}
                          </span>
                        </div>
                      </td>
                      <td *ngIf="!smallTable">
                        <app-sticker variant="neutral">{{ ticket.id }}</app-sticker>
                      </td>
                      <td>
                        <span class="-sm-td-title">{{ 'wc.ecare.ticket.startFrom.header' | translate }}</span>
                        <span>{{ ticket.startFrom | date : 'dd.MM.yyyy HH:mm' }}</span>
                      </td>
                      <td>
                        <span class="-sm-td-title">{{ 'wc.ecare.ticket.slaDueDate.header' | translate }}</span>
                        <span>{{ ticket.slaDueDate | date : 'dd.MM.yyyy HH:mm' }}</span>
                      </td>
                      <td *ngIf="!smallTable">
                        <span>{{ !account.external ? ticket['customer'] : ticket['customerAccount'] }}</span>
                      </td>
                      <td *ngIf="!smallTable && visibleParameters.get(TicketService.PREFERRED_CONTACT_PARAM_NAME)">
                        <span *ngIf="ticket['contact']">{{ ticket['contact'] | displayContact }}</span>
                      </td>
                      <td *ngIf="visibleParameters.get(contactParamNames.CONTACT_CHANNEL)">
                        <div *ngIf="ticket['contactChannel']">
                          <span
                            *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_CHANNEL') | async as codebook"
                            class="-contact-history-channel {{ ticket['contactChannel'] }}">
                            {{ codebook[ticket['contactChannel']] }}
                          </span>
                        </div>
                      </td>

                      <td *ngIf="!smallTable" app-acl-internal-evaluate-component>
                        {{ ticket.attachments.length ? ticket.attachments.length : '-' }}
                      </td>
                      <td *ngIf="!smallTable" app-acl-internal-evaluate-component>
                        {{ ticket.notes.length ? ticket.notes.length : '-' }}
                      </td>
                      <td *ngIf="!smallTable" app-acl-internal-evaluate-component>
                        {{ ticket['tasksIds']?.length ? ticket['tasksIds'].length : '-' }}
                      </td>
                      <td app-acl-internal-evaluate-component class="no-wrap mobile-right">
                        <ng-container>
                          <div
                            ngbDropdown
                            placement="bottom-right"
                            class="listing-row-actions-entry"
                            (click)="$event.stopPropagation()">
                            <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
                              <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
                            </span>

                            <div
                              ngbDropdownMenu
                              class="action-list-dropdown-menu"
                              id="{{ ticket.id }}_popover-content"
                              (click)="$event.stopPropagation()">
                              <div>
                                <button
                                  id="004_ecare_order_row"
                                  class="btn btn-link action-list-more-actions-button"
                                  type="button"
                                  (click)="showDetails(ticket)">
                                  {{ 'wc.ecare.orders.showDetails.button' | translate }}
                                </button>
                                <button
                                  class="btn btn-link action-list-more-actions-button"
                                  type="button"
                                  (click)="showDetails(ticket, true); $event.stopPropagation()">
                                  {{ 'wc.common.showDetailsInNewTab.button' | translate }}
                                </button>
                                <button
                                  *ngIf="removeRelationCallback"
                                  class="btn btn-link action-list-more-actions-button"
                                  type="button"
                                  (click)="removeRelation(ticket);$event.stopPropagation()">
                                  {{ 'wc.ecare.ticket.deleteRelation.button' | translate }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </td>
                    </tr>
                    <tr *ngIf="selected === ticket" class="notification-window">
                      <td colspan="12">
                        <app-contact-history-expanded-row-component
                          [ticket]="ticket"
                          (openTicketRelatedTask)="openRelatedTask($event)"
                          (addTicketRelatedTask)="addTicketRelatedTask($event)" />
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <app-ecare-contact-history-mobile
                class="ecare-responsive-mobile contact-history-table"
                [visibleParameters]="visibleParameters"
                [contactHistory]="tickets" />
            </ng-container>
          </div>
          <ng-template #no_results>
            <app-ecare-listing-no-results />
          </ng-template>

          <div *ngIf="moreRecordsExists">
            {{ 'wc.ecare.notifications.scrollDownForMoreRecords.text' | translate }}
          </div>
          <div *ngIf="canDisplayNoTicketsText" class="no-invoices-text">
            {{ 'wc.ecare.contactHistory.noContactHistory.text' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-ecare-page-layout>
<app-ticket-edit
  *ngIf="newRelatedTask"
  [ticket]="newRelatedTask"
  [cancelCallback]="newRelatedTaskCanceled"
  [saveCallback]="newRelatedTaskSaved"
  [caRefNo]="newRelatedTaskCaRef"
  [cuRefNo]="newRelatedTaskCuRef"
  [relatedTicketId]="ticketForRelatedTask.id"
  [ticketTypeCode]="newRelatedTaskCode" />
