import { Component, ElementRef, Input, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { CustomerLocalStorageService } from 'app/services/customer-local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import {
  AbstractPageComponent,
  BlockTemplateComponent,
  CompareType,
  EnableDynamicLoading,
  ServiceUtils
} from '@btl/btl-fe-wc-common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PartyElse } from '../../../models/party-holder';
import { SearchEntityDataType, SearchEntityType } from './ecare-search-filter/ecare-search-filter.component';
import { addressTypes } from 'app/pipes/display-address.pipe';
import { takeUntil } from 'rxjs/operators';
import { OpportunityDetailsComponent } from '../ecare-opportunities/opportunity-details/opportunity-details.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { EcareCustomersComponent } from '../ecare-customers/ecare-customers.component';
import { TicketService } from '@service/ticket.service';
import { TicketElse } from '../../../models/tickets-holder';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ecare-search',
  templateUrl: './ecare-search.component.html',
})
@EnableDynamicLoading({ customName: EcareSearchComponent.PAGE_ID })
export class EcareSearchComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'EcareSearchComponent';
  pageId(): string {
    return EcareSearchComponent.PAGE_ID;
  }

  HOMEPAGE_RECORDS_COUNT: number = 10;

  @Input() isHomepage: boolean = false;

  @ViewChild(EcareCustomersComponent) ecareCustomersComponent: EcareCustomersComponent;
  @ViewChildren('dropdownElement') dropdownElements: QueryList<ElementRef>;

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  isMore: boolean = false;
  addressTypes = addressTypes;
  searchEntityType: SearchEntityType;
  lastSearch: SearchEntityDataType;
  customers: PartyElse[] = null;
  foundOrders: OrderDto[] = null;
  foundOpportunities: TicketElse[] = null;
  isModuleBffCrmEnabled = false;

  constructor(
    private renderer: Renderer2,
    protected router: Router,
    protected route: ActivatedRoute,
    private customerLocalStorageService: CustomerLocalStorageService,
    private orderingService: WcOrderingService,
    private ticketService: TicketService,
    private propertyAccessorLocalService: PropertyAccessorLocalService
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');
    this.propertyAccessorLocalService
      .isModuleBffCrmEnabled()
      .subscribe(bffCrmEnabled => (this.isModuleBffCrmEnabled = bffCrmEnabled));
  }

  selectCustomer(customerId: string, targetUrl?: string) {
    this.customerLocalStorageService.selectSearchedCustomer(customerId, targetUrl);
  }

  /**
   * Search orders.
   */
  private searchOrders(orderId: string): void {
    this.isMore = false;
    this.blockUIElement.start();
    this.orderingService
      .searchOrders(orderId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order: OrderDto) => {
        this.foundOrders = [];
        if (order) {
          this.foundOrders.push(order);
        }
        if (this.isHomepage) {
          if (this.foundOrders.length > this.HOMEPAGE_RECORDS_COUNT) {
            this.isMore = true;
            this.foundOrders = this.foundOrders.slice(0, this.HOMEPAGE_RECORDS_COUNT);
          }
        }
        this.blockUIElement.stop();
      });
  }

  from = 0;
  private searchOpportunities(opportunityId: string): void {
    this.isMore = false;
    this.blockUIElement.start();
    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push(
      {
        column: 'type',
        compareType: null,
        value: {
          code: OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE,
          areaType: 'CUSTOMER',
        },
      },
      {
        column: 'subject',
        compareType: CompareTypeDtoEnum.LIKE,
        value: `*${opportunityId}*`,
      }
    );
    this.ticketService
      .searchByText(search, 10, this.from, OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.foundOpportunities = [];
        if (result) {
          this.foundOpportunities = result?.hits.map(ticketHolder => ticketHolder._source);
        }
        if (this.isHomepage) {
          if (this.foundOpportunities.length > this.HOMEPAGE_RECORDS_COUNT) {
            this.isMore = true;
            this.foundOpportunities = this.foundOpportunities.slice(0, this.HOMEPAGE_RECORDS_COUNT);
          }
        }
        this.blockUIElement.stop();
      });
  }

  handleSearch(searchData: SearchEntityDataType) {
    this.lastSearch = searchData;
    switch (searchData.searchEntity) {
      case SearchEntityType.CUSTOMER:
        this.ecareCustomersComponent.handleFilterChange(searchData.searchValue);
        break;
      case SearchEntityType.ORDER:
        this.searchOrders(searchData.searchValue);
        break;
      case SearchEntityType.OPPORTUNITIES:
        this.searchOpportunities(searchData.searchValue);
        break;
    }
  }

  changeSearchEntity(searchEntity: SearchEntityType) {
    this.searchEntityType = searchEntity;

    this.foundOrders = null;
    this.customers = null;
    this.foundOpportunities = null;
  }

  isSearchPerformed(): boolean {
    switch (this.searchEntityType) {
      case SearchEntityType.CUSTOMER:
        return !!this.customers;
      case SearchEntityType.ORDER:
        return !!this.foundOrders;
      case SearchEntityType.OPPORTUNITIES:
        return !!this.foundOpportunities;
    }
  }

  isSearchOrders(): boolean {
    return this.searchEntityType === SearchEntityType.ORDER;
  }

  isSearchCustomers(): boolean {
    return this.searchEntityType === SearchEntityType.CUSTOMER;
  }

  isSearchOpportunities(): boolean {
    return this.searchEntityType === SearchEntityType.OPPORTUNITIES;
  }

  get noResultsTextKey(): string {
    if (this.isSearchPerformed()) {
      return `wc.ecare.search.noResults.${this.searchEntityType}`;
    }
    return `wc.ecare.search.notSearched.${this.searchEntityType}`;
  }
}
