import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogBorderComponent } from '../confirmation-dialog/confirmation-dialog-border.component';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

export enum GenerateDocumentEntityType {
  QUOTE = 'Quote',
  ORDER = 'Order',
}

@Component({
  selector: 'app-entity-generate-document-modal',
  templateUrl: './entity-generate-document-modal.component.html'
})
export class EntityGenerateDocumentModalComponent extends ConfirmationDialogBorderComponent implements OnInit {

  @Input()
  entityType: GenerateDocumentEntityType;

  @Input()
  documentUrl: string;

  @Input()
  documentName: string;

  @Input()
  attachDocumentHandler: (dialogReference: NgbModalRef, documentName: string, url?: string) => void;

  documentGenerated = false;
  documentCreatedDate: Date;
  pdfUrl;

  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    this.httpClient
      .get(this.documentUrl, { responseType: 'blob' })
      .subscribe(data => {
        const file = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(file);
        this.pdfUrl = url;
      });
    if (!this.dialogReference) {
      throw new Error('The dialogReference parameter must have non-null value.');
    }
  }

  openInNewTab() {
    window.open(this.pdfUrl, '_blank');
  }

  downloadDocument() {
    const pseudoLink = document.createElement('a');
    pseudoLink.href = this.pdfUrl;
    pseudoLink.download = this.documentName;
    pseudoLink.click();
  }

  onDocumentGenerated() {
    this.documentGenerated = true;
    this.documentCreatedDate = new Date();
  }

  attachDocument() {
    this.attachDocumentHandler(this.dialogReference, this.documentName, this.pdfUrl);
  }
}
