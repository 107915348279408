<div *ngIf="paymentParams && wcOrderingService.currentOrder" class="page confirm__order">
  <app-payment-response [paymentParams]="paymentParams"></app-payment-response>
</div>

<div class="checkout-page">
  <!-- Generate content for mobile -->
  <div class="d-lg-none">
    <app-checkout-shopping-cart-secondary-actions></app-checkout-shopping-cart-secondary-actions>
    <app-ordering-wizard></app-ordering-wizard>
  </div>
  <!-- END Generate content for mobile -->
  <div class="page summary_order checkout-page_form">
    <div class="form">
      <!-- Delivery method -->
      <div class="delivery-container halfColumn">
        <div *ngIf="selectedDeliveryMethod"
          app-section-header
          class="single-method"
          [title]="'wc.shopping.customerCreation.deliveryMethod.heading' | translate">
          <div class="single-method_leftSide">
            <div class="icon-container white">
              <img
                alt="{{ selectedDeliveryMethod.productDetail.name }}"
                src="{{
                  selectedDeliveryMethod.productDetail.picturePreview
                    ? selectedDeliveryMethod.productDetail.picturePreview.href
                    : '/assets/img/delivery-methods/' + selectedDeliveryMethod.productDetail.productCode + '.png'
                }}" />
            </div>
            {{selectedDeliveryMethod.productDetail.shortDescription}}
          </div>
          <div
            class="single-method_priceSide"
            [innerHTML]="
              selectedDeliveryMethod.ocPrice
                ? (selectedDeliveryMethod.ocPrice | price | safeHtml)
                : (0 | price | safeHtml)
            "></div>
        </div>
      </div>

      <!-- Payment method -->
      <div class="payment-container halfColumn">
        <div *ngIf="selectedPaymentMethod"
          app-section-header
          class="single-method"
          [title]="'wc.shopping.customerCreation.paymentMethod.heading' | translate">
          <div class="single-method_leftSide">
            <div class="icon-container white">
              <img
                src="{{
                  selectedPaymentMethod.productDetail.picturePreview
                    ? selectedPaymentMethod.productDetail.picturePreview.href
                    : '/assets/img/payment-methods/' + selectedPaymentMethod.productDetail.productCode + '-logo.png'
                }}"
                alt="{{ selectedPaymentMethod.productDetail.name }}" />
            </div>
            {{selectedPaymentMethod.productDetail.shortDescription}}
          </div>
          <div
            class="single-method_priceSide"
            [innerHTML]="
              selectedPaymentMethod.ocPrice
                ? (selectedPaymentMethod.ocPrice | price | safeHtml)
                : (0 | price | safeHtml)
            "></div>
        </div>
      </div>

      <div class="summary-section" *ngIf="customer?.subject">
        <!--Invoice info-->
        <app-section-header
          textAlign="left"
          editable="true"
          [title]="'wc.shopping.customer.heading' | translate"
          [link]="'/eshop/'+ (!shoppingCartService.preCalculatedShoppingCart.containsService() ? 'delivery' : 'checkout-page')">
        </app-section-header>
        <div class="accordion-body">
          <div class="contact-details-dropdown single">
            <div class="ng-select-container">
              <div class="contact-details-dropdown_leftSide">
                <div class="icon-container fullHeight">
                  <img [src]="'/assets/svg/invoice-details.svg'"/>
                </div>
                <div class="contact-details-dropdown_wrapper full-width">
                  <div class="fullName">
                    {{ customer.subject.titleBefore }} {{ customer.subject.firstName }} {{ customer.subject.lastName }}
                    <span *ngIf="customer.subject.titleAfter">, </span>{{ customer.subject.titleAfter }}
                    {{customer.subject.nationality}} {{customer.subject.birthDate | date: 'dd.MM.yyyy'}}
                    {{ customer.subject.identityNumber }} {{ customer.subject.idIssueAuthority }}
                    <ng-container *ngIf="customer.subject.idIssueAuthority">
                      <i [inlineSVG]="'/assets/svg/' + customer.subject.idIssueAuthority + '-flag-icon.svg'"> </i>
                      {{ customer.subject.idIssueAuthority }}
                    </ng-container> {{ customer.subject.idNo }}
                    {{ customer.subject.idValidTo }} {{
                      customer.subject.idVerified != undefined ? ('wc.ecare.customerDetails.idVerified.' + (customer.subject.idVerified || false) | translate) : ''
                    }}
                    {{ customer.subject.taxNumber }}
                  </div>
                  <div class="flex-row-start" *ngIf="subjectAttributes">
                    <ng-container *ngFor="let parameter of subjectAttributes">
                      <app-parameter-data [onlyValues]="true" [metaEntityAttributeDto]="parameter" [parameterValue]="customer.subject.parameters[parameter.name]"></app-parameter-data>
                    </ng-container>
                  </div>
                  <div *ngIf="customer.address">
                    {{ customer.address | displayAddress }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="summary-section" *ngIf="customer?.contact">
        <!--Invoice info-->
        <app-section-header
          textAlign="left"
          editable="true"
          [title]="'wc.shopping.customerCreation.primaryContact' | translate"
          [link]="'/eshop/'+ (!shoppingCartService.preCalculatedShoppingCart.containsService() ? 'delivery' : 'checkout-page')">
        </app-section-header>
        <div class="accordion-body">
          <div class="contact-details-dropdown single">
            <div class="ng-select-container">
              <div class="contact-details-dropdown_leftSide">
                <div class="icon-container fullHeight">
                  <img [src]="'/assets/svg/invoice-details.svg'"/>
                </div>
                <div class="contact-details-dropdown_wrapper">
                  <div class="fullName">{{ customer.contact.firstName }} {{ customer.contact.lastName }}</div>
                  <div>
                    {{ customer.contact.email }} <span
                    *ngIf="customer.contact.phone1">• {{ customer.contact.phone1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="summary-section" *ngIf="customerAccount">
        <!--Invoice info-->
        <app-section-header
          textAlign="left"
          editable="true"
          [title]="'wc.ecare.dashboard.customerAccount.header' | translate"
          [link]="'/eshop/'+ (!shoppingCartService.preCalculatedShoppingCart.containsService() ? 'delivery' : 'checkout-page')">
        </app-section-header>
        <div class="accordion-body">
          <div class="contact-details-dropdown single">
            <div class="ng-select-container">
              <div class="contact-details-dropdown_leftSide">
                <div class="icon-container fullHeight">
                  <img [src]="'/assets/svg/invoice-details.svg'"/>
                </div>
                <div class="contact-details-dropdown_wrapper full-width">
                  <div class="fullName">
                    {{ customerAccount.displayName }}
                  </div>
                  <div class="flex-row-start" *ngIf="customerAccountAttributes">
                    <ng-container *ngFor="let parameter of customerAccountAttributes">
                      <app-parameter-data [onlyValues]="true" [metaEntityAttributeDto]="parameter" [parameterValue]="customerAccount.parameters[parameter.name]"></app-parameter-data>
                    </ng-container>
                  </div>
                  <div *ngIf="caInvoiceAddress || caInvoiceContact">
                    <span class="fullName">{{'wc.shopping.INVOICING.heading' | translate}}:</span> {{ caInvoiceContact | displayContact }} {{ caInvoiceAddress | displayAddress }}
                  </div>
                  <div *ngIf="caBillingContact || caBillingAddress">
                    <span class="fullName">{{'wc.shopping.BILLING.heading' | translate}}:</span> {{ caBillingContact | displayContact }} {{ caBillingAddress | displayAddress }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Customer Details-->
      <div class="summary-section" *ngIf="invoiceContact && invoiceAddress">
        <!--Invoice info-->
        <app-section-header
          textAlign="left"
          editable="true"
          [title]="'wc.shopping.customerCreation.invoicingContact' | translate"
          [link]="'/eshop/delivery'">
        </app-section-header>
        <div class="accordion-body">
          <div class="contact-details-dropdown single">
            <div class="ng-select-container">
              <div class="contact-details-dropdown_leftSide">
                <div class="icon-container fullHeight">
                  <img [src]="'/assets/svg/invoice-details.svg'" />
                </div>
                <div class="contact-details-dropdown_wrapper">
                  <div class="fullName">{{ invoiceContact.firstName }} {{ invoiceContact.lastName }}</div>
                  <div>
                    {{ invoiceContact.email }} <span *ngIf="invoiceContact.phone1">• {{ invoiceContact.phone1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Delivery info-->
      <div class="summary-section" *ngIf="deliveryContact && deliveryAddress">
        <app-section-header
          textAlign="left"
          editable="true"
          [title]="'wc.shopping.customerCreation.deliveryContact' | translate"
          [link]="'/eshop/delivery'">
        </app-section-header>
        <div class="accordion-body">
          <div class="contact-details-dropdown single">
            <div class="ng-select-container">
              <div class="contact-details-dropdown_leftSide">
                <div class="icon-container fullHeight">
                  <img [src]="'/assets/svg/delivery-details.svg'"/>
                </div>
                <div class="contact-details-dropdown_wrapper">
                  <div class="fullName">{{ deliveryContact.firstName }} {{ deliveryContact.lastName }}</div>
                  <div>
                    {{ deliveryContact.email }}
                    <span *ngIf="deliveryContact.phone1">• {{ deliveryContact.phone1 }}</span>
                  </div>
                  <div>
                    {{ deliveryAddress | displayAddress }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="padding-bottom-10">
        <app-button
          *ngFor="let document of this.orderDocumentsToGenerate | keyvalue"
          (click)="generateDocument(document.key)"
          [label]="document.value + '-' +('wc.shopping.wizard.documentGenerate.button' | translate)">
        </app-button>
      </div>

      <!-- Documents -->
      <div class="summary-section-col" *ngIf="showAttachments">
        <div class="summary-info">
          <div class="info-wrapper">
            <app-section-header textAlign="left" [title]="'wc.ecare.ticket.attachments' | translate">
            </app-section-header>
            <div class="summary-info-container">
              <app-ecare-documents
                entityType="com.emeldi.ecc.be.order.dto.Order"
                [simpleView]="true"
                [allowedNotifSending]="true"
                [notificationTypeCode]="notificationTypeCode"
                [notificationContextMap]="notificationContextMap"
                [defaultEmailList]="customer.contact.email"
                [entityId]="wcOrderingService.currentOrder.id">
              </app-ecare-documents>
            </div>
          </div>
        </div>
      </div>

      <!-- Agreements -->
      <div class="summary-section-col" *ngIf="!account || account.external" [hidden]="!formAgreementsComponent?.agreementsLoaded">
        <div class="summary-info">
          <div class="info-wrapper">
            <app-section-header textAlign="left" [title]="'wc.shopping.summary.agreements' | translate">
            </app-section-header>
            <div class="summary-info-container" [formGroup]="agreementsForm">
              <app-form-agreements #formAgreementsComponent formControlName="agreements" [control]="agreementsForm.get('agreements')">
              </app-form-agreements>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Right side of checkout - shopping cart -->
  <app-checkout-shopping-cart
    [termsAndConditions]="true"
    [checkFormAction]="continue.bind(this)">
  </app-checkout-shopping-cart>
</div>
