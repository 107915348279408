<ng-container *ngIf="!onlyValues; else valueOnlyTemplate">
  <label [for]="parameterName">
    {{ label }}
  </label>
  <div class="input full">
    <span [id]="parameterName" [ngSwitch]="metaEntityAttributeDto.type">
      <ng-container *ngSwitchCase="'BOOLEAN'">
        <input type="checkbox" class="checkbox" [checked]="parameterValue" [id]="parameterName + 'checkbox'" />
        <label [for]="parameterName + 'checkbox'"></label>
      </ng-container>
      <ng-container *ngSwitchCase="'CODEBOOK'">
        <ng-container *ngIf="codebookService.getCodebookTexts(metaEntityAttributeDto.typeDetail) | async as codebook">
          {{ codebook[parameterValue] ? codebook[parameterValue] : parameterValue }}
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ parameterValue ? parameterValue : '-' }}
      </ng-container>
    </span>
  </div>
</ng-container>
<ng-template #valueOnlyTemplate>
  <span [id]="parameterName" [ngSwitch]="metaEntityAttributeDto.type">
    <ng-container *ngSwitchCase="'BOOLEAN'" />
    <ng-container *ngSwitchCase="'CODEBOOK'">
      <ng-container *ngIf="codebookService.getCodebookTexts(metaEntityAttributeDto.typeDetail) | async as codebook">
        {{ codebook[parameterValue] ? codebook[parameterValue] : parameterValue }}
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ parameterValue ? parameterValue : '' }}
    </ng-container>
  </span>
</ng-template>
