import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { Subject } from 'rxjs';
import {
  AssetDto,
  CustomerAccountDto,
  GroupDto,
  PartyDto,
  ProductDetailDto,
  UsageInformationDto
} from '@btl/order-bff';
import { CustomerPartyUtil } from '../../../../helpers/customer-party.util';
import { CategoryTypeEnum } from '../../../../models/product-filter';
import { SubscriptionUtils } from '../../../../helpers/subscription-utils';
import { CodebookService } from '@btl/btl-fe-wc-common';
import { TariffRestrictionComponent } from '../../../page/tariff-restriction/tariff-restriction.component';
import { TariffDeactivationComponent } from '../../../page/tariff-deactivation/tariff-deactivation.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({ template: '' })
export abstract class EcareAbstractTariffWidgetComponent implements OnInit, OnDestroy {
  public onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input() editMode;
  @Input() group: GroupDto;
  @Input() widgetElementsGroups: GroupDto[] = [];
  @Input() subscriptions: PartyDto[];
  @Input() subscriptionsUsages = new Map<string, UsageInformationDto>();
  @Output() subscriptionChanged = new EventEmitter<void>();

  customerAccount: CustomerAccountDto;
  subscription: PartyDto;
  asset: AssetDto;
  tariffProduct: ProductDetailDto;
  mainTariffProduct: ProductDetailDto;
  coreProduct: ProductDetailDto;
  prodcSuTpFixProduct: ProductDetailDto;
  locationId: string;
  socketId: string;
  tariffProductPrice: number;
  tariffIdentification: string;
  customName: string;
  widgetElements = new Map<GroupDto, AssetDto>();
  subscriptionUsages: UsageInformationDto;
  widgetElementsInfos = new Map<string, WidgetElementInfo>();
  subscriptionsToSelect = new Map<string, PartyDto>();
  monthPrice = 0;
  amountOnTop = 0;
  isPostpaid = false;
  bannerTargetArea: string;

  constructor(
    protected customerLocalStorageService: CustomerLocalStorageService,
    public codebookService: CodebookService,
    protected ngbModal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.bannerTargetArea = this.group.groupParams['banner_TargetArea'];
    this.customerAccount = this.customerLocalStorageService.getCurrentCustomerAccount();

    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        this.subscriptionsToSelect.set(this.getTariffIdentification(sub), sub);
      });
      this.subscriptionsToSelect = new Map([...this.subscriptionsToSelect].sort());
      this.changeSelectedTariff(this.subscriptionsToSelect.values()?.next()?.value);
    }
  }

  changeSelectedTariff = (selectedTariff: PartyDto) => {
    this.subscription = selectedTariff;
    this.initTariffData();
  };

  protected initTariffData() {
    this.widgetElements.clear();
    this.widgetElementsInfos.clear();
    this.asset = this.subscription.assets[0];
    this.tariffProduct = this.asset['product'];
    this.coreProduct = CustomerPartyUtil.getChildAssetByCategoryId(this.asset, CategoryTypeEnum.PRODC_SU_CORE);
    this.tariffProductPrice = CustomerPartyUtil.getPriceRC(this.asset);
    if (this.subscription.parentId && !(this.getFirstFoundParameterValue(this.asset, 'subSubscription') === 'true')) {
      this.mainTariffProduct = CustomerPartyUtil.getChildPartyById(this.customerLocalStorageService.getCurrentCustomer(), this.subscription.parentId).assets[0].product;
    } else {
      this.mainTariffProduct = null;
    }
    this.isPostpaid = this.isPostpaidCheck(this.tariffProduct);
    this.tariffIdentification = this.getTariffIdentification(this.subscription);
    this.customName = this.asset?.productCharacteristic?.find(param => param.name === 'customName')?.value;
    this.prodcSuTpFixProduct = CustomerPartyUtil.getChildAssetByCategoryId(
      this.asset,
      CategoryTypeEnum.PRODC_SU_TP_FIX,
    );
    if (this.prodcSuTpFixProduct) {
      this.locationId = CustomerPartyUtil.getAssetProductCharacteristicValue(this.prodcSuTpFixProduct, 'locRefNo');
      this.socketId = CustomerPartyUtil.getAssetProductCharacteristicValue(this.prodcSuTpFixProduct, 'socketRefNo');
    }

    this.widgetElementsGroups.forEach(group => {
      const groupAsset = this.getAssetByGroup(this.asset, group.id);
      if (groupAsset) {
        this.widgetElements.set(group, groupAsset);
      }
    });

    this.subscriptionUsages = this.subscriptionsUsages.get(this.subscription.id);

    for (const [key, value] of this.widgetElements) {
      const widgetElementInfo = new WidgetElementInfo();
      const type = key.groupParams['widget_ElementType'];
      widgetElementInfo.type = type;
      if (this.subscriptionUsages) {
        const usage = this.subscriptionUsages.usage.find(usage => usage.usageType === type.toLowerCase());
        if (usage) {
          widgetElementInfo.onTop = usage.usageVolumeOnTopOfPackage;
          widgetElementInfo.currentUsage = usage.usageVolumeWithinPackage;
          widgetElementInfo.priceOnTop = usage.usageVolumeOnTopOfPackage * usage.unitPrice;
        }
      }

      widgetElementInfo.limit = Number.parseInt(
        this.getFirstFoundParameterValue(value, key.groupParams['widget_BundleVolumeParamNames']),
      );
      if (widgetElementInfo.limit) {
        widgetElementInfo.limitUnit = this.getFirstFoundParameterValue(
          value,
          key.groupParams['widget_BundleUnitsParamNames'],
        );
        widgetElementInfo.leftUsage =
          widgetElementInfo.limit > widgetElementInfo.currentUsage
            ? widgetElementInfo.limit - widgetElementInfo.currentUsage
            : 0;
        widgetElementInfo.usedPercentage = (widgetElementInfo.currentUsage / widgetElementInfo.limit) * 100;
      } else {
        widgetElementInfo.limit = -1;
      }

      this.widgetElementsInfos.set(type, widgetElementInfo);
    }

    this.amountOnTop = 0;
    this.monthPrice = 0;

    if (this.subscriptionUsages) {
      this.subscriptionUsages.usage.forEach(usage => {
        this.amountOnTop += usage.usageVolumeOnTopOfPackage * usage.unitPrice;

        if (!this.widgetElementsInfos.get(usage.usageType.toUpperCase())) {
          const widgetElementInfo = new WidgetElementInfo();
          widgetElementInfo.type = usage.usageType.toUpperCase();
          widgetElementInfo.limitUnit = usage.usageUnit === 'minute' ? 'MIN' : usage.usageUnit;
          widgetElementInfo.unitPrice = usage.unitPrice;
          widgetElementInfo.limit = 0;
          widgetElementInfo.onTop = usage.usageVolumeOnTopOfPackage;
          widgetElementInfo.currentUsage = usage.usageVolumeWithinPackage;
          widgetElementInfo.priceOnTop = usage.usageVolumeOnTopOfPackage * usage.unitPrice;
          this.widgetElementsInfos.set(widgetElementInfo.type, widgetElementInfo);
        }
        this.monthPrice = this.amountOnTop + this.tariffProductPrice;
      });
    }
  }

  isPostpaidCheck(tariffProduct?: ProductDetailDto): boolean {
    return SubscriptionUtils.isPostpaid(tariffProduct ? tariffProduct : this.tariffProduct);
  }

  getTariffName(tariff) {
    return tariff.parameters['customName'] ? tariff.parameters['customName'] : tariff.displayName;
  }

  getTariffIdentification(tariff: PartyDto, group?: GroupDto): string {
    const productIdentificationParameters = group
      ? group.groupParams['widget_IdentificationParamNames']
      : this.group.groupParams['widget_IdentificationParamNames'];
    const identification = this.getFirstFoundParameterValue(tariff.assets[0], productIdentificationParameters);
    return identification ? identification : this.getTariffName(tariff);
  }

  protected getFirstFoundParameterValue(asset: AssetDto, parametersNames: string): string {
    if (!parametersNames) {
      return null;
    }
    const names: string[] = parametersNames.split(',');

    for (const name of names) {
      let paramValue = asset.productCharacteristic.find(param => param.name === name)?.value;
      if (paramValue && paramValue !== '') {
        return paramValue;
      }

      if (asset.childAssets) {
        for (const childAsset of asset.childAssets) {
          paramValue = this.getFirstFoundParameterValue(childAsset, parametersNames);
          if (paramValue && paramValue !== '') {
            return paramValue;
          }
        }
      }
    }

    return null;
  }

  protected getAssetByGroup(asset: AssetDto, groupId: string): AssetDto {
    if (!groupId) {
      return null;
    }

    if (asset.product.groupInfos.find(productGroup => productGroup.id === groupId)) {
      return asset;
    }

    if (asset.childAssets) {
      for (const childAsset of asset.childAssets) {
        const retAsset = this.getAssetByGroup(childAsset, groupId);
        if (retAsset) {
          return retAsset;
        }
      }
    }
    return null;
  }

  getWidgetElementValue(elementType: string, groupParamName: string) {
    for (const [key, value] of this.widgetElements) {
      if (key.groupParams['widget_ElementType'] === elementType) {
        const parametersNames = key.groupParams[groupParamName];
        return this.getFirstFoundParameterValue(value, parametersNames);
      }
    }
  }

  getWidgetElementAssets(elementType: string) {
    for (const [key, value] of this.widgetElements) {
      if (key.groupParams['widget_ElementType'] === elementType) {
        return value;
      }
    }
  }

  getWidgetElementGroup(elementType: string) {
    for (const [key, value] of this.widgetElements) {
      if (key.groupParams['widget_ElementType'] === elementType) {
        return key;
      }
    }
  }

  protected getAssetsByGroup(asset: AssetDto, groupId: string, assets: AssetDto[]): void {
    if (groupId) {
      if (!assets) {
        assets = [];
      }

      if (asset.product.groupInfos.find(productGroup => productGroup.id === groupId)) {
        assets.push(asset);
      }

      if (asset.childAssets) {
        for (const childAsset of asset.childAssets) {
          this.getAssetsByGroup(childAsset, groupId, assets);
        }
      }
    }
  }

  /**
   * Open a dialog for tariff restriction.
   */
  restrictTariff(): void {
    const dialogReference = this.ngbModal.open(TariffRestrictionComponent, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'dialog dialog-input',
    });

    const confirmationDialogComponent = <TariffRestrictionComponent>dialogReference.componentInstance;
    confirmationDialogComponent.customer = this.customerLocalStorageService.getCurrentCustomer();
    confirmationDialogComponent.customerAccount = CustomerPartyUtil.getChildPartyById(this.customerLocalStorageService.getCurrentCustomer(), this.subscription.parentId);
    confirmationDialogComponent.tariffSpace = this.subscription;
    confirmationDialogComponent.tariff = this.subscription.assets[0];
    confirmationDialogComponent.localizationParameters = {
      tariffName: this.subscription.assets[0].product.name,
    };
    confirmationDialogComponent.dialogReference = dialogReference;
    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef): void => {
      setTimeout(() => {
        this.subscriptionChanged.emit();
      }, 2000);
    }
  }

  /**
   * Open a dialog for tariff deactivation.
   */
  deactivateTariff(): void {
    const dialogReference = this.ngbModal.open(TariffDeactivationComponent, {
      size: 'sm',
      backdrop: 'static',
      windowClass: 'dialog dialog-input',
    });

    const confirmationDialogComponent = <TariffDeactivationComponent>dialogReference.componentInstance;
    confirmationDialogComponent.customer = this.customerLocalStorageService.getCurrentCustomer();
    confirmationDialogComponent.customerAccount = CustomerPartyUtil.getChildPartyById(this.customerLocalStorageService.getCurrentCustomer(), this.subscription.parentId);
    confirmationDialogComponent.tariffSpace = this.subscription;
    confirmationDialogComponent.tariff = this.subscription.assets[0];
    confirmationDialogComponent.localizationParameters = {
      tariffName: this.subscription.assets[0].product.name,
    };
    confirmationDialogComponent.dialogReference = dialogReference;
    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef): void => {
      setTimeout(() => {
        this.subscriptionChanged.emit();
      }, 2000);
    }
  }
}

export class WidgetElementInfo {
  type: string;
  onTop: number = 0;
  priceOnTop: number = 0;
  currentUsage: number = 0;
  leftUsage: number = 0;
  limit: number = 0;
  limitUnit: string = '';
  usedPercentage: number = 0;
  unitPrice: number = 0;
}
