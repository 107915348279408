<div class="modal-header">
  <div>
    <h4 class="modal-title">
      {{ 'wc.ecare.files.uploadFiles' | translate }}
    </h4>
  </div>
  <div class="modal-close" [attr.aria-label]="'wc.common.close' | translate" (click)="dialogRef.dismiss()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>
<div class="modal-body picture-modal">
  <div class="card__wrapper">
    <div class="card__body">
      <div class="picture-wrapper">
        <ng-container *ngIf="uploadStep; else uploading">
          <div class="upload-title">
            {{ 'wc.ecare.files.selectFileType' | translate }}
          </div>
          <div class="upload-select-box">
            <div *ngFor="let pictureType of pictureTypes" class="upload-select-item" (click)="select(pictureType)">
              <div style="padding: 10px">
                <div fxLayout="column">
                  <div style="display: flex; place-content: center; padding: 10px 0 30px">
                    <div style="display: flex; text-align: center; align-self: center">
                      <div class="PC" style="width: 80px; height: 80px; border-radius: 10px">
                        <div
                          class="fa fa-portrait"
                          style="position: relative; top: 24px; font-size: 36px; height: 36px; width: 36px"></div>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="column" style="align-items: center">
                    <div style="font-size: 16px; font-weight: 600">{{ pictureType.id }}</div>
                    <div *ngIf="pictureType.width && pictureType.height">
                      {{ 'wc.ecare.files.ImageSizeShouldBe' | translate }}
                    </div>
                    <span *ngIf="pictureType.width && pictureType.height"
                      >{{ pictureType.width }} x{{ pictureType.height }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #uploading>
          <div class="uploading">
            <div class="container">
              <input
                #fileDropRef
                type="file"
                id="fileDropRef"
                [multiple]="multiple"
                (change)="fileBrowseHandler($event.target.files)" />
              <i alt="upload" [inlineSVG]="'/assets/svg/fileUploader/uploadfile-icon.svg'"></i>
              <h5>{{ 'wc.ecare.files.dragAndDropFiles' | translate }} {{ 'wc.ecare.files.or' | translate }}</h5>
              <div
                class="d-flex justify-content-center"
                app-button
                variant="whiteWithBorder"
                size="shrink"
                label="{{ 'wc.ecare.files.chooseFile' | translate }}"></div>
            </div>
            <div class="files-list">
              <div *ngFor="let file of files; let i = index" class="single-file">
                <div
                  class="fa pause"
                  style="
                    width: auto;
                    height: 32px;
                    font-size: 32px;
                    padding: 10px;
                    border-right: 1px solid rgba(32, 40, 48, 0.1);
                  "></div>
                <div class="info">
                  <h4 class="name">
                    {{ file?.name }}
                    <div style="display: flex; align-items: center; column-gap: 10px">
                      <ngb-progressbar class="file-progress" [value]="file?.progress"></ngb-progressbar>
                      {{ file?.progress }}%
                    </div>
                  </h4>
                  <p class="size"></p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="error" *ngIf="wrongFileFormat">{{ 'wc.ecare.products.product.pictures.wrongFileFormat' | translate }}</div>
  <div class="error" *ngIf="toBigFile">{{ 'wc.common.file.toBig' | translate : { maxBodySizeMb: maxBodySizeMb } }}</div>
</div>
