<div
  app-acl-evaluate
  [privilege]="metaParameters['privilege']"
  *ngIf="control"
  class="generated-input-container{{ cssContainerSufix ? '-' + cssContainerSufix : '' }}"
  [ngClass]="(guiStyleClasses ? guiStyleClasses + ' ' : '') + metaParameters['guiStyleClass']">
  <div class="generated-form-input-label">
    <i *ngIf="metaParameters['prefixIconPath']" [inlineSVG]="metaParameters['prefixIconPath']"></i>
    <label>
      {{ label }}
      <span *ngIf="metaParameters['isMandatory'] === 'true' && validate" class="required">*</span>
    </label>
  </div>

  <div class="input full" [ngSwitch]="metaParameters['guiElementType']" [formGroup]="form">
    <ng-container *ngSwitchCase="guiElementTypeEnum.BOOLEAN">
      <input
        class="checkbox"
        type="checkbox"
        [id]="formIdPrefix + '.' + name"
        [formControlName]="name"
        (focusin)="this.fieldValueChanged()"
        (ngModelChange)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />
      <label class="-align-center" [for]="formIdPrefix + '.' + name"> </label>
    </ng-container>

    <app-date-picker
      *ngSwitchCase="guiElementTypeEnum.DATE"
      class="full date-picker date-generated-form"
      [id]="formIdPrefix + '.' + name"
      [minDate]="getAssignmentStateFromMinDate ? getAssignmentStateFromMinDate() : null"
      [maxDate]="getAssignmentStateFromMaxDate ? getAssignmentStateFromMaxDate() : null"
      [formControlName]="name"
      [showTime]="false"
      (focusin)="this.fieldValueChanged()"
      (ngModelChange)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />

    <app-date-picker
      *ngSwitchCase="guiElementTypeEnum.DATE_TIME"
      class="full date-picker date-generated-form"
      [id]="formIdPrefix + '.' + name"
      [minDate]="getAssignmentStateFromMinDate()"
      [maxDate]="getAssignmentStateFromMaxDate()"
      [formControlName]="name"
      [showTime]="true"
      (focusin)="this.fieldValueChanged()"
      (ngModelChange)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />
    <ng-container *ngSwitchCase="guiElementTypeEnum.SELECT">
      <ng-container *ngIf="typeDetail !== 'EXTERNAL_VALUE' && !externalValues; else externalValue">
        <app-input-select-form-field
          *ngIf="type === attributeTypeEnum.CODEBOOK"
          class="full-width"
          inputType="codebook"
          [doNotShowInternalErrors]="true"
          [id]="formIdPrefix + '.' + name"
          [formControlName]="name"
          [showEmptyOption]="!(metaParameters['isMandatory'] === 'true')"
          [codebookType]="typeDetail"
          [noLabel]="true"
          (focusin)="this.fieldValueChanged()"
          (selectionChangeEmit)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />

        <app-input-select-form-field
          *ngIf="type === attributeTypeEnum.ENUM"
          class="full"
          inputType="enum"
          [doNotShowInternalErrors]="true"
          [id]="formIdPrefix + '.' + name"
          [formControlName]="name"
          [showEmptyOption]="!(metaParameters['isMandatory'] === 'true')"
          [sourceName]="sourceName"
          [enumName]="typeDetail"
          [noLabel]="true"
          (focusin)="this.fieldValueChanged()"
          (selectionChangeEmit)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />
      </ng-container>

      <ng-template #externalValue>
        <app-input-select-form-field
          class="full-width"
          valueOption="value"
          [labelOption]="externalValues[0]['labelKey'] ? 'labelKey' : 'label'"
          [doNotShowInternalErrors]="true"
          [id]="formIdPrefix + '.' + name"
          [formControlName]="name"
          [showEmptyOption]="!(metaParameters['isMandatory'] === 'true')"
          [options]="externalValues"
          [noLabel]="true"
          (focusin)="this.fieldValueChanged()"
          (selectionChangeEmit)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />
      </ng-template>
    </ng-container>

    <app-form-phone-number
      *ngSwitchCase="guiElementTypeEnum.PHONE"
      class="full-width"
      [id]="formIdPrefix + '.' + name"
      [validate]="validate"
      [showLabel]="false"
      [translationPrefix]="'wc.shopping.form'"
      [mandatory]="metaParameters['isMandatory'] === 'true'"
      [fieldName]="name"
      [control]="control"
      [formControlName]="name"
      (focusin)="this.fieldValueChanged()"
      (ngModelChange)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />

    <app-address-search-control
      *ngSwitchCase="'address'"
      [formControlName]="name"
      [control]="control"
      (focusin)="this.fieldValueChanged()"
      (ngModelChange)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />

    <app-simple-contact-control
      *ngSwitchCase="'contactSimple'"
      [formControlName]="name"
      [control]="control"
      (focusin)="this.fieldValueChanged()"
      (ngModelChange)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />

    <app-multi-ip-control
      *ngSwitchCase="'multiIP'"
      [pattern]="validationRegex"
      [formControlName]="name"
      [control]="control"
      (focusin)="this.fieldValueChanged()"
      (ngModelChange)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />

    <textarea
      *ngSwitchCase="'textarea'"
      [rows]="5"
      [formControlName]="name"
      (focusin)="this.fieldValueChanged()"
      (ngModelChange)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null">
    </textarea>

    <div *ngSwitchDefault class="generated-form-input-label">
      {{ prefix ? prefix : '' }}
      <input
        class="form__input full {{ name === guiElementTypeEnum.EMAIL ? 'withIcon' : '' }}"
        [id]="formIdPrefix + '.' + name"
        [formControlName]="name"
        [placeholder]="placeholderKey ? (placeholderKey | translate) : placeholder ? placeholder : ''"
        [mask]="mask"
        [type]="
          metaParameters['guiElementType'] === 'integer' || metaParameters['guiElementType'] === 'float'
            ? 'number'
            : 'text'
        "
        (focusin)="this.fieldValueChanged()"
        (ngModelChange)="attributeOnChangeEvent ? attributeOnChangeEvent.emit($event) : null" />
      {{ suffix ? suffix : '' }}
      <i
        *ngIf="name === guiElementTypeEnum.EMAIL"
        class="prefix_icon"
        aria-hidden="true"
        inlineSVG="/assets/img/ecare/mail.svg">
      </i>
    </div>

    <app-input-errors
      [control]="control"
      [translationPrefix]="
        (metaParameters['guiErrorTranslationPrefix']
          ? metaParameters['guiErrorTranslationPrefix'] + '.'
          : 'wc.shopping.form.') + name | translate
      " />
  </div>
</div>
