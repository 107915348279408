<ng-container *ngIf="ticketDtos?.length">
  <ng-container *ngIf="simpleMode">
    <table class="ecare_listing-table rounded tasks-table">
      <ng-container *ngFor="let ticketDto of ticketDtos">
        <tr class="ecare_listing-table-row clickable">
          <td>
            <i
              class="menu-icon task-icon"
              [inlineSVG]="'/assets/img/ecare/' + (ticketDto.parentId ? 'sub' : '') + 'task.svg'"></i>
          </td>
          <td>
            <div class="task-main-field">
              <div class="task_header">
                {{ ticketDto.subject }}
              </div>
              <i class="task-priority" [inlineSVG]="'/assets/svg/task-' + ticketDto.priority + '.svg'"> </i>
            </div>
          </td>
          <td class="no-wrap mobile-right">
            <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
              <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
                <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
              </span>

              <div ngbDropdownMenu class="action-list-dropdown-menu">
                <div>
                  <button class="btn btn-link action-list-more-actions-button" type="button" (click)="edit(ticketDto)">
                    {{ 'wc.ecare.ticket.edit.button' | translate }}
                  </button>
                  <button
                    class="btn btn-link action-list-more-actions-button"
                    type="button"
                    (click)="edit(ticketDto, true)">
                    {{ 'wc.ecare.ticket.editInNewTab.button' | translate }}
                  </button>
                  <button
                    class="btn btn-link action-list-more-actions-button"
                    type="button"
                    (click)="delete(ticketDto)">
                    {{ 'wc.ecare.ticket.delete.button' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
  </ng-container>

  <ng-container *ngIf="!simpleMode">
    <table class="ecare_listing-table tasks-table responsive-table table">
      <thead>
        <tr>
          <th *ngIf="selectFormArray">
            <input
              class="checkbox"
              type="checkbox"
              [id]="'ticket-all'"
              [checked]="allTicketsSelected"
              (change)="selectDeselectAllEvent.emit()" />
            <label
              style="vertical-align: super"
              class="{{ anyTicketsSelected ? 'partial-selection' : '' }}"
              [for]="'ticket-all'">
            </label>
          </th>
          <th
            class="with-icon"
            app-sortable-column
            column="subject"
            localizationKey="wc.ecare.ticket.subject"
            [sorting]="search.sorting"
            (sortChanged)="sortChangedEmitter.emit()"></th>
          <th></th>
          <th
            class="with-icon"
            app-sortable-column
            column="startFrom"
            localizationKey="wc.ecare.ticket.startFrom"
            [sorting]="search.sorting"
            (sortChanged)="sortChangedEmitter.emit()"></th>
          <th
            *ngIf="!hideDueDate"
            class="with-icon"
            app-sortable-column
            column="slaDueDate"
            localizationKey="wc.ecare.ticket.dueDate"
            [sorting]="search.sorting"
            (sortChanged)="sortChangedEmitter.emit()"></th>
          <th
            class="with-icon"
            app-sortable-column
            column="priority"
            localizationKey="wc.ecare.ticket.priority"
            [sorting]="search.sorting"
            (sortChanged)="sortChangedEmitter.emit()"></th>
          <th
            *ngIf="!hideState"
            class="with-icon"
            app-sortable-column
            column="businessState"
            localizationKey="wc.ecare.ticket.state"
            [sorting]="search.sorting"
            (sortChanged)="sortChangedEmitter.emit()"></th>
          <th app-acl-internal-evaluate-component class="with-icon">
            <div
              app-sortable-column
              column="assignedTo"
              localizationKey="wc.ecare.ticket.userLogin"
              [sorting]="search.sorting"
              (sortChanged)="sortChangedEmitter.emit()"></div>
          </th>
          <th class="with-icon" title="{{ 'wc.ecare.ticket.attachmentsCount.tootip' | translate }}">
            <i class="fa fa-paperclip" aria-hidden="true"> </i>
          </th>
          <th class="with-icon" title="{{ 'wc.ecare.ticket.notesCount.tootip' | translate }}">
            <i class="fa fa-sticky-note-o" aria-hidden="true"> </i>
          </th>
          <th class="with-icon" title="{{ 'wc.ecare.ticket.referencesCount.tootip' | translate }}">
            <i class="fa fa-check-square-o" aria-hidden="true"> </i>
          </th>
          <th>{{ 'wc.ecare.ticket.action' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let ticket of ticketDtos; let i = index">
          <tr
            class="ecare_listing-table-row pointer {{ selected === ticket ? 'selected' : '' }}"
            [ngClass]="{ '-not-important-row': ticket.businessState === 'CLOSED' }"
            (dblclick)="edit(ticket)">
            <td *ngIf="selectFormArray">
              <input class="checkbox" type="checkbox" [id]="'ticket-' + i" [formControl]="selectFormArray.at(i)" />
              <label style="vertical-align: super" [for]="'ticket-' + i"> </label>
            </td>
            <td>
              <span class="information">{{ ticket.subject }}</span>
            </td>
            <td>
              <i
                class="menu-icon task-icon"
                [inlineSVG]="'/assets/img/ecare/' + (ticket.parentId ? 'sub' : '') + 'task.svg'"></i>
            </td>
            <td>
              <span>{{ ticket.startFrom | date : 'dd.MM.yyyy HH:mm' }}</span>
            </td>
            <td *ngIf="!hideDueDate">
              <span>{{ ticket.slaDueDate | date : 'dd.MM.yyyy HH:mm' }}</span>
            </td>
            <td>
              <ng-container *ngIf="ticket.type.areaType === 'TASK'; else priorityNumber">
                <div class="wrapped">
                  <i class="task-priority" [inlineSVG]="'/assets/svg/task-' + ticket.priority + '.svg'"></i>
                  <div class="highlight">{{ 'wc.ecare.ticket.task.priorities.' + ticket.priority | translate }}</div>
                </div>
              </ng-container>
              <ng-template #priorityNumber>
                <div class="task-priority higlighted">{{ ticket.priority }}</div>
              </ng-template>
            </td>
            <td *ngIf="!hideState">
              <span class="task-state {{ ticket.businessState }}">{{ ticket.businessState }}</span>
            </td>
            <td app-acl-internal-evaluate-component>
              <span>{{ ticket.assignedTo }}</span>
            </td>
            <td>
              {{ ticket.attachments.length ? ticket.attachments.length : '-' }}
            </td>
            <td>
              {{ ticket.notes.length ? ticket.notes.length : '-' }}
            </td>
            <td>
              {{ ticket.references?.length ? ticket.references.length : '-' }}
            </td>
            <td class="no-wrap mobile-right">
              <ng-container>
                <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
                  <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
                    <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
                  </span>

                  <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ ticket.id }}_popover-content">
                    <div>
                      <button class="btn btn-link action-list-more-actions-button" type="button" (click)="edit(ticket)">
                        {{ 'wc.ecare.ticket.edit.button' | translate }}
                      </button>
                      <button
                        class="btn btn-link action-list-more-actions-button"
                        type="button"
                        (click)="edit(ticket, true)">
                        {{ 'wc.ecare.ticket.editInNewTab.button' | translate }}
                      </button>
                      <button
                        class="btn btn-link action-list-more-actions-button"
                        type="button"
                        (click)="duplicate(ticket); $event.stopPropagation()">
                        {{ 'wc.common.duplicate.button' | translate }}
                      </button>
                      <button
                        class="btn btn-link action-list-more-actions-button"
                        type="button"
                        (click)="delete(ticket)">
                        {{ 'wc.ecare.ticket.delete.button' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="selected === ticket" class="notification-window">
            <td colspan="15">
              <app-task-expanded-row-component [ticket]="ticket"/>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</ng-container>
