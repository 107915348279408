import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AppBlockerService,
  AuthFactoryService,
  BlockTemplateComponent,
  CurrentLocaleService,
  DmsService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '@service/customer.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { Attachment, AttachmentType } from '../../../form-attachments/form-attachments.component';
import { forkJoin } from 'rxjs';
import { from } from 'rxjs/internal/observable/from';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Location } from '@angular/common';
import { CustomerPartyUtil } from '../../../../helpers/customer-party.util';
import {
  CustomerAccountDto,
  CustomerDto,
  NotificationDto,
  NotificationFrontendService,
  NotificationGatewayFrontendService,
  NotificationTypeDto,
  NotificationTypeFrontendService,
  NotificationTypeGatewayDto
} from '@btl/order-bff';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.css'],
})
@EnableDynamicLoading({ customName: NotificationDetailComponent.PAGE_ID })
export class NotificationDetailComponent extends AbstractPageComponent implements OnDestroy {
  public static readonly PAGE_ID = 'NotificationDetailComponent';

  pageId(): string {
    return NotificationDetailComponent.PAGE_ID;
  }

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  notification: NotificationDto;
  customer: CustomerDto;

  notificationTypes: NotificationTypeDto[];
  gatewayTypes: NotificationTypeGatewayDto[];
  editMode = false;

  createDmsFilesList = [];

  attachmentType = AttachmentType.ATTACH_FILES;
  cuRefNo: string;
  caRefNo: string;
  returnUrl;
  filesExtIds;
  defaultEmailList;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: '600px',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: false,
    toolbarPosition: 'top',
    uploadUrl: '/api/bff/order/v1/files/content',
    toolbarHiddenButtons: [
      [
        'insertVideo',
        'fontName',
        'fontSize',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'subscript',
        'superscript',
        'strikeThrough',
        'customClasses',
      ],
    ],
  };

  form = this.formBuilder.group({
    id: [],
    gateway: [],
    state: ['INITIAL'],
    notificationType: [null, Validators.required],
    body: ['', Validators.required],
    bodyAlternative: [''],
    subject: [null, Validators.required],
    note: [null],
    fromAddress: [null],
    fromName: [null],
    recipients: [],
    attachments: [],
    priority: [0],
    toBeSent: [],
  });

  duplicationId;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private notificationFrontendService: NotificationFrontendService,
    private customerService: CustomerService,
    private dmsService: DmsService,
    private ngbModal: NgbModal,
    public customerLocalStorageService: CustomerLocalStorageService,
    private notificationGatewayFrontendService: NotificationGatewayFrontendService,
    private notificationTypeFrontendService: NotificationTypeFrontendService,
    private stickyMessageService: StickyMessageService,
    private authFactoryService: AuthFactoryService,
    private currentLocaleService: CurrentLocaleService
  ) {
    super(router, route);

    this.route.queryParams.subscribe(queryParams => {
      this.duplicationId = queryParams['duplicationId'];
    });

    this.notificationTypeFrontendService
      .filterNotificationTypes(null, null, null, null, 1, -1)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.notificationTypes = result.data.filter(notificationType => notificationType.productionEnabled);
      });

    this.route.queryParams.subscribe(queryParams => {
      this.returnUrl = queryParams['returnUrl'];
      this.filesExtIds = queryParams['filesExtIds'];
      this.defaultEmailList = queryParams['defaultEmailList'];
      this.cuRefNo = queryParams['cuRefNo'];
      this.caRefNo = queryParams['caRefNo'];
    });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const notificationId = this.params.id;
      if (notificationId && notificationId !== 'newNotification') {
        this.loadNotification(notificationId);
      } else {
        this.editMode = true;

        if (this.duplicationId) {
          this.loadNotification(this.duplicationId);
        } else {
          from(this.authFactoryService.getAuthService().getUserInfo()).subscribe(userProfile => {
            this.notification = {
              id: null,
              subject: null,
              recipients: [],
              attachments: [],
              note: null,
            };

            this.notification.fromAddress = userProfile.email;
            this.notification.fromName = `${userProfile.firstName} ${userProfile.lastName}`;

            if (this.filesExtIds) {
              const attachments = [];
              if (!(this.filesExtIds instanceof Array)) {
                this.filesExtIds = [this.filesExtIds];
              }
              this.filesExtIds.forEach(extId => {
                attachments.push({
                  extId: extId,
                  type: 'GENERAL',
                });
              });
              this.notification.attachments = attachments;
            }
            if (this.defaultEmailList) {
              this.notification.recipients.push({
                partyRefNo: null,
                recipientType: 'TO',
                recipientName: null,
                address: this.defaultEmailList,
                sent: false,
                recipientState: 'READY',
              });
            } else {
              if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
                this.notification.recipients.push(
                  this.getRecipientRecursively(this.customerLocalStorageService.getCurrentCustomerAccount())
                );
              } else if (this.customerLocalStorageService.getCurrentCustomer()) {
                this.notification.recipients.push(
                  this.getRecipientFromCustomer(this.customerLocalStorageService.getCurrentCustomer())
                );
              }
            }
            // @ts-ignore
            this.form.patchValue(this.notification);
          });
        }
      }
    } else {
      this.notification = undefined;
    }
  }

  getRecipientRecursively(customerAccount) {
    if (customerAccount) {
      if (customerAccount.invoicingContact) {
        return this.getRecipientFromCustomerAccount(customerAccount);
      } else {
        return this.getRecipientRecursively(
          CustomerPartyUtil.getChildPartyById(
            this.customerLocalStorageService.getCurrentCustomer(),
            customerAccount.parentId
          )
        );
      }
    } else {
      return this.getRecipientFromCustomer(this.customerLocalStorageService.getCurrentCustomer());
    }
  }

  getRecipientFromCustomerAccount(customerAccount: CustomerAccountDto) {
    return {
      partyRefNo: customerAccount.id,
      recipientType: 'TO',
      recipientName: `${customerAccount.invoicingContact?.firstName} ${customerAccount.invoicingContact?.lastName}`,
      address: customerAccount.invoicingContact?.email,
      sent: false,
      recipientState: 'READY',
    };
  }

  getRecipientFromCustomer(customer: CustomerDto) {
    return {
      partyRefNo: customer.id,
      recipientType: 'TO',
      recipientName:
        (customer.subject.firstName ? `${customer.subject.firstName} ` : '') + customer.subject.lastName
          ? customer.subject.lastName
          : '',
      address: customer.contact.email,
      sent: false,
      recipientState: 'READY',
    };
  }

  getAttachmentsFormArray() {
    return this.form.get('attachments') as FormArray;
  }

  loadNotification(notificationId) {
    this.notificationFrontendService
      .getNotificationById(notificationId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadNotification(result);
      });
  }

  reloadNotification(notificationDto: NotificationDto) {
    if (this.duplicationId) {
      notificationDto.created = null;
      notificationDto.createdBy = null;
      notificationDto.modified = null;
      notificationDto.modifiedBy = null;
      notificationDto.id = null;
      notificationDto.sent = null;
      notificationDto.toBeSent = null;
      notificationDto.state = 'INITIAL';
    }
    this.notification = notificationDto;
    // @ts-ignore
    this.form.patchValue(this.notification);
    this.notificationTypeChanged(this.notification.notificationType, true);
    this.reloadAttachments(notificationDto);
    this.customer = null;
  }

  reloadAttachments(notificationDto: NotificationDto) {
    const existingAttachments = notificationDto.attachments.filter(attachment => !attachment.inline);

    notificationDto.attachments.length = 0;
    existingAttachments.forEach(attachment => notificationDto.attachments.push(attachment));

    if (this.form.get('gateway').value) {
      const gatewayType = this.gatewayTypes?.find(find => find.gateway === this.form.get('gateway').value);
      gatewayType?.attachments
        .filter(
          attachment =>
            !attachment.inline && !notificationDto.attachments.find(existing => existing.extId === attachment.extId)
        )
        .forEach(attachment =>
          notificationDto.attachments.push({
            extId: attachment.extId,
          })
        );
    }
    this.form.controls.attachments.patchValue(notificationDto.attachments);
  }

  saveChanges() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      this.notification = this.form.getRawValue();
      this.notification.id = null;
      this.notification.recipients.forEach(recipient => {
        recipient.id = null;
        recipient.createdBy = null;
        recipient.modified = null;
        recipient.created = null;
        recipient.modifiedBy = null;
      });
      if (!this.notification.toBeSent) {
        this.notification.toBeSent = new Date();
      }
      this.storeNewAttachments();
      this.notificationFrontendService
        .createNotification(this.notification)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(() => {
          if (this.createDmsFilesList.length > 0) {
            const calls = this.createDmsFilesList.map(file => this.dmsService.createFile(file));
            forkJoin(calls)
              .pipe(takeUntil(this.onDestroy$))
              .pipe(finalize(this.appBlockerService.unblock))
              .subscribe(() => {
                this.backToListing();
              });
          } else {
            this.backToListing();
          }
        });
    }
  }

  backToListing() {
    this.cancel();
    this.stickyMessageService.addStickySuccessMessage('wc.ecare.notifications.notificationAdded');
  }

  storeNewAttachments() {
    this.createDmsFilesList = [];

    this.notification.attachments.forEach((attachment: Attachment) => {
      attachment.id = null;
      attachment['name'] = attachment.dmsFile.name;
      attachment['contentType'] = attachment.dmsFile.mediaType;
      attachment.dmsFile.contentHref = null;
      attachment.dmsFile.size = null;
      if (!attachment.dmsFile?.id) {
        this.createDmsFilesList.push(attachment.dmsFile);
      }
      delete attachment.dmsFile;
    });
    this.notification.attachments = this.notification.attachments.filter(attachment => !attachment['delete']);
  }

  discardChanges() {
    this.loadNotification(this.notification.id);
  }

  send() {
    this.saveChanges();
  }

  cancel() {
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.navigateSibling('EcareNotificationsComponent', { cuRefNo: this.cuRefNo, caRefNo: this.caRefNo });
    }
  }

  notificationTypeChanged(notificationType, init = false) {
    if (this.notificationTypes) {
      if (notificationType) {
        const notificationTypeDto = this.notificationTypes.find(
          notType => notType.notificationType === notificationType
        );
        if (notificationTypeDto) {
          this.gatewayTypes = notificationTypeDto.notificationTypeGateways;
          if (this.gatewayTypes.length > 0 && !init) {
            this.form.controls['gateway'].patchValue(this.gatewayTypes[0].gateway);
            this.gatewayTypeChanged(this.gatewayTypes[0].gateway);
          }
        }
      } else {
        this.gatewayTypes = [];
        this.gatewayTypeChanged(null);
      }
    } else {
      this.notificationTypeFrontendService
        .filterNotificationTypes(null, null, null, null, 1, -1)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.notificationTypes = result.data.filter(notificationType => notificationType.productionEnabled);
          this.notificationTypeChanged(notificationType, init);
        });
    }
  }

  gatewayTypeChangeBlock = false;

  gatewayTypeChanged(gateway) {
    if (!this.gatewayTypeChangeBlock) {
      this.gatewayTypeChangeBlock = true;
      if (!gateway) {
        this.form.controls.subject.reset();
        this.form.controls.body.reset();
        this.form.controls.bodyAlternative.reset();
      } else {
        const gatewayType = this.gatewayTypes?.find(find => find.gateway === gateway);
        if (gatewayType) {
          // @ts-ignore
          this.form.controls.subject.patchValue(gatewayType.templateSubject);
          this.form.controls.body.patchValue(gatewayType.templateBody);
          this.form.controls.bodyAlternative.patchValue(gatewayType.templateBodyAlternative);
        }
      }
      this.reloadAttachments(this.notification);
      this.gatewayTypeChangeBlock = false;
    }
  }
}
