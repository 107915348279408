import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ProductInShoppingCart } from '../../../models/product-in-shopping-cart';
import { ShoppingCartSocket } from '../shopping-cart-item/shopping-cart-item.component';
import { PrecalculatedShoppingCart } from 'app/models/precalculated-shopping-cart';
import { WcOrderingService } from '@service/wc-ordering.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderDto, ProductParamMetadataDto } from '@btl/order-bff';
import { forkJoin, Observable, of, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { FormUtils, ProductService, StickyMessageService } from '@btl/btl-fe-wc-common';
import { map, mergeMap } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-shopping-cart-product',
  templateUrl: './shopping-cart-product.component.html',
})
export class ShoppingCartProductComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @ViewChildren(ShoppingCartProductComponent)
  private shoppingCartProductComponents: QueryList<ShoppingCartProductComponent>;

  @Input() shoppingCartSocket: ShoppingCartSocket;
  @Input() precalculatedShoppingCart: PrecalculatedShoppingCart;
  @Input() displayMode: 'FULL' | 'COMPACT' | 'ROLLOUT';
  @Input() orderItemsFormsValues = {};
  @Input() showVat: boolean = true;
  @Input() showRemoveBtn = true;

  childProduct: ProductInShoppingCart;
  dynamicParameters: Map<string, ProductParamMetadataDto[]> = new Map<string, ProductParamMetadataDto[]>();
  dynamicParametersForm = null;

  constructor(
    private orderingService: WcOrderingService,
    private router: Router,
    private stickyMessageService: StickyMessageService,
    private productService: ProductService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.childProduct = this.shoppingCartSocket.product;
    if (this.childProduct.display) {
      this.productService
        .getProductParameterMetadata(this.childProduct.productDetail.categoryId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(metadata => {
          const dynamicParameters = metadata
            .filter(param => this.shouldParentBeDisplayed(param))
            .sort((a, b) => (Number(a.metadata['guiSortOrder']) > Number(b.metadata['guiSortOrder']) ? 1 : -1));
          if (dynamicParameters.length > 0) {
            //ToDo Handle more than one order item
            this.dynamicParameters.set(this.childProduct.orderItems[0].id, dynamicParameters);
          }

          this.addNotDisplayedChildrenDynamicParameters(this.childProduct).subscribe(result => {
            const dynamicParametersForm = this.fb.group({});
            result.forEach((value, key) => {
              const newDynamicParametersFormControl = new FormGroup({});
              value.forEach(param => {
                let value = this.orderingService.getOrderItemAttributeValue(
                  param.name,
                  this.precalculatedShoppingCart.order.orderItems.find(orderItem => orderItem.id === key)
                );
                if (this.orderItemsFormsValues[key] && this.orderItemsFormsValues[key][param.name]) {
                  value = this.orderItemsFormsValues[key][param.name];
                }

                newDynamicParametersFormControl.addControl(param.name, this.fb.control(value));
                if (param.metadata['addToOcPrice'] === 'true') {
                  newDynamicParametersFormControl
                    .get(param.name)
                    .valueChanges.pipe(takeUntil(this.onDestroy$))
                    .subscribe(value => {
                      const numberValue: number = isNaN(value) ? 0 : Number(value);
                      const productsInShoppingCart = [];
                      Array.from(this.precalculatedShoppingCart.products.values()).forEach(productsInShoppingCartLoop =>
                        productsInShoppingCart.push(...productsInShoppingCartLoop)
                      );
                      const productInShoppingCart = this.precalculatedShoppingCart.getProductInCartWithOrderItemId(
                        productsInShoppingCart,
                        key
                      );
                      if (productInShoppingCart) {
                        const orderItemOcPrice = productInShoppingCart.orderItems
                          .find(orderItem => orderItem.id === key)
                          .prices.find(price => price.priceType === 'OC');
                        if (orderItemOcPrice) {
                          const newPriceWithTax = Number((numberValue + orderItemOcPrice.price.value).toFixed(2));
                          if (
                            (!orderItemOcPrice.overridingPrice && numberValue > 0) ||
                            (orderItemOcPrice.overridingPrice && orderItemOcPrice.overridingPrice != newPriceWithTax)
                          ) {
                            const newPriceWithoutTax = Number(
                              (newPriceWithTax / (1 + orderItemOcPrice.tax / 100)).toFixed(2)
                            );
                            const tax: number = Number((newPriceWithTax - newPriceWithoutTax).toFixed(2));
                            if (orderItemOcPrice.overridingPrice) {
                              this.precalculatedShoppingCart.totalOcPrice =
                                this.precalculatedShoppingCart.totalOcPrice -
                                orderItemOcPrice.overridingPrice +
                                orderItemOcPrice.price.value +
                                numberValue;
                              this.precalculatedShoppingCart.totalOcPriceTax =
                                this.precalculatedShoppingCart.totalOcPriceTax -
                                orderItemOcPrice.priceWithoutTax +
                                newPriceWithoutTax;
                              productInShoppingCart.ocPrice =
                                productInShoppingCart.ocPrice -
                                orderItemOcPrice.overridingPrice +
                                orderItemOcPrice.price.value +
                                numberValue;
                              productInShoppingCart.ocPriceTax =
                                productInShoppingCart.ocPriceTax -
                                orderItemOcPrice.priceWithoutTax +
                                newPriceWithoutTax;
                            } else {
                              this.precalculatedShoppingCart.totalOcPrice += numberValue;
                              this.precalculatedShoppingCart.totalOcPriceTax =
                                this.precalculatedShoppingCart.totalOcPriceTax -
                                orderItemOcPrice.priceWithoutTax +
                                newPriceWithoutTax;
                              productInShoppingCart.ocPrice += numberValue;
                              productInShoppingCart.ocPriceTax += newPriceWithoutTax;
                            }
                            orderItemOcPrice.overridingPrice = numberValue + orderItemOcPrice.price.value;
                            orderItemOcPrice.taxValue = tax;
                            orderItemOcPrice.priceWithoutTax = newPriceWithoutTax;
                          }
                        }
                      }
                    });
                }
              });
              dynamicParametersForm.addControl(key, newDynamicParametersFormControl);
            });

            this.dynamicParametersForm = dynamicParametersForm;
            this.dynamicParametersForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
              for (const key in value) {
                this.orderItemsFormsValues[key] = value[key];
              }
            });
            if (!this.showRemoveBtn) {
              this.dynamicParametersForm.disable();
            }
          });
        });
    }
  }

  shouldParentBeDisplayed(param: ProductParamMetadataDto): boolean {
    return (
      !param.isStatic &&
      param.metadata['guiVisibility'] === 'true' &&
      (!this.precalculatedShoppingCart.isQuoteOrder ||
        !param.metadata['guiVisibilityQuote'] ||
        param.metadata['guiVisibilityQuote'] === 'true')
    );
  }

  addNotDisplayedChildrenDynamicParameters(
    product: ProductInShoppingCart
  ): Observable<Map<string, ProductParamMetadataDto[]>> {
    if (product.children.length === 0) {
      return of(this.dynamicParameters);
    }

    const childObservables = product.children.map(child => {
      return this.productService.getProductParameterMetadata(child.productDetail.categoryId).pipe(
        map(metadata => metadata.filter(param => this.shouldParentBeDisplayed(param))),
        mergeMap(dynamicParameters => {
          if (dynamicParameters.length > 0) {
            this.dynamicParameters.set(product.orderItems[0].id, dynamicParameters);
          }

          return this.addNotDisplayedChildrenDynamicParameters(child);
        })
      );
    });

    return forkJoin(childObservables).pipe(map(() => this.dynamicParameters));
  }

  /**
   * Perform validate() on all child instances of ProductAttributesConfigurationComponent.
   *
   * @return true if everything is valid, false otherwise.
   */
  validate(): boolean {
    let valid = true;
    if (this.dynamicParametersForm) {
      FormUtils.validateAllFormFields(this.dynamicParametersForm);
      valid = this.dynamicParametersForm.valid;
    }

    if (this.shoppingCartProductComponents) {
      this.shoppingCartProductComponents.forEach(shoppingCartProductComponent => {
        if (!shoppingCartProductComponent.validate()) {
          valid = false;
        }
      });
    }

    return valid;
  }

  /**
   * Perform save() on all child instances of ProductAttributesConfigurationComponent.
   *
   * @param order The order to save the data in.
   */
  save(order: OrderDto): void {
    if (this.dynamicParametersForm) {
      Object.keys(this.dynamicParametersForm.controls).forEach(field => {
        const orderItemFormGroup = this.dynamicParametersForm.get(field);
        const orderItem = order.orderItems.find(orderItem => orderItem.id === field);
        if (orderItem) {
          Object.keys(orderItemFormGroup.controls).forEach(orderItemFormGroupField => {
            this.orderingService.saveAttribute(
              orderItemFormGroupField,
              orderItem,
              orderItemFormGroup.get(orderItemFormGroupField).value
            );
          });
        }
      });
    }

    if (this.shoppingCartProductComponents) {
      this.shoppingCartProductComponents.forEach(shoppingCartProductComponent => {
        shoppingCartProductComponent.save(order);
      });
    }
  }

  remove(product?): void {
    if (!product) {
      product = this.childProduct;
    }
    const removeOrderItems = (currentOrder: OrderDto) => {
      this.orderingService
        .removeProductInShoppingCartFromCart(currentOrder, product)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(null, errorResponse => {
          if (errorResponse instanceof HttpErrorResponse) {
            errorResponse.error.failures.forEach(failure => {
              if (failure.code === 15150) {
                this.router.navigate([this.router.url]);
                this.stickyMessageService.addStickyWarningMessage(failure.key, null, errorResponse);
              }
            });
          }
        });
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(removeOrderItems);
  }
}
