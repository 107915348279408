<ng-container *ngIf="!customerAccount && customer">
  <app-ecare-page-layout
    *blockUI="blockUIElement.name; template: blockTemplate"
    [columnTemplate]="createCustomer || editMode ? '1fr' : '2fr 1fr'"
    [showBreadcrumbs]="showBreadcrumbs"
    [title]="
      !editMode && !createCustomer
        ? ('wc.ecare.customerDetails.headline' | translate)
        : ((createCustomer ? undefined : 'wc.ecare.editCustomer.headline') | translate)
    ">
    <ng-container *ngIf="!createCustomer" page-actions>
      <ng-container *ngIf="!editMode && !createCustomer; else editActions">
        <app-acl-internal-evaluate-component>
          <app-button
            *ngIf="customer.state === 'CREATED'"
            variant="whiteWithBorder"
            iconPath="/assets/img/ecare/deactivate.svg"
            label="{{ 'wc.ecare.customerDetails.deactivate' | translate }}"
            (click)="deactivate()" />
        </app-acl-internal-evaluate-component>
        <app-acl-internal-evaluate-component>
          <app-button
            variant="whiteWithBorder"
            iconPath="/assets/svg/edit.svg"
            label="{{ 'wc.ecare.customerDetails.editCustomer' | translate }}"
            (click)="edit()" />
        </app-acl-internal-evaluate-component>
        <app-acl-internal-evaluate-component *ngIf="customer.state === 'CANCELED'">
          <app-button
            variant="secondary"
            iconPath="/assets/img/ecare/anonymize.svg"
            label="{{ 'wc.ecare.customerDetails.anonymize' | translate }}"
            (click)="edit()" />
        </app-acl-internal-evaluate-component>
        <app-button
          *ngIf="customer.state === 'CREATED'"
          variant="primary"
          iconPath="assets/img/ecare/feather-plus.svg"
          label="{{ 'wc.ecare.dashboard.createCa.link' | translate }}"
          [routerLink]="['/ecare/dashboard/' + customer.id + '/-/new-ca']" />
      </ng-container>
      <ng-template #editActions>
        <app-button variant="secondary" label="Cancel" (click)="backEvent.emit(true); edit()" />
        <app-button
          variant="primary"
          [iconPath]="createCustomer ? '/assets/img/ecare/feather-plus.svg' : '/assets/svg/save.svg'"
          [label]="
            (createCustomer ? 'wc.ecare.homepage.createCustomer' : 'wc.ecare.opportunity.saveChanges') | translate
          "
          (click)="customerAccount ? saveCustomerAccount() : saveCustomer().subscribe()" />
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!editMode && !createCustomer; else editCustomer">
      <div left-side>
        <app-section-header
          [title]="'wc.ecare.customerDetails.registrationDetails' | translate"
          [isCollapsed]="refRegistrationDetails.activeIds.length > 0"
          (click)="registrationDetails.toggle('toggleRegistrationDetails')" />
        <ngb-accordion
          #registrationDetails="ngbAccordion"
          #refRegistrationDetails
          activeIds="toggleRegistrationDetails"
          [destroyOnHide]="false">
          <ngb-panel id="toggleRegistrationDetails">
            <ng-template ngbPanelContent>
              <div class="section-content row-gap-10">
                <div class="generated-form">
                  <div
                    app-acl-internal-evaluate-component
                    class="generated-input-container col3 customer_internal_card">
                    <label for="identification">
                      {{ 'wc.ecare.dashboard.identification.label' | translate }}
                    </label>
                    <div class="input full customer_internal_card_wrapper">
                      <span id="identification">
                        {{ customer.id }}
                      </span>
                      <i
                        aria-hidden="true"
                        tooltipClass="ecare-settings-tooltip"
                        placement="bottom"
                        inlineSVG="assets/img/ecare/copy_icon.svg"
                        [cdkCopyToClipboard]="customer.id"
                        [ngbTooltip]="'wc.ecare.customerDetails.copy' | translate">
                      </i>
                    </div>
                  </div>
                  <div
                    app-acl-internal-evaluate-component
                    class="generated-input-container col3 customer_internal_card">
                    <label for="subjectType">
                      {{ 'wc.ecare.dashboard.subjectType.label' | translate }}
                    </label>
                    <div class="input full">
                      <span id="subjectType">
                        {{ customer.subject.subjectType }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="!isBusinessView" class="generated-input-container col3">
                    <label>
                      {{ 'wc.ecare.customerDetails.fullName' | translate }}
                      <span *ngIf="customer.extId"
                        >({{
                          (isBusinessView ? 'wc.ecare.dashboard.bulstat.label' : 'wc.ecare.dashboard.idNumber.label')
                            | translate
                        }}: {{ customer.extId }})
                      </span>
                      <span app-acl-internal-evaluate-component class="customer_subject content-md">
                        {{ customer.subject.subjectType }}</span
                      >
                    </label>
                    <div class="input full">
                      {{ customer.subject.titleBefore }} {{ customer.subject.firstName }}
                      {{ customer.subject.middleName }} {{ customer.subject.lastName }}
                      <span *ngIf="customer.subject.titleAfter">, </span>{{ customer.subject.titleAfter }}
                    </div>
                  </div>
                  <div *ngIf="!isBusinessView" class="generated-input-container col3">
                    <label for="nationality">
                      {{ 'wc.ecare.dashboard.nationality.label' | translate }}
                    </label>
                    <div class="input full">
                      <span id="nationality">
                        {{ customer.subject.nationality ? customer.subject.nationality : '-' }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="!isBusinessView" class="generated-input-container col3">
                    <label for="birthDate">
                      {{ 'wc.ecare.dashboard.birthDate.label' | translate }}
                    </label>
                    <div class="input full">
                      <span id="birthDate">
                        {{ customer.subject.birthDate ? customer.subject.birthDate : '-' }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="!isBusinessView" class="generated-input-container col3">
                    <label for="identityNumber">
                      {{ 'wc.ecare.dashboard.identityNumber.label' | translate }}
                    </label>
                    <div class="input full">
                      <span id="identityNumber">
                        {{ customer.subject.identityNumber }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="!isBusinessView" class="generated-input-container col3">
                    <label for="identityCard">
                      {{ 'wc.ecare.customerDetails.identityCard' | translate }}
                    </label>
                    <div *ngIf="codebookService.getCodebookTexts('ID_TYPE') | async as codebook" class="input full">
                      <span *ngIf="customer.subject.idType" id="identityCard">
                        {{ codebook[customer.subject.idType] }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="!isBusinessView" class="generated-input-container col3">
                    <label for="issuingCountry">
                      {{ 'wc.ecare.customerDetails.issuingCountry' | translate }}
                    </label>
                    <div class="input full">
                      <span id="issuingCountry">
                        <ng-container *ngIf="customer.subject.idIssueAuthority; else noIdIssueAuthority">
                          <i [inlineSVG]="'/assets/svg/' + customer.subject.idIssueAuthority + '-flag-icon.svg'"> </i>
                          {{ customer.subject.idIssueAuthority }}
                        </ng-container>
                        <ng-template #noIdIssueAuthority>-</ng-template>
                      </span>
                    </div>
                  </div>
                  <div *ngIf="!isBusinessView" class="generated-input-container col3">
                    <label for="idCardNo">
                      {{ 'wc.ecare.customerDetails.idCardNo' | translate }}
                    </label>
                    <div class="input full">
                      <span id="idCardNo">
                        {{ customer.subject.idNo }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="!isBusinessView" class="generated-input-container col3">
                    <label for="idValidTo">
                      {{ 'wc.ecare.customerDetails.idValidTo' | translate }}
                    </label>
                    <div class="input full">
                      <span id="idValidTo">
                        {{ customer.subject.idValidTo }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="!isBusinessView" class="generated-input-container col3">
                    <label for="idVerified">
                      {{ 'wc.ecare.customerDetails.idVerified' | translate }}
                    </label>
                    <div class="input full">
                      <span id="idVerified">
                        {{
                          'wc.ecare.customerDetails.idVerified.' + (customer.subject.idVerified || false) | translate
                        }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="isBusinessView && subjectAttributesMap.get('taxNumber')" class="generated-input-container col3">
                    <label for="taxNumber">
                      {{ 'wc.shopping.customer.taxNumber.label' | translate }}
                    </label>
                    <div class="input full">
                      <span id="taxNumber">
                        {{ customer.subject.taxNumber }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="isBusinessView" class="generated-input-container col3">
                    <label for="lastName">
                      {{ 'wc.shopping.customer.name.label' | translate }}
                    </label>
                    <div class="input full">
                      <span id="lastName">
                        {{ customer.subject.lastName }}
                      </span>
                    </div>
                  </div>
                  <ng-container *ngIf="isBusinessView">
                    <div
                      *ngFor="let parameter of customer.subject.parameters | keyvalue"
                      class="generated-input-container col3">
                      <label [for]="parameter.key">
                        {{ 'wc.shopping.customer.' + parameter.key | translate }}
                      </label>
                      <div class="input full">
                        <span [id]="parameter.key">
                          <ng-container
                            *ngIf="parameter.value === 'true' || parameter.value === 'false'; else notBooleanValue">
                            <input
                              type="checkbox"
                              class="checkbox"
                              disabled
                              [checked]="parameter.value"
                              [id]="parameter.key + 'checkbox'" />
                            <label [for]="parameter.key + 'checkbox'"></label>
                          </ng-container>
                          <ng-template #notBooleanValue>
                            {{ parameter.value ? parameter.value : '-' }}
                          </ng-template>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="codebookService.getCodebookTexts('CUSTOMER_HEALTH') | async as codebook">
                    <div
                      *ngIf="customer.parameters['healthScore']"
                      app-acl-internal-evaluate-component
                      class="generated-input-container col3 customer_internal_card"
                      tooltipClass="ecare-settings-tooltip"
                      placement="bottom"
                      [ngbTooltip]="codebook[customer.parameters['healthScore']]">
                      <label for="healthStatus">
                        {{ 'wc.shopping.customer.customerDetails.healthScore' | translate }}
                      </label>
                      <div class="customer_internal_card_wrapper">
                        <div class="customer_health_status">
                          {{ codebook[customer.parameters['healthScore']] }}
                        </div>
                        <div class="customer-box__health-status {{ customer.parameters['healthScore'] }}"></div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <div class="customer-right-side mobile-fallback">
          <ng-container *ngTemplateOutlet="personalAndRegistration" />
        </div>
        <div [hidden]="!showCustomerContacts">
          <app-section-header
            [title]="'wc.ecare.contacts.customerContacts.header' | translate"
            [isCollapsed]="refCustomerContacts.activeIds.length > 0"
            (click)="customerContacts.toggle('toggleCustomerContacts')" />
          <ngb-accordion
            #customerContacts="ngbAccordion"
            #refCustomerContacts
            activeIds="toggleCustomerContacts"
            [destroyOnHide]="false">
            <ngb-panel id="toggleCustomerContacts">
              <ng-template ngbPanelContent>
                <div class="section-content">
                  <app-customer-contacts />
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        <div [hidden]="!formAgreementsComponent?.agreementsLoaded">
          <app-section-header
            [title]="'wc.ecare.accountSettings.agreements' | translate"
            [isCollapsed]="refAgreements.activeIds.length > 0"
            (click)="agreements.toggle('toggleAgreements')" />
          <ngb-accordion #agreements="ngbAccordion" #refAgreements activeIds="toggleAgreements" [destroyOnHide]="false">
            <ngb-panel id="toggleAgreements">
              <ng-template ngbPanelContent>
                <div class="section-content">
                  <app-form-agreements entityType="com.emeldi.ecc.be.crm.dto.Customer" [entityId]="customer.id" />
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
      <div right-side>
        <div class="customer-right-side">
          <ng-container *ngTemplateOutlet="personalAndRegistration" />
        </div>
      </div>
    </ng-container>
    <ng-template #editCustomer>
      <div [formGroup]="customerForm">
        <input id="001_customer_form" formControlName="id" type="hidden" />
        <input id="002_customer_form" formControlName="extId" type="hidden" />

        <app-customer-form
          #customerFormComponent
          [patchCustomer]="customer"
          [customerForm]="customerForm"
          [showHeader]="false"
          [columnsCount]="3"
          [checkFormVisibility]="false"
          [parentGroup]="customerForm"
          [checkEmailUniqueness]="!editMode"
          [initGenerateForm]="true"
          [formName]="createCustomer ? 'CreateCustomer' : null"
          (formGeneratedEmitter)="customerFormGenerated()" />

        <div *ngIf="customerForm.controls.parameters.controls.healthScore" app-acl-internal-evaluate-component>
          <app-ecare-section sectionTitle="{{ 'wc.shopping.customer.customerDetails.healthScore' | translate }}">
            <div class="health_statuses">
              <div class="internal_view" [formGroup]="customerForm.get('parameters')">
                <label
                  *ngFor="let customerHealth of customerHealths"
                  class="health_status {{
                    customerHealth.value === getHealthScoreFormGroup()?.value ? 'selected' : ''
                  }}">
                  <div class="top">
                    <input
                      class="cursor-pointer"
                      type="radio"
                      formControlName="healthScore"
                      id="{{ 'healthStatus.' + customerHealth.value }}"
                      [value]="customerHealth.value" />
                    <label
                      *ngIf="codebookService.getCodebookTexts('CUSTOMER_HEALTH') | async as codebook"
                      for="{{ 'healthStatus.' + customerHealth.value }}">
                      {{ codebook[customerHealth.value] }}
                    </label>
                    <i class="-icon" [inlineSVG]="'/assets/img/ecare/health_' + customerHealth.value + '.svg'"></i>
                  </div>
                  <div class="description">
                    {{ 'wc.ecare.codebook.CUSTOMER_HEALTH.' + customerHealth.value + '.description' | translate }}
                  </div>
                </label>
              </div>
            </div>
          </app-ecare-section>
        </div>

        <div *ngIf="!createCustomer" class="buttons no_margin">
          <app-button
            variant="primary"
            [iconPath]="'/assets/svg/save.svg'"
            label="{{ 'wc.ecare.opportunity.saveChanges' | translate }}"
            (click)="customerAccount ? saveCustomerAccount() : saveCustomer().subscribe()" />
        </div>
      </div>
    </ng-template>
  </app-ecare-page-layout>

  <ng-template #personalAndRegistration>
    <ng-container [ngTemplateOutlet]="contactTemplate" [ngTemplateOutletContext]="{ contact: customer.contact }" />
    <ng-container [ngTemplateOutlet]="addressTemplate" [ngTemplateOutletContext]="{ address: customer.address }" />
  </ng-template>
</ng-container>

<ng-container *ngIf="customerAccount">
  <app-ecare-page-layout
    *blockUI="blockUIElement.name; template: blockTemplate"
    [columnTemplate]="editMode ? '1fr' : '2fr 1fr'"
    [title]="
      !editMode
        ? ('wc.ecare.customerAccountDetails.headline' | translate)
        : ('wc.ecare.editCustomerAccount.headline' | translate)
    ">
    <ng-container *ngIf="!createCustomer" page-actions>
      <ng-container *ngIf="!editMode && !createCustomer; else editActions">
        <app-acl-internal-evaluate-component>
          <app-button
            *ngIf="customer.state === 'CREATED'"
            variant="whiteWithBorder"
            iconPath="/assets/img/ecare/deactivate.svg"
            label="{{ 'wc.ecare.customerDetails.deactivate' | translate }}"
            (click)="deactivate()" />
        </app-acl-internal-evaluate-component>
        <app-acl-internal-evaluate-component>
          <app-button
            variant="whiteWithBorder"
            iconPath="/assets/svg/edit.svg"
            label="{{ 'wc.ecare.customerDetails.editCustomer' | translate }}"
            (click)="edit()" />
        </app-acl-internal-evaluate-component>
        <app-acl-internal-evaluate-component *ngIf="customer.state === 'CANCELED'">
          <app-button
            variant="secondary"
            iconPath="/assets/img/ecare/anonymize.svg"
            label="{{ 'wc.ecare.customerDetails.anonymize' | translate }}"
            (click)="edit()" />
        </app-acl-internal-evaluate-component>
        <app-button
          *ngIf="customer.state === 'CREATED'"
          variant="primary"
          iconPath="assets/img/ecare/feather-plus.svg"
          label="{{ 'wc.ecare.dashboard.createCa.link' | translate }}"
          [routerLink]="['/ecare/dashboard/' + customer.id + '/-/new-ca']" />
      </ng-container>
      <ng-template #editActions>
        <app-button variant="secondary" label="Cancel" (click)="backEvent.emit(true); edit()" />
        <app-button
          variant="primary"
          [iconPath]="createCustomer ? '/assets/img/ecare/feather-plus.svg' : '/assets/svg/save.svg'"
          [label]="
            (createCustomer ? 'wc.ecare.homepage.createCustomer' : 'wc.ecare.opportunity.saveChanges') | translate
          "
          (click)="customerAccount ? saveCustomerAccount() : saveCustomer()" />
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!editMode && !createCustomer; else editCustomerAccount">
      <div left-side>
        <app-section-header
          [title]="'wc.ecare.customerDetails.registrationDetails' | translate"
          [isCollapsed]="refRegistrationDetails.activeIds.length > 0"
          (click)="registrationDetails.toggle('toggleRegistrationDetails')" />
        <ngb-accordion
          #registrationDetails="ngbAccordion"
          #refRegistrationDetails
          activeIds="toggleRegistrationDetails"
          [destroyOnHide]="false">
          <ngb-panel id="toggleRegistrationDetails">
            <ng-template ngbPanelContent>
              <div class="section-content row-gap-10">
                <div class="generated-form">
                  <div
                    app-acl-internal-evaluate-component
                    class="generated-input-container col3 customer_internal_card">
                    <label for="identification">
                      {{ 'wc.ecare.dashboard.identification.label' | translate }}
                    </label>
                    <div class="input full customer_internal_card_wrapper">
                      <span id="identification">
                        {{ customerAccount.id }}
                      </span>
                      <i
                        aria-hidden="true"
                        tooltipClass="ecare-settings-tooltip"
                        placement="bottom"
                        inlineSVG="assets/img/ecare/copy_icon.svg"
                        [cdkCopyToClipboard]="customerAccount.id"
                        [ngbTooltip]="'wc.ecare.customerDetails.copy' | translate">
                      </i>
                    </div>
                  </div>
                  <div class="generated-input-container col3">
                    <label for="displayName">
                      {{ 'wc.ecare.accountSettings.displayName' | translate }}
                    </label>
                    <div class="input full">
                      <span id="displayName">
                        {{ customerAccount.displayName ? customerAccount.displayName : '-' }}
                      </span>
                    </div>
                  </div>
                  <div *ngFor="let parameter of customerAccountAttributes" class="generated-input-container col3">
                    <label [for]="parameter.name">
                      {{ getAttributeLabel(parameter) }}
                    </label>
                    <div class="input full">
                      <span [id]="parameter.name" [ngSwitch]="parameter.type">
                        <ng-container *ngSwitchCase="'BOOLEAN'">
                          <input
                            type="checkbox"
                            class="checkbox"
                            [checked]="customerAccount.parameters[parameter]"
                            [id]="parameter.name + 'checkbox'" />
                          <label [for]="parameter.name + 'checkbox'"></label>
                        </ng-container>
                        <ng-container *ngSwitchCase="'CODEBOOK'">
                          <ng-container *ngIf="codebookService.getCodebookTexts(parameter.typeDetail) | async as codebook">
                            {{ codebook[customerAccount.parameters[parameter.name]] ? codebook[customerAccount.parameters[parameter.name]] : customerAccount.parameters[parameter.name] }}
                          </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          {{
                            customerAccount.parameters[parameter.name]
                              ? customerAccount.parameters[parameter.name]
                              : '-'
                          }}
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <div class="customer-right-side mobile-fallback">
          <ng-container *ngTemplateOutlet="invoicingAndBilling" />
        </div>
      </div>
      <div right-side>
        <div class="customer-right-side">
          <ng-container *ngTemplateOutlet="invoicingAndBilling" />
        </div>
      </div>
    </ng-container>
    <ng-template #editCustomerAccount>
      <div [formGroup]="customerForm">
        <input id="001_customer_form" formControlName="id" type="hidden" />
        <input id="002_customer_form" formControlName="extId" type="hidden" />

        <app-customer-account-form
          #customerAccountFormComponent
          [entityGenerateForm]="true"
          [customerAccountForm]="customerAccountForm"
          [showHeader]="false"
          [visible]="true"
          [hideExistingCaList]="true"
          [parentGroup]="customerAccountForm"
          (formGeneratedEmitter)="customerAccountFormGenerated()" />

        <div *ngIf="!createCustomer" class="buttons no_margin">
          <app-button
            variant="primary"
            [iconPath]="'/assets/svg/save.svg'"
            [label]="'wc.ecare.opportunity.saveChanges' | translate"
            (click)="customerAccount ? saveCustomerAccount() : saveCustomer()" />
        </div>
      </div>
    </ng-template>
  </app-ecare-page-layout>

  <ng-template #invoicingAndBilling>
    <ng-container
      [ngTemplateOutlet]="contactTemplate"
      [ngTemplateOutletContext]="{
        contact: customerAccount.invoicingContact ? customerAccount.invoicingContact : customer.contact,
        type: 'INVOICING'
      }" />
    <ng-container
      [ngTemplateOutlet]="addressTemplate"
      [ngTemplateOutletContext]="{ address: customerAccount.address }" />
    <ng-container
      [ngTemplateOutlet]="contactTemplate"
      [ngTemplateOutletContext]="{
        contact: customerAccount.billingContact ? customerAccount.billingContact : customer.contact,
        type: 'BILLING'
      }" />
  </ng-template>
</ng-container>

<ng-template #contactTemplate let-contact="contact" let-type="type">
  <div *ngIf="contact">
    <app-section-header
      [title]="'wc.shopping.contact.' + (type ? type : contact.type) + '.heading' | translate"
      [isCollapsed]="refPrimaryContact.activeIds.length > 0"
      (click)="primaryContact.toggle('togglePrimaryContact')" />
    <ngb-accordion
      #primaryContact="ngbAccordion"
      #refPrimaryContact
      activeIds="togglePrimaryContact"
      [destroyOnHide]="false">
      <ngb-panel id="togglePrimaryContact">
        <ng-template ngbPanelContent>
          <div class="section-content">
            <div class="sidebox-row-wrapper">
              <div class="sidebox-label">
                {{ 'wc.ecare.dashboard.primaryContact.fullName' | translate }}
              </div>
              <div class="sidebox-value">{{ contact?.firstName }} {{ contact?.lastName }}</div>
            </div>
            <div class="sidebox-row-wrapper">
              <div class="sidebox-label">
                {{ 'wc.ecare.dashboard.primaryContact.email.label' | translate }}
              </div>
              <div class="sidebox-value">
                <ng-container *ngIf="contact?.email; else noEmail">
                  <i [inlineSVG]="'/assets/img/ecare/mail.svg'"></i>
                  {{ contact?.email }}
                </ng-container>
                <ng-template #noEmail>-</ng-template>
              </div>
            </div>
            <div class="sidebox-row-wrapper">
              <div class="sidebox-label">
                {{ 'wc.ecare.dashboard.primaryContact.phone.label' | translate }}
              </div>
              <div class="sidebox-value">
                <ng-container *ngIf="contact?.phone1; else noContactPhone">
                  <!-- todo: set correct icon preferred language (probably customer.contact.language ??)  -->
                  <i [inlineSVG]="'/assets/svg/CZE-flag-icon.svg'"></i>

                  <div class="prefferedChannel">
                    <div>{{ contact?.phone1 | phoneNumber }}</div>
                    <div *ngIf="['SMS', 'ALL'].includes(contact?.preferredChannel || '')">
                      <i
                        tooltipClass="ecare-settings-tooltip"
                        placement="bottom"
                        [ngbTooltip]="'wc.ecare.customerDetails.preferredChannel' | translate"
                        [inlineSVG]="'/assets/svg/awesome-star-of-life.svg'">
                      </i>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noContactPhone>-</ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</ng-template>

<ng-template #addressTemplate let-address="address" let-type="type">
  <div *ngIf="address">
    <app-section-header
      [title]="'wc.shopping.address.' + (type ? type : address.type) + '.heading' | translate"
      [isCollapsed]="refRegistrationAddress.activeIds.length > 0"
      (click)="registrationAddress.toggle('toggleRegistrationAddress')" />
    <ngb-accordion
      #registrationAddress="ngbAccordion"
      #refRegistrationAddress
      activeIds="toggleRegistrationAddress"
      [destroyOnHide]="false">
      <ngb-panel id="toggleRegistrationAddress">
        <ng-template ngbPanelContent>
          <div class="section-content">
            <div *ngIf="isAttributeVisible('country')" class="sidebox-row-wrapper">
              <div class="sidebox-label">
                {{ 'wc.ecare.dashboard.address.country.label' | translate }}
              </div>
              <div *ngIf="codebookService.getCodebookTexts('COUNTRY') | async as codebook" class="sidebox-value">
                <ng-container *ngIf="address.country; else noAddressCountry">
                  <ng-container *ngIf="countryFromCodebook; else textCountry">
                    <i [inlineSVG]="'/assets/svg/' + address.country + '-flag-icon.svg'"></i>
                    {{ codebook[address.country] }}
                  </ng-container>
                  <ng-template #textCountry>{{ address.country }}</ng-template>
                </ng-container>
                <ng-template #noAddressCountry>-</ng-template>
              </div>
            </div>
            <div *ngIf="isAttributeVisible('city')" class="sidebox-row-wrapper">
              <div class="sidebox-label">
                {{ 'wc.ecare.dashboard.address.city.label' | translate }}
              </div>
              <div class="sidebox-value">
                <ng-container *ngIf="address.city; else noAddressCity">
                  <ng-container *ngIf="cityFromCodebook; else textCity">
                    {{ 'codebook.codebook.CITY.' + address.city | translate }}
                  </ng-container>
                  <ng-template #textCity>{{ address.city }}</ng-template>
                </ng-container>
                <ng-template #noAddressCity>-</ng-template>
              </div>
            </div>
            <div *ngIf="isAttributeVisible('district')" class="sidebox-row-wrapper">
              <div class="sidebox-label">
                {{ 'wc.ecare.dashboard.address.district.label' | translate }}
              </div>
              <div class="sidebox-value">
                {{ address.district || '-' }}
              </div>
            </div>
            <div *ngIf="isAttributeVisible('zipCode')" class="sidebox-row-wrapper">
              <div class="sidebox-label">
                {{ 'wc.ecare.dashboard.address.zip.label' | translate }}
              </div>
              <div class="sidebox-value">
                {{ address.zipCode || '-' }}
              </div>
            </div>
            <div *ngIf="isAttributeVisible('street')" class="sidebox-row-wrapper">
              <div class="sidebox-label">
                {{ 'wc.ecare.dashboard.address.street.label' | translate }}
              </div>
              <div class="sidebox-value">
                {{ address.street || '-' }}
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</ng-template>
