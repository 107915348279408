import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressFormComponent } from 'app/components/page/address-form/address-form.component';
import { ContactFormComponent } from 'app/components/page/contact-form/contact-form.component';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import _ from 'lodash';
import { CustomerPartyUtil } from 'app/helpers/customer-party.util';
import { FormUtils } from 'app/helpers/form-utils';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { Subject, Subscription } from 'rxjs';
import { BlockUIService } from 'ng-block-ui';
import { ExternalValue } from '../../entity-generated-form/entity-generated-form.component';
import { CodebookService, CurrentLocaleService, ServiceUtils } from '@btl/btl-fe-wc-common';
import {
  AddressDto,
  BillCycleSpecificationDto,
  CodebookDto,
  ContactDto,
  CustomerAccountDto,
  CustomerDto
} from '@btl/order-bff';
import { takeUntil } from 'rxjs/operators';
import { CustomerService } from '@service/customer.service';

@Component({
  selector: "app-customer-account-form",
  templateUrl: "./customer-account-form.component.html",
})
export class CustomerAccountFormComponent implements OnInit, OnDestroy {
  @ViewChild("invoicingAddressForm", { static: true })
  addressFormComponent: AddressFormComponent;
  @ViewChild("invoicingContactForm", { static: true })
  invoicingContactFormComponent: ContactFormComponent;
  @ViewChild("billingContactForm", { static: true })
  billingContactFormComponent: ContactFormComponent;

  readonly AddressTypeEnum = AddressDto.TypeDtoEnum;
  readonly ContactTypeEnum = ContactDto.TypeDtoEnum;

  billMediums = ["EBILL_PDF", "EBILL_XML", "PRINT"];

  billingCycles: Array<BillCycleSpecificationDto>;

  paymentTypes: Array<CodebookDto>;

  defaultDueDate: number;

  defaultDueDateSubscription: Subscription;

  @Input() parentGroup: FormGroup;

  @Input() customerAccountForm: FormGroup;

  @Input() translationPrefix: string;

  visibleStyle: string = "none"; //TP2-48

  @Input() visible = false;

  disabled = false;

  @Input() hideExistingCaList = false;

  @Input() showHeader = true;

  @Output()
  readonly formGeneratedEmitter = new EventEmitter<void>();

  oldPaymentType = null;
  oldPaymentMethod = null;

  @Input() entityGenerateForm = false;
  patchCustomerAccount;

  billMediasExternalValues: Array<ExternalValue>;
  billCyclesExternalValues: Array<ExternalValue>;
  paymentMethodsExternalValues: Array<ExternalValue>;
  lastPaymentTypeId: string;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private readonly customerLocalStorageService: CustomerLocalStorageService,
    private shoppingCartService: ShoppingCartService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private readonly customerService: CustomerService,
    private blockUIService: BlockUIService,
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService
  ) {}

  disable(disable: boolean, excluds: string[]) {
    FormUtils.disableEnableForm(this.customerAccountForm, disable, excluds);
    this.invoicingContactFormComponent.disable(disable);
    this.billingContactFormComponent.disable(disable);
    this.addressFormComponent.disable(disable);
    this.disabled = disable ? true : false;
  }

  public ngOnInit() {
    this.billMediasExternalValues = this.getBillMediasExternalValues();

    this.currentLocaleService.currentLocaleChange
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.getPaymentTypes());
    this.getPaymentTypes();
    this.propertyAccessorLocalService
      .isModuleBffCrmEnabled()
      .subscribe((bffCrmEnabled) => {
        if (bffCrmEnabled) {
          this.getBillingCycles();
        } else {
          this.billingCycles = [];
          this.billCyclesExternalValues = this.getBillCyclesExternalValues();
        }
      });

    this.getDefaultDueDate();
  }

  getPaymentTypes() {
    this.codebookService
      .getCodebooks("PAYMENT_TYPE")
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.paymentTypes = result;
      });
  }

  getBillingCycles(): void {
    this.customerService
      .getBillCycles(ServiceUtils.getUnlimitedSearch())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(billCycleSpecificationsDto => {
        if (billCycleSpecificationsDto) {
          this.billingCycles = billCycleSpecificationsDto.billCycleSpecificationList;
          this.billCyclesExternalValues = this.getBillCyclesExternalValues();
        } else {
          this.billingCycles = [];
        }
      });
  }

  getBillCyclesExternalValues(): Array<ExternalValue> {
    return this.billingCycles.map((billingCycle) => {
      return {
        value: billingCycle.name,
        labelKey: `wc.shopping.customerAccount.billingCycle.${billingCycle.name}.label`,
      };
    });
  }

  getPaymentMethodsExternalValues(): Array<ExternalValue> {
    const paymentTypeCb = this.paymentTypes.find(
      (cd) => cd.code === this.getCurrentPaymentType()
    );

    if (this.lastPaymentTypeId !== paymentTypeCb?.id) {
      this.paymentMethodsExternalValues = this.paymentTypes
        .filter((cb) => paymentTypeCb && cb.parentId === paymentTypeCb.id)
        .map((pm) => {
          return {
            value: pm.code,
            label: pm.localizedTexts.find(
              (lt) =>
                lt.locale === this.currentLocaleService.getCurrentLanguage()
            )?.text,
          };
        });
    }

    this.lastPaymentTypeId = paymentTypeCb?.id;
    return this.paymentMethodsExternalValues;
  }

  getBillMediasExternalValues(): Array<ExternalValue> {
    return this.billMediums.map((bm) => {
      return {
        value: bm,
        labelKey: `wc.shopping.customerAccount.billMedium.${bm}.label`,
      };
    });
  }

  getCustomerAccountGroupControl(): FormGroup {
    this.customerAccountForm = this.fb.group({
      address: this.fb.group(AddressFormComponent.getBaseFormConfiguration()),
      allowInvoicingContact: false,
      invoicingContact: this.fb.group(
        ContactFormComponent.getBaseFormConfiguration()
      ),
      allowBillingContact: false,
      billingContact: this.fb.group(
        ContactFormComponent.getBaseFormConfiguration()
      ),
      id: null,
      displayName: null,
      extId: null,
      parentId: null,
      role: "CustomerAccount",
      state: "CREATED",
    });

    return this.customerAccountForm;
  }

  public static getBaseFormConfiguration() {
    return {
      address: AddressFormComponent.getBaseFormConfiguration(),
      allowInvoicingContact: false,
      invoicingContact: ContactFormComponent.getBaseFormConfiguration(),
      allowBillingContact: false,
      billingContact: ContactFormComponent.getBaseFormConfiguration(),
      id: null,
      displayName: null,
      extId: null,
      parentId: null,
      role: "CustomerAccount",
      state: "CREATED",
    };
  }

  customerAccountPartyChanged($event) {
    const partyId = $event;
    let customerAccount;

    if (partyId) {
      const customerAccounts =
        this.customerLocalStorageService.getActiveCustomerAccounts();
      customerAccounts.forEach((loopCustomerAccount) => {
        if (loopCustomerAccount.id === partyId) {
          customerAccount =
            CustomerPartyUtil.getFormCustomerAccount(loopCustomerAccount);
        }
      });
    }

    if (!customerAccount) {
      customerAccount = CustomerPartyUtil.getDefaultCustomerAccount();
    }

    FormUtils.disableEnableForm(this.customerAccountForm, partyId, ["id"]);
    if (!customerAccount.address) {
      customerAccount.address = CustomerPartyUtil.getDefaultAddress(
        AddressDto.TypeDtoEnum.INVOICING
      );
    }
    if (!customerAccount.invoicingContact) {
      customerAccount.invoicingContact = CustomerPartyUtil.getDefaultContact(
        ContactDto.TypeDtoEnum.INVOICING
      );
    }
    if (!customerAccount.billingContact) {
      customerAccount.billingContact = CustomerPartyUtil.getDefaultContact(
        ContactDto.TypeDtoEnum.BILLING
      );
    }

    delete customerAccount.id;

    if (!customerAccount.dueDate) {
      this.getDefaultDueDate();
    }
    this.customerAccountForm.patchValue(customerAccount);
    this.patchCustomerAccount = customerAccount;
  }

  isCustomerAccountsDropdownVisible(): boolean {
    return (
      !_.isNil(this.customerLocalStorageService.getCurrentCustomer()) &&
      this.shoppingCartService.preCalculatedShoppingCart.allRootOrderItemsAdding()
    );
  }

  getAllCustomerAccounts(): CustomerAccountDto[] {
    return this.customerLocalStorageService.getActiveCustomerAccounts();
  }

  clearField(field: string) {
    const control = this.customerAccountForm.get(field);
    FormUtils.setValidation(control, null);
    control.setValue(null);
  }

  allowContactChanged(
    $event: boolean,
    contactTypeEnum: ContactDto.TypeDtoEnum
  ) {
    if (ContactDto.TypeDtoEnum.INVOICING === contactTypeEnum) {
      this.customerAccountForm.get("allowInvoicingContact").setValue($event);
    } else if (ContactDto.TypeDtoEnum.BILLING === contactTypeEnum) {
      this.customerAccountForm.get("allowBillingContact").setValue($event);
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();

    if (this.defaultDueDateSubscription) {
      this.defaultDueDateSubscription.unsubscribe();
    }
  }

  private getDefaultDueDate(): void {
    this.blockUIService.start("main");
    this.defaultDueDateSubscription = this.propertyAccessorLocalService
      .getDefaultDueDate()
      .subscribe((dueDate) => {
        this.defaultDueDate = dueDate;
        this.blockUIService.stop("main");
      });
  }

  public generateForm(customerAccount) {
    this.patchCustomerAccount = customerAccount;
    this.entityGenerateForm = true;
    return this.formGeneratedEmitter;
  }

  customerAccountFormGenerated() {
    if (this.patchCustomerAccount) {
      this.customerAccountForm.patchValue(this.patchCustomerAccount);
    }
    FormUtils.disableEnableForm(
      this.customerAccountForm,
      this.customerAccountForm.value.id && !this.hideExistingCaList,
      ["id"]
    );
    this.patchCustomerAccount = null;
    this.formGeneratedEmitter.emit();
  }

  getCurrentPaymentType() {
    let customerAccount = this.patchCustomerAccount;
    if (!customerAccount?.parameters["paymentType"]) {
      customerAccount = this.customerAccountForm.getRawValue();
    }
    if (!customerAccount?.parameters) {
      return this.oldPaymentType;
    }

    this.oldPaymentType = customerAccount?.parameters["paymentType"];
    return this.oldPaymentType;
  }

  getCurrentPaymentMethod() {
    let customerAccount = this.patchCustomerAccount;
    if (!customerAccount) {
      customerAccount = this.customerAccountForm.getRawValue();
    }
    if (
      !customerAccount?.parameters ||
      !customerAccount?.parameters["paymentMethod"]
    ) {
      return this.oldPaymentMethod;
    }

    this.oldPaymentMethod = customerAccount?.parameters["paymentMethod"];
    return this.oldPaymentMethod;
  }

  getFormGroup(name): FormGroup {
    return this.customerAccountForm.get(name) as FormGroup;
  }

  initContactsAndAddreses(
    customerAccount: CustomerAccountDto,
    customer: CustomerDto
  ) {
    if (customerAccount) {
      if (!customerAccount.address) {
        this.addressFormComponent.inputMode = false;
        customerAccount.address = CustomerPartyUtil.getDefaultAddress(
          AddressDto.TypeDtoEnum.INVOICING
        );
      } else {
        if (
          CustomerPartyUtil.equalsWithExclude(
            customer.address,
            customerAccount.address,
            "type",
            "id",
            "parameters"
          )
        ) {
          this.addressFormComponent.inputMode = false;
          this.addressFormComponent.setSameAs(true);
        } else {
          this.addressFormComponent.inputMode = true;
          this.addressFormComponent.setSameAs(false);
        }
      }

      const customerContact: ContactDto = customer.contact;
      if (!customerAccount.invoicingContact) {
        this.invoicingContactFormComponent.inputMode = false;
        this.invoicingContactFormComponent.allowContact = false;
        customerAccount.invoicingContact = CustomerPartyUtil.getDefaultContact(
          ContactDto.TypeDtoEnum.INVOICING
        );
      } else {
        if (
          CustomerPartyUtil.equalsWithExclude(
            customerContact,
            customerAccount.invoicingContact,
            "type",
            "id",
            "parameters"
          )
        ) {
          this.invoicingContactFormComponent.inputMode = false;
          this.invoicingContactFormComponent.allowContact = true;
        } else {
          this.invoicingContactFormComponent.inputMode = true;
          this.invoicingContactFormComponent.allowContact = true;
        }
      }

      if (!customerAccount.billingContact) {
        this.billingContactFormComponent.inputMode = false;
        this.billingContactFormComponent.allowContact = false;
        customerAccount.billingContact = CustomerPartyUtil.getDefaultContact(
          ContactDto.TypeDtoEnum.BILLING
        );
      } else {
        if (
          CustomerPartyUtil.equalsWithExclude(
            customerContact,
            customerAccount.billingContact,
            "type",
            "id",
            "parameters"
          )
        ) {
          this.billingContactFormComponent.inputMode = false;
          this.billingContactFormComponent.allowContact = true;
        } else {
          this.billingContactFormComponent.inputMode = true;
          this.billingContactFormComponent.allowContact = true;
        }
      }
    } else {
      this.addressFormComponent.inputMode = false;
      this.invoicingContactFormComponent.inputMode = false;
      this.billingContactFormComponent.inputMode = false;
    }
  }

  getFormCustomerAccount(customer: CustomerDto): CustomerAccountDto {
    const contacts: Array<ContactDto> = [];
    let customerAccount = this.customerAccountForm.getRawValue();
    if (!this.addressFormComponent.inputMode) {
      CustomerPartyUtil.copyWithExclude(
        customer.address,
        customerAccount.address,
        "type",
        "id"
      );
    }

    if (!this.customerAccountForm.get("allowInvoicingContact").value) {
      customerAccount.invoicingContact = null;
    } else if (!this.invoicingContactFormComponent.inputMode) {
      CustomerPartyUtil.copyWithExclude(
        customer.contact,
        customerAccount.invoicingContact,
        "type",
        "id"
      );
    }

    if (customerAccount.invoicingContact) {
      contacts.push(customerAccount.invoicingContact);
    }

    if (!this.customerAccountForm.get("allowBillingContact").value) {
      customerAccount.billingContact = null;
    } else if (!this.billingContactFormComponent.inputMode) {
      CustomerPartyUtil.copyWithExclude(
        customer.contact,
        customerAccount.billingContact,
        "type",
        "id"
      );
    }

    if (customerAccount.billingContact) {
      contacts.push(customerAccount.billingContact);
    }

    customerAccount["contacts"] = contacts;
    delete customerAccount.invoicingContact;
    delete customerAccount.billingContact;
    delete customerAccount.allowInvoicingContact;
    delete customerAccount.allowBillingContact;

    return customerAccount;
  }
}
