import { Component, Input } from '@angular/core';
import {
  AddressDto,
  ContactDto,
  CustomerAccountDto,
  CustomerDto,
  TicketDto,
  TicketTypeDto,
  TicketTypeParamDto
} from '@btl/order-bff';
import {
  AbstractPageComponent,
  AppBlockerService,
  AuthService,
  BlockTemplateComponent,
  CompareType,
  DmsService,
  ServiceUtils,
  StickyMessageService,
  TicketingService
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { TicketService } from '@service/ticket.service';
import { TicketEditComponent } from './edit/ticket-edit.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalFactoryService } from '@service/modal-factory.service';
import { CreateTicketModalComponent } from './create-ticket-modal/create-ticket-modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup } from '@angular/forms';
import { PickerInputType } from '../../form-field-modal-picker/form-field-modal-picker.component';
import {
  ContactEditPopupComponent
} from '../ecare-user-account/user-account-contacts/contact-edit-popup/contact-edit-popup.component';
import { Attachment } from '../../form-attachments/form-attachments.component';
import { CustomerService } from '@service/customer.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { forkJoin } from 'rxjs';
import {
  AddressEditPopupComponent
} from '../ecare-user-account/user-account-contacts/address-edit-popup/address-edit-popup.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-abstract-ticket',
  template: '',
})
export abstract class AbstractTicketComponent extends AbstractPageComponent {
  pageId(): string {
    return '';
  }

  @Input() ticket: TicketDto;
  @Input() ticketTypeCode;
  @Input() relatedTicketId;
  @Input() cuRefNo: string;
  @Input() caRefNo: string;
  @Input() cancelCallback: () => void;
  @Input() saveCallback: (ticketDto: TicketDto) => void;

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  visibleParameters = new Map<string, TicketTypeParamDto>();
  allParameters = new Map<string, TicketTypeParamDto>();
  relatedTasks: Array<TicketDto> = [];
  relatedTicketsByCode = new Map<string, Array<TicketDto>>();
  supportEditMode = false;
  editMode = true;
  newTicket: boolean = false;
  pickerInputType = PickerInputType;
  duplicationId;
  forDuplication;
  authService: AuthService;
  backUrl: string = null;
  listingComponentName: string = 'EcareTasksComponent';
  form: FormGroup;
  ticketType: TicketTypeDto;
  notEditSaveAllowedFieldsNames: string[] = [];

  contactTypesEnum = ContactDto.TypeDtoEnum;
  addressTypesEnum = AddressDto.TypeDtoEnum;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected ngModal: NgbModal,
    protected appBlockerService: AppBlockerService,
    protected ticketingService: TicketingService,
    protected stickyMessageService: StickyMessageService,
    protected modalFactory: ModalFactoryService,
    protected customerService: CustomerService,
    protected customerLocalStorageService: CustomerLocalStorageService,
    protected dmsService: DmsService
  ) {
    super(router, route);
    this.getQueryParams();
  }

  abstract reloadTicket(ticket: TicketDto): void;
  abstract loadTicket(ticketId): void;

  getQueryParams(): any {
    this.route.queryParams.subscribe(queryParams => {
      this.ticketTypeCode = queryParams['code'];
      this.forDuplication = queryParams['forDuplication'];
      this.duplicationId = queryParams['duplicationId'];
      this.relatedTicketId = queryParams['relatedTicketId'];
      this.cuRefNo = queryParams['cuRefNo'];
      this.caRefNo = queryParams['caRefNo'];
      this.backUrl = queryParams['backUrl'];
    });
  }

  addQueryParametersConfiguration(ticket: TicketDto) {
    if (this.relatedTicketId) {
      TicketService.addReference(ticket, 'com.emeldi.ecc.be.ticket.dto.Ticket', this.relatedTicketId);
    }
    if (this.cuRefNo) {
      TicketService.addReference(ticket, 'com.emeldi.ecc.be.crm.dto.Customer', this.cuRefNo);
    }
    if (this.caRefNo) {
      TicketService.addReference(ticket, 'com.emeldi.ecc.be.crm.dto.CustomerAccount', this.caRefNo);
    }
  }

  removeParameterFromUrl(paramKey: string) {
    const queryParams = { ...this.route.snapshot.queryParams };
    delete queryParams[paramKey];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }

  saveRelatedTicketReturn(ticketDto: TicketDto) {
    TicketService.addReference(ticketDto, 'com.emeldi.ecc.be.ticket.dto.Ticket', this.relatedTicketId);

    const id = ticketDto.id;
    ticketDto.id = null;
    TicketService.clearFieldsBeforeUpdate(ticketDto);
    this.ticketingService
      .updateTicket(id, ticketDto)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(savedTicket => {
        this.relatedTicketId = null;
        this.removeParameterFromUrl('relatedTicketId');
        this.reloadTicket(savedTicket);
      });
  }

  addRelatedTask(ticketCode?: string) {
    if (ticketCode) {
      this.navigateModuleByCode(this.getModuleIdTicketEditByCode(ticketCode), {
        id: 'newTicket',
        code: ticketCode,
        cuRefNo: this.ticket?.references?.find(
          reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.Customer'
        )?.entityId,
        caRefNo: this.ticket?.references?.find(
          reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.CustomerAccount'
        )?.entityId,
        relatedTicketId: this.ticket.id,
        backUrl: this.router.url.split('?')[0],
      });
    } else {
      const modalRef = this.ngModal.open(CreateTicketModalComponent);
      const createTicketModalComponent = <CreateTicketModalComponent>modalRef.componentInstance;
      createTicketModalComponent.dialogRef = modalRef;
      createTicketModalComponent.createTicketEvent.subscribe(ticketForm => {
        if (ticketForm) {
          this.navigateSibling('TicketEditComponent', {
            id: 'newTicket',
            code: ticketForm.code,
            cuRefNo: this.ticket?.references?.find(
              reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.Customer'
            )?.entityId,
            caRefNo: this.ticket?.references?.find(
              reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.CustomerAccount'
            )?.entityId,
            relatedTicketId: this.ticket.id,
            backUrl: this.router.url.split('?')[0],
          });
        }
      });
    }
  }

  getModuleIdTicketEditByCode(code: string) {
    if (this.relatedTicketsByCode.has(code)) {
      return `${code}_EDIT`;
    } else {
      return `TASKS_EDIT`;
    }
  }

  getModuleIdTicketListingByCode(code: string) {
    if (this.relatedTicketsByCode.has(code)) {
      return `${code}_LISTING`;
    } else {
      return `TASKS`;
    }
  }

  goToRelatedTicket = (ticket: TicketDto, inNewTab: boolean = false) => {
    const params = {
      id: ticket.id,
      //backUrl: this.router.url.split('?')[0]
    };
    if (inNewTab) {
      window.open(
        this.router.serializeUrl(
          this.moduleByCodeUrlTree(this.getModuleIdTicketEditByCode(ticket.type.code), params)
        ),
        '_blank'
      );
    } else {
      this.navigateModuleByCode(this.getModuleIdTicketEditByCode(ticket.type.code), params);
    }
  };

  deleteTicketRelation = (relatedTicket: TicketDto) => {
    this.relatedTicketsByCode.set(relatedTicket.type.code, this.relatedTicketsByCode.get(relatedTicket.type.code).filter(ticket => ticket.id !== relatedTicket.id));
    this.relatedTasks = this.relatedTasks.filter(task => task.id !== relatedTicket.id);

    this.ticket.references = this.ticket.references.filter(
      reference =>
        reference.entityType !== 'com.emeldi.ecc.be.ticket.dto.Ticket' || reference.entityId !== relatedTicket.id
    );

    if (!this.editMode) {
      this.updateIfNotEdit();
    }
  };

  protected updateIfNotEdit(fieldName?: string) {
    if (!this.editMode && !this.newTicket && (!fieldName || this.notEditSaveAllowedFieldsNames.find(loopFieldName => loopFieldName === fieldName))) {
      this.saveChanges(true);
    }
  }

  protected saveChanges(skipFormValidation = false){}

  protected saveTicket() {
    const id = this.ticket.id;
    if (id) {
      this.ticket.id = null;
      TicketService.clearFieldsBeforeUpdate(this.ticket);

      this.ticketingService
        .updateTicket(id, this.ticket)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => this.saveFiles(result));
    }
  }

  edit() {
    this.editMode = true;
    this.form.enable();
    this.checkNewContact();
  }

  afterSaveSuccess(ticket: TicketDto) {
    if (this.saveCallback) {
      this.saveCallback(ticket);
    } else if (this.backUrl) {
      let extras = {};
      if (this.relatedTicketId) {
        extras = {
          queryParams: {
            relatedTicketId: ticket.id,
          },
        };
      }
      this.router.navigate([this.backUrl], extras);
    } else {
      if (this.newTicket) {
        this.newTicket = false;
        this.navigateSelf({
          id: ticket.id,
        });
      } else {
        this.editMode = false;
        this.reloadTicket(ticket);
      }
    }
  }

  editModeCancel() {
    this.form.reset();
    if (this.ticket.id) {
      this.loadTicket(this.ticket.id);
    }
    this.editMode = false;
    this.checkNewContact();
  }

  cancel() {
    if (this.cancelCallback) {
      this.cancelCallback();
    } else if (this.backUrl) {
      this.router.navigate([this.backUrl]);
    } else {
      if (!this.supportEditMode || this.newTicket) {
        this.navigateSibling(this.listingComponentName);
      } else {
        this.editModeCancel();
      }
    }
  }

  deleteTicket() {
    this.modalFactory.deleteEntityModal((dialogReference: NgbModalRef) => {
      this.ticketingService
        .deleteTicket(this.ticket.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.tickets.ticketDeleted');
          this.navigateSibling(this.listingComponentName, { cuRefNo: this.cuRefNo, caRefNo: this.caRefNo });
        });
    });
  }

  discardChanges() {
    this.modalFactory.discardChangesModal(
      dialogReference => {
        this.form.reset();
        if (this.ticket.id) {
          this.loadTicket(this.ticket.id);
        }
        dialogReference.dismiss();
      },
      dialogReference => {
        dialogReference.dismiss();
      }
    );
  }

  fieldChanged(paramName: string, value: any) {}

  selectedContactIndex = -1;
  contacts: ContactDto[] = [];
  newContactForm = false;
  customer: CustomerDto;

  setPreferredContact(contact: ContactDto, index?: number) {
    if (this.selectedContactIndex === index || this.newContactForm) {
      return;
    }
    const newContactSerialized = JSON.stringify(contact);
    if (TicketService.getParamValue(this.ticket, TicketService.PREFERRED_CONTACT_PARAM_NAME) === newContactSerialized) {
      return;
    }
    this.fieldChanged(TicketService.PREFERRED_CONTACT_PARAM_NAME, newContactSerialized);
    if (index >= 0) {
      this.selectedContactIndex = index;
    }
  }

  checkPreferredContact() {
    const preferredContactString: string = TicketService.getParamValue(this.ticket, TicketService.PREFERRED_CONTACT_PARAM_NAME);
    if (preferredContactString) {
      const preferredContact: ContactDto = JSON.parse(preferredContactString);

      if (preferredContact.id) {
        this.selectedContactIndex = this.contacts.findIndex(contact => contact.id === preferredContact.id);
      } else {
        this.selectedContactIndex = this.contacts.findIndex(contact => ServiceUtils.equalsWithExclude(preferredContact, contact, 'type', 'id'));
      }
    }
  }

  setCustomerContact() {
    if (this.customer?.id) {
      this.contacts.length = 0;
      this.contacts.push(...this.customer.contacts.filter(contact => contact.email || contact.phone1));
      if (this.form.controls.caRefNo.value) {
        this.contacts.push(
          ...this.form.controls.caRefNo.value.contacts.filter(contact => contact.email || contact.phone1)
        );
      }

      this.checkPreferredContact();
      if (this.selectedContactIndex < 0 && this.contacts.length > 0) {
        this.setPreferredContact(this.contacts[0], 0);
      } else if (this.contacts.length < 1) {
        this.selectedContactIndex = -1;
      }
    }
  }

  checkNewContact() {
    const paramContact = TicketService.getParamValue(this.ticket, TicketService.OPPORTUNITY_CONTACT_PARAM_NAME);
    this.useNewContactForm(!this.customer?.id ? true : false);
    if (paramContact) {
      this.form.controls.newContact.patchValue(JSON.parse(paramContact));
    }
  }

  useNewContactForm(useForm: boolean) {
    this.newContactForm = useForm;
    if (useForm && this.editMode) {
      this.form.controls.newContact?.enable();
    } else {
      this.form.controls.newContact?.disable();
    }
  }

  addContact() {
    const modalRef = this.ngModal.open(ContactEditPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const contactEditPopupComponent = <ContactEditPopupComponent>modalRef.componentInstance;
    contactEditPopupComponent.dialogRef = modalRef;
    contactEditPopupComponent.contact = null;
    contactEditPopupComponent.sourceName = 'crm';
    contactEditPopupComponent.contactType = this.contactTypesEnum.OPPORTUNITY;
    contactEditPopupComponent.preferredContactWithoutNone = true;
    contactEditPopupComponent.handler = (contact: ContactDto) => {
      contact.type = this.contactTypesEnum.OPPORTUNITY;
      delete contact['address'];
      if (this.form.controls.caRefNo.value) {
        //Maybe patch will be require
        this.form.controls.caRefNo.value.contacts.push(contact);
      } else {
        this.customer.contacts.push(contact);
      }
      this.contacts.push(contact);
      this.setPreferredContact(contact, this.contacts.length - 1);
      modalRef.close();
    };
  }

  editContact(contact: ContactDto, index: number) {
    const modalRef = this.ngModal.open(ContactEditPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const contactEditPopupComponent = <ContactEditPopupComponent>modalRef.componentInstance;
    contactEditPopupComponent.dialogRef = modalRef;
    contactEditPopupComponent.contact = contact;
    contactEditPopupComponent.sourceName = 'crm';
    contactEditPopupComponent.contactType = this.contactTypesEnum.OPPORTUNITY;
    contactEditPopupComponent.preferredContactWithoutNone = true;
    contactEditPopupComponent.handler = (contact: ContactDto) => {
      const contactFromContacts = this.contacts[index];
      this.contacts[index] = contact;
      const indexOfCustomer = this.customer.contacts.indexOf(contactFromContacts);
      if (indexOfCustomer > 0) {
        this.customer.contacts[indexOfCustomer] = contact;
      } else if (this.form.controls.caRefNo.value) {
        const indexOfCA = this.form.controls.caRefNo.value.contacts.indexOf(contactFromContacts);
        this.form.controls.caRefNo.value.contacts[indexOfCA] = contact;
      }

      if (this.selectedContactIndex === index) {
        this.selectedContactIndex = -1;
        this.setPreferredContact(contact, index);
      }
      modalRef.close();
    };
  }

  removeContact(contact: ContactDto) {
    const contactIndex = this.contacts.indexOf(contact);
    this.contacts = this.contacts.filter(loopContact => loopContact != contact);
    const indexOfCustomer = this.customer.contacts.indexOf(contact);
    if (indexOfCustomer > 0) {
      this.customer.contacts = this.customer.contacts.filter(loopContact => loopContact != contact);
    } else if (this.form.controls.caRefNo.value) {
      this.form.controls.caRefNo.value.contacts = this.form.controls.caRefNo.value.contacts.filter(
        loopContact => loopContact != contact
      );
    }

    if (contactIndex === this.selectedContactIndex) {
      this.selectedContactIndex = -1;
      this.setPreferredContact(this.contacts[0], 0);
    } else if (contactIndex < this.selectedContactIndex) {
      this.selectedContactIndex--;
    }
  }

  customerChanged(customer: any, resetCa = true) {
    this.customer = customer;
    this.contacts.length = 0;

    this.checkNewContact();

    if (this.visibleParameters.get(TicketService.PREFERRED_CONTACT_PARAM_NAME)) {
      this.setCustomerContact();
    }

    if (this.visibleParameters.get(TicketService.PREFERRED_ADDRESS_PARAM_NAME)) {
      this.setCustomerAddress();
    }

    if (resetCa) {
      this.form.controls.caRefNo.patchValue(null);
    }
  }

  protected loadRelatedTasks() {
    const relatedTicketsIds = this.ticket.references
      .filter(reference => reference.entityType === 'com.emeldi.ecc.be.ticket.dto.Ticket')
      .map(reference => reference.entityId);
    if (relatedTicketsIds.length) {
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({
        column: 'id',
        compareType: CompareTypeDtoEnum.IN,
        value: relatedTicketsIds,
      });

      this.ticketingService
        .getTicketsByFilter(search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.relatedTasks = result.data;
          Array.from(this.relatedTicketsByCode.values()).forEach(value => (value.length = 0));
          this.relatedTasks.forEach(ticket => {
            if (this.relatedTicketsByCode.get(ticket.type.code)) {
              this.relatedTicketsByCode.get(ticket.type.code).push(ticket);
            } else {
              this.relatedTicketsByCode.set(ticket.type.code, [ticket]);
            }
          });
        });
    }
  }

  createDmsFilesList = [];

  protected prepareTicketAttachmentsToSave() {
    this.ticket.attachments.forEach((attachment: Attachment) => {
      attachment.id = null;
      attachment.dmsFile.contentHref = null;
      attachment.dmsFile.size = null;
      if (!attachment.dmsFile?.id) {
        this.createDmsFilesList.push(attachment.dmsFile);
      }
      delete attachment.dmsFile;
    });
    this.ticket.attachments = this.ticket.attachments.filter(attachment => !attachment['delete']);
  }

  protected initRelatedTicketsByCode() {
    const relatedTicketsParam = this.allParameters.get(TicketService.RELATED_TASK_TYPES_PARAM_NAME);
    if (relatedTicketsParam && relatedTicketsParam.defaultValue) {
      relatedTicketsParam.defaultValue.split(',').forEach(type => {
        this.relatedTicketsByCode.set(type, []);
      });
    }
  }

  protected getCuAndCa() {
    const referenceCustomer = this.ticket.references.find(
      reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.Customer'
    );

    if (referenceCustomer) {
      this.customerService
        .getCustomer(referenceCustomer.entityId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          const caRefNo = this.ticket.references.find(
            reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.CustomerAccount'
          )?.entityId;

          if (caRefNo) {
            const ca: CustomerAccountDto = this.customerLocalStorageService.getPartyByIdRecursive(
              result.childParties,
              caRefNo
            );
            this.form.controls.caRefNo.patchValue(ca);
          }
          if (!this.editMode) {
            this.form.disable();
          }
          this.customerChanged(result, false);
          this.form.controls.cuRefNo.patchValue(this.customer);
        });
    }
  }

  saveFiles(ticket: TicketDto) {
    const calls = [];

    if (this.createDmsFilesList.length > 0) {
      this.createDmsFilesList.forEach(file => calls.push(this.dmsService.createFile(file)));
    }
    if (this.customer?.id) {
      const id = this.customer.id;
      this.customer.id = null;
      calls.push(this.customerService.updateCustomer(id, this.customer));

      if (this.form.controls.caRefNo.value) {
        calls.push(
          this.customerService.updateCustomerAccount(
            this.form.controls.caRefNo.value.id,
            this.form.controls.caRefNo.value
          )
        );
      }
    }
    if (calls.length > 0) {
      forkJoin(calls)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(() => {
          this.createDmsFilesList = [];
          this.afterSaveSuccess(ticket);
        });
    } else {
      this.afterSaveSuccess(ticket);
    }
  }

  selectedAddressIndex: number = -1;
  addresses: AddressDto[] = [];
  newAddressForm = false;

  setPreferredAddress(address: AddressDto, index?: number) {
    if (this.selectedAddressIndex === index || this.newAddressForm) {
      return;
    }
    const newAddressSerialized = JSON.stringify(address);
    if (TicketService.getParamValue(this.ticket, TicketService.PREFERRED_ADDRESS_PARAM_NAME) === newAddressSerialized) {
      return;
    }
    this.fieldChanged(TicketService.PREFERRED_ADDRESS_PARAM_NAME, newAddressSerialized);
    if (index >= 0) {
      this.selectedAddressIndex = index;
    }
  }

  checkPreferredAddress() {
    const preferredAddressString = TicketService.getParamValue(this.ticket, TicketService.PREFERRED_ADDRESS_PARAM_NAME);
    if (preferredAddressString) {
      const preferedAddress: AddressDto = JSON.parse(preferredAddressString);
      if (preferedAddress.id) {
        this.selectedAddressIndex = this.addresses.findIndex(
          address => address.id === preferedAddress.id
        );
      } else {
        this.selectedAddressIndex = this.addresses.findIndex(
          address => ServiceUtils.equalsWithExclude(preferedAddress, address, 'type', 'id'));
      }
    }
  }

  setCustomerAddress() {
    if (this.customer?.id) {
      this.addresses.length = 0;
      this.addresses.push(...this.customer.addresses.filter(address => address.street || address.city));
      if (this.form.controls.caRefNo.value) {
        this.addresses.push(
          ...this.form.controls.caRefNo.value.addresss.filter(address => address.street || address.city)
        );
      }

      this.checkPreferredAddress();
      if (this.selectedAddressIndex < 0 && this.addresses.length > 0) {
        this.setPreferredAddress(this.addresses[0], 0);
      } else if (this.addresses.length < 1) {
        this.selectedAddressIndex = -1;
      }
    }
  }

  addAddress() {
    const modalRef = this.ngModal.open(AddressEditPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const addressEditPopupComponent = <AddressEditPopupComponent>modalRef.componentInstance;
    addressEditPopupComponent.dialogRef = modalRef;
    addressEditPopupComponent.address = null;
    addressEditPopupComponent.addressType = this.addressTypesEnum.OPPORTUNITY;
    addressEditPopupComponent.preferredAddressWithoutNone = true;
    addressEditPopupComponent.handler = (address: AddressDto) => {
      address.type = this.addressTypesEnum.OPPORTUNITY;
      if (this.form.controls.caRefNo.value) {
        //Maybe patch will be require
        this.form.controls.caRefNo.value.addresss.push(address);
      } else {
        this.customer.addresses.push(address);
      }
      this.addresses.push(address);
      this.setPreferredAddress(address, this.addresses.length - 1);
      modalRef.close();
    };
  }

  editAddress(address: AddressDto, index: number) {
    const modalRef = this.ngModal.open(AddressEditPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const addressEditPopupComponent = <AddressEditPopupComponent>modalRef.componentInstance;
    addressEditPopupComponent.dialogRef = modalRef;
    addressEditPopupComponent.address = address;
    addressEditPopupComponent.addressType = this.addressTypesEnum.OPPORTUNITY;
    addressEditPopupComponent.preferredAddressWithoutNone = true;
    addressEditPopupComponent.handler = (address: AddressDto) => {
      const addressFromAddresss = this.addresses[index];
      this.addresses[index] = address;
      const indexOfCustomer = this.customer.addresses.indexOf(addressFromAddresss);
      if (indexOfCustomer > 0) {
        this.customer.addresses[indexOfCustomer] = address;
      } else if (this.form.controls.caRefNo.value) {
        const indexOfCA = this.form.controls.caRefNo.value.addresss.indexOf(addressFromAddresss);
        this.form.controls.caRefNo.value.addresss[indexOfCA] = address;
      }

      if (this.selectedAddressIndex === index) {
        this.selectedAddressIndex = -1;
        this.setPreferredAddress(address, index);
      }
      modalRef.close();
    };
  }

  removeAddress(address: AddressDto) {
    const addressIndex = this.addresses.indexOf(address);
    this.addresses = this.addresses.filter(loopAddress => loopAddress != address);
    const indexOfCustomer = this.customer.addresses.indexOf(address);
    if (indexOfCustomer > 0) {
      this.customer.addresses = this.customer.addresses.filter(loopAddress => loopAddress != address);
    } else if (this.form.controls.caRefNo.value) {
      this.form.controls.caRefNo.value.addresss = this.form.controls.caRefNo.value.addresss.filter(
        loopAddress => loopAddress != address
      );
    }

    if (addressIndex === this.selectedAddressIndex) {
      this.selectedAddressIndex = -1;
      this.setPreferredAddress(this.addresses[0], 0);
    } else if (addressIndex < this.selectedAddressIndex) {
      this.selectedAddressIndex--;
    }
  }

  setContactsAndAddresses() {
    if (this.customerLocalStorageService.getCurrentCustomer()) {
      this.contacts.push(...this.customerLocalStorageService.getCurrentCustomer().contacts);
      this.addresses.push(...this.customerLocalStorageService.getCurrentCustomer().addresses);
      if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
        this.contacts.push(...this.customerLocalStorageService.getCurrentCustomerAccount().contacts);
        this.addresses.push(...this.customerLocalStorageService.getCurrentCustomerAccount().addresses);
      }
      if (this.visibleParameters.get(TicketService.PREFERRED_CONTACT_PARAM_NAME)) {
        this.setPreferredContact(this.contacts[0], 0);
      }
      if (this.visibleParameters.get(TicketService.PREFERRED_ADDRESS_PARAM_NAME)) {
        this.setPreferredAddress(this.addresses[0], 0);
      }
    } else {
      this.contacts = [];
      this.addresses = [];
    }
  }
}
