<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ 'wc.ecare.productConfiguration.heading' | translate }}
  </h4>
  <div class="modal-close" (click)="dialogRef.dismiss()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div *ngIf="dataTypeEnums" class="modal-body">
  <form class="form" [formGroup]="form">
    <div class="generated-form">
      <div *ngFor="let param of dynamicProductParameters" class="generated-input-container col12">
        <div class="label-input-form-row">
          <app-entity-generated-form-input
            [name]="param.name"
            [label]="param.label"
            cssContainerSufix="grid"
            guiStyleClasses="label-with-input"
            translationPrefix="wc.shopping.productAttributes"
            [metaParameters]="param.metadata"
            [form]="form"
            [type]="param.type"
            [typeDetail]="param.typeDetail" />
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div>
    <app-button
      variant="link"
      ngbAutofocus
      label="{{ 'wc.common.cancel.button' | translate }}"
      (click)="dialogRef.dismiss()"></app-button>
    <app-button
      variant="primary"
      iconPath="/assets/svg/save.svg"
      label="{{ 'wc.ecare.productConfiguration.set.button' | translate }}"
      (click)="set()"></app-button>
  </div>
</div>
