import { CategoryTypeEnum } from 'app/models/product-filter';
import { ProductUtils } from './product-utils';
import _ from 'lodash';
import { environment } from '../../environments/environment';
import { CrmPartyRoleEnum } from '../models/CrmPartyRoleEnum';
import {
  AddressDto,
  AssetDto,
  ContactDto,
  CustomerAccountDto,
  CustomerDto,
  PartyDto,
  PriceTypeDto
} from '@btl/order-bff';

export class CustomerPartyUtil {
  public static getChildAssetByProductParameterWithValue(asset, paramName, paramValue): any {
    let retAsset = undefined;
    const childAssets = asset['childAssets'];
    if (childAssets) {
      childAssets.forEach((childAsset: AssetDto) => {
        let asset;
        const product = childAsset['product'];
        if (product) {
          if (product.parametersStatic[paramName] === paramValue) {
            asset = childAsset;
          } else {
            asset = this.getChildAssetByProductParameterWithValue(childAsset, paramName, paramValue);
          }
        }

        if (asset) {
          retAsset = asset;
        }
      });
    }

    return retAsset;
  }

  public static getAssetProductCharacteristicValue(asset, name: string): string {
    let value = null;
    if (asset) {
      const productCharacteristics = asset['productCharacteristic'];
      productCharacteristics.forEach(productCharacteristic => {
        if (productCharacteristic['name'] === name) {
          value = productCharacteristic['value'];
        }
      });
    }

    return value;
  }

  public static getChildAssetByCategoryId(asset, categoryId: CategoryTypeEnum): any {
    let retAsset = undefined;
    const childAssets = asset['childAssets'];
    if (childAssets) {
      childAssets.forEach(childAsset => {
        let asset;
        const product = childAsset['product'];
        if (product) {
          if (ProductUtils.isOfCategory(product, categoryId)) {
            asset = childAsset;
          } else {
            asset = this.getChildAssetByCategoryId(childAsset, categoryId);
          }
        }

        if (asset) {
          retAsset = asset;
        }
      });
    }

    return retAsset;
  }

  public static getChildAssetsByCategoryIds(asset, categoryIds: Array<CategoryTypeEnum>): any {
    const assestIds = [];
    categoryIds.forEach(categoryId => {
      const childAssest = this.getChildAssetByCategoryId(asset, categoryId);
      if (childAssest && childAssest.state === 'ACTIVATED') {
        assestIds.push(childAssest.id);
      }
    });
    return assestIds;
  }

  public static getPrice(asset, priceType: PriceTypeDto) {
    const prices = asset['prices'];
    let price = 0;
    if (prices) {
      Object.keys(prices).forEach(key => {
        const priceByType = prices[priceType];
        if (priceByType) {
          price = priceByType.price;
        }
      });
    }

    if (!price) {
      price = 0;
    }
    return price;
  }

  public static getTariffRC(tariffProduct, coreProduct) {
    let tariffPrice = 0;

    if (tariffProduct) {
      tariffPrice = this.getPriceRC(tariffProduct);
    }

    if (coreProduct && coreProduct['childAssets']) {
      coreProduct['childAssets'].forEach(addon => {
        tariffPrice += this.getPriceRC(addon);
      });
    }
    return tariffPrice;
  }

  public static getPriceRC(asset: AssetDto) {
    let price = CustomerPartyUtil.getPrice(asset, PriceTypeDto.RC);
    asset.childAssets?.forEach(child => {
      price += CustomerPartyUtil.getPriceRC(child);
    });
    return price;
  }

  public static equalsWithExclude(object: Object, object2: Object, ...excludedFieds: String[]): boolean {
    for (const key in object) {
      if (excludedFieds.indexOf(key) < 0) {
        if (object[key] !== object2[key]) {
          return false;
        }
      }
    }

    return true;
  }

  public static copyWithExclude(from: Object, to: Object, ...excludedFields: String[]) {
    if (!to) {
      to = {};
    }
    for (const key in from) {
      if (excludedFields.indexOf(key) < 0) {
        to[key] = from[key];
      }
    }
  }

  public static getFormCustomerAccount(customerAccount: CustomerAccountDto) {
    const copyCustomerAccount = {};
    CustomerPartyUtil.copyWithExclude(
      customerAccount,
      copyCustomerAccount,
      'childParties',
      'assets',
      'parentId',
      'state',
      'role'
    );
    return CustomerPartyUtil.updateCustomerAccountContacts(copyCustomerAccount);
  }

  public static getFormCustomer(customer: CustomerDto) {
    const copyCustomer = {};
    CustomerPartyUtil.copyWithExclude(
      customer,
      copyCustomer,
      'customName',
      'childParties',
      'assets',
      'parentId',
    );
    return copyCustomer;
  }

  public static updateCustomerAccountContacts(customerAccount: any): any {
    if (customerAccount.contacts) {
      customerAccount.invoicingContact = customerAccount.contacts.find(
        contact => contact.type === ContactDto.TypeDtoEnum.INVOICING
      );
      customerAccount.billingContact = customerAccount.contacts.find(
        contact => contact.type === ContactDto.TypeDtoEnum.BILLING
      );
    }
    customerAccount.allowInvoicingContact = customerAccount.invoicingContact ? true : false;
    customerAccount.allowBillingContact = customerAccount.billingContact ? true : false;

    if (customerAccount.contacts) {
      delete customerAccount.contacts;
    }
    return customerAccount;
  }

  public static getDefaultCustomerAccount() {
    const customerAccount = {
      id: null,
      extId: null,
      parameters: {},
      address: null,
      invoicingContact: null,
      allowInvoicingContact: false,
      allowBillingContact: false,
      billingContact: null,
    };

    return customerAccount;
  }

  public static getDefaultCustomer() {
    const customer = {
      address: this.getDefaultAddress(AddressDto.TypeDtoEnum.REGISTRATION),
      contact: this.getDefaultContact(ContactDto.TypeDtoEnum.PRIMARY),
      id: null,
      extId: null,
      childParties: [],
      parameters: {},
    };

    return customer;
  }

  public static getDefaultAddress(type: AddressDto.TypeDtoEnum) {
    const address: AddressDto = {
      id: null,
      type: type,
      city: null,
      street: null,
      streetNumber: null,
      zipCode: null,
      district: null,
      country: environment.country,
    };

    return address;
  }

  public static getDefaultContact(type: ContactDto.TypeDtoEnum) {
    const contact: ContactDto = {
      id: null,
      type: type,
      preferredChannel: null,
      firstName: null,
      middleName: null,
      lastName: null,
      phone1: null,
      email: null,
      language: environment.localization.defaultLocale,
    };

    return contact;
  }

  static getChildCustomerAccounts(parties: PartyDto[]): PartyDto[] {
    return CustomerPartyUtil.filterChildPartiesByRole(parties, 'CustomerAccount');
  }

  static getChildPartyById(party: PartyDto, childId: string): PartyDto {
    let childParty = null;
    if (party.childParties && childId) {
      for (const loopParty of party.childParties) {
        if (loopParty.id === childId) {
          childParty = loopParty;
          return childParty;
        } else {
          childParty = CustomerPartyUtil.getChildPartyById(loopParty, childId);
        }
      }
    }
    return childParty;
  }

  static getChildTariffs(parties: PartyDto[]): PartyDto[] {
    return CustomerPartyUtil.filterChildPartiesByRole(parties, 'Subscription');
  }

  public static getChildPartiesByRole(parties: PartyDto[], role: CrmPartyRoleEnum): any {
    let childParties = [];
    parties.forEach(party => {
      if (CustomerPartyUtil.hasPartyRole(party, role)) {
        childParties.push(party);
      } else {
        const subChildParties = this.getChildPartiesByRole(party.childParties, role);
        subChildParties.forEach(subChildParte => {
          childParties.push(subChildParte);
        });
      }
    });
    return childParties;
  }

  private static filterChildPartiesByRole(childParties: PartyDto[], role: string): PartyDto[] {
    return _.filter(childParties, party => party['role'] === role);
  }

  public static hasPartyRole(party: PartyDto, role: CrmPartyRoleEnum) {
    if (_.isNil(party) || _.isNil(role)) {
      return false;
    }
    return party.role === role.toString();
  }

  public static hasPartyOneOfRole(party: PartyDto, ...roles: CrmPartyRoleEnum[]) {
    for (const role of roles) {
      if (this.hasPartyRole(party, role)) {
        return true;
      }
    }
    return false;
  }
}
