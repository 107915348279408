import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CustomerDto, EntityMetaDescriptionDto, MetaEntityAttributeDto } from '@btl/order-bff';
import { EnableDynamicLoading, MetaDescriptionService } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '@service/customer.service';
import { addressTypes } from '../../../pipes/display-address.pipe';
import { PartyElse } from '../../../models/party-holder';
import {
  AbstractEcareListingPageComponent
} from '../abstract-ecare-listing-page/abstract-ecare-listing-page.component';

@Component({
  selector: 'app-ecare-customers',
  templateUrl: './ecare-customers.component.html',
})
@EnableDynamicLoading({ customName: EcareCustomersComponent.PAGE_ID })
export class EcareCustomersComponent extends AbstractEcareListingPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareCustomersComponent';

  pageId(): string {
    return EcareCustomersComponent.PAGE_ID;
  }

  @Output() readonly selectedCustomer = new EventEmitter<CustomerDto>();

  protected readonly addressTypes = addressTypes;
  subjectMetaParameters: Map<string, MetaEntityAttributeDto> = null;

  constructor(
    private renderer: Renderer2,
    private readonly customerService: CustomerService,
    protected router: Router,
    protected route: ActivatedRoute,
    private metaDescriptionService: MetaDescriptionService
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.metaDescriptionService.getEntityMetaDescriptions("crm", "Subject", {})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((metaDescriptions: EntityMetaDescriptionDto) => {
        this.subjectMetaParameters = new Map<string, MetaEntityAttributeDto>();
        metaDescriptions.attributes.forEach((attribute: MetaEntityAttributeDto) => {
          if (attribute.metaParameters['guiVisible'] === 'true') {
            this.subjectMetaParameters.set(attribute.name, attribute);
          }
        });
      });
  }

  protected loadData() {
    this.isMore = false;
    this.blockUIElement.start();
    this.customerService
      .searchByText(this.searchText, this.pageSize, this.from)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(partyHits => {
        const foundCustomers = partyHits.hits.map(customerHolder => customerHolder._source);
        foundCustomers.forEach(customer => {
          if (!customer.companyRegNo) {
            //ToDo BTL-6896 Use placeholders for tables columns and configure them in meta data
            const uniqueNumber = customer.subjectParameters.find(param => param['name'] === 'uniqueNumber');
            if (uniqueNumber) {
              customer.companyRegNo = uniqueNumber['value'];
            }
          }
        });
        this.setRecords(foundCustomers, partyHits.total.value);
        this.blockUIElement.stop();
      });
  }

  selectCustomer(customer: PartyElse) {
    this.customerService
      .getCustomer(customer.code)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(customer => {
        this.selectedCustomer.emit(customer);
      });
  }
}
