import { Component, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import {
  AccountDto,
  BulkDeleteListDto,
  PagedTicketsDto,
  SortDto,
  TicketDto,
  TicketTypeDto,
  TicketTypeParamDto
} from '@btl/order-bff';
import {
  AppBlockerService,
  AuthFactoryService,
  AuthService,
  BlockTemplateComponent,
  CodebookService,
  CompareType,
  OrderingService,
  ServiceUtils,
  StickyMessageService,
  TicketingService
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlParamsService } from '@service/url-params.service';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { TicketService } from '@service/ticket.service';
import { TicketEditComponent } from './edit/ticket-edit.component';
import { CustomerService } from '@service/customer.service';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalFactoryService } from '@service/modal-factory.service';
import { CreateTicketModalComponent } from './create-ticket-modal/create-ticket-modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { Observable, of } from 'rxjs';
import { OpportunityDetailsComponent } from '../ecare-opportunities/opportunity-details/opportunity-details.component';
import {
  AbstractEcareListingPageComponent
} from '../abstract-ecare-listing-page/abstract-ecare-listing-page.component';
import { TicketElse } from '../../../models/tickets-holder';

@Component({
  selector: 'app-abstract-ticket-table',
  template: '',
})
export abstract class AbstractTicketTableComponent extends AbstractEcareListingPageComponent implements OnInit, OnChanges {
  pageId(): string {
    return '';
  }

  @Input() inputTickets: TicketDto[] | TicketElse[] = [];
  @Input() externalSearchMode = false;
  @Input() staticSearch = null;
  @Input() smallTable = false;
  @Input() editCallback: (ticketDto: TicketElse, inNewTab) => void;
  @Input() removeRelationCallback: (ticketDto: TicketElse) => void;
  @Input() showDuplicateBtn = true;
  @Input() showDeleteBtn = true;

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  defaultSortOrdering: SortDto = { column: 'created', sortOrder: 'desc' };

  tickets: any[] = [];
  authService: AuthService;
  account: AccountDto;
  cuRefNo = null;
  caRefNo = null;
  queryParams = null;
  selected: TicketDto;
  ticketForRelatedTask;
  newRelatedTask;
  newRelatedTaskCode;
  newRelatedTaskCuRef;
  newRelatedTaskCaRef;
  pagedDto: PagedTicketsDto;
  moreRecordsExists = false;
  allTicketsSelected = false;
  anyTicketsSelected = false;
  ticketType: TicketTypeDto;
  visibleParameters = new Map<string, TicketTypeParamDto>();
  selectForm: FormGroup = this.formBuilder.group({
    selected: new FormArray([]),
  });

  get selectedFormArray() {
    return this.selectForm.get('selected') as FormArray;
  }

  constructor(
    protected renderer: Renderer2,
    protected readonly customerService: CustomerService,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    protected readonly appBlockerService: AppBlockerService,
    protected readonly propertyAccessorLocalService: PropertyAccessorLocalService,
    protected readonly ticketingService: TicketingService,
    protected readonly ticketService: TicketService,
    protected modal: NgbModal,
    protected codebookService: CodebookService,
    protected readonly authFactoryService: AuthFactoryService,
    protected orderingService: OrderingService,
    protected modalFactory: ModalFactoryService,
    protected stickyMessageService: StickyMessageService,
    private formBuilder: FormBuilder
  ) {
    super(router, route);
    this.authService = authFactoryService.getAuthService();
    this.account = this.authService.account;
    this.authService.accountChange.pipe(takeUntil(this.onDestroy$)).subscribe(account => (this.account = account));
    this.renderer.addClass(document.body, 'ecare');
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputTickets && changes.inputTickets.currentValue) {
      this.extendTickets(this.inputTickets);
    }
  }

  extendTickets(resultTickets: any[]) { }

  removeRelation(ticket: TicketElse){
    this.removeRelationCallback(ticket);
  }

  initSearch(code: string, areaType: string): Observable<any> {
    return this.ticketingService.getTicketType(code).pipe(
      tap(ticketType => {
        this.ticketType = ticketType;

        this.ticketType.parameters.forEach(param => {
          if (param.paramMetas.find(meta => meta.name === 'guiVisible' && meta.value === 'true')) {
            this.visibleParameters.set(param.name, param);
          }
        });

        if (this.staticSearch || this.externalSearchMode) {
          this.search = this.staticSearch;
          return of(this.search);
        } else {
          if (code && areaType) {
            this.search.filtering.push({
              column: 'type',
              compareType: null,
              value: {
                code: code,
                areaType: areaType
              }
            });
          }

          if (this.authService.account?.login && code === OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE) {
            this.search.filtering.push({
              column: 'assignedTo',
              compareType: 'EQUAL',
              value: this.authService.account.login
            });
          }

          this.search.sorting = [this.defaultSortOrdering];
          if (this.cuRefNo) {
            let entityTypeId: string, entityId: string;
            if (this.caRefNo) {
              entityTypeId = 'com.emeldi.ecc.be.crm.dto.CustomerAccount';
              entityId = this.caRefNo;
            } else {
              entityTypeId = 'com.emeldi.ecc.be.crm.dto.Customer';
              entityId = this.cuRefNo;
            }
            if (entityTypeId && entityId) {
              this.search.filtering.push({
                column: 'references',
                compareType: null,
                value: [
                  {
                    entityTypeId: entityTypeId,
                    entityId: entityId
                  }
                ]
              });
            }
          }
        }
      })
    );
  }

  rowClicked(contact: TicketDto) {
    this.selected === contact ? (this.selected = null) : (this.selected = contact);
    if (this.selected && contact['tasks'].length === 0 && contact['tasksIds']?.length > 0) {
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({
        column: 'id',
        compareType: CompareType.CompareTypeDtoEnum.IN,
        value: contact['tasksIds'],
      });
      this.ticketingService
        .getTicketsByFilter(search)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(tasks => {
          contact['tasks'] = tasks.data;
        });
    }
  }

  addTicketRelatedTask($event: TicketDto) {
    const modalRef = this.modal.open(CreateTicketModalComponent);
    const createTicketModalComponent = <CreateTicketModalComponent>modalRef.componentInstance;
    createTicketModalComponent.dialogRef = modalRef;
    createTicketModalComponent.createTicketEvent.pipe(takeUntil(this.onDestroy$)).subscribe(ticketForm => {
      if (ticketForm) {
        this.ticketForRelatedTask = $event;
        this.newRelatedTaskCode = ticketForm.code;
        this.newRelatedTaskCuRef = this.ticketForRelatedTask.references?.find(
          reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.Customer'
        )?.entityId;
        this.newRelatedTaskCaRef = this.ticketForRelatedTask.references?.find(
          reference => reference.entityType === 'com.emeldi.ecc.be.crm.dto.CustomerAccount'
        )?.entityId;
        this.newRelatedTask = {
          id: 'newTicket',
        };
      }
    });
  }

  newRelatedTaskCanceled = (): void => {
    this.ticketForRelatedTask = null;
    this.newRelatedTask = null;
  };

  newRelatedTaskSaved = (ticketDto: TicketDto): void => {
    TicketService.addReference(this.ticketForRelatedTask, 'com.emeldi.ecc.be.ticket.dto.Ticket', ticketDto.id);

    const id = this.ticketForRelatedTask.id;
    this.appBlockerService.block();
    this.ticketForRelatedTask.id = null;
    TicketService.clearFieldsBeforeUpdate(this.ticketForRelatedTask);

    if (id) {
      this.ticketingService
        .updateTicket(id, this.ticketForRelatedTask)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          ServiceUtils.copyWithExclude(result, this.ticketForRelatedTask);
          this.ticketForRelatedTask['tasksIds'].push(ticketDto.id);

          this.ticketForRelatedTask['tasks'].push(ticketDto);
          this.ticketForRelatedTask = null;
          this.newRelatedTask = null;
        });
    }
  };

  openRelatedTask(taskId: string) {
    const params = UrlParamsService.mergeParams(this.queryParams, {
      id: taskId,
      backUrl: this.router.url.split('?')[0],
    });

    this.navigateSibling(TicketEditComponent.PAGE_ID, params);
  }

  onSortChange() {
    this.tickets.length = 0;
    this.from = 0;
    this.loadData();
  }

  public handleFilterChange(): void {
    this.tickets.length = 0;
    this.search.paging.page = 1;
    this.from = 0;
    this.loadData();
  }

  loading = false;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (!this.loading && this.totalItems > this.tickets.length) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight && this.moreRecordsExists) {
        this.loading = true;
        this.from += this.pageSize;
        this.loadData();
      }
    }
  }

  getSelectedTickets() {
    const selected = [];
    for (let i = 0; i < this.selectedFormArray.length; i++) {
      if (this.selectedFormArray.getRawValue()[i]) {
        selected.push(this.tickets[i]);
      }
    }
    return selected;
  }

  areAllSelected() {
    for (let i = 0; i < this.selectedFormArray.length; i++) {
      if (!this.selectedFormArray.getRawValue()[i]) {
        return false;
      }
    }
    return true;
  }

  isAnySelected() {
    for (let i = 0; i < this.selectedFormArray.length; i++) {
      if (this.selectedFormArray.getRawValue()[i]) {
        return true;
      }
    }
    return false;
  }

  selectDeselectAll() {
    if (this.allTicketsSelected) {
      this.selectedFormArray.controls.forEach(form => form.patchValue(false));
    } else {
      this.selectedFormArray.controls.forEach(form => form.patchValue(true));
    }
  }

  deleteTasks(deleteTicketIds?: Array<string>) {
    const bulkDelete: BulkDeleteListDto = {
      bulkDeleteList: [],
    };

    if (!deleteTicketIds) {
      this.getSelectedTickets().forEach(ticket =>
        bulkDelete.bulkDeleteList.push({
          id: ticket.id,
        })
      );
    } else {
      deleteTicketIds.forEach(ticketId =>
        bulkDelete.bulkDeleteList.push({
          id: ticketId,
        })
      );
    }

    if (bulkDelete.bulkDeleteList.length > 0) {
      const dialogReference = this.modal.open(ConfirmationDialogComponent, {
        windowClass: 'dialog dialog-confirmation',
      });

      const confirmationDialogComponent = <ConfirmationDialogComponent>dialogReference.componentInstance;
      confirmationDialogComponent.heading = 'wc.ecare.ticket.delete';
      confirmationDialogComponent.texts.push('wc.ecare.ticket.delete.confirm');
      confirmationDialogComponent.dialogReference = dialogReference;
      confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
        dialogReference.dismiss();

        if (bulkDelete.bulkDeleteList.length > 0) {
          if (bulkDelete.bulkDeleteList.length === 1) {
            this.ticketingService
              .deleteTicket(bulkDelete.bulkDeleteList[0].id)
              .pipe(finalize(this.appBlockerService.unblock))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(result => {
                this.deleteSuccess();
              });
          } else {
            this.ticketingService
              .deleteTickets(bulkDelete)
              .pipe(finalize(this.appBlockerService.unblock))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(result => {
                this.deleteSuccess();
              });
          }
        }
      };
    }
  }

  deleteSuccess() {
    this.allTicketsSelected = true;
    this.selectDeselectAll();
    this.tickets.length = 0;
    this.loadData();
    this.stickyMessageService.addStickySuccessMessage('wc.ecare.ticket.delete.success');
  }
}
