<div app-breadcrumb [breadcrumbs]="breadcrumbs"></div>

<app-page-header [isWithBreadcrumb]="true" title="{{ 'wc.shopping.gui_productComparison.header' | translate }}">
</app-page-header>

<div class="productComparison-wrapper">
  <div class="productComparison-row topPart">
    <div *ngFor="let productDetail of productDetailDtos" class="productComparison-cell">
      <!-- Image -->
      <div class="productComparison-productImage">
        <!-- remove icon -->
        <div class="delete-icon" (click)="removeProductFromComparison(productDetail.seoUrl)">
          <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
        </div>
        <img *ngIf="productDetail" [src]="getComparisonPictureHref(productDetail)" />
      </div>

      <!-- Name -->
      <h3 class="productComparison-productName">
        <a class="productComparison-productName" [routerLink]="['/eshop/product-detail/' + productDetail.seoUrl]">
          {{ productDetail.name }}
        </a>
      </h3>

      <!-- Description -->
      <div class="productComparison-productDescription" [innerHTML]="productDetail.shortDescription | safeHtml"></div>

      <!-- Price -->
      <div class="productComparison-productPrice">
        <span [innerHTML]="productDetail.prices['OC'].price | price"></span>
      </div>

      <!-- Main Actions -->
      <div class="productComparison-mainActions">
        <app-button
          variant="primary"
          iconPath="
          /assets/svg/shopping-cart-icon.svg"
          size="fullWidth"
          label="{{
            isQuoteOrder
              ? ('wc.ecare.productListing.addToQuote' | translate)
              : ('wc.ecare.productListing.addToCart' | translate)
          }}"
          (click)="addToCart(productDetail)"></app-button>
        <app-button
          variant="secondary"
          iconPath="/assets/svg/favorite-heart.svg"
          size="fullWidth"
          label="{{ 'wc.shopping.gui_wishlist.addToWishList.heading' | translate }}"
          (click)="wishListPopup(productDetail.productCode)">
        </app-button>
      </div>
    </div>
  </div>

  <app-page-header title="{{ 'wc.shopping.gui_productComparison.keySpecs' | translate }}"></app-page-header>

  <!-- Parameters -->
  <div class="productComparison-parameterWrapper">
    <div *ngFor="let section of sections$ | async" class="productComparison-row params">
      <div *ngFor="let productBlock of section.products; last as isLast" class="productComparison-cell">
        <ng-container *ngFor="let parameter of productBlock.parameters; let i = index; let odd = odd">
          <div
            app-compared-product-parameter
            class="parameter-item-container"
            [parameter]="parameter"
            [ngClass]="{ odd: odd, last: isLast }"
            [paramHeights]="paramHeights"
            [blockLength]="productBlock.parameters.length"
            [index]="i"
            [isLastColumn]="isLast"></div>
        </ng-container>
        <hr />
      </div>
    </div>

    <!-- Product color component -->
    <div class="productComparison-row params">
      <ng-container *ngFor="let colorVariant of colorVariants">
        <div class="colorVariant-container">
          <div class="parameter-label">{{ 'wc.shopping.gui_productComparison.colors' | translate }}</div>
          <div class="parameter-colors">
            <ng-container *ngFor="let productColorVariant of colorVariant">
              <!-- Color -->
              <div class="colorVariant-wrapper">
                <div class="color" [style.background-color]="'rgb(' + productColorVariant.rgb + ')'"></div>
                <div>{{ productColorVariant.name }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="productComparison-row mt-3">
    <div *ngFor="let productDetail of productDetailDtos" class="productComparison-cell">
      <app-button
        variant="primary"
        size="fullWidth"
        label="{{ 'wc.common.buyNow.button' | translate }}"
        [routerLink]="['/eshop/shopping-cart']"
        [queryParams]="{ productId: productDetail.id }"></app-button>
    </div>
  </div>
</div>
