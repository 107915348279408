<div class="dashboard_search ecare-ordersSearch">
  <table *ngIf="orders.length > 0" class="ecare_listing-table orders-table table">
    <thead class="content-sm">
      <tr>
        <th>
          <a
            class="{{ getSortOrderCssClass(COLUMN_NAME_ORDER_ID) }}"
            (click)="
              sortTableByColumn({
                column: COLUMN_NAME_ORDER_ID,
                sortOrder: getCurrentSorting(COLUMN_NAME_ORDER_ID, true)
              })
            "
            >{{ 'wc.ecare.orders.table.orderNumber.header' | translate }}
            <i [inlineSVG]="'/assets/svg/down-arrow-icon.svg'"></i
          ></a>
        </th>
        <th *ngIf="isModuleBffCrmEnabled" app-acl-internal-evaluate-component class="content-md">
          {{ (selectMode ? 'wc.ecare.ordersFilter.extId' : 'wc.ecare.orderSearch.customer.label') | translate }}
        </th>
        <th app-acl-internal-evaluate-component class="content-sm">
          <div class="hide-col-md">
            <a
              class="{{ getSortOrderCssClass(COLUMN_NAME_CREATOR) }}"
              (click)="
                sortTableByColumn({
                  column: COLUMN_NAME_CREATOR,
                  sortOrder: getCurrentSorting(COLUMN_NAME_CREATOR, true)
                })
              "
              >{{ 'wc.ecare.orders.table.creator.header' | translate }}
              <i [inlineSVG]="'/assets/svg/down-arrow-icon.svg'"></i
            ></a>
          </div>
        </th>
        <th>
          <a
            class="{{ getSortOrderCssClass(COLUMN_NAME_CREATED) }}"
            (click)="
              sortTableByColumn({
                column: COLUMN_NAME_CREATED,
                sortOrder: getCurrentSorting(COLUMN_NAME_CREATED, true)
              })
            "
            >{{ 'wc.ecare.orders.table.created.header' | translate }}
            <i [inlineSVG]="'/assets/svg/down-arrow-icon.svg'"></i
          ></a>
        </th>
        <th>
          {{ 'wc.ecare.orders.table.status.header' | translate }}
        </th>
        <th class="hide-col-md">
          <a
            class="{{ getSortOrderCssClass(COLUMN_NAME_STATE_HAPPENED) }}"
            (click)="
              sortTableByColumn({
                column: COLUMN_NAME_STATE_HAPPENED,
                sortOrder: getCurrentSorting(COLUMN_NAME_STATE_HAPPENED, true)
              })
            "
            >{{ 'wc.ecare.ordersFilter.stateDatePeriod' | translate }}</a
          >
        </th>
        <th *ngIf="hasOCPrice" class="no-wrap">{{ 'wc.ecare.orders.table.totalOcPrice.header' | translate }}</th>
        <th *ngIf="hasRCPrice" class="no-wrap">{{ 'wc.ecare.orders.table.totalRcPrice.header' | translate }}</th>
        <th *ngIf="!authorizationService.isInternal">Shopping Cart</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <app-ecare-order-row
        *ngFor="let order of orders"
        [order]="order"
        [isModuleBffCrmEnabled]="isModuleBffCrmEnabled"
        [maxThumbnailsToShow]="maxThumbnailsToShow"
        [opportunity]="opportunity"
        [selectMode]="selectMode"
        [priceRCexists]="hasRCPrice"
        [priceOCexists]="hasOCPrice"
        [thumbnailMap]="thumbnailMap"
        (selectedOrder)="itemSelected($event)"
        (selectCustomer)="selectCustomerInTable($event)" />
    </tbody>
  </table>

  <app-ecare-order-table-mobile
    [canDisplayNoOrdersText]="canDisplayNoOrdersText"
    [selectMode]="selectMode"
    [orders]="orders"
    [currentSortOrder]="currentSortOrder"
    [thumbnailMap]="thumbnailMap"
    (selectedOrder)="itemSelected($event)" />

  <ng-container *ngIf="canDisplayNoOrdersText">
    <app-ecare-listing-no-results />
  </ng-container>
</div>
