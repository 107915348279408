<ng-container *ngIf="subscriptions.length > 0; else banner">
  <ng-container *ngIf="tariffProduct">
    <div class="widget--header">
      <div class="widget--header_title">
        <i [inlineSVG]="'/assets/svg/dashboard-widgets/internet.svg'"></i>
        <h6>{{ 'wc.ecare.widgets.fixedInternetTariff.header' | translate }}</h6>
      </div>
      <app-subscription-select-widget
        [subscription]="subscription"
        [subscriptionsToSelect]="subscriptionsToSelect"
        [tariffIdentification]="tariffIdentification"
        (subscriptionChanged)="changeSelectedTariff($event)">
      </app-subscription-select-widget>
    </div>

    <div class="widget--content">
      <app-ecare-widget-main-info
        [label1]="customName"
        [label2]="tariffProduct.name"
        [price]="tariffProductPrice"
        [period]="'wc.shopping.confirmation.price.RC.label' | translate">
        <div class="content-in-row">
          <app-acl-internal-evaluate-component class="padding-5">
            <app-acl-evaluate privilege="DEACTIVATION_B2C_SUBS" class="btn-no-padding">
              <button
                *ngIf="subscription?.assets[0]['state'] !== 'DEACTIVATED'"
                id="008_subscription_detail"
                class="btn -btn-tariff-restriction"
                tooltipClass="ecare-settings-tooltip"
                placement="bottom"
                ngbTooltip="{{ 'wc.ecare.dashboard.tariffRestriction.label' | translate }}"
                (click)="restrictTariff()">
                <img src="../../../../assets/img/ecare/ant-design_stop-outlined.svg" />
              </button>
            </app-acl-evaluate>
          </app-acl-internal-evaluate-component>
          <app-acl-internal-evaluate-component class="padding-5">
            <app-acl-evaluate privilege="DEACTIVATION_B2C_SUBS" class="btn-no-padding">
              <button
                *ngIf="subscription?.assets[0]['state'] !== 'DEACTIVATED'"
                id="007_subscription_detail"
                class="btn -btn-tariff-deactivation"
                tooltipClass="ecare-settings-tooltip"
                placement="bottom"
                ngbTooltip="{{ 'wc.ecare.dashboard.tariffDeactivation.label' | translate }}"
                (click)="deactivateTariff()">
                <img src="../../../../assets/img/ecare/ionic-ios-power.svg" />
              </button>
            </app-acl-evaluate>
          </app-acl-internal-evaluate-component>
        </div>
      </app-ecare-widget-main-info>
      <div class="details-wraper">
        <div class="detail-item">
          <div class="detail-item--label">
            <i [inlineSVG]="'/assets/svg/dashboard-widgets/download.svg'"></i
            >{{ 'wc.ecare.widgets.fixedInternetTariff.download' | translate }}
          </div>
          <div class="detail-item--value">{{ dnSpeed }} Mbps</div>
        </div>
        <div class="detail-item">
          <div class="detail-item--label">
            <i [inlineSVG]="'/assets/svg/dashboard-widgets/upload.svg'"></i>
            {{ 'wc.ecare.widgets.fixedInternetTariff.upload' | translate }}
          </div>
          <div class="detail-item--value">{{ upSpeed }} Mbps</div>
        </div>
      </div>
    </div>
    <div class="widget--cta">
      <div
        *ngIf="isPostpaid()"
        app-button
        class="change-tariff-btn"
        routerLink="/eshop/tariff-change"
        [queryParams]="{
        tariffSpaceId: subscription.id,
        tariffId: asset.id,
        customerAccountId: customerAccount?.id ? customerAccount.id : subscription.parentId,
        locationId: locationId,
        socketId: socketId
      }"
        [variant]="'primary'"
        [size]="'fullWidth'"
        [label]="'wc.ecare.dashboard.tariffChange.label' | translate"></div>
    </div>
  </ng-container>
</ng-container>
<ng-template #banner>
  <div class="full-height" app-cms-widget-banner [bannerTargeter]="bannerTargetArea"></div>
</ng-template>

<app-button
  *ngIf="editMode"
  class="remove-widget"
  iconPath="/assets/img/ecare/feather-minus.svg"
  variant="whiteWithBorder"
  placement="top"
  ngbTooltip="Remove widget">
</app-button>
