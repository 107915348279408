import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BlockTemplateComponent, EnumDataTypeService } from '@btl/btl-fe-wc-common';
import { DataTypeEnumDto, OrderDto, OrderItemDto, ProductDetailDto, ProductParamMetadataDto } from '@btl/order-bff';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OrderUtils } from '../../../helpers/order-utils';
import { addressTypes } from '../../../pipes/display-address.pipe';
import { ProductCustomService } from '@service/product-custom.service';
import { ProductInShoppingCart } from '../../../models/product-in-shopping-cart';

@Component({
  selector: 'app-product-configuration',
  templateUrl: './product-configuration.component.html',
  styleUrls: ['./product-configuration.component.css'],
})
export class ProductConfigurationComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input() productInShoppingCart: ProductInShoppingCart;
  @Input() orderItem: OrderItemDto;
  @Input() order: OrderDto;
  @Input() product: ProductDetailDto;
  @Input() dialogRef;
  @Input() dynamicProductParameters: Array<ProductParamMetadataDto>;

  blockTemplate = BlockTemplateComponent;
  dataTypeEnums: { [name: string]: DataTypeEnumDto };
  addressTypes = addressTypes;
  form: FormGroup = this.fb.group({});

  constructor(
    private productCustomService: ProductCustomService,
    private fb: FormBuilder,
    private enumDataTypeService: EnumDataTypeService,
    private orderingService: WcOrderingService
  ) {}

  ngOnInit(): void {
    this.enumDataTypeService.getEnums('pc').subscribe(result => {
      this.dataTypeEnums = {};
      result.forEach(e => (this.dataTypeEnums[e.name] = e));
    });

    if (this.dynamicProductParameters) {
      this.createForm();
    } else {
      this.productCustomService
        .getVisibleProductParameterMetadata(this.product.categoryId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: Array<ProductParamMetadataDto>) => {
          this.dynamicProductParameters = result;
          this.createForm();
        });
    }
  }

  subsidyChanged0 = false;
  subsidyPercentageDiscountChanged0 = false;

  subsidyChanged = false;
  subsidyPercentageDiscountChanged = false;

  createForm() {
    this.dynamicProductParameters.forEach(param => {
      const orderParamDto = this.orderItem.attributes.find(orderAttr => orderAttr.name === param.name);
      let value = null;
      if (orderParamDto) {
        value = orderParamDto.value;
      }
      this.form.addControl(param.name, this.fb.control(value, [Validators.pattern(param.validationRegex)]));
    });

    if (this.form.get('subsidyPercentageDiscount') && this.form.get('committedPeriod') && this.form.get('subsidy')) {
      this.form.get('subsidy').valueChanges.subscribe(value => {
        this.subsidyChanged = true;
        if (this.productInShoppingCart?.rcPrice && !this.subsidyPercentageDiscountChanged && this.subsidyChanged0) {
          const subsidyPercentageDiscount: any = (value / (this.productInShoppingCart.rcPrice * (this.form.get('committedPeriod').value / 100))).toFixed(0);
          if (this.form.get('subsidyPercentageDiscount').value !== subsidyPercentageDiscount) {
            this.form.get('subsidyPercentageDiscount').setValue(subsidyPercentageDiscount);
          }
        }
        this.subsidyChanged0 = true;
        this.subsidyPercentageDiscountChanged = false;
      });

      this.form.get('subsidyPercentageDiscount').valueChanges.subscribe(value => {
        this.subsidyPercentageDiscountChanged = true;
        if (this.productInShoppingCart?.rcPrice && !this.subsidyChanged && this.subsidyPercentageDiscountChanged0) {
          const subsidy: any = (value * this.productInShoppingCart.rcPrice * (this.form.get('committedPeriod').value / 100)).toFixed(0);
          if (this.form.get('subsidy').value !== subsidy) {
            this.form.get('subsidy').setValue(subsidy);
          }
        }
        this.subsidyPercentageDiscountChanged0 = true;
        this.subsidyChanged = false;
      });

      this.form.get('committedPeriod').valueChanges.subscribe(value => {
        if (this.productInShoppingCart?.rcPrice && !this.subsidyChanged && !this.subsidyPercentageDiscountChanged) {
          const subsidy: any = (this.form.get('subsidyPercentageDiscount').value * this.productInShoppingCart.rcPrice * (this.form.get('committedPeriod').value/100)).toFixed(0);
          if (this.form.get('subsidy').value !== subsidy) {
            this.form.get('subsidy').setValue(subsidy);
          }
        }
        this.subsidyPercentageDiscountChanged = false;
        this.subsidyChanged = false;
      });
    }
  }

  clear() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        if (control.enabled) {
          control.patchValue(null);
        }
      }
    });
  }

  set() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        OrderUtils.updateOrderItemAttr(this.orderItem.attributes, field, control.value);
      }
    });
    if (this.orderItem.action === 'NO_OPERATION') {
      this.orderItem.action = 'UPDATE';
    }
    this.orderingService.updateOrderItems(this.order, [this.orderItem]).subscribe(() => this.dialogRef.dismiss());
  }
}
