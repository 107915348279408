<div class="modal-header">
  <h4 class="modal-title">
    {{ 'wc.shopping.contact.' + addressType + '.heading' | translate }}
  </h4>
  <div class="modal-close" (click)="closePopup()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body contact-form" [formGroup]="form">
  <app-address-form
    #addressForm
    [type]="addressType"
    sourceName="crm"
    [form]="form"
    [parentGroup]="form"
    [main]="true"
    [columnsCount]="3"

    (formGeneratedEmitter)="addressFormGenerated()">
  </app-address-form>
</div>

<div class="modal-footer">
  <div class="button-wrapper">
    <app-button variant="link" label="{{ 'wc.common.close.button' | translate }}" (click)="closePopup()"></app-button>
    <app-button variant="primary" label="{{ 'wc.common.save.button' | translate }}" (click)="save()"></app-button>
  </div>
</div>
