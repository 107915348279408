<ng-container *ngIf="tariffProduct">
  <div class="widget--header">
    <div class="widget--header_title">
      <i [inlineSVG]="'/assets/svg/dashboard-widgets/mobile-tariff.svg'"></i>
      <h6>{{ 'wc.ecare.widgets.mobileTariffUsage.header' | translate }}</h6>
    </div>
    <app-subscription-select-widget
      [subscription]="subscription"
      [subscriptionsToSelect]="subscriptionsToSelect"
      [tariffIdentification]="tariffIdentification"
      (subscriptionChanged)="changeSelectedTariff($event)">
    </app-subscription-select-widget>
  </div>

  <div class="widget--content content-in-row">
    <div class="content-in-column space-between">
      <div
        app-ecare-widget-main-info
        class="widget--content_mainInfo"
        [label1]="customName"
        [label2]="mainTariffProduct ? mainTariffProduct.name : tariffProduct.name"
        [price]="monthPrice"
        [period]="'wc.ecare.widgets.thisMonth' | translate"
        [price2]="tariffProductPrice"
        [period2]="'wc.shopping.confirmation.price.RC.label' | translate"
        [priceOnTop]="amountOnTop"
        [periodOnTop]="'wc.ecare.widgets.amountOnTop' | translate"></div>
      <div>
        <div class="widget--cta">
          <div
            *ngIf="isPostpaid"
            app-button
            class="change-tariff-btn"
            routerLink="/eshop/tariff-change"
            [queryParams]="{
              tariffSpaceId: subscription.id,
              tariffId: asset.id,
              customerAccountId: customerAccount?.id ? customerAccount.id : subscription.parentId,
              locationId: locationId,
              socketId: socketId
            }"
            [variant]="'primary'"
            [size]="'fullWidth'"
            [label]="'wc.ecare.dashboard.tariffChange.label' | translate"></div>
        </div>
      </div>
    </div>
    <div class="content-in-column usages-box">
      <ng-container *ngFor="let widgetElementInfo of widgetElementsInfos | keyvalue">
        <div class="usage vertical">
          <mat-slider
            *ngIf="widgetElementInfo.value.limit > 0"
            min="1"
            max="100"
            step="1"
            thumbLabel><input matSliderThumb [value]="widgetElementInfo.value.usedPercentage" />
          </mat-slider>

          <i [inlineSVG]="'/assets/svg/dashboard-widgets/' + widgetElementInfo.value.type?.toLowerCase() + '.svg'"></i>
          <div class="usages-infos">
            <div class="content-in-row baseline">
              <ng-container *ngIf="widgetElementInfo.value.limit > 0">
                <span class="left-usage">{{ widgetElementInfo.value.leftUsage }}</span>
                <ng-container *ngIf="codebookService.getCodebookTexts('FUOM_UNITS') | async as codebook">
                  {{ codebook[widgetElementInfo.value.limitUnit] }}
                </ng-container>
                {{
                ' ' +
                ('wc.ecare.widgets.mobileInternetTariff.leftOf' | translate) +
                ' ' +
                widgetElementInfo.value.limit +
                ' '
                }}
                <ng-container *ngIf="codebookService.getCodebookTexts('FUOM_UNITS') | async as codebook">
                  {{ codebook[widgetElementInfo.value.limitUnit] }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="widgetElementInfo.value.limit < 0">
                <span class="left-usage">{{ widgetElementInfo.value.leftUsage }}</span>
                <ng-container *ngIf="codebookService.getCodebookTexts('FUOM_UNITS') | async as codebook">
                  {{ codebook[widgetElementInfo.value.limitUnit] }}
                </ng-container>
                {{
                ' ' +
                ('wc.ecare.widgets.usedOf' | translate) +
                ' '
                }}
                <span class="left-usage">∞</span>
                <ng-container *ngIf="codebookService.getCodebookTexts('FUOM_UNITS') | async as codebook">
                  {{ codebook[widgetElementInfo.value.limitUnit] }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="widgetElementInfo.value.limit === 0">
                <span class="left-usage">{{ widgetElementInfo.value.leftUsage }}</span>
                <ng-container *ngIf="codebookService.getCodebookTexts('FUOM_UNITS') | async as codebook">
                  {{ codebook[widgetElementInfo.value.limitUnit] }}
                </ng-container>
                {{
                ' ' +
                ('wc.ecare.widgets.used' | translate) +
                ', '
                }}
                <div class="price" [innerHTML]="widgetElementInfo.value.unitPrice | price"></div>
                {{ 'wc.ecare.widgets.unit.' + widgetElementInfo.value.limitUnit | translate }}
              </ng-container>
            </div>
            <div *ngIf="widgetElementInfo.value.priceOnTop > 0" class="on-top">
              +
              <div class="price" [innerHTML]="widgetElementInfo.value.priceOnTop | price"></div>
              {{ 'wc.ecare.widgets.amountOnTop' | translate }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <app-button
    *ngIf="editMode"
    class="remove-widget"
    iconPath="/assets/img/ecare/feather-minus.svg"
    variant="whiteWithBorder"
    placement="top"
    ngbTooltip="Remove widget">
  </app-button>
</ng-container>
