<nav class="header__primaryMenu">
  <div *ngIf="isSearchActive" class="drawer-overlay drawer-overlay_search"></div>
  <div class="menu-wrapper">
    <div class="primary-menu">
      <a id="016_navigation_menu" class="emeldi-logo" routerLink="/"></a>
      <div class="d-print-none">
        <a class="primary-menu__icon primary-menu__icon-hamburger" (click)="toggleShowMenu()">
          <i [inlineSVG]="'/assets/svg/hamburger_icon.svg'"></i>
        </a>
      </div>
      <a id="016b_navigation_menu" class="emeldi-logo-small" routerLink="/"></a>
      <div
        *ngIf="navigationType === NavigationTypeEnum.checkout"
        app-ordering-wizard
        class="ml-auto d-none d-lg-block"></div>
      <ng-template #navigation>
        <ng-container
          *ngIf="
            navigationType === NavigationTypeEnum.eshop ||
            (navigationType !== NavigationTypeEnum.checkout && !isContextLoaded)
          ">
          <!-- 001 Categories listing -->
          <ng-container *ngFor="let productGroupNode of productGroupsTree; let i = index">
            <a
              class="top-menu__item"
              [class.active]="isCurrentGroupOrParent(productGroupNode.group.id)"
              id="{{ i }}_023_navigation_menu"
              [routerLink]="getProductGroupNavigationUrl(productGroupNode)"
              title="{{ getGroupName(productGroupNode.group) }}">
              {{ getGroupName(productGroupNode.group) }}
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="navigationType === NavigationTypeEnum.ecare && isContextLoaded && showCustomerMenu">
          <a
            *ngFor="let pageModule of getCustomerMainMenuItems()"
            class="primary-menu__item"
            [id]="'navigation_menu_' + pageModule.code"
            [routerLink]="[pageModule.parentUrlPattern + pageModule.urlPattern]"
            [queryParams]="getUrlParams()"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLinkActive]="'nav-active'">
            {{ pageModules.getModuleText(pageModule.code) }}
          </a>

          <span ngbDropdown style="display: contents" (openChange)="toggleOthersMenu = $event">
            <a
              ngbDropdownToggle
              id="027_navigation_menu"
              class="primary-menu__item"
              title="{{ 'wc.common.navigation.others.button' | translate }}"
              [ngClass]="{ 'nav-active': toggleOthersMenu }">
              {{ 'wc.common.navigation.others.button' | translate }}
              <i class="navigation-dropdown_arrow" [inlineSVG]="'/assets/svg/menu-down-arrow.svg'"></i>
            </a>
            <span aria-labelledby="othersMenuDropdown" ngbDropdownMenu class="primary-menu__item-others-wrapper">
              <div class="nav-menu-items-others">
                <ng-container *ngFor="let pageModule of getCustomerOtherMenuItems()">
                  <!-- ToDo Add internal only parameter to  MODULE_PAGE -OPPORTUNITIES  CB -->
                  <app-acl-internal-evaluate-component *ngIf="pageModule.code === 'OPPORTUNITIES'; else allUsers">
                    <a
                      class="dropdown-menu__item"
                      ngbDropdownItem
                      [id]="'navigation_menu_' + pageModule.code"
                      [routerLink]="[pageModule.parentUrlPattern + pageModule.urlPattern]"
                      [queryParams]="getUrlParams()"
                      [routerLinkActiveOptions]="{ exact: true }"
                      [routerLinkActive]="'nav-active'">
                      <i class="menu-icon" [inlineSVG]="'/assets/svg/' + pageModule.cssClass + '.svg'"></i>
                      {{ pageModules.getModuleText(pageModule.code) }}
                    </a>
                  </app-acl-internal-evaluate-component>
                  <ng-template #allUsers>
                    <a
                      class="dropdown-menu__item"
                      ngbDropdownItem
                      [id]="'navigation_menu_' + pageModule.code"
                      [routerLink]="[pageModule.parentUrlPattern + pageModule.urlPattern]"
                      [queryParams]="getUrlParams()"
                      [routerLinkActiveOptions]="{ exact: true }"
                      [routerLinkActive]="'nav-active'">
                      <i class="menu-icon" [inlineSVG]="'/assets/svg/' + pageModule.cssClass + '.svg'"></i>
                      {{ pageModules.getModuleText(pageModule.code) }}
                    </a>
                  </ng-template>
                </ng-container>
              </div>
            </span>
          </span>
        </ng-container>
      </ng-template>
      <ng-template #mainNavigation>
        <ng-container *ngIf="navigationType === NavigationTypeEnum.eshop || !isContextLoaded">
          <!-- 001 Categories listing -->
          <ng-container *ngFor="let navigationGroupNode of navigationGroupsTree; let i = index">
            <ng-conatainer *ngIf="!navigationGroupNode.internal; else internalMenuGroup">
              <ng-container *ngTemplateOutlet="navigationGroupsItems" />
            </ng-conatainer>
            <ng-template #internalMenuGroup>
              <app-acl-internal-evaluate-component>
                <ng-container *ngTemplateOutlet="navigationGroupsItems" />
              </app-acl-internal-evaluate-component>
            </ng-template>

            <ng-template #navigationGroupsItems>
              <ng-container *ngIf="navigationGroupNode.children.length > 1; else showNavItemLink">
                <span ngbDropdown style="display: contents" (openChange)="toggleOthersMenu = $event">
                  <a
                    ngbDropdownToggle
                    class="primary-menu__item"
                    [class.nav-active]="isCurrentGroupOrParent(navigationGroupNode.group.id)"
                    id="{{ i }}_023_navigation_menu"
                    title="{{ getGroupName(navigationGroupNode.group) }}">
                    {{ getGroupName(navigationGroupNode.group) }}
                    <i class="navigation-dropdown_arrow" [inlineSVG]="'/assets/svg/menu-down-arrow.svg'"></i>
                  </a>
                  <span ngbDropdownMenu class="primary-menu__item-others-wrapper">
                    <div class="nav-menu-items-others one-column">
                      <a
                        *ngFor="let thirdGroupNode of navigationGroupNode.children; let i = index"
                        class="dropdown-menu__item"
                        ngbDropdownItem
                        [class.nav-active]="isCurrentGroupOrParent(thirdGroupNode.group.id)"
                        [routerLink]="'/eshop/product-listing/' + thirdGroupNode.group.seoUrl">
                        {{ getGroupName(thirdGroupNode.group) }}
                      </a>
                    </div>
                  </span>
                </span>
              </ng-container>
              <ng-template #showNavItemLink>
                <a
                  class="primary-menu__item"
                  [class.nav-active]="isCurrentGroupOrParent(navigationGroupNode.group.id)"
                  id="{{ i }}_023_navigation_menu"
                  [routerLink]="getProductGroupNavigationUrl(navigationGroupNode)"
                  title="{{ getGroupName(navigationGroupNode.group) }}">
                  {{ getGroupName(navigationGroupNode.group) }}
                </a>
              </ng-template>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-template>
      <div *ngIf="!isSearchActive" class="nav-links-large d-print-none">
        <ng-container *ngIf="navigationType === NavigationTypeEnum.eshop && navigationGroupsTree">
          <ng-container *ngTemplateOutlet="mainNavigation" />
        </ng-container>
        <ng-container *ngIf="navigationType !== NavigationTypeEnum.eshop && isContextLoaded">
          <ng-container *ngTemplateOutlet="navigation" />
        </ng-container>
      </div>
    </div>
    <div
      *ngIf="navigationType !== NavigationTypeEnum.checkout && !router.url.includes('ecare')"
      class="right-container">
      <div class="primary-menu__icons d-print-none">
        <div class="search-wrapper" (clickOutside)="closeSearchForm(searchForm)">
          <div class="primary-menu__icon-wrapper" (click)="showSearchForm(searchForm, hwSearch)">
            <i
              id="019_navigation_menu"
              class="primary-menu__icon primary-menu__icon-search"
              [inlineSVG]="'/assets/svg/search-icon.svg'"></i>
            <span class="primary-menu__icon-search_text">{{ 'wc.common.navigation.search.button' | translate }}...</span>
          </div>
          <form #searchForm class="primary-menu__icon-wrapper mainSearch" hidden [formGroup]="customersSearchFormGroup">
            <i
              id="019_navigation_menu"
              class="primary-menu__icon primary-menu__icon-search"
              [inlineSVG]="'/assets/svg/search-icon.svg'"></i>
            <input
              #hwSearch
              type="text"
              id="hw-search"
              placeholder="Type what are you searching for..."
              autocomplete="off"
              [value]="filter?.attributes?.text ? filter?.attributes?.text : ''"
              (input)="searchChange(hwSearch.value); autocomplete.hidden = false"
              (keyup.enter)="setFilteringText(hwSearch.value); autocomplete.hidden = true"
              (keyup.escape)="closeSearchForm(searchForm)"
              (focusin)="autocomplete.hidden = false" />
            <div class="key-mockup">esc</div>

            <div #autocomplete class="search-results_container">
              <div *ngFor="let product of autocompleteProducts | async; let i = index">
                <p *ngIf="i === 0" class="search-results_heading">All Results</p>
                <a
                  class="search-results_link"
                  id="{{ i }}_018_navigation_menu"
                  [routerLink]="['/eshop/product-detail/' + product._source.seoUrl]">
                  <img
                    *ngIf="product._source.image && product._source.image.medium"
                    alt="{{ product._source.name | selectLocaleProperty }}"
                    [src]="'/api/bff/order/v1/img/' + product._source.image.medium"
                    [defaultImage]="'/assets/img/products/loading.gif'" />
                  <img
                    *ngIf="(!product._source.image || !product._source.image.medium) && !product._source.picture"
                    src="/assets/img/products/phone_example-listing.png"
                    alt="{{ product._source.name | selectLocaleProperty }}" />
                  <span
                    class="search-results_text"
                    [innerHTML]="product._source.name | selectLocaleProperty | highlight : hwSearch.value"></span>
                </a>
              </div>
            </div>
          </form>
        </div>
        <div
          *ngIf="wishLists && wishLists.length > 0"
          class="primary-menu__icon-wrapper primary-menu__icon-wrapper-large"
          [routerLink]="['/eshop/wish-lists']"
          (mouseenter)="openWishListPopup()"
          (mouseleave)="closePopup()">
          <i
            id="021_navigation_menu"
            class="primary-menu__icon primary-menu__icon-wish-list"
            [inlineSVG]="'/assets/svg/red-heart-fill.svg'"></i>
        </div>
        <div
          *ngIf="showPopup === 'WishList'"
          class="wish-lists-box dropdown-menu"
          (mouseenter)="openWishListPopup()"
          (mouseleave)="closePopup()">
          <div class="wish-lists-box-title">{{ 'wc.common.navigation.wishLists' | translate }}</div>
          <div class="wish-lists-box-items">
            <ng-container *ngFor="let wishList of wishLists">
              <div
                class="dropdown-menu__item"
                key="wishList.id"
                [ngbTooltip]="wishList.name.length > 24 ? wishList.name : ''"
                (click)="this.router.navigate(['/eshop/wish-lists', wishList.id])">
                {{ wishList.name }}
                <span *ngIf="wishList.productCount > 0" class="badge badge-primary wishlist">{{
                  wishList.productCount
                }}</span>
              </div>
            </ng-container>
          </div>
          <hr />
          <div class="dropdown-menu__item showAll" (click)="this.router.navigate(['/eshop/wish-lists'])">
            {{ 'wc.common.navigation.wishLists.showAll' | translate }}
            <i [inlineSVG]="'/assets/svg/menu-down-arrow.svg'"></i>
          </div>
        </div>

        <div
          *ngIf="wishLists && wishLists.length === 0"
          class="primary-menu__icon-wrapper primary-menu__icon-wrapper-large">
          <i
            id="021_navigation_menu"
            class="primary-menu__icon primary-menu__icon-wish-list"
            [inlineSVG]="'/assets/svg/red-heart-outlined.svg'"
            [routerLink]="['/eshop/wish-lists']"></i>
        </div>
        <div *ngIf="preCalculatedShoppingCart" class="cart-menu">
          <ng-container
            *ngIf="
              preCalculatedShoppingCart.order &&
                preCalculatedShoppingCart.order.orderStateType !== orderStateTypeEnum.CONFIRMED &&
                preCalculatedShoppingCart.order.orderStateType !== orderStateTypeEnum.PENDING &&
                preCalculatedShoppingCart.quantityOfItemsInShoppingCart > 0;
              else emptyBasket
            ">
            <div class="primary-menu__icon-wrapper" (click)="openShoppingCartPopup()">
              <i
                id="020_navigation_menu"
                class="primary-menu__icon primary-menu__icon-cart primary-menu__icon-cart-solid"
                [inlineSVG]="'/assets/svg/shopping-cart-icon-filled.svg'">
              </i>
              <span class="badge badge-primary">
                {{ preCalculatedShoppingCart.quantityOfItemsInShoppingCart }}
              </span>

              <div *ngIf="preCalculatedShoppingCart.totalOcPrice" class="total-price">
                <one-time-price [price]="preCalculatedShoppingCart.totalOcPrice" />
              </div>
            </div>
          </ng-container>
          <ng-template #emptyBasket>
            <div class="primary-menu__icon-wrapper" (click)="openShoppingCartPopup()">
              <i
                id="022_navigation_menu"
                class="primary-menu__icon primary-menu__icon-cart"
                [inlineSVG]="'/assets/svg/shopping-cart-icon.svg'"></i>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div *ngIf="router.url.includes('ecare') && showCustomerMenu" class="right-container">
      <app-ecare-customer-account-box [customer]="customer" [selectedAccount]="selectedParty" />
    </div>
  </div>
</nav>
