import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContactDto, CustomerDto, OrderDto, SortDto, TicketDto } from '@btl/order-bff';
import { CompareType, EnableDynamicLoading, ServiceUtils } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { OpportunityDetailsComponent } from './opportunity-details/opportunity-details.component';
import { OpportunityFilterComponent } from './opportunity-filter/opportunity-filter.component';
import { TicketService } from '@service/ticket.service';
import { ScenarioTypeEnum } from '../../../helpers/order-utils';
import { AbstractTicketTableComponent } from '../tickets/abstract-ticket-table.component';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { TicketElse } from '../../../models/tickets-holder';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

export declare type OpportunityProbabilityType = 'hot' | 'warm' | 'cold';

@Component({
  selector: 'app-opportunities-component',
  templateUrl: './ecare-opportunities.component.html',
})
@EnableDynamicLoading({ customName: EcareOpportunitiesComponent.PAGE_ID })
export class EcareOpportunitiesComponent extends AbstractTicketTableComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareOpportunitiesComponent';

  pageId(): string {
    return EcareOpportunitiesComponent.PAGE_ID;
  }

  @Input() canDisplayNoTicketsText: boolean;

  @ViewChild('opportunityFilterComponent') opportunityFilterComponent: OpportunityFilterComponent;
  @ViewChild(MatSort) sort: MatSort;

  defaultSortOrdering: SortDto = { column: 'slaDueDate', sortOrder: 'desc' };
  columns = [
    { column: 'subject', label: 'wc.ecare.ticket.subject' },
    { column: 'priority', label: 'wc.ecare.ticket.priority' },
    { column: 'opportunityState', label: 'wc.ecare.ticket.state' },
    { column: 'expectedRevenue', label: 'wc.ecare.ticket.expectedRevenue' },
    { column: 'customerDisplayName', label: 'wc.ecare.opportunity.customersName' },
    { column: 'slaDueDate', label: 'wc.ecare.ticket.dueDate' },
    { column: 'teamAssignment', label: 'wc.ecare.ticket.salesTeam' },
    { column: 'assignedTo', label: 'wc.ecare.ticket.assignedTo' },
  ];

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(queryParams => {
      this.cuRefNo = queryParams['cuRefNo'];
      this.caRefNo = queryParams['caRefNo'];
      this.queryParams = queryParams;
    });

    this.initSearch(OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE, 'CUSTOMER')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (!this.externalSearchMode) {
          if (this.authService.account) {
            this.loadData();
          }
        } else {
          this.extendTickets(this.inputTickets);
        }
      });

    this.authService.accountChange.subscribe(() => {
      this.search.filtering.push({
        column: 'assignedTo',
        compareType: 'EQUAL',
        value: this.authService.account.login,
      });
      this.loadData();
    });
  }

  loadData() {
    this.appBlockerService.block();
    this.ticketService
      .searchByText(this.search, this.pageSize, this.from, OpportunityDetailsComponent.OPPORTUNITY_TICKET_TYPE)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.totalItems = result.total.value;
        this.extendTickets(result?.hits.map(ticketHolder => ticketHolder._source));
      });
  }

  extendTickets(resultTickets: any[]) {
    const tickets = [];
    const customers: string[] = [];
    const orders: string[] = [];

    if (resultTickets) {
      resultTickets.forEach(ticket => {
        const formControl = new FormControl();
        formControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
          this.allTicketsSelected = this.areAllSelected();
          this.anyTicketsSelected = this.isAnySelected();
        });
        this.selectedFormArray.push(formControl);

        const tasks: string[] = [];
        ticket['expectedRevenue'] = TicketService.getParamValue(ticket, TicketService.EXPECTED_REVENUE_PARAM_NAME);
        if (ticket.slaDueDate) {
          ticket['percentageToDueDate'] = this.calculatePercentage(
            new Date(ticket.created),
            new Date(ticket.slaDueDate)
          );
        }

        ticket['salesTeam'] = TicketService.getParamValue(ticket, TicketService.TEAM_ASSIGNMENT_PARAM_NAME);
        ticket['state'] = TicketService.getParamValue(ticket, TicketService.OPPORTUNITY_STATE_PARAM_NAME);
        ticket['opportunityType'] = TicketService.getParamValue(ticket, TicketService.OPPORTUNITY_TYPE_PARAM_NAME);
        ticket['probability'] = TicketService.getParamValue(ticket, TicketService.OPPORTUNITY_PROBABILITY_PARAM_NAME);
        ticket['currentOperator'] = TicketService.getParamValue(ticket, TicketService.CURRENT_OPERATOR_PARAM_NAME);
        ticket['preferredContact'] = TicketService.getJsonParamValue(
          ticket,
          TicketService.PREFERRED_CONTACT_PARAM_NAME
        );
        ticket['customer'] = ticket.parties?.find(party => party.partyRole === 'Customer')?.displayName;
        ticket['customerAccount'] = ticket.parties?.find(party => party.partyRole === 'CustomerAccount')?.displayName;
        if (!ticket['customer']) {
          ticket['customer'] = TicketService.getReferenceValue(ticket, 'com.emeldi.ecc.be.crm.dto.Customer');
          if (ticket['customer']) {
            customers.push(ticket['customer']);
          }
        }

        if (!ticket['customerAccount']) {
          ticket['customerAccount'] = TicketService.getReferenceValue(
            ticket,
            'com.emeldi.ecc.be.crm.dto.CustomerAccount'
          );
        }

        if (!ticket['customer']) {
          const customer: CustomerDto = TicketService.getJsonParamValue(
            ticket,
            TicketService.OPPORTUNITY_CUSTOMER_PARAM_NAME
          );
          const customerNames = [];

          if (customer) {
            if (customer.subject.firstName) {
              customerNames.push(customer.subject.firstName);
            }
            if (customer.subject.lastName) {
              customerNames.push(customer.subject.lastName);
            }
          } else {
            const contact: ContactDto = TicketService.getJsonParamValue(
              ticket,
              TicketService.OPPORTUNITY_CONTACT_PARAM_NAME
            );
            if (contact) {
              if (contact.firstName) {
                customerNames.push(contact.firstName);
              }
              if (contact.lastName) {
                customerNames.push(contact.lastName);
              }
            }
          }
          if (customerNames.length > 0) {
            ticket['customer'] = customerNames.join(' ');
          }
        }

        ticket.references.forEach(reference => {
          if (reference.entityType === 'com.emeldi.ecc.be.ticket.dto.Ticket') {
            tasks.push(reference.entityId);
          }
          if (reference.entityType === 'com.emeldi.ecc.be.order.dto.Order') {
            orders.push(reference.entityId);
          }
        });
        ticket['tasksIds'] = tasks;
        ticket['tasks'] = [];
        tickets.push(ticket);
      });

      if (customers.length > 0) {
        const search = ServiceUtils.getUnlimitedSearch();
        search.filtering.push({
          column: 'id',
          compareType: CompareType.CompareTypeDtoEnum.IN,
          value: customers,
        });
        this.customerService
          .getCustomers(search)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(customers => {
            tickets.forEach(ticket => {
              if (ticket['customer']) {
                const customer = customers.data.find(loopCustomer => loopCustomer.id === ticket['customer']);
                if (customer) {
                  ticket['customer'] = customer.displayName;
                  if (ticket['customerAccount']) {
                    const ca = this.customerLocalStorageService.getPartyByIdRecursive(
                      customer.childParties,
                      ticket['customerAccount']
                    );
                    if (ca) {
                      ticket['customerAccount'] = ca.displayName;
                    }
                  }
                }
              }
            });
          });
      }

      if (orders.length > 0) {
        const search = ServiceUtils.getUnlimitedSearch();
        search.filtering.push({
          column: 'id',
          compareType: CompareType.CompareTypeDtoEnum.IN,
          value: orders,
        });
        this.orderingService
          .filterOrders(search, null)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(pagedOrders => {
            tickets.forEach(ticket => {
              const orders: OrderDto[] = [];
              const quotes: OrderDto[] = [];
              ticket.references.forEach(ref => {
                if (ref.entityType === 'com.emeldi.ecc.be.order.dto.Order') {
                  const order = pagedOrders.data.find(loopCustomer => loopCustomer.id === ref.entityId);
                  if (order.scenarioType === ScenarioTypeEnum.QUOTE_MANAGEMENT) {
                    orders.push(order);
                  } else {
                    quotes.push(order);
                  }
                }
              });
              ticket['orders'] = orders;
              ticket['quotes'] = quotes;
            });
          });
      }

      if (this.externalSearchMode) {
        this.tickets.length = 0;
      }
      tickets.forEach(ticket => this.tickets.push(ticket));
      this.loading = false;
      if (this.tickets.length > 0 && this.tickets.length < this.totalItems) {
        this.moreRecordsExists = true;
      } else {
        this.moreRecordsExists = false;
      }
    }
  }

  showDetails(ticketDto: TicketElse, inNewTab: boolean = false) {
    if (this.editCallback) {
      this.editCallback(ticketDto, inNewTab);
    } else {
      if (inNewTab) {
        window.open(
          this.router.serializeUrl(this.siblingUrlTree('OpportunityDetailsComponent', { id: ticketDto.id })),
          '_blank'
        );
      } else {
        this.navigateSibling('OpportunityDetailsComponent', { id: ticketDto.id });
      }
    }
  }

  new() {
    this.navigateSibling('OpportunityDetailsComponent', { id: 'newTicket' });
  }

  delete(ticket: TicketDto) {
    this.modalFactory.deleteEntityModal((dialogReference: NgbModalRef) => {
      this.ticketingService
        .deleteTicket(ticket.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.stickyMessageService.addStickySuccessMessage('wc.ecare.opportunities.opportunityDeleted');
          this.tickets.length = 0;
          this.loadData();
          dialogReference.dismiss();
        });
    });
  }

  get appCurrency(): string {
    return environment.currency;
  }

  onPrevPage() {
    this.search.paging.page--;
    this.loadData();
  }

  onNextPage() {
    this.search.paging.page++;
    this.loadData();
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.page = 1;
    this.search.paging.pageSize = pageSize;
    this.loadData();
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.loadData();
  }

  getOpportunityProbabilityType(probability: number): OpportunityProbabilityType {
    if (probability <= 30) {
      return 'cold';
    }
    if (probability <= 70) {
      return 'warm';
    }
    return 'hot';
  }

  sectionTabsChange(event: any) {
    if (event === 'MY') {
      this.search.filtering = this.search.filtering.filter(filter => filter.column != 'teamAssignment');
      this.search.filtering.push({
        column: 'assignedTo',
        compareType: CompareTypeDtoEnum.EQUAL,
        value: this.account.login,
      });
    } else if (event === 'TEAM') {
      this.search.filtering = this.search.filtering.filter(filter => filter.column != 'assignedTo');
      this.search.filtering.push({
        column: 'teamAssignment',
        compareType: null,
        value: this.account.roles.map(role => role.roleName).join(','),
      });
    } else {
      this.search.filtering = this.search.filtering.filter(
        filter => filter.column != 'assignedTo' && filter.column != 'teamAssignment'
      );
    }
    this.tickets.length = 0;
    this.from = 0;
    this.loadData();

    this.allTicketsSelected = true;
    this.selectDeselectAll();
  }

  calculatePercentage(startDate: Date, endDate: Date): number {
    if (startDate && endDate) {
      // Get the current date
      const currentDate: Date = new Date();
      // Calculate the difference in milliseconds between the current date and the start date
      const timeDifferenceStart = currentDate.getTime() - startDate.getTime();

      // Calculate the difference in milliseconds between the end date and the start date
      const timeDifferenceTotal = endDate.getTime() - startDate.getTime();

      // Calculate the percentage
      let percentage = (timeDifferenceStart / timeDifferenceTotal) * 100;

      if (percentage > 100) {
        percentage = 100;
      }
      return percentage;
    }
    return null;
  }

  duplicate(ticket: TicketDto) {
    this.ticketService
      .getDuplicateParameters(ticket)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(duplicateParamters => {
        if (duplicateParamters) {
          this.navigateSibling('OpportunityDetailsComponent', duplicateParamters);
        }
      });
  }
}
