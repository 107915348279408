import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import _ from 'lodash';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { AbstractPageComponent, BlockTemplateComponent, EnableDynamicLoading, Search } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '@service/customer.service';
import { CustomerAccountDto, PagedCustomerAccountsDto, SortDto } from '@btl/order-bff';

@Component({
  selector: 'app-ecare-customer-accounts',
  templateUrl: './ecare-customer-accounts.component.html',
})
@EnableDynamicLoading({ customName: EcareCustomerAccountsComponent.PAGE_ID })
export class EcareCustomerAccountsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareCustomerAccountsComponent';

  pageId(): string {
    return EcareCustomerAccountsComponent.PAGE_ID;
  }

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  @Input() pageSize: number = 20;

  @Input() customerId: string;
  @Input() canChangeCustomer = true;

  @Output() readonly selectedCustomerAccount = new EventEmitter<CustomerAccountDto>();

  customerAccountDtos: CustomerAccountDto[] = [];
  pagedDto: PagedCustomerAccountsDto;
  canDisplayNoDataText = false;
  defaultSortOrdering: SortDto = { column: 'displayName', sortOrder: 'asc' };
  columns = [
    { column: 'displayName', label: 'wc.ecare.customerAccounts.table.customName.header' },
    { column: 'variableSymbol', label: 'wc.ecare.customerAccounts.table.variableSymbol.header' },
  ]


  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  constructor(
    private renderer: Renderer2,
    private readonly customerService: CustomerService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');
  }

  ngOnInit(): void {
    this.search.paging.pageSize = this.pageSize;
    //TODO BTL-6943 uncomment when customer sorting works
    //this.search.sorting.push(this.defaultSortOrdering);
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadData();
  }

  private loadData() {
    this.blockUIElement.start();
    this.customerService
      .filterCustomerAccounts(this.search)
      .pipe(
        finalize(() => {
          this.canDisplayNoDataText = _.isEmpty(this.customerAccountDtos);
          this.blockUIElement.stop();
        })
      )
      .subscribe(pagedCustomers => {
        this.pagedDto = pagedCustomers;
        this.customerAccountDtos = pagedCustomers.data;
        this.blockUIElement.stop();
      });
  }

  onSortChanged() {
    this.loadData();
  }

  onPrevPage() {
    this.search.paging.page--;
    this.loadData();
  }

  onNextPage() {
    this.search.paging.page++;
    this.loadData();
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.page = 1;
    this.search.paging.pageSize = pageSize;
    this.loadData();
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.loadData();
  }

  itemSelected(customerAccount: CustomerAccountDto): void {
    this.selectedCustomerAccount.emit(customerAccount);
  }
}
