import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { OrderDto } from '@btl/order-bff';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { PrecalculatedShoppingCart } from 'app/models/precalculated-shopping-cart';
import { ShoppingCartItemComponent } from '../shopping-cart-item/shopping-cart-item.component';
import _ from 'lodash';
import { Observable } from 'rxjs';

/**
 * Component for wrapping ShoppingCartItemComponents. It is passing inputs parameters to each ShoppingCartItemComponents and passing down
 * ShoppingCartItemComponent events. It is also summing RC, OC prices and taxes of containing items.
 */
@Component({
  selector: 'app-shopping-cart-items, [app-shopping-cart-items]',
  templateUrl: './shopping-cart-items.component.html',
})
export class ShoppingCartItemsComponent {
  @Output()
  readonly onChange: EventEmitter<OrderDto> = new EventEmitter<OrderDto>();

  @Input() notFromShoppingCart = false;
  @Input() showQuantityChange = true;
  @Input() showQuantity: boolean = true;
  @Input() showRemoveBtn = true;
  @Input() showConfigurationBtn = true;
  @Input() displayMode: 'FULL' | 'COMPACT' | 'ROLLOUT';
  @Input() showSpecialOffer = false;
  @Input() showGoToProductListingLink = true;
  @Input() showDetails = false;
  @Input() showAddons = false;
  @Input() showImg = true;
  @Input() readOnlyOrder: boolean = false;

  precalculatedShoppingCartReady = false;

  @ViewChildren(ShoppingCartItemComponent)
  private shoppingCartItemComponents: QueryList<ShoppingCartItemComponent>;

  readOnlyPreCalculatedShoppingCart;
  orderItemsFormsValues = {};

  get precalculatedShoppingCart(): PrecalculatedShoppingCart {
    if (this.readOnlyOrder) {
      return this.readOnlyPreCalculatedShoppingCart;
    } else {
      return this.shoppingCartService.preCalculatedShoppingCart;
    }
  }

  constructor(private shoppingCartService: ShoppingCartService) {}

  useOrder(orderDto: OrderDto): Observable<PrecalculatedShoppingCart> {
    return this.shoppingCartService.createNewPreCalculatedShoppingCart(orderDto).pipe(result => {
      this.readOnlyPreCalculatedShoppingCart = this.shoppingCartService.readOnlyOrder;
      this.precalculatedShoppingCartReady = true;
      return result;
    });
  }

  /**
   * Perform validate() on all child instances of ShoppingCartItemComponent.
   *
   * @return true if everything is valid, false otherwise.
   */
  validate(): boolean {
    // If no details are displayed, there is nothing to validate.
    if (!this.showDetails) {
      return true;
    }

    let validationResult = true;
    this.shoppingCartItemComponents.forEach((itemComponent: ShoppingCartItemComponent) => {
      if (!itemComponent.validate()) {
        validationResult = false;
      }
    });

    return validationResult;
  }

  /**
   * Perform save() on all child instances of ShoppingCartItemComponent.
   *
   * @param order The order to save the data in.
   */
  save(order: OrderDto): void {
    this.shoppingCartItemComponents.forEach((itemComponent: ShoppingCartItemComponent) => {
      itemComponent.save(order);
    });
  }

  hasOrderItems(): boolean {
    return !_.isNil(this.precalculatedShoppingCart) && !_.isEmpty(this.precalculatedShoppingCart.products);
  }
}
