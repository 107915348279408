<div app-breadcrumb [breadcrumbs]="breadcrumbs"></div>

<app-page-header *ngIf="selectedWishList" title="{{ selectedWishList.name }}">
  <div *ngIf="selectedWishList.isManageable" class="content-share_container ml-2">
    <div ngbDropdown class="d-inline-block">
      <app-button
        label="Share"
        variant="whiteWithBorder"
        size="small"
        iconPathAfter="/assets/svg/share-icon.svg"
        id="shareDropdown"
        ngbDropdownToggle></app-button>
      <div ngbDropdownMenu aria-labelledby="shareDropdown">
        <div class="dropdown-menu-container">
          <div ngbDropdownItem class="dropdown-item dropdown-menu__item" (click)="shareWishlistPopup(selectedWishList)">
            <div class="social-button social-button-mail">
              <i class="social-button-icon" [inlineSVG]="'/assets/svg/mail_icon.svg'"></i>
              {{ 'wc.shopping.gui_wishlist.shareWishList.shareViaEmail' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-button
    variant="secondary"
    size="shrink"
    class="ml-auto mr-2"
    iconPath="/assets/svg/delete.svg"
    label="{{ 'wc.shopping.gui_wishlist.wishListDetail.deleteList.deleteWishlist' | translate }}"
    (click)="deleteWishlistPopup(selectedWishList); $event.stopPropagation()"></app-button>
</app-page-header>

<ng-container *ngIf="selectedWishList && productsStock && !selectedWishList.isUserOwner">
  <p>
    <ngb-alert>
      <strong>{{
      selectedWishList.ownerName
        ? selectedWishList.ownerName
        : ('wc.shopping.gui_wishlist.wishListDetail.somebody' | translate)
      }}</strong>
      &nbsp;{{ 'wc.shopping.gui_wishlist.wishListDetail.sentYouThisWishList' | translate }}</ngb-alert
    >
  </p>
</ng-container>

<div class="wish-list-detail">
  <ng-container *ngIf="selectedWishList; else noWishList">
    <div *ngFor="let item of selectedWishListItems" class="shoppingCart-container shoppingCart-parent-item">
      <div *ngIf="item.product" class="parent shoppingCart">
        <div class="parent-img-container">
          <div class="parent-img">
            <ng-container *ngIf="isHw(item); else non_hw">
              <img
                *ngIf="item.product.picturePreview"
                alt="{{ item.product.name }}"
                src="{{ item.product.picturePreview.href }}" />
              <img *ngIf="!item.product.picturePreview" src="/assets/img/products/default_product_image.png" />
            </ng-container>
            <ng-template #non_hw>
              <img
                *ngIf="item.product.picturePreview"
                alt="{{ item.product.name }}"
                src="{{ item.product.picturePreview.href }}" />
              <img
                *ngIf="!item.product.picturePreview"
                src="/assets/img/products/tariff_default_product_image.png"
                alt="{{ item.product.label | selectLocaleProperty }}" />
            </ng-template>
          </div>
        </div>
        <div class="parent-name">
          <div class="parent-name-inner">
            <a [routerLink]="['/eshop/product-detail/' + item.product.seoUrl]">{{ item.product.name }}</a>
            <app-acl-internal-evaluate-component>
              <div>
                {{ item.product.productCode }}
              </div>
            </app-acl-internal-evaluate-component>
          </div>
          <div class="parent-action-container">
            <div *ngIf="productsStock[item.product.productCode] as stock" class="wish-list-item-price">
              <div *ngIf="stock.realStock > 0; else noStock">
                <span [innerHTML]="stock.text | safeHtml"></span>
              </div>
              <ng-template #noStock>
                <div>
                  {{ 'wc.shopping.gui_productCard.main.itemAvailability.outOfStock' | translate }}
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="inner-quantity wishlist-action">
          <ng-container *ngIf="productsStock[item.product.productCode] as stock">
            <app-button
              *ngIf="stock.realStock > 0"
              label="{{
                isQuoteOrder
                  ? ('wc.ecare.productListing.addToQuote' | translate)
                  : ('wc.ecare.productListing.addToCart' | translate)
              }}"
              iconPath="/assets/svg/shopping-cart-icon.svg"
              variant="secondary"
              (click)="addProductToCart(item.product)"></app-button>
          </ng-container>
        </div>

        <div class="rightSide-container">
          <div class="parent-prices">
            <ng-container *ngIf="hasProductPrice(item.product)">
              <ng-container *ngIf="showVat; else noVat">
                <div *ngIf="item.product.prices['RC']?.price" class="inner-prices">
                  <div class="price-name">
                    {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                  </div>
                  <div class="price-amount">
                    <app-recurrent-price [disableTexts]="true" [price]="item.product.prices['RC']?.price">
                    </app-recurrent-price>
                  </div>
                </div>
                <div *ngIf="item.product.prices['OC']?.price" class="inner-prices">
                  <div class="price-name">
                    {{ 'wc.shopping.confirmation.price.OC.label' | translate }}
                  </div>
                  <div class="price-amount one-time">
                    <one-time-price [price]="item.product.prices['OC']?.price"></one-time-price>
                  </div>
                </div>
              </ng-container>
              <ng-template #noVat>
                <div *ngIf="item.product.prices['RC']?.tax" class="inner-prices">
                  <div class="price-name">
                    {{ 'wc.shopping.confirmation.price.RC.label' | translate }}
                  </div>
                  <div class="price-amount">
                    <app-recurrent-price [disableTexts]="true" [price]="getTaxPrice(item.product.prices['RC'])">
                    </app-recurrent-price>
                  </div>
                </div>
                <div *ngIf="item.product.prices['OC']?.tax" class="inner-prices">
                  <div class="price-name">
                    {{ 'wc.shopping.confirmation.price.OC.label' | translate }}
                  </div>
                  <div class="price-amount one-time">
                    <one-time-price [price]="getTaxPrice(item.product.prices['OC'])"></one-time-price>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </div>
          <div class="parent-action">
            <a
              *ngIf="this.selectedWishList.isManageable"
              title="{{ 'wc.shopping.gui_wishlist.wishListDetail.removeProductFromList' | translate }}"
              (click)="deleteProductFromWishList(selectedWishList.id, item.id)">
              <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="hasWishListItems(); else noWishList" class="summary-page-actions displayMode-full">
      <div class="discount-container">
        <app-button label="Back to wishlists" variant="secondary" [routerLink]="['/eshop/wish-lists']"></app-button>
      </div>
      <div class="summary-container">
        <div class="prices-container">
          <div class="summary-page-prices">
            <div class="prices one-time-price">
              <div class="prices-label">
                {{ 'wc.shopping.confirmation.totalTodayPrice.label' | translate }}
              </div>
              <ng-container *ngIf="showVat; else noVatOc">
                <div class="prices-unit">
                  <one-time-price [price]="totalPriceOC"></one-time-price>
                </div>
              </ng-container>
              <ng-template #noVatOc>
                <div class="prices-unit">
                  <one-time-price [price]="totalPriceOCtax"></one-time-price>
                </div>
              </ng-template>
            </div>
            <div class="prices monthly-price">
              <div class="prices-label">
                {{ 'wc.shopping.confirmation.monthlyPrice.label' | translate }}
              </div>
              <ng-container *ngIf="showVat; else noVatRc">
                <div class="prices-unit">
                  <one-time-price [price]="totalPriceRC"></one-time-price>
                </div>
              </ng-container>
              <ng-template #noVatRc>
                <div class="prices-unit">
                  <one-time-price [price]="totalPriceRCtax"></one-time-price>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="taxes">
          <div class="button-radio-container">
            <div *ngIf="showVatButton" class="tax-switch-button">
              <div class="label">{{ 'wc.shopping.confirmation.taxes.label' | translate }}</div>
              <app-toggle-switch checked (change)="changeCheckbox()"></app-toggle-switch>
            </div>
          </div>
        </div>

        <div class="navigation-container">
          <div class="navigation-item">
            <app-button
              *ngIf="showBuyAll()"
              size="fullWidth"
              label="{{ 'wc.shopping.gui_wishlist.wishListDetail.buyAllProducts' | translate }}"
              (click)="addAllProductsToCart()">
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noWishList>
    <div class="text-center">
      <p>
        {{ 'wc.shopping.gui_wishlist.wishListDetail.noWishList' | translate }}
      </p>
    </div>
  </ng-template>
</div>
