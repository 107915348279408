import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppBlockerService, AuthFactoryService, BlockTemplateComponent, Search } from '@btl/btl-fe-wc-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerLocalStorageService, StorageCustomerDto } from '@service/customer-local-storage.service';
import { CustomerService } from '@service/customer.service';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { EcareOrdersFilterComponent } from '../../ecare-orders/ecare-orders-filter/ecare-orders-filter.component';

export enum SearchEntityType {
  CUSTOMER = 'customerSearch',
  ORDER = 'orderSearch',
  OPPORTUNITIES = 'opportunitySearch',
}

export interface SearchEntityDataType {
  searchEntity: SearchEntityType;
  searchValue: string;
}

@Component({
  selector: 'app-ecare-search-filter, [app-ecare-search-filter]',
  templateUrl: './ecare-search-filter.component.html',
})
export class EcareSearchFilterComponent implements OnInit, AfterViewChecked {
  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  @ViewChild('filterComponent', { static: false }) filterComponent: EcareOrdersFilterComponent;
  @Output() readonly handleSearch = new EventEmitter<SearchEntityDataType>();
  @Output() readonly handleChangeEntity = new EventEmitter<SearchEntityType>();
  @Input() isHomepage: boolean = false;

  cacheFilterKey = 'ecareFilterCache';
  orderCacheFilterKey = 'ecareOrderFilterCache';

  entitySearchForm: FormGroup;
  toggleEntitySelection: boolean;
  isModuleBffCrmEnabled = false;
  lastVisitedCustomers;
  orderSearch: Search = {
    filtering: [],
    sorting: [{ column: 'created', sortOrder: 'desc' }],
    paging: { pageSize: 10, page: 1 },
  };

  searchChangedEmitter = new EventEmitter<Search>();
  filterOrderIdChangedEmitter = new EventEmitter<string>();

  constructor(
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private customerService: CustomerService,
    private customerLocalStorageService: CustomerLocalStorageService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    public authFactoryService: AuthFactoryService,
  ) {}

  ngOnInit(): void {
    this.propertyAccessorLocalService
      .isModuleBffCrmEnabled()
      .subscribe(bffCrmEnabled => (this.isModuleBffCrmEnabled = bffCrmEnabled));
    this.initSearchFilter();
    this.customerLocalStorageService.clearSearchField.subscribe(clear => {
      if (clear) {
        this.clearFilterValue();
      }
    });

    const currentCustomerId = this.customerLocalStorageService.getCurrentCustomer()?.id;
    this.lastVisitedCustomers = this.customerLocalStorageService
      .getLastCustomers()
      .filter(cu => !currentCustomerId || currentCustomerId !== cu.id)
      .slice()
      .reverse();
  }

  changeSearchEntity(selectedSearchEntity?: SearchEntityType): void {
    sessionStorage.setItem(this.cacheFilterKey, JSON.stringify(this.entitySearchForm.getRawValue()));
    const searchFormValues = this.getEntitySearchFormDefault();
    searchFormValues.searchEntity = selectedSearchEntity;
    this.entitySearchForm.reset(searchFormValues);
    this.handleChangeEntity.emit(selectedSearchEntity);
    if (this.entitySearchForm.value.searchEntity === SearchEntityType.ORDER) {
      setTimeout(() => this.orderFilterChanged(), 100);
    }
  }

  doSearch() {
    this.handleSearch.emit(this.entitySearchForm.getRawValue());
  }

  selectCustomer(customerId: string) {
    this.appBlockerService.block();
    this.customerService
      .getCustomer(customerId, true)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(customerDto => {
        if (customerDto) {
          this.router.navigate(['/ecare/dashboard'], {
            queryParams: {
              cuRefNo: customerDto.id,
            },
          });
        }
      });
  }

  getLastCustomersList(): StorageCustomerDto[] {
    const currentCustomerId = this.customerLocalStorageService.getCurrentCustomer()?.id;
    return this.customerLocalStorageService
      .getLastCustomers()
      .filter(cu => !currentCustomerId || currentCustomerId !== cu.id)
      .slice()
      .reverse();
  }

  get searchEntityTypes(): SearchEntityType[] {
    const searchEntityTypes = Object.keys(SearchEntityType);
    if (!this.isModuleBffCrmEnabled) {
      // remove unsupported search types
      const customerIndex = searchEntityTypes.indexOf('CUSTOMER');
      if (customerIndex >= 0) {
        searchEntityTypes.splice(customerIndex, 1);
      }
      const opportunitiesIndex = searchEntityTypes.indexOf('OPPORTUNITIES');
      if (opportunitiesIndex >= 0) {
        searchEntityTypes.splice(opportunitiesIndex, 1);
      }
    }
    return searchEntityTypes.map(e => SearchEntityType[e]);
  }

  get activeSearchEntity(): SearchEntityType {
    return this.entitySearchForm.get('searchEntity').value;
  }

  private getEntitySearchFormDefault(): SearchEntityDataType {
    return {
      searchEntity: this.searchEntityTypes?.length ? this.searchEntityTypes[0] : undefined,
      searchValue: null,
    };
  }

  public clearFilterValue() {
    this.entitySearchForm.get('searchValue').patchValue('');
  }

  private initSearchFilter() {
    // handle search on page init
    const routerState = this.location.getState() ? this.location.getState()['state'] : undefined;
    if (routerState && routerState['searchData']) {
      const inputSearchData = routerState['searchData'] as SearchEntityDataType;
      if (inputSearchData.searchEntity) {
        this.doSearch();
        return;
      }
    }

    // set default filter
    const searchFormDefault = this.getEntitySearchFormDefault();
    this.buildSearchForm(searchFormDefault);

    const search: Search = JSON.parse(sessionStorage.getItem(this.orderCacheFilterKey));
    if (search) {
      const idSearch = search.filtering.find(filter => filter.column === 'id');
      if (idSearch) {
        this.entitySearchForm.get('searchValue').patchValue(idSearch.value);
      }
      this.orderSearch = search;
    }

    const filter = JSON.parse(sessionStorage.getItem(this.cacheFilterKey));
    if (filter) {
      filter.searchValue = null;
      this.entitySearchForm.get('searchEntity').patchValue(filter.searchEntity);
      this.handleChangeEntity.emit(filter.searchEntity);
      return;
    }
  }

  buildSearchForm(searchForm) {
    this.entitySearchForm = this.formBuilder.group(searchForm);
    this.entitySearchForm.controls.searchValue.valueChanges.subscribe(value => {
      this.filterOrderIdChangedEmitter.emit(value);
    });
    this.handleChangeEntity.emit(searchForm.searchEntity);
  }

  getSearchEntityIcon(searchEntity: SearchEntityType) {
    switch (searchEntity) {
      case SearchEntityType.CUSTOMER:
        return 'find-customer';
      case SearchEntityType.ORDER:
        return 'shopping-cart-icon';
      case SearchEntityType.OPPORTUNITIES:
        return 'opportunities';
    }
  }

  orderFilterChanged(keepPaging?: boolean) {
    this.orderSearch['keepPaging'] = keepPaging;
    if (!this.orderSearch.filtering.find(filter => filter.column === 'orderType')) {
      this.orderSearch.filtering.push({
        column: 'orderType',
        compareType: 'EQUAL',
        value: 'SALES',
      });
    }
    this.searchChangedEmitter.emit(this.orderSearch);
  }

  orderInitLoadDone = false;

  ngAfterViewChecked(): void {
    if (!this.orderInitLoadDone && this.entitySearchForm.value.searchEntity === SearchEntityType.ORDER) {
      this.orderInitLoadDone = true;
      this.orderFilterChanged(true);
    }
  }
}
