<div
  class="summary-page-actions"
  [ngClass]="{
    'displayMode-full': displayMode === 'FULL',
    'displayMode-rollout': displayMode === 'ROLLOUT'
  }"
  [hidden]="!hasOrderItems()">
  <div *ngIf="displayMode === 'FULL'" class="discount-container">
    <div *ngIf="showDiscount">
      <input type="text" placeholder="{{ 'wc.shopping.cart.discount.placeholder' | translate }}" />
      <app-button variant="secondary" disabled="true" label="{{ 'wc.shopping.cart.apply' | translate }}" />
    </div>
    <app-button
      *ngIf="showBackToShopping"
      variant="secondary"
      size="shrink"
      class="desktop-back_bottom"
      label="{{ 'wc.shopping.cart.backToShopping' | translate }}"
      [routerLink]="['/']" />
  </div>
  <div class="summary-container">
    <div
      *ngIf="preCalculatedShoppingCart"
      class="prices-container"
      app-shopping-cart-totals
      [readOnlyOrder]="readOnlyOrder"
      [showVatPrice]="showVatPrice"
      [displayMode]="displayMode">
    </div>
    <div *ngIf="displayMode !== 'ROLLOUT'" class="navigation-container">
      <div class="navigation-item">
        <app-button
          *ngIf="displayMode !== 'FULL' && displayMode !== 'COMPACT'"
          variant="primary"
          size="fullWidth"
          label="{{ isQuoteOrder ? 'View Quote' : 'View Cart' }}"
          [routerLink]="['/eshop/shopping-cart']" />
      </div>
      <div *ngIf="showContinue" class="navigation-item">
        <app-button
          *ngIf="displayMode === 'FULL' || displayMode === 'COMPACT'"
          variant="primary"
          size="fullWidth"
          label="{{
            termsAndConditions
              ? 'Place Order'
              : displayMode === 'COMPACT'
              ? ('wc.common.continue.button' | translate) +
                ' to ' +
                ('wc.shopping.wizard.' + nextStepLabel + '.button' | translate)
              : isQuoteOrder
              ? 'Finalize Quote'
              : 'Place Order'
          }}"
          iconPath="{{ termsAndConditions ? 'assets/svg/safe-order.svg' : undefined }}"
          [disabled]="disableContinueButton"
          (click)="continue()" />
      </div>
      <div *ngIf="previousStep && previousStepLabel" class="navigation-item">
        <app-button
          *ngIf="displayMode === 'COMPACT'"
          variant="link"
          size="fullWidth"
          label="{{ 'Back to ' + ('wc.shopping.wizard.' + previousStepLabel + '.button' | translate) }}"
          (click)="back()" />
      </div>
    </div>
  </div>
</div>

<app-button
  *ngIf="displayMode === 'FULL' && !readOnlyOrder"
  variant="secondary"
  size="shrink"
  class="mobile-back_bottom"
  label="{{ 'wc.shopping.cart.backToShopping' | translate }}"
  [routerLink]="['/']" />

<app-suspend-order
  *ngIf="suspendOrder && !readOnlyOrder"
  [suspendOrderPopup]="suspendOrder"
  (close)="suspendOrderPopup()" />
