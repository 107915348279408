import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressFormComponent } from 'app/components/page/address-form/address-form.component';
import { FormUtils } from '@btl/btl-fe-wc-common';
import { SegmentEnum } from '../../../models/segmentEnum';
import { SubjectTypeEnum } from '../../../models/subjectTypeEnum';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import {
  EntityGeneratedFormComponent,
  ExternalValue
} from '../../entity-generated-form/entity-generated-form.component';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { AccountDto, AddressDto, ContactDto, CustomerDto } from '@btl/order-bff';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
})
export class CustomerFormComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @ViewChild('addressForm', { static: false }) addressFormComponent: AddressFormComponent;
  @ViewChild('contactForm', { static: false }) contactFormComponent: ContactFormComponent;

  @ViewChild('customerEntityGeneratedForm', { static: false })
  customerEntityGeneratedForm: EntityGeneratedFormComponent;
  @ViewChild('subjectEntityGeneratedForm', { static: false })
  subjectEntityGeneratedForm: EntityGeneratedFormComponent;
  @ViewChild('personalIdEntityGeneratedForm', { static: false })
  personalIdEntityGeneratedForm: EntityGeneratedFormComponent;

  maxDate;

  readonly AddressTypeEnum = AddressDto.TypeDtoEnum;
  readonly ContactTypeEnum = ContactDto.TypeDtoEnum;

  @Input() parentGroup: FormGroup;
  @Input() customerForm: FormGroup;
  @Input() translationPrefix: string;
  @Input() isVerifiedCustomerRequired = false;
  @Input() simpleCustomer = false;
  @Input() showHeader = true;
  @Input() validate = true;
  @Input() account: AccountDto;
  @Input() showAddress = true;
  @Input() showContact = true;
  @Input() columnsCount: number = 2;
  @Input() checkFormVisibility = true;
  @Input() patchCustomer: CustomerDto;
  @Input() checkoutMode = false;
  @Input() checkEmailUniqueness = true;
  @Input() formName: string;

  @Output()
  readonly formGeneratedEmitter = new EventEmitter<void>();

  @Output()
  readonly componentInitEmitter = new EventEmitter<CustomerFormComponent>();

  globalHomeCountry: string;
  disabled = false;
  @Input() initGenerateForm = false;
  @Input() entityGenerateForm = false;
  @Input() isCustomerSelected = false;

  constructor(
    private fb: FormBuilder,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    public shoppingCartService: ShoppingCartService
  ) {
    const today = new Date();
    this.maxDate = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };
  }

  public static getFormControlConfig() {
    return {
      subject: null,
      address: null,
      contact: null,
      id: null,
      extId: null,
      displayName: null,
      state: null,
      role: null,
    };
  }

  public ngOnInit() {
    if (!this.customerForm?.get('subject')) {
      this.customerForm = this.getCustomerGroupControl();
    }
    this.loadProperties();
    this.componentInitEmitter.emit(this);

    if (this.initGenerateForm) {
      this.entityGenerateForm = true;
    }
  }

  public generateForm(customer) {
    this.patchCustomer = customer;
    this.entityGenerateForm = true;
    return this.formGeneratedEmitter;
  }

  disable(disable: boolean, excluds: string[]) {
    FormUtils.disableEnableForm(this.customerForm, disable, excluds);
    this.disabled = disable;
  }

  getCustomerGroupControl(): FormGroup {
    this.customerForm = this.fb.group(CustomerFormComponent.getFormControlConfig());
    this.customerForm.setControl(
      'subject',
      this.fb.group({
        id: null,
        personalId: this.fb.group({}),
      })
    );
    if (this.showAddress) {
      this.customerForm.setControl('address', this.fb.group(AddressFormComponent.getBaseFormConfiguration()));
    }
    if (this.showContact) {
      this.customerForm.setControl('contact', this.fb.group(ContactFormComponent.getBaseFormConfiguration()));
    }

    return this.customerForm;
  }

  getSubjectFormGroup() {
    return this.customerForm.get('subject') as FormGroup;
  }

  getPersonalIdtFormGroup() {
    return (this.customerForm.get('subject') as FormGroup).get('personalId') as FormGroup;
  }

  getContactFormGroup() {
    return this.customerForm.get('contact') as FormGroup;
  }

  getAddressFormGroup() {
    return this.customerForm.get('address') as FormGroup;
  }

  countryCheck(subject: string, idIssueAuthority: string) {
    return group => {
      const subjectType = group.controls[subject];
      const idAuthority = group.controls[idIssueAuthority];
      if (subjectType.value === SubjectTypeEnum.FOREIGNER) {
        if (idAuthority.value === this.globalHomeCountry) {
          return idAuthority.setErrors({ equivalent: true });
        }
      }
    };
  }

  isPerson() {
    return this.customerForm.get('segment')?.value === SegmentEnum.R.toString();
  }

  isBusiness() {
    return this.customerForm.get('segment')?.value === SegmentEnum.SMB.toString();
  }

  isCORP() {
    return (
      this.customerForm.get('segment')?.value === SegmentEnum.CORP.toString() ||
      this.customerForm.get('segment')?.value === SegmentEnum.GOV.toString()
    );
  }

  loadProperties() {
    this.propertyAccessorLocalService.getGlobalHomeCountry().subscribe(globalHomeCountry => {
      this.globalHomeCountry = globalHomeCountry;
    });
  }

  getCurrentSegment() {
    if (this.customerForm.get('segment')) {
      return this.customerForm.get('segment')?.value;
    } else if (this.patchCustomer) {
      return this.patchCustomer.segment;
    }
    return null;
  }

  getCurrentSubjectType() {
    let customer = this.patchCustomer;
    if (!customer) {
      customer = this.customerForm.value;
    }
    let subjectType = customer?.subject?.subjectType;
    if (this.isPerson() && subjectType !== SubjectTypeEnum.PERSON && subjectType !== SubjectTypeEnum.FOREIGNER) {
      subjectType = SubjectTypeEnum.PERSON;
    } else if (this.isBusiness()) {
      subjectType = SubjectTypeEnum.ENTREPRENEUR;
    } else if (this.isCORP()) {
      subjectType = SubjectTypeEnum.BUSINESS;
    }
    //Do not generate subject form part as BE will try to create customer if subjectType set
    if (this.notHavingService()) {
      subjectType = null;
    }
    (this.customerForm.get('subject') as FormGroup)?.get('subjectType')?.setValue(subjectType);
    return subjectType;
  }

  getDefaultNationality() {
    return [SubjectTypeEnum.PERSON.toString(), SubjectTypeEnum.FOREIGNER.toString()].find(
      st => st === this.getCurrentSubjectType()
    )
      ? this.globalHomeCountry
      : null;
  }

  subjectTypes: Array<ExternalValue>;
  lastSegmentPerson: boolean;

  getSubjectType(): Array<ExternalValue> {
    if (this.lastSegmentPerson !== this.isPerson()) {
      if (this.isPerson()) {
        this.subjectTypes = [
          {
            value: SubjectTypeEnum.PERSON,
            labelKey: `wc.shopping.customer.subjectType.${SubjectTypeEnum.PERSON}.label`,
          },
          {
            value: SubjectTypeEnum.FOREIGNER,
            labelKey: `wc.shopping.customer.subjectType.${SubjectTypeEnum.FOREIGNER}.label`,
          },
        ];
      } else {
        this.subjectTypes = [
          {
            value: SubjectTypeEnum.BUSINESS,
            labelKey: `wc.shopping.customer.subjectType.${SubjectTypeEnum.BUSINESS}.label`,
          },
          {
            value: SubjectTypeEnum.ENTREPRENEUR,
            labelKey: `wc.shopping.customer.subjectType.${SubjectTypeEnum.ENTREPRENEUR}.label`,
          },
        ];
      }
    }
    this.lastSegmentPerson = this.isPerson();
    return this.subjectTypes;
  }

  nationalityChangedEventEmitter = new EventEmitter<any>();
  nationalityChangedEventEmitterSub = false;

  nationalityChanged(event: any) {
    if (this.isPerson() && event !== environment.country) {
      this.customerForm.get('subject').get('subjectType').setValue(SubjectTypeEnum.FOREIGNER);
      this.customerForm.get('subject').get('identityNumber').setValue(null);
    }
  }

  subjectTypeChangedEventEmitter = new EventEmitter<any>();
  subjectTypeChangedEventEmitterSub = false;

  subjectTypeChanged(event) {
    if (event === 'Foreigner') {
      this.customerForm.get('subject')?.get('idIssueAuthority')?.setValue(null);
      this.customerForm.get('subject')?.get('nationality')?.setValue(null);
    } else {
      this.customerForm.get('subject')?.get('idIssueAuthority')?.setValue(this.globalHomeCountry);
      this.customerForm.get('subject')?.get('nationality')?.setValue(this.globalHomeCountry);
    }
  }

  customerFormGenerated() {
    if (
      ((this.customerEntityGeneratedForm?.formGenerated &&
        (this.subjectEntityGeneratedForm?.formGenerated || !this.getCurrentSubjectType()) &&
        (this.personalIdEntityGeneratedForm?.formGenerated || !this.getCurrentSubjectType()) &&
        (!this.showAddress || this.addressFormComponent?.formGenerated)) ||
        (!this.subjectEntityGeneratedForm?.formVisibility && this.notHavingService())) &&
      (this.contactFormComponent?.formGenerated || !this.showContact)
    ) {
      if (!this.nationalityChangedEventEmitterSub) {
        this.nationalityChangedEventEmitter.pipe(takeUntil(this.onDestroy$)).subscribe(event => {
          this.nationalityChanged(event);
        });
        this.nationalityChangedEventEmitterSub = true;
      }

      if (!this.subjectTypeChangedEventEmitterSub) {
        this.subjectTypeChangedEventEmitter.pipe(takeUntil(this.onDestroy$)).subscribe(event => {
          this.subjectTypeChanged(event);
        });
        this.subjectTypeChangedEventEmitterSub = true;
      }
      this.patchCustomer = null;
      this.formGeneratedEmitter.emit();
    }
  }

  customerFormFieldAvailable() {
    return (
      this.subjectEntityGeneratedForm?.formVisibility ||
      this.customerEntityGeneratedForm?.formVisibility ||
      this.personalIdEntityGeneratedForm?.formVisibility
    );
  }

  showCustomerData() {
    return (
      (!this.subjectEntityGeneratedForm?.formVisibility &&
        this.shoppingCartService.preCalculatedShoppingCart.containsService()) ||
      ((this.isCustomerSelected ||
      this.account && this.account.external)  && !this.shoppingCartService.preCalculatedShoppingCart.containsService())
    );
  }

  notHavingService(): boolean {
    return this.checkoutMode && !this.shoppingCartService.preCalculatedShoppingCart.containsService();
  }

  /**
   *
   * @param recipient can be typed as CustomerElse or NotificationRecipientDto
   */
  public static getRecipientEmail(recipient: any): string | null {
    return (
      recipient?.contacts?.find(contact => contact?.contactType === ContactDto.TypeDtoEnum.PRIMARY)?.email ||
      recipient.address
    );
  }
}
