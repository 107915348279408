<div class="modal-header">
  <ng-container *ngIf="productCode && wishLists; else emptyPopupTitle">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'wc.shopping.gui_wishlist.addToWishList.heading' | translate }}
    </h4>
  </ng-container>
  <ng-template #emptyPopupTitle>
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'wc.shopping.gui_wishlist.createNewWishList.heading' | translate }}
    </h4>
  </ng-template>
  <div class="modal-close" (click)="closePopup()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body">
  <div class="wish-list-popup">
    <div class="wish-list-content">
      <ng-container *ngIf="productCode && wishLists">
        <div class="wish-list-rows">
          <ng-container *ngFor="let wishList of wishLists; let i = index">
            <app-wish-list-item-popup *ngIf="wishList" [id]="i" [productCode]="productCode" [wishList]="wishList">
            </app-wish-list-item-popup>
          </ng-container>
        </div>
      </ng-container>
      <div class="wish-list-add-part" [ngClass]="showAddWishListInput ? '' : 'blank'">
        <form #newListForm="ngForm" (ngSubmit)="addNew(newListForm)">
          <div class="form-container">
            <div *ngIf="showAddWishListInput; else showInput" class="wish-list-input-part">
              <input
                #addWishListInput="ngModel"
                type="text"
                class="wish-list-add-input"
                id="addWishList"
                ngModel
                placeholder="{{ 'wc.shopping.gui_wishlist.createNewWishList.validation.listName' | translate }}"
                [name]="ADD_WISH_LIST_FIELD"
                [class.is-invalid]="isInvalid" />
              <app-button
                *ngIf="showAddWishListInput"
                buttonType="submit"
                variant="tertiary"
                label="{{ 'wc.shopping.gui_wishlist.addToWishList.button' | translate }}"></app-button>
            </div>
            <ng-template #showInput>
              <app-button
                buttonType="submit"
                variant="link"
                iconPath="/assets/svg/circle-plus_icon.svg"
                label="{{ 'wc.shopping.gui_wishlist.createNewWishList.heading' | translate }}"></app-button>
            </ng-template>
          </div>
          <div *ngIf="isInvalid" class="alert-danger">
            {{ 'wc.shopping.gui_wishlist.createNewWishList.validation.listName' | translate }}
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="productCode && wishLists" class="modal-footer">
  <app-button label="{{ 'wc.shopping.gui_wishlist.button.done' | translate }}" (click)="closePopup()"></app-button>
</div>
