import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AbstractPageComponent, BlockTemplateComponent, Search } from '@btl/btl-fe-wc-common';
import { SortDto } from '@btl/order-bff';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({ template: '' })
export abstract class AbstractEcareListingPageComponent extends AbstractPageComponent implements OnInit {
  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  @Input() pageSize: number = 10;
  @Input() showFilter: boolean = true;
  @Input() withPaging: boolean = true;
  @Input() clearFilterEmitter = new EventEmitter<boolean>();

  searchText: string = null;
  totalItems: number = 0;
  totalPages: number = 0;

  from: number = 0;
  canDisplayNoCustomersText = false;
  isMore: boolean = false;
  records: any[] = [];

  defaultSortOrdering: SortDto = { column: 'displayName', sortOrder: 'asc' };

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  ngOnInit() {
    this.from = 0;
    this.records.length = 0;
    this.searchText = null;
    this.search.sorting = [this.defaultSortOrdering];
  }

  protected abstract loadData(): void;

  public handleFilterChange(searchValue: string): void {
    this.from = 0;
    this.records.length = 0;
    this.searchText = searchValue;
    this.loadData();
  }

  public getSearchWithoutSearchText(){
    let result = {...this.search};
    result.filtering = result.filtering.filter(filter => filter.column !== 'searchText');
    return result;
  }

  setRecords(records: any[], totalRecordsCount: number) {
    if (this.withPaging) {
      this.records = [...records];
    } else {
      this.records.push(...records);
    }

    this.totalItems = totalRecordsCount;
    this.totalPages = Math.ceil(this.totalItems / this.search.paging.pageSize);

    if (this.totalItems > this.records.length) {
      this.isMore = true;
    } else {
      this.isMore = false;
    }
  }

  loadMoreRecords() {
    this.from = this.records.length;
    this.loadData();
  }

  onPrevPage() {
    if (this.search.paging.page > 1) {
      this.search.paging.page--;
      this.from = (this.search.paging.page - 1) * this.search.paging.pageSize;
      this.loadData();
    }
  }

  onNextPage() {
    this.search.paging.page++;
    this.from = (this.search.paging.page - 1) * this.search.paging.pageSize;
    this.loadData();
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.pageSize = pageSize;
    this.search.paging.page = 1;
    this.from = 0;
    this.loadData();
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.from = (pageNo - 1) * this.search.paging.pageSize;
    this.loadData();
  }

  onSortChange() {
    this.records.length = 0;
    this.from = 0;
    this.loadData();
    this.search.sorting = [...this.search.sorting];
  }
}
