<app-ecare-mobile-row-general *ngFor="let contact of contactHistory">
  <div class="mobile-table-row-wrapper" (dblclick)="ecareContactHistoryComponent.showDetails(contact)">
    <div class="mobile-table-row-flex space-between">
      <div class="mobile-table-row-flex information">
        <div>{{ contact.subject }}</div>
        <app-sticker variant="neutral">{{ contact.id }}</app-sticker>
      </div>
      <div>
        <ng-container>
          <div ngbDropdown placement="bottom-right" class="listing-row-actions-entry">
            <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
              <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
            </span>

            <div ngbDropdownMenu class="action-list-dropdown-menu" id="{{ contact.id }}_popover-content">
              <div>
                <button
                  id="004_ecare_order_row"
                  class="btn btn-link action-list-more-actions-button"
                  type="button"
                  (click)="ecareContactHistoryComponent.showDetails(contact);$event.stopPropagation()">
                  {{ 'wc.ecare.orders.showDetails.button' | translate }}
                </button>
                <button
                  class="btn btn-link action-list-more-actions-button"
                  type="button"
                  (click)="ecareContactHistoryComponent.showDetails(contact, true);$event.stopPropagation()">
                  {{ 'wc.common.showDetailsInNewTab.button' | translate }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="contact.customer"
      class="mobile-table-row-flex highlight">
      <span>{{ 'wc.ecare.ticket.customer' | translate }}: {{contact.customer}}</span>
    </div>
    <div *ngIf="contact.contact"
         class="mobile-table-row-flex additional-info">
      <span>{{ 'wc.ecare.dashboard.contact.label' | translate }}: {{contact.contact | displayContact}}</span>
    </div>
    <div class="mobile-table-row-flex additional-info">
      <div>{{ 'wc.ecare.ticket.startFrom' | translate }}: {{ contact.startFrom | date : 'dd.MM.yyyy HH:mm' }}</div>
      <div>{{ 'wc.ecare.ticket.slaDueDate' | translate }}: {{ contact.slaDueDate | date : 'dd.MM.yyyy HH:mm' }}</div>
    </div>
    <div class="mobile-table-row-flex" *ngIf="visibleParameters.get(contactParamNames.CONTACT_CHANNEL)">
      <div *ngIf="contact.contactChannel">
        <span
          *ngIf="codebookService.getCodebookTexts('TICKET_CONTACT_CHANNEL') | async as codebook"
          class="-contact-history-channel {{ contact.contactChannel }}">
          {{ codebook[contact.contactChannel] }}
        </span>
      </div>
    </div>
  </div>
</app-ecare-mobile-row-general>
