import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ShoppingCartSocket } from '../shopping-cart-item/shopping-cart-item.component';
import { PrecalculatedShoppingCart } from 'app/models/precalculated-shopping-cart';
import { OrderDto, SocketDto } from '@btl/order-bff';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductAddingToCart } from '@service/product-listing.service';
import { Subject, takeUntil } from 'rxjs';
import { WcOrderingService } from '@service/wc-ordering.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-shopping-cart-add-product-to-socket',
  templateUrl: './shopping-cart-add-product-to-socket.component.html',
})
export class ShoppingCartAddProductToSocketComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input() shoppingCartSocket: ShoppingCartSocket;
  @Input() socketChildren: ShoppingCartSocket[];
  @Input() precalculatedShoppingCart: PrecalculatedShoppingCart;

  socket: SocketDto;
  socketChildrenProductOfferings = null;
  addProductForm: FormGroup = this.formBuilder.group({
    product: [null, [Validators.required]],
  });

  constructor(private formBuilder: FormBuilder, private orderingService: WcOrderingService) {}

  ngOnInit() {
    if (this.socketChildren.length > 0) {
      this.socket = this.socketChildren[0].socket;
    }

    let mainSocket = this.shoppingCartSocket.parent ? this.shoppingCartSocket.parent : this.shoppingCartSocket;
    while (mainSocket?.parent != null) {
      mainSocket = mainSocket.parent;
    }

    if (this.precalculatedShoppingCart.promotionConfiguration.get(mainSocket.product?.productDetail.productCode)) {
      this.socketChildrenProductOfferings = this.precalculatedShoppingCart.promotionConfiguration
        .get(mainSocket.product?.productDetail.productCode)
        .filter(productOffering => productOffering.socket.code === this.socket?.code)
        .filter(productOffering => {
          return (
            productOffering.socket.socketContentQty.max >
              this.socketChildren.find(
                socket => socket.product.productDetail.productCode === productOffering.product.productCode
              )?.currentContentQty ||
            !this.socketChildren.find(
              socket => socket.product.productDetail.productCode === productOffering.product.productCode
            )
          );
        })
        .map(productOffering => {
          const price = productOffering.eligiblePrices.find(price => price.price.value > 0);
          return {
            value: productOffering.product,
            label: productOffering.product.name + (price ? ' - ' + price.price.value + ' ' + environment.currency : ''),
          };
        });
    }
  }

  addProduct() {
    const addOrderItem = (currentOrder: OrderDto) => {
      this.orderingService
        .addProductToShoppingCartWithParent(
          currentOrder,
          this.addProductForm.value.product as ProductAddingToCart,
          this.shoppingCartSocket.product.productDetail.id,
          this.shoppingCartSocket.product.orderItems[0].id,
          null,
          null,
          false
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
    };
    this.orderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(addOrderItem);
  }
}
