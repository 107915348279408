import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AccountDto,
  AddressDto,
  AgreementDto,
  ContactDto,
  CustomerAccountDto,
  CustomerDto,
  MetaEntityAttributeDto,
  OrderDto,
  OrderParamDto
} from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { OneTimePriceComponent } from '../../page/price/one-time-price.component';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { PrecalculatedShoppingCart } from 'app/models/precalculated-shopping-cart';
import {
  AuthFactoryService,
  AuthService,
  CurrentLocaleService,
  DmsService,
  getCurrentHost,
  MetaDescriptionService,
  OrderingService,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderUtils, ScenarioStepTypeEnum } from 'app/helpers/order-utils';
import { PaymentComponent } from '../payment/payment.component';
import { TranslateService } from '@ngx-translate/core';
import { CustomerAccountFormComponent } from '../../page/customer-account-form/customer-account-form.component';
import { ProductInShoppingCart } from '../../../models/product-in-shopping-cart';
import _ from 'lodash';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormUtils } from '../../../helpers/form-utils';
import { CheckoutShoppingCartComponent } from '../checkout-shopping-cart/checkout-shopping-cart.component';
import { FormAgreementsComponent } from 'app/components/form-agreements/form-agreements.component';
import { from } from 'rxjs/internal/observable/from';
import {
  GenerateDocumentEntityType
} from '../../entity-generate-document-modal/entity-generate-document-modal.component';
import { ModalFactoryService } from '@service/modal-factory.service';
import { DmsFileDto } from '@btl/admin-bff';
import { HttpClient } from '@angular/common/http';
import { FilesAddModalComponent } from '../../files-add-modal/files-add-modal.component';

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
})
export class SummaryPageComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private static readonly API_PATH_GENERATE_ORDER_DOCUMENT = '/api/bff/order/v1/generated-documents';
  public readonly HW_INVOICING_CONTACT = 'hwInvoicingContact';
  public readonly HW_INVOICING_ADDRESS = 'hwInvoicingAddress';
  public readonly DELIVERY_CONTACT = 'deliveryContact';
  public readonly DELIVERY_ADDRESS = 'deliveryAddress';

  ScenarioStepTypeEnum = ScenarioStepTypeEnum;

  @ViewChild('taxOcSum', { static: false }) taxOcSumComponent: OneTimePriceComponent;
  @ViewChild('ocSum', { static: false }) ocSumComponent: OneTimePriceComponent;
  @ViewChild('taxRcSum', { static: false }) taxRcSumComponent: OneTimePriceComponent;
  @ViewChild('rcSum', { static: false }) rcSumComponent: OneTimePriceComponent;
  @ViewChild('todaySum', { static: false }) todaySumComponent: OneTimePriceComponent;

  @ViewChild('customerAccountForm', { static: true }) customerAccountFormComponent: CustomerAccountFormComponent;
  @ViewChild(CheckoutShoppingCartComponent) checkoutShoppingCart: CheckoutShoppingCartComponent;
  @ViewChild('formAgreementsComponent', { static: false }) formAgreementsComponent: FormAgreementsComponent;

  get preCalculatedShoppingCart(): PrecalculatedShoppingCart {
    return this.shoppingCartService.preCalculatedShoppingCart;
  }

  agreementsForm: FormGroup = this.formBuilder.group({
    agreements: [],
  });

  paymentParams;
  document: Blob;
  customer: CustomerDto;
  customerAccount: CustomerAccountDto;
  loading;
  showVat: boolean;
  selectedDeliveryMethod: ProductInShoppingCart;
  selectedPaymentMethod: ProductInShoppingCart;
  deliveryContact: ContactDto;
  deliveryAddress: AddressDto;
  invoiceContact: ContactDto;
  invoiceAddress: AddressDto;
  caInvoiceContact: ContactDto;
  caInvoiceAddress: AddressDto;
  caBillingContact: ContactDto;
  caBillingAddress: AddressDto;
  customerAccountAttributes: MetaEntityAttributeDto[];
  subjectAttributes: MetaEntityAttributeDto[];
  account: AccountDto;
  showAttachments = true;
  notificationContextMap: {
    [key: string]: any;
  };

  orderDocumentsToGenerate: Map<string, string> = new Map<string, string>();
  notificationTypeCode: string = null;

  constructor(
    private orderingService: OrderingService,
    public wcOrderingService: WcOrderingService,
    public shoppingCartService: ShoppingCartService,
    private currentLocaleService: CurrentLocaleService,
    public orderingWizardService: OrderingWizardService,
    private activatedRoute: ActivatedRoute,
    protected translateService: TranslateService,
    protected router: Router,
    private formBuilder: FormBuilder,
    private metaDescriptionService: MetaDescriptionService,
    private authFactoryService: AuthFactoryService,
    protected modalFactory: ModalFactoryService,
    private http: HttpClient,
    private dmsService: DmsService,
    private stickyMessageService: StickyMessageService
  ) {

    this.orderingWizardService.showVat.pipe(takeUntil(this.onDestroy$)).subscribe(showVat => {
      if (showVat) {
        this.showVat = showVat === 'vat';
      }
    });

    const authService: AuthService = this.authFactoryService.getAuthService();
    this.account = authService.account;
    authService.accountChange.pipe(takeUntil(this.onDestroy$)).subscribe(account => {
      this.account = account;
    });

    this.metaDescriptionService
      .getEntityMetaDescriptions('crm', 'CustomerAccount', {})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(metadata => {
        this.customerAccountAttributes = metadata.attributes
          .filter(attr => attr.metaParameters['guiVisible'] === 'true')
          .sort((a, b) =>
            Number(a.metaParameters['guiSortOrder']) > Number(b.metaParameters['guiSortOrder']) ? 1 : -1
          );
      });
  }

  ngOnInit(): void {
    const handleGetCurrentOrder = (orderDto: OrderDto): void => {
      this.notificationContextMap = {
        orderId: orderDto.id
      }

      if (this.wcOrderingService.currentOrder) {
        if (this.hasOrderItems()) {
          this.updateSummaryInfo();
          this.orderingService
            .getOrderAgreements(this.wcOrderingService.currentOrder.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(result => {
              const agreements: Array<AgreementDto> = JSON.parse(
                OrderUtils.getOrderAttributeValue(orderDto, this.AGREEMENTS_ORDER_ATTR)
              );
              if (agreements) {
                agreements.forEach(agreement => {
                  const resultAgreement = result.find(findAgreement => findAgreement.type.id === agreement.type.id);
                  if (resultAgreement) {
                    resultAgreement.agreed = agreement.agreed;
                  }
                });
              }
              this.agreementsForm.patchValue({ agreements: result });
            });
        } else {
          this.router.navigate(['/']);
        }
      } else {
        throw new Error('There is no current order!');
      }
    };

    this.showVat = true;
    this.loadProducts();
    if (
      this.activatedRoute.snapshot.queryParamMap.has(PaymentComponent.NTS_ORDER_ID_URL_QUERY_PARAMETER) ||
      this.activatedRoute.snapshot.queryParamMap.has(PaymentComponent.PAYMENT_TECHNICAL_FAIL_URL_QUERY_PARAMETER)
    ) {
      this.activatedRoute.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
        this.paymentParams = params;
        this.wcOrderingService
          .getOrderByRefNo(params[PaymentComponent.NTS_ORDER_ID_URL_QUERY_PARAMETER])
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(handleGetCurrentOrder);
      });
    } else {
      this.wcOrderingService.getCurrentOrder().pipe(takeUntil(this.onDestroy$)).subscribe(handleGetCurrentOrder);
    }
  }

  loadProducts() {
    const deliveryAndPaymentProducts: ProductInShoppingCart[] = [];

    if (this.shoppingCartService.preCalculatedShoppingCart.deliveryProduct) {
      this.selectedDeliveryMethod = this.shoppingCartService.preCalculatedShoppingCart.deliveryProduct;
      deliveryAndPaymentProducts.push(this.selectedDeliveryMethod);
    }

    if (this.shoppingCartService.preCalculatedShoppingCart.paymentProduct) {
      this.selectedPaymentMethod = this.shoppingCartService.preCalculatedShoppingCart.paymentProduct;
      deliveryAndPaymentProducts.push(this.selectedPaymentMethod);
    }

    for (const product of deliveryAndPaymentProducts) {
      if (product.ocPrice !== 0 || product.rcPrice > 0) {
      }
    }

    this.orderDocumentsToGenerate = this.shoppingCartService.preCalculatedShoppingCart.getProductDocumentsToGenerate();
    this.notificationTypeCode = this.shoppingCartService.preCalculatedShoppingCart.getMainProductDocumentsNotificationTypeCode();
  }

  generateDocument(document: string): void {
    const formatTimestampPart = t => (t < 10 ? '0' : '') + t;

    const now = new Date();
    const timestamp =
      now.getFullYear() +
      formatTimestampPart(now.getMonth() + 1) +
      formatTimestampPart(now.getDate()) +
      formatTimestampPart(now.getHours()) +
      formatTimestampPart(now.getMinutes());

    const locale = this.currentLocaleService.getCurrentLanguage();
    const orderId = this.wcOrderingService.currentOrder.id;
    const documentName = `Order#${orderId}_${timestamp}.pdf`;

    this.modalFactory.entityGenerateDocumentModal(
      GenerateDocumentEntityType.ORDER,
      documentName,
      this.getGenerateOrderDocumentUrl(locale, orderId, document),
      (dialogRef, documentName, url) => {
        this.showAttachments = false;
        from(this.http.get(url, { responseType: 'blob' })).subscribe(response => {
          const file = new File([response], documentName, { type: response.type });
          let uploadedHandler = (newFile: DmsFileDto) => {
            newFile.type = 'CRM_DOCUMENT';
            newFile.contentHref = null;
            newFile.size = null;
            newFile.extId = `CRM_DOCUMENT${ServiceUtils.getRandomId()}`;
            newFile.params = {
              language: this.currentLocaleService.getCurrentLanguage(),
            };
            newFile.references = [
              {
                entityType: 'com.emeldi.ecc.be.order.dto.Order',
                entityId: orderId,
                refType: 'GENERAL',
              },
            ];
            this.dmsService.createFile(newFile).subscribe(file => {
              dialogRef.close();
              this.showAttachments = true;
              this.stickyMessageService.addStickySuccessMessage('wc.ecare.documents.documentUploaded');
            });
          }
          FilesAddModalComponent.readFile(file, uploadedHandler);
        });

        dialogRef.close();
      }
    );
  }

  /**
   * Create full URL (including domain, path and query params) corresponding with 'generateQuoteDocument' API.
   * @param locale locale
   * @param quoteId relevant Quote ID
   * @param documentCode user login
   */
  public getGenerateOrderDocumentUrl(locale: string, orderId: string, documentCode: string): string {
    const url = new URL(getCurrentHost() + SummaryPageComponent.API_PATH_GENERATE_ORDER_DOCUMENT);
    url.searchParams.append('locale', locale);
    url.searchParams.append('orderId', orderId);
    url.searchParams.append('code', documentCode);
    return url.href;
  }

  updateSummaryInfo(): void {
    this.customer = JSON.parse(OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, 'customer'));
    this.metaDescriptionService
      .getEntityMetaDescriptions('crm', 'Subject', {
        resourceIdentification: {
          subjectType: this.customer.subject.subjectType
        }
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(metadata => {
        this.subjectAttributes = metadata.attributes
          .filter(attr => attr.metaParameters['guiVisible'] === 'true')
          .sort((a, b) =>
            Number(a.metaParameters['guiSortOrder']) > Number(b.metaParameters['guiSortOrder']) ? 1 : -1
          );
      });
    this.customerAccount = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, 'customerAccount')
    );

    if (this.customerAccount) {
      this.caInvoiceAddress = this.customerAccount.addresses?.find(address => address.type === AddressDto.TypeDtoEnum.INVOICING);
      this.caInvoiceContact = this.customerAccount.contacts?.find(contact => contact.type === ContactDto.TypeDtoEnum.INVOICING);
      this.caBillingContact = this.customerAccount.contacts?.find(contact => contact.type === ContactDto.TypeDtoEnum.BILLING);
      this.caBillingAddress = this.customerAccount.addresses?.find(address => address.type === AddressDto.TypeDtoEnum.BILLING);
    }

    this.invoiceContact = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, this.HW_INVOICING_CONTACT)
    );
    this.invoiceAddress = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, this.HW_INVOICING_ADDRESS)
    );
    this.deliveryContact = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, this.DELIVERY_CONTACT)
    );
    this.deliveryAddress = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, this.DELIVERY_ADDRESS)
    );
  }

  choose(event) {
    this.showVat = event !== 'noVat';
  }

  private readonly AGREEMENTS_ORDER_ATTR = 'agreements';

  continue() {
    FormUtils.validateAllFormFields(this.agreementsForm);
    if (this.agreementsForm.valid) {
      const orderParamsDto: Array<OrderParamDto> = [];
      OrderUtils.updateOrderAttr(
        orderParamsDto,
        this.AGREEMENTS_ORDER_ATTR,
        JSON.stringify(this.agreementsForm.controls.agreements.value ?? [])
      );

      const handleGetCurrentOrder = (orderDto: OrderDto) => {
        this.wcOrderingService.currentOrder = orderDto;
        this.checkoutShoppingCart.orderingWizardActions.finishWizard();
      };
      const orderAsMap = {
        orderAttributes: orderParamsDto,
        recordVersion: this.wcOrderingService.currentOrder.recordVersion,
      };
      this.wcOrderingService.addAccountId(this.wcOrderingService.currentOrder, orderAsMap);
      this.wcOrderingService
        .patchOrder(this.wcOrderingService.currentOrder.id, orderAsMap)
        .subscribe(handleGetCurrentOrder);
    }
  }

  hasOrderItems(): boolean {
    return (
      !_.isNil(this.shoppingCartService.preCalculatedShoppingCart) &&
      !_.isEmpty(this.shoppingCartService.preCalculatedShoppingCart.products)
    );
  }
}
