import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { DmsFileDto } from '@btl/admin-bff';
import { CompareType, DmsService, FormUtils, Search, ServiceUtils } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FilesAddModalComponent } from '../files-add-modal/files-add-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

export enum AttachmentType {
  ATTACH_FILES = 'attach-filles',
  UPLOAD_DOCUMENT = 'upload-document',
}

@Component({
  selector: 'app-form-attachments',
  templateUrl: './form-attachments.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormAttachmentsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormAttachmentsComponent),
    },
  ],
})
export class FormAttachmentsComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy, OnChanges {
  private static readonly FILE_NAME_GUI_MAX_LENGTH = 30;

  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  value: Array<Attachment>;

  @Input()
  dmsType: string;

  @Input()
  extIdPrefix: string;

  @Input()
  collapsed = false;

  @Input()
  showHeader = true;

  @Input()
  uploadButtonBelow = true;

  @Input()
  control: AbstractControl;

  @Input()
  types: Array<any>;

  @Input()
  attachmentType: AttachmentType = AttachmentType.UPLOAD_DOCUMENT;

  @Input()
  allowedNotifSending = false;

  @Input()
  defaultEmailList;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  new: boolean;

  uploadedImage = null;

  form: FormGroup = this.formBuilder.group({
    attachments: this.formBuilder.array([]),
  });

  selectedAttachments = [];

  constructor(
    private formBuilder: FormBuilder,
    private ngbModal: NgbModal,
    private dmsService: DmsService,
    private httpClient: HttpClient,
    private router: Router
  ) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange: any = () => {};

  ngOnInit(): void {
    if (this.control) {
      const self = this;
      const origFunc = this.control.markAsTouched;
      this.control.markAsTouched = function () {
        FormUtils.validateAllFormFields(self.form);
        if (!self.form.valid) {
          self.control.setErrors(self.form.errors);
          self.discloseValidatorChange();
        } else {
          self.control.setErrors(null);
        }
        origFunc.apply(this, arguments);
      };
    }

    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes['value'] && !changes.value.firstChange) {
      const newAttachmentsToLoad: string[] = [];
      const displayedAttachments = this.getFormArray().controls.map(control => control.value.extId);
      changes.value.currentValue
        .filter(attachment => !displayedAttachments.includes(attachment.extId))
        .forEach(newAttachment => {
          if (newAttachment.dmsFile && newAttachment.dmsFile.contentHref) {
            this.addToFormArray(newAttachment);
          } else {
            newAttachmentsToLoad.push(newAttachment.extId);
          }
        });

      if (newAttachmentsToLoad.length) {
        this.dmsService
          .filterFiles(this.getFilterFilesSearchCriteria(newAttachmentsToLoad), null)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.value.forEach(attachment => {
              const foundDmsFile = result.data.find(file => file.extId === attachment.extId);
              if (foundDmsFile) {
                attachment.dmsFile = foundDmsFile;
                this.addToFormArray(attachment);
              }
            });
          });
      }
    }
  }

  formatBytes(bytes: number): string {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'kB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const convertedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));
    return `${convertedSize} ${sizes[i]}`;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.new) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }
    this.new = false;
    return null;
  }

  valueChanged() {
    this.getFormArray().clear();
    if (!this.value) {
      this.value = [];
    } else {
      const extIds = [];
      this.value.forEach(attachment => {
        if (attachment.extId) {
          extIds.push(attachment.extId);
        }
        if (attachment['documentType']) {
          attachment.type = attachment['documentType'];
          delete attachment['documentType'];
        }
      });

      if (extIds.length > 0) {
        this.dmsService
          .filterFiles(this.getFilterFilesSearchCriteria(extIds), null)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.value.forEach(attachment => {
              const foundDmsFile = result.data.find(file => file.extId === attachment.extId);
              if (foundDmsFile) {
                attachment.dmsFile = foundDmsFile;
              }
            });

            if (this.value) {
              this.value.forEach(param => {
                this.addToFormArray(param);
              });
            }
          });
      }
    }
  }

  public static getSubFormControlConfig(formBuilder, type) {
    return {
      id: [null],
      href: [null],
      extId: [null, Validators.required],
      priority: [0],
      type: [],
      delete: [],
      inline: [false],
      dmsFile: formBuilder.group({
        id: [null],
        contentHref: [null],
        extId: [null, Validators.required],
        note: [null],
        type: [type],
        code: [],
        name: [null, Validators.required],
        mediaType: [],
        size: [],
        extension: [],
        params: [],
        content: [],
        texts: [],
        created: [],
        recordVersion: [],
      }),
    };
  }

  addToFormArray(value) {
    const newDataFormGroup = this.formBuilder.group(
      FormAttachmentsComponent.getSubFormControlConfig(this.formBuilder, this.dmsType)
    );
    if (!value.dmsFile) {
      value.dmsFile = {};
    }
    newDataFormGroup.patchValue(value);
    newDataFormGroup.get('href').patchValue(value.dmsFile.contentHref);
    this.getFormArray().push(newDataFormGroup);
    this.new = false;
  }

  getFormArray() {
    return this.form.get('attachments') as FormArray;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    if (this.new) {
      return null;
    }
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      return this.form.get('attachments').value;
    }
    return null;
  }

  add() {
    const modalRef = this.ngbModal.open(FilesAddModalComponent);
    const pictureAddModalComponent = <FilesAddModalComponent>modalRef.componentInstance;
    pictureAddModalComponent.dialogRef = modalRef;
    pictureAddModalComponent.uploadStep = false;
    pictureAddModalComponent.multiple = false;
    pictureAddModalComponent.uploadedHandler = (newFile: DmsFileDto) => {
      this.newFile(newFile);
      modalRef.close();
      this.onChange.emit(this.getValue());
    };
  }

  private newFile(newFile: DmsFileDto) {
    newFile.extId = this.extIdPrefix + ServiceUtils.getRandomId();
    const attachment = {
      extId: newFile.extId,
      href: null,
      type: this.types.length > 0 ? this.types[0] : null,
      dmsFile: newFile,
    };
    this.addToFormArray(attachment);
  }

  remove(formToRemove: AbstractControl) {
    formToRemove.get('delete').patchValue(1);
    if (!formToRemove.get('dmsFile').get('extId').value) {
      formToRemove.get('dmsFile').get('extId').patchValue('DELETE');
    }
    if (!formToRemove.get('dmsFile').get('name').value) {
      formToRemove.get('dmsFile').get('name').patchValue('DELETE');
    }
    this.onChange.emit(this.getValue());
  }

  download(subForm: AbstractControl) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    this.httpClient.get(subForm.get('href').value, httpOptions).subscribe((data: any) => {
      const blob = new Blob([data]);

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = subForm.value.dmsFile.name;
      link.click();
    });
  }

  getTypeIcon(attachment) {
    return attachment?.name?.split('.')[1];
  }

  public static formatFileName(fileName: string): string {
    if (this.shouldShortenFileName(fileName)) {
      return `${fileName.substr(0, FormAttachmentsComponent.FILE_NAME_GUI_MAX_LENGTH - 3)}...`;
    }
    return fileName;
  }

  public static shouldShortenFileName(fileName: string): boolean {
    return fileName?.length > FormAttachmentsComponent.FILE_NAME_GUI_MAX_LENGTH;
  }

  selectUnselectAttachment(value: any) {
    if (this.selectedAttachments.find(attach => attach === value)) {
      this.selectedAttachments = this.selectedAttachments.filter(attach => attach !== value);
    } else {
      this.selectedAttachments.push(value);
    }
  }

  sendNotification() {
    this.router.navigate(['/ecare/notifications/newNotification'], {
      queryParams: {
        returnUrl: this.router.url,
        defaultEmailList: this.defaultEmailList,
        filesExtIds: this.selectedAttachments.map(attachment => attachment.dmsFile.extId),
      },
    });
  }

  private getFilterFilesSearchCriteria(extIds: string[]): Search {
    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push({
      column: 'extId',
      compareType: CompareTypeDtoEnum.IN,
      value: extIds,
    });
    return search;
  }
}

export interface Attachment {
  id?: string;
  extId: string;
  href?: string;
  type?: string;
  content?: any;
  delete?: any;
  inline?: any;
  dmsFile?: DmsFileDto;
}
