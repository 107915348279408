import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ShoppingCartService } from 'app/services/shopping-cart.service';
import { PrecalculatedShoppingCart } from '../../../models/precalculated-shopping-cart';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';

/**
 * {@code ShoppingCartItemComponent} is a component responsible for displaying one product in {@link
 * ShoppingCartComponent shopping cart}.
 *
 * Clients of shopping cart item can subscribe changes via {@link onChange}.
 */
@Component({
  selector: 'app-shopping-cart-totals, [app-shopping-cart-totals]',
  templateUrl: './shopping-cart-totals.component.html',
})
export class ShoppingCartTotalsComponent implements OnDestroy, OnInit {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input() preCalculatedShoppingCart: PrecalculatedShoppingCart;

  @Input() showVatPrice: boolean = false;
  @Input() readOnlyOrder: boolean = false;
  @Input() displayMode: 'FULL' | 'COMPACT' | 'ROLLOUT';

  showVat: boolean = true;
  showVatButton: boolean = true;

  constructor( private shoppingCartService: ShoppingCartService,
               private orderingWizardService: OrderingWizardService,
               private propertyAccessorLocalService: PropertyAccessorLocalService) {
    this.propertyAccessorLocalService.getSwitchVatButtonVisibleBoolean()
      .subscribe(property => this.showVatButton = property === 'true');
    this.orderingWizardService.showVat.pipe(takeUntil(this.onDestroy$)).subscribe(showVat => {
      if (showVat) {
        this.showVat = showVat === 'vat';
      }
    });
  }

  ngOnInit() {
    if (!this.preCalculatedShoppingCart) {
      this.shoppingCartService.onChange.subscribe((precalculatedShoppingCart: PrecalculatedShoppingCart) => {
        this.preCalculatedShoppingCart = precalculatedShoppingCart;
      });
      if (!this.readOnlyOrder) {
        this.preCalculatedShoppingCart = this.shoppingCartService.preCalculatedShoppingCart;
      } else {
        this.preCalculatedShoppingCart = this.shoppingCartService.readOnlyOrder;
      }
    }
  }

  changeCheckbox() {
    const customSwitch = document.getElementById('taxSwitch') as HTMLInputElement;

    if (customSwitch.checked) {
      this.showVat = true;
      this.orderingWizardService.showVat.next('vat');
    } else {
      this.showVat = false;
      this.orderingWizardService.showVat.next('noVat');
    }
  }
}
