import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchEntityType } from '../../ecare-search/ecare-search-filter/ecare-search-filter.component';

@Component({
  selector: 'app-customers-filter',
  templateUrl: './ecare-customers-filter.component.html',
})
export class EcareCustomersFilterComponent implements OnInit {
  @Output() readonly filterChanged = new EventEmitter<string>();
  entitySearchForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.buildSearchForm({
      searchValue: '',
      searchEntity: SearchEntityType.CUSTOMER,
    });
  }

  buildSearchForm(searchForm) {
    this.entitySearchForm = this.formBuilder.group(searchForm);
  }

  handleChange() {
    this.filterChanged.emit(this.entitySearchForm.value.searchValue);
  }
}
