<div class="ecare dashboard">
  <div class="ecare-header_container">
    <div class="ecare-header_headline">
      {{ 'wc.ecare.customerAccounts.headline' | translate }}
    </div>
  </div>

  <div class="ecare-numberOforders">
    {{ 'wc.ecare.orders.showing.label' | translate }} {{ customerAccountDtos.length }}
    {{ 'wc.ecare.customerAccounts.customerAccounts.label' | translate }}
  </div>
  <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
    <div class="tile -tile12">
      <div class="">
        <app-customer-accounts-filter
          [canChangeCustomer]="canChangeCustomer"
          [customerId]="customerId"
          [filtering]="search.filtering"
          (filterChanged)="handleFilterChange()" />
      </div>
      <div *ngIf="search?.sorting" class="mobile-sorting-container">
        <app-sortable-dropdown [columns]="columns" [sorting]="search.sorting" (sortChanged)="onSortChange()" />
      </div>

      <div class="dashboard_search ecare-ordersSearch">
        <table *ngIf="customerAccountDtos.length > 0" class="ecare_listing-table orders-table table">
          <thead class="content-sm">
            <tr>
              <th>{{ 'wc.ecare.customerAccounts.table.customName.header' | translate }}</th>
              <th>{{ 'wc.ecare.customerAccounts.table.variableSymbol.header' | translate }}</th>
              <th>{{ 'wc.ecare.customerAccounts.table.caNo.header' | translate }}</th>
              <th>{{ 'wc.ecare.customerAccounts.table.externalNo.header' | translate }}</th>
              <th>{{ 'wc.ecare.customerAccounts.table.paymentType.header' | translate }}</th>
              <th>{{ 'wc.ecare.customerAccounts.table.invoicingAddress.header' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <app-ecare-customer-account-row
              *ngFor="let customerAccount of customerAccountDtos"
              [customerAccount]="customerAccount"
              (selectedCustomerAccount)="itemSelected($event)">
            </app-ecare-customer-account-row>
          </tbody>
        </table>

        <ng-container *ngIf="canDisplayNoDataText">
          <app-ecare-listing-no-results></app-ecare-listing-no-results>
        </ng-container>
      </div>
      <div>
        <app-ecare-pagination
          [page]="search.paging.page"
          [pageSize]="search.paging.pageSize"
          [totalCount]="pagedDto?.totalItems"
          [pagesToShow]="pagedDto?.totalPages"
          (goPrev)="onPrevPage()"
          (goNext)="onNextPage()"
          (goPage)="onSpecificPage($event)"
          (pageSizeChanged)="onPageSizeChanged($event)">
        </app-ecare-pagination>
      </div>
    </div>
  </div>
</div>
