<div class="ecare dashboard">
  <div *ngIf="showFilter" class="ecare-header_container">
    <div class="ecare-header_headline">
      {{ 'wc.ecare.customers.headline' | translate }}
      <div class="ecare-numberOforders">
        {{ 'wc.ecare.orders.showing.label' | translate }} {{ records.length }}
        {{ 'wc.ecare.customers.customers.label' | translate }}
      </div>
    </div>

    <app-customers-filter class="full-width" [clearFilterEmitter]="clearFilterEmitter" (filterChanged)="handleFilterChange($event)" />
  </div>
  <div *ngIf="search?.sorting" class="mobile-sorting-container">
    <app-sortable-dropdown [columns]="columns" [sorting]="search.sorting" (sortChanged)="onSortChange()" />
  </div>

  <div *blockUI="blockUIElement.name; template: blockTemplate" class="dashboard_wrapper">
    <div *ngIf="subjectMetaParameters" class="tile -tile12">
      <div class="dashboard_search ecare-ordersSearch">
        <table *ngIf="records.length > 0; else no_results" class="ecare_listing-table orders-table table">
          <thead class="content-sm">
            <tr>
              <th class="no-wrap">{{ 'wc.ecare.search.segment.header' | translate }}</th>
              <th *ngIf="subjectMetaParameters.get('identityNumber')">
                {{ 'wc.ecare.search.identityNumber.header' | translate }}
              </th>
              <th>{{ 'wc.ecare.search.name.header' | translate }}</th>
              <th>{{ 'wc.ecare.homepage.parameterColumn.label' | translate }}</th>
              <th>{{ 'wc.ecare.search.address.header' | translate }}</th>
              <th>{{ 'wc.ecare.dashboard.contact.label' | translate }}</th>
            </tr>
          </thead>
          <tr
            *ngFor="let customer of records; let i = index"
            class="ecare_listing-table-row clickable"
            (click)="selectCustomer(customer)">
            <td class="no-wrap font-highlight">
              <app-sticker variant="neutral">{{ customer.segment }}</app-sticker>
            </td>
            <td *ngIf="subjectMetaParameters.get('identityNumber')" class="no-wrap font-highlight">
              {{ customer.identityNumber }}
            </td>
            <td class="no-wrap font-highlight">
              {{ customer.firstName }} {{ customer.middleName }} {{ customer.lastName }}
            </td>
            <td class="no-wrap -customer-params">
              <span *ngIf="customer.subjectType === 'Person'">
                <i [inlineSVG]="'/assets/svg/customer-search-parameter-birth.svg'"></i>{{ customer.birthDate }}
              </span>
              <span *ngIf="customer.subjectType !== 'Person'">
                <i [inlineSVG]="'/assets/svg/customer-search-parameter-id.svg'"></i>{{ customer.companyRegNo }}
              </span>
            </td>
            <td class="no-wrap">
              <span *ngIf="customer.address">
                {{ customer.address | displayAddress : addressTypes.DASHBOARD_CA_DETAIL }}
              </span>
            </td>
            <td class="no-wrap">
              <span *ngIf="customer.contacts && customer.contacts[0]">
                {{ customer.contacts[0] | displayContact }}
              </span>
            </td>
          </tr>
        </table>
        <app-ecare-search-table-mobile
          pickerInputType="customer"
          [rows]="records"
          (selectedRow)="selectCustomer($event.id)" />
        <ng-template #no_results>
          <app-ecare-listing-no-results isHomepage="true" [clearFilterEmitter]="clearFilterEmitter"/>
        </ng-template>
        <app-button
          *ngIf="!withPaging && isMore"
          variant="secondary"
          label="{{ 'wc.ecare.homepage.loadMoreResults.button' | translate }}"
          (click)="loadMoreRecords()" />

        <div *ngIf="withPaging">
          <app-ecare-pagination
            [page]="search.paging.page"
            [pageSize]="search.paging.pageSize"
            [totalCount]="totalItems"
            [pagesToShow]="totalPages"
            (goPrev)="onPrevPage()"
            (goNext)="onNextPage()"
            (goPage)="onSpecificPage($event)"
            (pageSizeChanged)="onPageSizeChanged($event)" />
        </div>

        <ng-container *ngIf="canDisplayNoCustomersText">
          <app-ecare-listing-no-results [clearFilterEmitter]="clearFilterEmitter" />
        </ng-container>
      </div>
    </div>
  </div>
</div>
