import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TicketDto } from '@btl/order-bff';
import { from } from 'rxjs/internal/observable/from';
import { EnableDynamicLoading, PageModuleService } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import moment_ from 'moment';
import { CreateTicketModalComponent } from '../tickets/create-ticket-modal/create-ticket-modal.component';
import { FormControl } from '@angular/forms';
import { TicketEditComponent } from '../tickets/edit/ticket-edit.component';
import { UrlParamsService } from '@service/url-params.service';
import { AbstractTicketTableComponent } from '../tickets/abstract-ticket-table.component';
import { TicketFilterComponent } from '../tickets/filter/ticket-filter.component';
import { Observable } from 'rxjs';
import { TicketElse } from '../../../models/tickets-holder';

@Component({
  selector: 'app-ecare-tasks',
  templateUrl: './ecare-tasks.component.html',
  styleUrls: ['./ecare-tasks.component.css'],
})
@EnableDynamicLoading({ customName: EcareTasksComponent.PAGE_ID })
export class EcareTasksComponent extends AbstractTicketTableComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareTasksComponent';

  pageId(): string {
    return EcareTasksComponent.PAGE_ID;
  }

  @ViewChild('ticketFilterComponent') ticketFilterComponent: TicketFilterComponent;

  @Input() simpleMode = false;
  @Input() externalSearch;
  @Input() backUrl;
  @Input() tickets: any[] = null;
  @Input() editCallback: (ticketDto: TicketElse, inNewTab) => void;
  @Input() deleteCallback: (ticketDto: TicketElse) => void;
  @Output() readonly tasksDeletedEmitter = new EventEmitter<void>();
  @Output() readonly showAllTasksEmitter = new EventEmitter<string>();

  onlyTable = false;
  moment = moment_;
  selectedDate: Date | null;
  externalSearchMode;

  ngOnInit(): void {
    this.selectedDate = new Date();
    if (!this.tickets) {
      this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(queryParams => {
        this.cuRefNo = queryParams['cuRefNo'];
        this.caRefNo = queryParams['caRefNo'];
        this.queryParams = queryParams;
        this.initSearch()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {});

        if (this.authService.account?.login) {
          this.search.filtering.push({
            column: 'assignedTo',
            compareType: 'EQUAL',
            value: this.authService.account.login,
          });
          this.loadData();
        }

        this.authService.accountChange.subscribe(() => {
          if (this.ticketFilterComponent) {
            this.sectionTabsChange(this.account.login);
          } else {
            this.search.filtering.push({
              column: 'assignedTo',
              compareType: 'EQUAL',
              value: this.authService.account.login,
            });
            this.loadData();
          }
        });
      });
    } else {
      this.onlyTable = true;
    }
  }

  initSearch(): Observable<any> {
    this.search = {
      filtering: [],
      sorting: [{ column: 'startFrom', sortOrder: 'asc' }],
      paging: {
        page: 1,
        pageSize: 20,
      },
    };

    if (this.cuRefNo) {
      if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
        this.search.filtering.push({
          column: 'references',
          compareType: null,
          value: [
            {
              entityType: 'com.emeldi.ecc.be.crm.dto.CustomerAccount',
              entityId: this.caRefNo,
            },
          ],
        });
      } else {
        this.search.filtering.push({
          column: 'references',
          compareType: null,
          value: [
            {
              entityType: 'com.emeldi.ecc.be.crm.dto.Customer',
              entityId: this.cuRefNo,
            },
          ],
        });
      }
    }

    if (this.simpleMode) {
      this.search.filtering.push(
        {
          column: 'type',
          compareType: null,
          value: {
            code: null,
            areaType: 'TASK',
          },
        },
        {
          column: 'businessState',
          compareType: 'DIFFERENT',
          value: 'CANCELED',
        },
        {
          column: 'businessState',
          compareType: 'DIFFERENT',
          value: 'CLOSED',
        }
      );

      this.search.filtering.push({
        column: 'startFrom',
        compareType: 'BIGGEREQ',
        value: this.moment.utc(this.selectedDate).format(),
      });

      return from(this.authFactoryService.getAuthService().getUserInfo()).pipe(
        tap(userProfile => {
          this.search.filtering.push({
            column: 'assignedTo',
            compareType: 'EQUAL',
            value: userProfile.username,
          });
        })
      );
    } else {
      if (this.externalSearch) {
        this.search = this.externalSearch;
      }
      return from([]);
    }
  }

  loadData() {
    this.ticketingService
      .getTicketsByFilter(this.search)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          if (!this.tickets) {
            this.tickets = [];
          }
          const tickets = [];

          result.data.forEach(ticket => {
            const formControl = new FormControl();
            formControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
              this.allTicketsSelected = this.areAllSelected();
              this.anyTicketsSelected = this.isAnySelected();
            });
            this.selectedFormArray.push(formControl);
            tickets.push(ticket);
          });

          tickets.forEach(ticket => this.tickets.push(ticket));
          if (result.data.length > 0 && result.data.length === this.search.paging.pageSize) {
            this.moreRecordsExists = true;
          } else {
            this.moreRecordsExists = false;
          }
        }
      });
  }

  public handleFilterChange(): void {
    this.tickets.length = 0;
    this.search.paging.page = 1;
    this.loadData();
  }

  newTask() {
    const modalRef = this.modal.open(CreateTicketModalComponent, {
      size: 'sm',
      windowClass: 'dialog dialog-input',
    });
    const createTicketModalComponent = <CreateTicketModalComponent>modalRef.componentInstance;
    createTicketModalComponent.dialogRef = modalRef;
    createTicketModalComponent.createTicketEvent.subscribe(ticketForm => {
      if (ticketForm) {
        if (!this.modulePath) {
          this.setModulePath(PageModuleService.find(EcareTasksComponent.PAGE_ID).path);
        }
        this.navigateSibling(TicketEditComponent.PAGE_ID, {
          id: 'newTicket',
          code: ticketForm.code,
          backUrl: this.backUrl,
          cuRefNo: this.cuRefNo,
          caRefNo: this.customerLocalStorageService.getCurrentCustomerAccount()?.id,
        });
      }
    });
  }

  dateChanged(event: Date) {
    const startFrom = this.search.filtering.find(filter => filter.column === 'startFrom');
    startFrom.value = this.moment.utc(event).local().format();
    this.loadData();
  }

  get tasksOfSelectedDay(): TicketDto[] {
    return this.tickets?.filter(task => this.isSelectedDate(task.startFrom));
  }

  get tasksOfOtherDays(): TicketDto[] {
    return this.tickets?.filter(task => !this.isSelectedDate(task.startFrom));
  }

  isSelectedDate(date: Date) {
    const d = this.moment(this.selectedDate, this.moment.ISO_8601);
    return d.isSame(date, 'day');
  }

  isTodaySelected() {
    const today = new Date();
    return today.toDateString() === this.selectedDate.toDateString();
  }

  selectedDateAsDate() {
    return this.selectedDate;
  }

  showAllTasks() {
    this.showAllTasksEmitter.emit(this.cuRefNo);
  }

  deleteTask(deleteTicket: TicketElse) {
    if (this.deleteCallback) {
      this.deleteCallback(deleteTicket);
    } else {
      this.deleteTasks([deleteTicket.id]);
    }
  }

  deleteSuccess() {
    if (this.onlyTable) {
      this.tasksDeletedEmitter.emit();
    } else {
      this.tickets.length = 0;
      this.loadData();
      this.stickyMessageService.addStickySuccessMessage('wc.ecare.ticket.delete.success');
    }
  }

  edit(event) {
    const ticket = event.ticket;
    if (this.editCallback) {
      this.editCallback(ticket, event.inNewTab);
    } else {
      if (!this.modulePath) {
        this.setModulePath(PageModuleService.find(EcareTasksComponent.PAGE_ID).path);
      }
      const params = UrlParamsService.mergeParams(this.queryParams, { id: ticket.id, backUrl: this.backUrl });

      if (event.inNewTab) {
        window.open(
          this.router.serializeUrl(this.siblingUrlTree('TicketEditComponent', params)),
          '_blank',
        );
      } else {
        this.navigateSibling(TicketEditComponent.PAGE_ID, params);
      }
    }
  }

  duplicate(ticket: TicketDto) {
    this.ticketService.getDuplicateParameters(ticket)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(duplicateParamters => {
        if (duplicateParamters) {
          this.navigateSibling('TicketEditComponent', duplicateParamters);
        }
      });
  }

  sectionTabsChange(event: any) {
    this.ticketFilterComponent.assignedToChanged(event);
    this.ticketFilterComponent.handleChange();
    this.allTicketsSelected = true;
    this.selectDeselectAll();
  }
}
