import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AccountContactDto,
  AddressDto,
  ContactDto,
  CustomerDto,
  OrderDto,
  OrderItemDto,
  OrderParamDto,
  ProductDetailDto
} from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { FormUtils } from '../../../helpers/form-utils';
import { OrderUtils, ScenarioStepTypeEnum, ScenarioTypeEnum } from '../../../helpers/order-utils';
import { Router } from '@angular/router';
import {
  AccountContactsService,
  AuthFactoryService,
  CodebookService,
  CurrentLocaleService,
  ElasticsearchService,
  MetaDescriptionService,
  NetworkInventoryService,
  ProductService
} from '@btl/btl-fe-wc-common';
import { ShoppingCartService } from '@service/shopping-cart.service';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { BlockUIService } from 'ng-block-ui';
import { FiltersComponent } from '../product-listing/filters/filters.component';
import { ProductElasticFilter } from '../../../models/product-elastic-filter';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { PickUpPointService } from '@service/pick-up-point.service';
import { OrderingWizardService } from '@service/ordering-wizard-service';
import { CategoryTypeEnum } from '../../../models/product-filter';
import { addressTypes, DisplayAddressPipe } from '../../../pipes/display-address.pipe';
import { CustomerPartyUtil } from '../../../helpers/customer-party.util';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from '../../../constants';
import { PartyElse } from '../../../models/party-holder';
import { ContactFormComponent } from '../../page/contact-form/contact-form.component';
import { AddressFormComponent } from '../../page/address-form/address-form.component';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ProductAddingToCart } from '@service/product-listing.service';
import {
  ContactEditPopupComponent
} from '../ecare-user-account/user-account-contacts/contact-edit-popup/contact-edit-popup.component';
import { CheckoutPageComponent } from '../checkout-page/checkout-page.component';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import { ProductInShoppingCart } from '../../../models/product-in-shopping-cart';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
})
export class DeliveryComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  private onDestroy$: Subject<void> = new Subject<void>();

  public static readonly HW_INVOICING_CONTACT = 'hwInvoicingContact';
  public static readonly HW_INVOICING_ADDRESS = 'hwInvoicingAddress';
  public static readonly DELIVERY_CONTACT = 'deliveryContact';
  public static readonly DELIVERY_ADDRESS = 'deliveryAddress';
  @ViewChild('hwInvoicingContactForm', { static: false }) hwInvoicingContactForm: ContactFormComponent;
  @ViewChild('hwInvoicingAddressForm', { static: false }) hwInvoicingAddressForm: AddressFormComponent;
  @ViewChild('pickUpPointSearch', { static: false }) private searchInput: ElementRef;
  @ViewChild('contactForm', { static: false }) contactForm: ContactFormComponent;
  @ViewChild('addressForm', { static: false }) addressForm: AddressFormComponent;
  @ViewChild('deliveryNoteField', { static: false }) deliveryNoteField: ElementRef;
  @ViewChild('checkout', { static: false }) checkout: CheckoutPageComponent;

  public deliveryMethods: Array<ProductDetailDto>;
  public onetimePaymentMethods: Array<ProductDetailDto>;

  private searchTerms = new Subject<string>();
  public autocompleteProducts;
  private maxAutocompleteResults: number;
  private maxAutocompleteResultsSubscription: Subscription;
  public filter: ProductElasticFilter;
  private deliveryTimeSlots;
  formDelivery: FormGroup;

  selectedDeliveryContactForm = this.fb.group({
    deliveryContact: [],
  });
  addressTypes = addressTypes;

  orderAttributes: any;
  scenarioTypeEnum = ScenarioTypeEnum;
  selectedDeliveryProduct: ProductDetailDto;
  selectedPaymentProduct: ProductDetailDto;
  selectedPickUpPoint;
  isLoading: boolean;
  ScenarioStepTypeEnum = ScenarioStepTypeEnum;

  // DeliveryInstall Section
  displayDelivery: boolean;
  deliveryInstallProduct: ProductInShoppingCart;
  firstLocation: string;
  secondLocation: string;
  displaySecondForm: boolean;
  customer: CustomerDto;
  defaultContact;
  account;
  hwInvoicingContactDtos: Array<AccountContactDto>;
  deliveryContactDtos: Array<AccountContactDto>;

  // helper
  visibleDeliveryNote: boolean = false;
  selectedDeliveryContact: string = null;
  isSelectedDeliveryContact: boolean = false;

  selectedHwInvoicingContactForm = this.fb.group({
    hwInvoicingContact: [],
  });

  hwInvoicingContactVisible = false;
  primaryContactVisible = false;
  registrationAddressVisible = true;
  selectedInvoiceContact: string = null;
  isSelectedInvoiceContact = false;
  contactTypeEnum = ContactDto.TypeDtoEnum;
  addressTypeEnum = AddressDto.TypeDtoEnum;

  constructor(
    public wcOrderingService: WcOrderingService,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private router: Router,
    private productService: ProductService,
    public shoppingCartService: ShoppingCartService,
    private blockUIService: BlockUIService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private pickUpPointService: PickUpPointService,
    public orderingWizardService: OrderingWizardService,
    private lightNetworkInventory: NetworkInventoryService,
    private elasticsearchService: ElasticsearchService,
    private ngbModal: NgbModal,
    private authFactoryService: AuthFactoryService,
    private accountContactsService: AccountContactsService,
    private metaDescriptionService: MetaDescriptionService,
    private customerLocalStorageService: CustomerLocalStorageService,
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService
  ) {
    this.formDelivery = this.fb.group({
      deliveryMethod: [],
      onetimePaymentMethod: [],
      forgiveDeliveryFee: '',
      differentContact: '',
      deliveryNote: '',
      deliveryContact: this.fb.group(ContactFormComponent.getBaseFormConfiguration()),
      deliveryAddress: this.fb.group(ContactFormComponent.getBaseFormConfiguration()),
      timeSlot: [],
      pickUpPoint: [],
      preferredShop: [],
    });
  }

  ngAfterViewChecked(): void {
    if (this.wcOrderingService.currentOrder) {
      let hwInvoicingContact;
      let hwInvoicingAddress;

      if (this.hwInvoicingContactForm && !this.hwInvoicingContactForm.contactFormComponent) {
        this.fillHwInvoicingContactAndAddress();
      }

      if (this.hwInvoicingContactDtos && this.hwInvoicingContactDtos.length > 0) {
        hwInvoicingContact = JSON.parse(
          OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.HW_INVOICING_CONTACT),
        );
        hwInvoicingAddress = JSON.parse(
          OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.HW_INVOICING_ADDRESS),
        );
        if (!this.isSelectedInvoiceContact && hwInvoicingContact === null) {
          this.isSelectedInvoiceContact = true;
          this.changedHwInvoicingContact(this.hwInvoicingContactDtos[this.hwInvoicingContactDtos.length - 1].id);
        }
      }

      let deliveryContact;
      let deliveryAddress;

      if (this.contactForm && !this.contactForm.contactFormComponent) {
        this.fillContactAndAddress();
      }

      this.checkDeliveryNote();

      if (this.deliveryContactDtos && this.deliveryContactDtos.length > 0) {
        deliveryContact = JSON.parse(
          OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.DELIVERY_CONTACT),
        );
        deliveryAddress = JSON.parse(
          OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.DELIVERY_ADDRESS),
        );
        if (!this.isSelectedDeliveryContact && deliveryContact === null) {
          this.isSelectedDeliveryContact = true;
          this.changedDeliveryContact(this.deliveryContactDtos[this.deliveryContactDtos.length - 1].id);
        }
      }
    }
  }

  public ngAfterViewInit(): void {
    if (this.wcOrderingService.currentOrder) {
      this.fillFormWithOrderAttributes(this.wcOrderingService.currentOrder.orderAttributes);
      this.initDeliveryInstallSection();
      this.fillHwInvoicingContactAndAddress();
      this.fillContactAndAddress();
    }
  }

  ngOnInit() {
    this.blockUIService.start('main');
    this.formDelivery.addControl(
      DeliveryComponent.HW_INVOICING_CONTACT,
      this.fb.group(ContactFormComponent.getBaseFormConfiguration())
    );
    this.formDelivery.addControl(
      DeliveryComponent.HW_INVOICING_ADDRESS,
      this.fb.group(ContactFormComponent.getBaseFormConfiguration())
    );

    this.isLoading = true;
    const handleGetCurrentOrder = (orderDto: OrderDto) => {
      this.wcOrderingService.currentOrder = orderDto;
      this.orderAttributes = this.wcOrderingService.currentOrder.orderAttributes;
      this.wcOrderingService.orderChanged.pipe(takeUntil(this.onDestroy$)).subscribe(orderDto => {
        const orderAttrChanged = OrderUtils.formOrderAttributesChanged(
          this.formDelivery,
          this.orderAttributes,
          orderDto.orderAttributes,
        );
        this.wcOrderingService.currentOrder = orderDto;
        this.orderAttributes = this.wcOrderingService.currentOrder.orderAttributes;
        this.account = this.authFactoryService.getAuthService().account;

        if (orderAttrChanged) {
          this.fillFormWithOrderAttributes(this.orderAttributes);
          this.initDeliveryInstallSection();
          this.getUserHwInvoiceContacts();
          this.fillHwInvoicingContactAndAddress();
          this.getUserDeliveryContacts();
          this.fillContactAndAddress();
        }
      });

      this.account = this.authFactoryService.getAuthService().account;

      this.authFactoryService
        .getAuthService()
        .accountChange.pipe(takeUntil(this.onDestroy$))
        .subscribe(account => {
          const orderDto = this.wcOrderingService.currentOrder;
          this.wcOrderingService.currentOrder = null;
          this.orderAttributes = null;
          this.account = account;
          this.wcOrderingService.currentOrder = orderDto;
          this.orderAttributes = this.wcOrderingService.currentOrder.orderAttributes;
          this.fillFormWithOrderAttributes(this.orderAttributes);
          this.initDeliveryInstallSection();
          this.getUserHwInvoiceContacts();
          this.fillHwInvoicingContactAndAddress();
          this.getUserDeliveryContacts();
          this.fillContactAndAddress();
        });
      this.getUserDeliveryContacts();
      this.getUserHwInvoiceContacts();

      const deliveryProducts$ = this.productService
        .getProductGroupByGroupCode('DELIVERY_ORDER')
        .pipe(
          switchMap(group =>
            group['id'] ? this.productService.getGroupProducts([group['id']], null, null, orderDto.id) : of([])
          )
        );
      let paymentProducts$ = of([]);
      if (
        this.shoppingCartService.preCalculatedShoppingCart &&
        this.shoppingCartService.preCalculatedShoppingCart.checkUpfrontPayment()
      ) {
        paymentProducts$ = this.productService
          .getProductGroupByGroupCode('PAYMENT_ORDER')
          .pipe(
            switchMap(group =>
              group['id'] ? this.productService.getGroupProducts([group['id']], null, null, orderDto.id) : of([])
            )
          );
      }

      forkJoin([deliveryProducts$, paymentProducts$])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(([deliveryProducts, paymentProducts]) => {
          this.deliveryMethods = deliveryProducts;
          this.onetimePaymentMethods = paymentProducts;

          const orderItems: OrderItemDto[] = [];

          const deliveryOi = this.getDeliveryProductFromCart();
          this.displayDelivery = this.shoppingCartService.preCalculatedShoppingCart.isDeliveryNeeded();
          if (this.displayDelivery && !deliveryOi) {
            this.selectedDeliveryProduct = deliveryProducts[0];
            orderItems.push(this.createProductOrderItem(deliveryProducts[0]));
          } else if (deliveryOi) {
            this.selectedDeliveryProduct = deliveryProducts.find(product => product.id === deliveryOi.productId);
          }

          const paymentOi = this.getPaymentProductFromCart();
          if (!paymentOi && this.shoppingCartService.preCalculatedShoppingCart.checkUpfrontPayment()) {
            this.selectedPaymentProduct = this.onetimePaymentMethods[0];
            orderItems.push(this.createProductOrderItem(this.selectedPaymentProduct));
          } else if (paymentOi) {
            this.selectedPaymentProduct = paymentProducts.find(product => product.id === paymentOi.productId);
          }

          if (orderItems.length > 0) {
            orderItems.forEach(orderItem => this.wcOrderingService.currentOrder.orderItems.push(orderItem));
            this.wcOrderingService
              .updateOrderItems(this.wcOrderingService.currentOrder, this.wcOrderingService.currentOrder.orderItems)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(order => {
                this.wcOrderingService.currentOrder = order;
              });
          }

          let forgiveDeliveryFee = false;
          if (deliveryOi) {
            deliveryOi.prices.forEach(price => {
              if (price.overridingPrice === 0) {
                forgiveDeliveryFee = true;
              }
            });
          }

          this.formDelivery.controls['deliveryMethod'].patchValue(
            this.selectedDeliveryProduct ? this.selectedDeliveryProduct.productCode : ''
          );
          this.formDelivery.controls['onetimePaymentMethod'].patchValue(
            this.selectedPaymentProduct ? this.selectedPaymentProduct.productCode : ''
          );
          this.blockUIService.stop('main');
          this.fillFormWithOrderAttributes(this.orderAttributes);
          this.initDeliveryInstallSection();
          this.isLoading = false;
        });
    };
    const hwInvoicingVisibleCall = this.metaDescriptionService.getEntityMetaDescriptions('account', 'Contact', {
      resourceIdentification: { contactType: this.contactTypeEnum.HWINVOICING },
    });

    const registrationAddressPresentCall = this.metaDescriptionService.getEntityMetaDescriptions('account', 'Address', {
      resourceIdentification: { addressType: AddressDto.TypeDtoEnum.REGISTRATION },
    });

    const primaryContactPresentCall = this.metaDescriptionService.getEntityMetaDescriptions('account', 'Contact', {
      resourceIdentification: { contactType: 'PRIMARY' },
    });

    forkJoin([
      this.wcOrderingService.getCurrentOrder(),
      hwInvoicingVisibleCall,
      registrationAddressPresentCall,
      primaryContactPresentCall,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([currentOrder, hwInvoicingVisible, registrationAddressPresent, primaryContactPresent]) => {
        this.hwInvoicingContactVisible = hwInvoicingVisible.metaParameters['guiVisible'] === 'true';
        this.registrationAddressVisible = registrationAddressPresent.metaParameters['guiVisible'] === 'true';
        this.primaryContactVisible = primaryContactPresent.metaParameters['guiVisible'] === 'true';
        handleGetCurrentOrder(currentOrder);
      });

    this.initAddressAutocomplete();
  }

  getUserDeliveryContacts() {
    if (this.account) {
      this.accountContactsService
        .getAccountContactsByType(this.contactTypeEnum.DELIVERY, this.account.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.deliveryContactDtos = result.data;
          this.fillContactAndAddress();
        });
    }
  }

  getUserHwInvoiceContacts() {
    if (this.account) {
      this.accountContactsService
        .getAccountContactsByType(this.contactTypeEnum.HWINVOICING, this.account.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.hwInvoicingContactDtos = result.data;
          this.fillHwInvoicingContactAndAddress();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  fillHwInvoicingContactAndAddress() {
    let hwInvoicingContact = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.HW_INVOICING_CONTACT),
    );
    const hwInvoicingAddress = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.HW_INVOICING_ADDRESS),
    );

    if (hwInvoicingContact) {
      hwInvoicingContact.address = hwInvoicingAddress;
    }
    if (!hwInvoicingContact) {
      if (this.hwInvoicingContactDtos && this.hwInvoicingContactDtos.length > 0) {
        hwInvoicingContact = this.hwInvoicingContactDtos[0];
      }
    } else if (hwInvoicingContact.id) {
      hwInvoicingContact = this.hwInvoicingContactDtos?.find(fdc => fdc.id === hwInvoicingContact.id);
    }
    this.hwInvoicingContactChanged(hwInvoicingContact);
  }

  changedHwInvoicingContact(contactId: string) {
    this.selectedInvoiceContact = contactId;
    this.hwInvoicingContactChanged(this.hwInvoicingContactDtos.find(contact => contact.id === contactId));
  }

  hwInvoicingContactChanged(contact: any) {
    if (this.hwInvoicingContactForm && this.hwInvoicingAddressForm) {
      if (contact) {
        if (this.hwInvoicingContactForm.formGenerated) {
          this.hwInvoicingContactForm.contactFormComponent.form.patchValue(contact);
          this.sameAs(
            contact,
            this.hwInvoicingContactForm,
            this.getPrimaryContactFormGroup(),
            this.getPrimaryContactFormGroup() ? null : this.customer?.contact,
          );
        }

        if (this.hwInvoicingAddressForm.formGenerated && contact?.address) {
          this.hwInvoicingAddressForm.addressFormComponent.form.patchValue(contact?.address);
          if (this.registrationAddressVisible) {
            this.sameAs(
              contact?.address,
              this.hwInvoicingAddressForm,
              this.getRegistrationAddressFormGroup(),
              this.getRegistrationAddressFormGroup() ? null : this.customer?.address,
            );
          }
        }

        if (!this.hwInvoicingContactForm.formGenerated) {
          this.hwInvoicingContactForm
            .generateForm(contact)
            .pipe(first())
            .subscribe(() => {
              this.hwInvoicingContactForm.contactFormComponent.form.patchValue(contact);
              this.sameAs(
                contact,
                this.hwInvoicingContactForm,
                this.getPrimaryContactFormGroup(),
                this.getPrimaryContactFormGroup() ? null : this.customer?.contact,
              );
            });
        }

        if (!this.hwInvoicingAddressForm.formGenerated) {
          this.hwInvoicingAddressForm
            .generateForm(contact?.address)
            .pipe(first())
            .subscribe(() => {
              if (contact?.address) {
                this.hwInvoicingAddressForm.addressFormComponent.form.patchValue(contact?.address);
                if (this.registrationAddressVisible) {
                  this.sameAs(
                    contact?.address,
                    this.hwInvoicingAddressForm,
                    this.getRegistrationAddressFormGroup(),
                    this.getRegistrationAddressFormGroup() ? null : this.customer?.address,
                  );
                }
              }
            });
        }
      } else {
        if (!this.hwInvoicingContactForm.formGenerated) {
          this.hwInvoicingContactForm
            .generateForm(null)
            .pipe(first())
            .subscribe(() => {
            });
        }
        if (!this.hwInvoicingAddressForm.formGenerated) {
          this.hwInvoicingAddressForm
            .generateForm(null)
            .pipe(first())
            .subscribe(() => {
            });
        }
        this.hwInvoicingContactForm.resetForm();
        this.hwInvoicingAddressForm.resetForm();
      }
      if (contact?.id) {
        this.selectedHwInvoicingContactForm.get(DeliveryComponent.HW_INVOICING_CONTACT).patchValue(contact?.id);
      } else {
        this.selectedHwInvoicingContactForm.get(DeliveryComponent.HW_INVOICING_CONTACT).patchValue('');
        this.sameAs(
          contact,
          this.hwInvoicingContactForm,
          this.getPrimaryContactFormGroup(),
          this.getPrimaryContactFormGroup() ? null : this.customer?.contact,
        );
        if (this.registrationAddressVisible) {
          this.sameAs(
            contact?.address,
            this.hwInvoicingAddressForm,
            this.getRegistrationAddressFormGroup(),
            this.getRegistrationAddressFormGroup() ? null : this.customer?.address,
          );
        }
      }
    }
  }

  sameAs(
    object,
    form: ContactFormComponent | AddressFormComponent,
    sameAsForm: FormGroup,
    sameAsObject?: ContactDto | AddressDto,
  ) {
    if (sameAsForm) {
      form.sameAsForm = sameAsForm;
    } else if (sameAsObject) {
      form.sameAsObject = sameAsObject;
    }

    if (
      (sameAsForm || sameAsObject) &&
      CustomerPartyUtil.equalsWithExclude(
        object,
        sameAsForm ? sameAsForm.getRawValue() : sameAsObject,
        'type',
        'id',
        'parameters',
        'address',
      )
    ) {
      form.setSameAs(true);
    } else {
      form.setSameAs(false);
    }
  }

  getPrimaryContactFormGroup(): FormGroup {
    const form = this.checkout?.createCustomerComponent?.customerFormComponent?.contactFormComponent?.form;
    return form as FormGroup;
  }

  getRegistrationAddressFormGroup(): FormGroup {
    const form =
      this.registrationAddressVisible &&
      !this.checkout?.createCustomerComponent?.customerFormComponent?.addressFormComponent?.hide
        ? this.checkout?.createCustomerComponent?.customerFormComponent?.addressFormComponent?.form
        : null;
    return form;
  }

  getHwInvoicingContactFormGroup() {
    return this.formDelivery?.get(DeliveryComponent.HW_INVOICING_CONTACT) as FormGroup;
  }

  getHwInvoicingAddressFormGroup() {
    return this.formDelivery?.get(DeliveryComponent.HW_INVOICING_ADDRESS) as FormGroup;
  }

  fillContactAndAddress() {
    this.customer = JSON.parse(OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, 'customer'));

    if (!this.customer) {
      this.customer = this.customerLocalStorageService.getCurrentCustomer();
    }
    let deliveryAddress = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.DELIVERY_ADDRESS),
    );
    let deliveryContact = JSON.parse(
      OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.DELIVERY_CONTACT),
    );

    if (this.customer) {
      this.defaultContact = {};
      CustomerPartyUtil.copyWithExclude(this.customer?.contact, this.defaultContact);
      this.defaultContact.type = this.contactTypeEnum.DELIVERY;
      if (!this.customer?.address) {
        this.customer.address = CustomerPartyUtil.getDefaultAddress(AddressDto.TypeDtoEnum.REGISTRATION);
      }
      this.defaultContact.address = {};
      CustomerPartyUtil.copyWithExclude(this.customer?.address, this.defaultContact.address);
      this.defaultContact.address.type = this.contactTypeEnum.DELIVERY;

      this.orderAttributes.forEach(attribute => {
        const attr = attribute.attr;
        const value = attribute.value;
        if (attr === DeliveryComponent.DELIVERY_ADDRESS && this.showDeliveryContact()) {
          deliveryAddress = JSON.parse(value);
        }
        if (attr === DeliveryComponent.DELIVERY_CONTACT && this.showDeliveryContact()) {
          deliveryContact = JSON.parse(value);
        }
        if (this.formDelivery.controls['differentContact'].value) {
          if (attr === 'contactsSection') {
            const differentContact = JSON.parse(value);
            let i = 1;
            for (const item of differentContact) {
              if (item.contact) {
                this.formDelivery.controls[`contact${i}`]?.patchValue(item.contact);
              }
              i++;
            }
          }
        } else {
          if (attr === 'customer') {
            if (this.formDelivery.controls['contact1']) {
              this.formDelivery.controls['contact1'].patchValue(this.defaultContact);
            }
            if (this.formDelivery.controls['contact2']) {
              this.formDelivery.controls['contact2'].patchValue(this.defaultContact);
            }
          }
        }
        if (attr === 'contactsSection') {
          const differentContact = JSON.parse(value);
          let i = 1;
          for (const item of differentContact) {
            if (item.timeSlot) {
              this.formDelivery.controls[`timeSlot${i}`]?.patchValue(item.timeSlot);
            }
            i++;
          }
        }
      });
    }

    if (!this.showDeliveryContact()) {
      this.formDelivery.controls[DeliveryComponent.DELIVERY_ADDRESS].disabled;
      this.formDelivery.controls[DeliveryComponent.DELIVERY_CONTACT].disabled;
    }
    if (
      !deliveryContact &&
      this.defaultContact &&
      (!this.deliveryContactDtos || this.deliveryContactDtos.length === 0)
    ) {
      deliveryContact = this.defaultContact;
    }
    if (deliveryAddress) {
      deliveryContact.address = deliveryAddress;
    }

    if (!deliveryContact) {
      if (this.deliveryContactDtos && this.deliveryContactDtos.length > 0) {
        deliveryContact = this.deliveryContactDtos[0];
      }
    } else if (deliveryContact.id) {
      deliveryContact = this.deliveryContactDtos?.find(fdc => fdc.id === deliveryContact.id);
    }
    this.deliveryContactChanged(deliveryContact);
  }

  noRegistrationAddress() {
    this.customer = JSON.parse(OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, 'customer'));
    return !this.customer || !this.customer?.address || !this.customer?.address.zipCode;
  }

  fillFormWithOrderAttributes(orderAttributes: Array<OrderParamDto>) {
    this.orderAttributes = orderAttributes;
    this.orderAttributes.forEach(attribute => {
      const attr = attribute.attr;
      const value = attribute.value;
      const control = this.formDelivery.get(attr);
      if (control) {
        if (control instanceof FormGroup) {
          control.patchValue(value);
        } else if (attr === 'forgiveDeliveryFee') {
          if (value === 'true') {
            control.setValue(value);
          } else {
            control.setValue(false);
          }
        } else {
          control.setValue(value);
        }
      }
    });
  }

  hwScenario() {
    return this.shoppingCartService.preCalculatedShoppingCart.checkShipment();
  }

  updateOrderDtoAttr(orderAttributes: Array<OrderParamDto>, formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl && control.value) {
        const value = control.value;
        OrderUtils.updateOrderAttr(orderAttributes, field, control.value);
      } else if (control instanceof FormGroup) {
        let value;
        if (
          (field === DeliveryComponent.DELIVERY_CONTACT || field === DeliveryComponent.DELIVERY_ADDRESS) &&
          this.showDeliveryContact()
        ) {
          value = control.getRawValue();
        }

        if (field === DeliveryComponent.HW_INVOICING_CONTACT || field === DeliveryComponent.HW_INVOICING_ADDRESS) {
          if (!(this.hwScenario() && this.hwInvoicingContactVisible)) {
            value = null;
          } else {
            value = control.getRawValue();
          }
        }
        OrderUtils.updateOrderAttr(orderAttributes, field, JSON.stringify(value));
      }
    });

    if (this.deliveryInstallProduct) {
      const installationTerm = [];

      if (this.firstLocation) {
        const firstContact = {
          location: this.formDelivery.get('location1').value,
          contact: this.formDelivery.get('contact1').value,
          timeSlot: this.formDelivery.get('timeSlot1').value,
        };
        installationTerm.push(firstContact);
      }
      if (this.secondLocation) {
        const secondContact = {
          location: this.formDelivery.get('location2').value,
          contact: this.formDelivery.get('contact2').value,
          timeSlot: this.formDelivery.get('timeSlot2').value,
        };
        installationTerm.push(secondContact);
      }

      if (!this.deliveryNoteField || this.deliveryNoteField.nativeElement.value === '') {
        OrderUtils.updateOrderAttr(this.wcOrderingService.currentOrder.orderAttributes, 'deliveryNote', null);
      }

      OrderUtils.updateOrderAttr(
        this.wcOrderingService.currentOrder.orderAttributes,
        'contactsSection',
        JSON.stringify(installationTerm),
      );
    }
  }
  confirmDeliveryData() {
    this.validateForm();
    let checkOutOrderParamsDto: Array<OrderParamDto> = [];
    if (this.checkout) {
      checkOutOrderParamsDto = this.checkout.getOrderToUpdate();
    }

    if (this.formDelivery.valid && (!this.checkout || !this.checkout.createCustomerComponent || this.checkout.createCustomerComponent.formUserDetails.valid)) {
      if (this.isReservationAtStore()) {
        const shop: PartyElse = JSON.parse(this.formDelivery.value.preferredShop);
        this.wcOrderingService
          .getCurrentOrderHwAvailability(shop.code)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(response => {
            if (!response.stockAvailability) {
              const dialogReference = this.ngbModal.open(ConfirmationDialogComponent, {
                windowClass: 'dialog dialog-confirmation',
              });

              const confirmationDialogComponent = <ConfirmationDialogComponent>dialogReference.componentInstance;
              confirmationDialogComponent.texts.push('wc.ecare.shop.productsAtStockNotAvailable');
              confirmationDialogComponent.cancellationVisible = false;
              confirmationDialogComponent.confirmationLocalizedKey = 'wc.common.ok.button';
              confirmationDialogComponent.dialogReference = dialogReference;
              confirmationDialogComponent.confirmationHandler = () => {
                confirmationDialogComponent.dialogReference.dismiss();
              };
            } else {
              this.updateOrder(checkOutOrderParamsDto);
            }
          });
      } else {
        this.updateOrder(checkOutOrderParamsDto);
      }
    } else {
      setTimeout(() => {
        const errorElements = this.renderer.selectRootElement('app-input-errors div', true);
        errorElements.style.scrollMarginTop = Constants.NAVIGATION_HEIGHT;
        errorElements.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }

  updateOrder(orderParamsDto: Array<OrderParamDto>) {
    this.updateOrderDtoAttr(orderParamsDto, this.formDelivery);
    const handleGetCurrentOrder = (orderDto: OrderDto) => {
      this.deliveryTimeSlots = null;
      this.wcOrderingService.currentOrder = orderDto;
      this.router.navigateByUrl('/eshop/summary-page');
    };

    const orderAsMap = {
      orderAttributes: orderParamsDto,
      recordVersion: this.wcOrderingService.currentOrder.recordVersion,
    };
    this.wcOrderingService.addAccountId(this.wcOrderingService.currentOrder, orderAsMap);
    this.wcOrderingService
      .patchOrder(this.wcOrderingService.currentOrder.id, orderAsMap)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        const toUpdateOrderItems = [];
        const deliveryProduct = this.getDeliveryProduct(order.orderItems);
        if (deliveryProduct) {
          this.wcOrderingService.saveAttribute('timeSlotProposal', deliveryProduct, this.deliveryTimeSlots);
          toUpdateOrderItems.push(deliveryProduct);
        }
        if (toUpdateOrderItems.length > 0) {
          this.wcOrderingService
            .updateOrderItems(order, toUpdateOrderItems)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(handleGetCurrentOrder);
        } else {
          handleGetCurrentOrder(order);
        }
      });
  }

  showDeliveryContact() {
    let ret = true;
    if (!this.selectedDeliveryProduct || this.isDeliveryToShop() || this.isDeliveryToPickupPoint() || this.isReservationAtStore()) {
      ret = false;
    }
    if (
      this.customer &&
      this.customer?.contact.preferredChannel === 'NONE' &&
      !this.isDeliveryToShop() &&
      !this.isDeliveryToPickupPoint() &&
      !this.isReservationAtStore()
    ) {
      ret = true;
    }
    return ret;
  }

  validateForm() {
    if (this.isDeliveryToShop() || this.isDeliveryToPickupPoint()) {
      FormUtils.setValidation(this.formDelivery.get('pickUpPoint'), Validators.required);
    } else {
      FormUtils.setValidation(this.formDelivery.get('pickUpPoint'), null);
    }

    if (this.isReservationAtStore()) {
      FormUtils.setValidation(this.formDelivery.get('preferredShop'), Validators.required);
    } else {
      FormUtils.setValidation(this.formDelivery.get('preferredShop'), null);
    }

    FormUtils.validateAllFormFields(this.formDelivery);
  }

  isDeliveryToShop(): boolean {
    return (
      this.selectedDeliveryProduct &&
      this.selectedDeliveryProduct.parametersStatic &&
      this.selectedDeliveryProduct.parametersStatic['deliveryToShop'] === 'true'
    );
  }

  isDeliveryToPickupPoint(): boolean {
    return (
      this.selectedDeliveryProduct &&
      this.selectedDeliveryProduct.parametersStatic &&
      this.selectedDeliveryProduct.parametersStatic['deliveryToPickUpPoint'] === 'true'
    );
  }

  isReservationAtStore(): boolean {
    return (
      this.selectedDeliveryProduct &&
      this.selectedDeliveryProduct.parametersStatic &&
      this.selectedDeliveryProduct.parametersStatic['reservationAtStore'] === 'true'
    );
  }

  isDeliveryFree() {
    return (
      this.selectedDeliveryProduct &&
      (!this.selectedDeliveryProduct.prices['OC'] || this.selectedDeliveryProduct.prices['OC'].price === 0)
    );
  }

  isInstallationTerm() {
    return this.deliveryInstallProduct;
  }

  getInstallDeliveryProduct(orderItems?) {
    if (!orderItems) {
      orderItems = this.shoppingCartService.preCalculatedShoppingCart.order.orderItems;
    }
    return orderItems.find(orderItem => orderItem.productId === this.deliveryInstallProduct?.productDetail.id);
  }

  getDeliveryProduct(orderItems?) {
    if (!orderItems) {
      orderItems = this.shoppingCartService.preCalculatedShoppingCart.order.orderItems;
    }
    if (this.selectedDeliveryProduct) {
      return orderItems.find(orderItem => orderItem.productId === this.selectedDeliveryProduct.id);
    }
    return null;
  }

  createProductOrderItem(product: ProductDetailDto): OrderItemDto {
    return {
      productId: product.id,
      parentOrderItemId: null,
      partyRefNo: null,
      action: 'ADD',
    };
  }

  changeDeliveryProduct(dm: ProductDetailDto) {
    this.selectedDeliveryProduct = dm;
    this.formDelivery.controls['pickUpPoint'].patchValue(null);
    const deliveryMethod = this.getDeliveryProductFromCart();
    if (deliveryMethod) {
      if (deliveryMethod.productId !== dm.id) {
        this.wcOrderingService
          .removeOrderItem(this.wcOrderingService.currentOrder, deliveryMethod)
          .pipe(
            switchMap(order =>
              this.wcOrderingService.addProductToShoppingCartWithParent(
                order,
                dm as ProductAddingToCart,
                null,
                null,
                null,
                null,
                false,
              ),
            ),
            takeUntil(this.onDestroy$),
          )
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(order => {
            this.wcOrderingService.currentOrder = order;
          });
      }
    } else {
      this.wcOrderingService
        .addProductToShoppingCartWithParent(this.wcOrderingService.currentOrder, dm as ProductAddingToCart, null, null, null, null, false)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(order => {
          this.wcOrderingService.currentOrder = order;
        });
    }
  }

  getDeliveryProductFromCart() {
    return this.deliveryMethods
      .map(deliveryMethod =>
        this.shoppingCartService.preCalculatedShoppingCart.getOrderItemByProductId(deliveryMethod.id)
      )
      .find(oi => oi);
  }

  changePaymentProduct(opt: ProductDetailDto) {
    this.selectedPaymentProduct = opt;
    const paymentMethod = this.getPaymentProductFromCart();
    if (paymentMethod) {
      this.wcOrderingService
        .removeOrderItem(this.wcOrderingService.currentOrder, paymentMethod)
        .pipe(
          switchMap(order =>
            this.wcOrderingService.addProductToShoppingCartWithParent(
              this.wcOrderingService.currentOrder,
              opt as ProductAddingToCart,
              null,
              null,
              null,
              null,
              false,
            ),
          ),
          takeUntil(this.onDestroy$),
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(order => {
          this.wcOrderingService.currentOrder = order;
        });
    } else {
      this.wcOrderingService
        .addProductToShoppingCartWithParent(
          this.wcOrderingService.currentOrder,
          opt as ProductAddingToCart,
          null,
          null,
          null,
          null,
          false,
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(order => (this.wcOrderingService.currentOrder = order));
    }
  }

  getPaymentProductFromCart() {
    return this.onetimePaymentMethods
      .map(onetimePaymentMethod =>
        this.shoppingCartService.preCalculatedShoppingCart.getOrderItemByProductId(onetimePaymentMethod.id)
      )
      .find(oi => oi);
  }

  forgiveDeliveryFeeChanged($event) {
    const deliveryItem = this.getDeliveryProductFromCart();
    if ($event.target.checked) {
      deliveryItem.action = 'ADD';
      deliveryItem.prices.forEach(price => {
        price.overridingPrice = 0;
      });
    } else {
      deliveryItem.action = 'ADD';
      deliveryItem.prices.forEach(price => {
        price.overridingPrice = null;
      });
    }
    this.wcOrderingService
      .updateOrderItems(this.wcOrderingService.currentOrder, [deliveryItem])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(order => {
        this.wcOrderingService.currentOrder = order;
      });
  }

  selectPoint(point) {
    this.formDelivery.controls['pickUpPoint'].patchValue(point._source.name1);
    this.selectedPickUpPoint = point;
    this.addPickUpPointAttribute();
  }

  addPickUpPointAttribute() {
    const deliveryItem = this.getDeliveryProductFromCart();
    if (this.selectedPickUpPoint) {
      if (deliveryItem.productId === this.selectedDeliveryProduct.id) {
        this.wcOrderingService.saveAttribute('ouRefNo', deliveryItem, this.selectedPickUpPoint._source.ouRefNo);
      }
      this.wcOrderingService
        .updateOrderItems(this.wcOrderingService.currentOrder, [deliveryItem])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(order => {
          this.wcOrderingService.currentOrder = order;
        });
    }
  }

  initDeliveryInstallSection() {
    const allProduct = [];
    this.shoppingCartService.preCalculatedShoppingCart.products.forEach(loopProduct => {
      loopProduct.forEach(product => {
        allProduct.push(product);
      });
    });
    this.deliveryInstallProduct = this.shoppingCartService.preCalculatedShoppingCart.getProductInCartByCategoryId(
      allProduct,
      CategoryTypeEnum.PRODC_GE_DELIVERY_INSTALL
    );
    if (this.deliveryInstallProduct) {
      const productTpFix = this.shoppingCartService.preCalculatedShoppingCart.getProductInCartByCategoryId(
        allProduct,
        CategoryTypeEnum.PRODC_SU_TP_FIX
      );
      const productTpFixCircuit = this.shoppingCartService.preCalculatedShoppingCart.getProductInCartByCategoryId(
        allProduct,
        CategoryTypeEnum.PRODC_SU_TP_FIX_CIRCUIT
      );
      if (productTpFix) {
        this.firstLocation = this.shoppingCartService.preCalculatedShoppingCart.getProductOrderItemAttr(
          productTpFix,
          'locRefNo'
        );
        if (this.firstLocation) {
          this.lightNetworkInventory
            .getLocationById(this.firstLocation)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(location => {
              if (location) {
                this.getAddressText(location.adrRefNo)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(address => {
                    location['address'] = address;
                    this.formDelivery.controls['location1'].patchValue(location);
                  });
              }
            });
        }
      }

      if (productTpFixCircuit) {
        this.secondLocation = this.shoppingCartService.preCalculatedShoppingCart.getProductOrderItemAttr(
          productTpFixCircuit,
          'locRefNo'
        );
        this.displaySecondForm = true;
        if (this.secondLocation) {
          this.lightNetworkInventory
            .getLocationById(this.firstLocation)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(location => {
              if (location) {
                this.getAddressText(location.adrRefNo)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(address => {
                    location['address'] = address;
                    this.formDelivery.controls['location2'].patchValue(location);
                  });
              }
            });
        }
      }
    }
  }

  public getAddressText(adrRefNo: string) {
    const properties: Map<string, any> = new Map<string, any>();
    properties.set('adrRefNo', adrRefNo);

    return this.elasticsearchService
      .query(ElasticsearchService.ADDRESS_INDEX, null, null, properties, null, 1, 1)
      .pipe(map(oph => oph.hits.hits))
      .pipe(
        map(addresses => {
          if (addresses && addresses.length > 0) {
            const addressPipe = new DisplayAddressPipe(this.codebookService, this.currentLocaleService);
            return addressPipe.transform(addresses[0]._source, addressTypes.DELIVERY);
          }
        })
      );
  }

  /**
   * Called from GUI when search input changes
   * @param text
   */
  public searchChange(text: string) {
    this.searchTerms.next(text);
  }

  /**
   * Sets text filter in filtering service
   * @param text Text to set
   */
  public setFilteringText(text: string) {
    if (text && text.length >= FiltersComponent.MIN_TEXT_LENGTH_FOR_SEARCH) {
    }
  }

  public autocompleteFocusOut(autocomplete: HTMLElement) {
    setTimeout(() => (autocomplete.hidden = true), 500);
  }

  private initAddressAutocomplete() {
    this.maxAutocompleteResultsSubscription = this.propertyAccessorLocalService
      .getMaxAutocompleteResults()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(maxAutocompleteResults => (this.maxAutocompleteResults = maxAutocompleteResults));
    this.autocompleteProducts = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(Constants.DEBOUNCE_TIME),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => {
        if (term.length < FiltersComponent.MIN_TEXT_LENGTH_FOR_SEARCH || !this.maxAutocompleteResults) {
          return of<[]>([]);
        } else {
          return this.pickUpPointService.searchByText(term, this.maxAutocompleteResults).pipe(source => {
            return source;
          });
        }
      })
    );
  }

  differentContact(event) {
    if (event.target.checked) {
      this.formDelivery.get('contact1').enable();
      this.formDelivery.get('contact1').reset({
        type: ['TP_INSTALLATION'],
        firstName: [null],
        preferredChannel: ['ALL'],
        middleName: [null],
        lastName: [null],
        phone1: [null],
        email: [null],
        id: null,
      });
    } else {
      if (this.defaultContact) {
        this.formDelivery.get('contact1').patchValue(this.defaultContact);
      }
      this.formDelivery.get('contact1').disable();
    }
  }

  changedDeliveryContact(contactId: string) {
    this.selectedDeliveryContact = contactId;
    this.deliveryContactChanged(this.deliveryContactDtos.find(contact => contact.id === contactId));
  }

  deliveryContactChanged(contact: any) {
    if (this.contactForm && this.addressForm) {
      if (contact) {
        if (this.contactForm.contactFormComponent) {
          this.contactForm.contactFormComponent.form.patchValue(contact);
          this.sameAs(
            contact,
            this.contactForm,
            this.hwInvoicingContactVisible ? this.getHwInvoicingContactFormGroup() : this.getPrimaryContactFormGroup(),
            this.hwInvoicingContactVisible || this.getHwInvoicingContactFormGroup() ? null : this.customer?.contact,
          );
        }

        if (this.addressForm.addressFormComponent && contact?.address) {
          this.addressForm.addressFormComponent.form.patchValue(contact?.address);
          this.sameAs(
            contact?.address,
            this.addressForm,
            this.hwInvoicingContactVisible
              ? this.getHwInvoicingAddressFormGroup()
              : this.getRegistrationAddressFormGroup(),
            this.hwInvoicingContactVisible || this.getRegistrationAddressFormGroup() ? null : this.customer?.address,
          );
        }

        this.contactForm
          .generateForm(contact)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(first())
          .subscribe(() => {
            this.contactForm.contactFormComponent.form.patchValue(contact);
            this.sameAs(
              contact,
              this.contactForm,
              this.hwInvoicingContactVisible
                ? this.getHwInvoicingContactFormGroup()
                : this.getPrimaryContactFormGroup(),
              this.hwInvoicingContactVisible || this.getPrimaryContactFormGroup() ? null : this.customer?.contact,
            );
          });
        this.addressForm
          .generateForm(contact?.address)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(first())
          .subscribe(() => {
            if (contact?.address) {
              this.addressForm.addressFormComponent.form.patchValue(contact?.address);
              this.sameAs(
                contact?.address,
                this.addressForm,
                this.hwInvoicingContactVisible
                  ? this.getHwInvoicingAddressFormGroup()
                  : this.getRegistrationAddressFormGroup(),
                this.hwInvoicingContactVisible || this.getRegistrationAddressFormGroup() ? null : this.customer?.address,
              );
            }
          });
      } else {
        if (!this.contactForm.contactFormComponent) {
          this.contactForm
            .generateForm(null)
            .pipe(takeUntil(this.onDestroy$))
            .pipe(first())
            .subscribe(() => {});
        }
        if (!this.addressForm.addressFormComponent) {
          this.addressForm
            .generateForm(null)
            .pipe(takeUntil(this.onDestroy$))
            .pipe(first())
            .subscribe(() => {});
        }
        this.contactForm.resetForm();
        this.addressForm.resetForm();
      }
      if (contact?.id) {
        this.selectedDeliveryContactForm.get(DeliveryComponent.DELIVERY_CONTACT).patchValue(contact?.id);
      } else {
        this.selectedDeliveryContactForm.get(DeliveryComponent.DELIVERY_CONTACT).patchValue('');
        this.sameAs(
          contact,
          this.contactForm,
          this.hwInvoicingContactVisible ? this.getHwInvoicingContactFormGroup() : this.getPrimaryContactFormGroup(),
          this.hwInvoicingContactVisible || this.getPrimaryContactFormGroup() ? null : this.customer?.contact,
        );
        this.sameAs(
          contact?.address,
          this.addressForm,
          this.hwInvoicingContactVisible
            ? this.getHwInvoicingAddressFormGroup()
            : this.getRegistrationAddressFormGroup(),
          this.hwInvoicingContactVisible || this.getRegistrationAddressFormGroup() ? null : this.customer?.address,
        );
      }
    }
  }

  getContactFormGroup() {
    return this.formDelivery.get(DeliveryComponent.DELIVERY_CONTACT) as FormGroup;
  }

  getAddressFormGroup() {
    return this.formDelivery.get(DeliveryComponent.DELIVERY_ADDRESS) as FormGroup;
  }

  updateDeliveryContact() {
    if (this.account) {
      this.accountContactsService
        .getAccountContactsByType(this.contactTypeEnum.DELIVERY, this.account.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.deliveryContactDtos = result.data;
          this.changedDeliveryContact(this.deliveryContactDtos[this.deliveryContactDtos.length - 1].id);
        });
    }
  }

  showNewDeliveryModal() {
    const modalRef = this.ngbModal.open(ContactEditPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const contactEditPopupComponent = <ContactEditPopupComponent>modalRef.componentInstance;
    contactEditPopupComponent.dialogRef = modalRef;
    contactEditPopupComponent.accountId = this.account.id;
    contactEditPopupComponent.contactType = this.contactTypeEnum.DELIVERY;
    contactEditPopupComponent.handler = () => {
      this.updateDeliveryContact();
      modalRef.close();
    };
  }

  showDeliveryNote() {
    this.visibleDeliveryNote = true;
    setTimeout(() => {
      this.deliveryNoteField.nativeElement.focus();
    });
  }

  checkDeliveryNote() {
    if (OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, 'deliveryNote') !== null) {
      this.visibleDeliveryNote = true;
    }
  }

  updateInvoiceContact() {
    if (this.account) {
      this.accountContactsService
        .getAccountContactsByType(this.contactTypeEnum.HWINVOICING, this.account.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.hwInvoicingContactDtos = result.data;
          this.changedHwInvoicingContact(this.hwInvoicingContactDtos[this.hwInvoicingContactDtos.length - 1].id);
        });
    }
  }

  showNewInvoiceModal() {
    const modalRef = this.ngbModal.open(ContactEditPopupComponent, {
      size: 'md',
      windowClass: 'dialog dialog-input',
    });
    const contactEditPopupComponent = <ContactEditPopupComponent>modalRef.componentInstance;
    contactEditPopupComponent.dialogRef = modalRef;
    contactEditPopupComponent.accountId = this.account.id;
    contactEditPopupComponent.contactType = this.contactTypeEnum.HWINVOICING;
    contactEditPopupComponent.handler = () => {
      this.updateInvoiceContact();
      modalRef.close();
    };
  }

  hwInvoicingFormsGenerated() {
    if (this.hwInvoicingAddressForm.formGenerated && this.hwInvoicingContactForm.formGenerated) {
      const hwInvoicingContact = JSON.parse(
        OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.HW_INVOICING_CONTACT),
      );
      const hwInvoicingAddress = JSON.parse(
        OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.HW_INVOICING_ADDRESS),
      );
      if (this.primaryContactVisible && !this.checkout) {
        if (hwInvoicingContact) {
          this.sameAs(hwInvoicingContact, this.hwInvoicingContactForm, null, this.customer?.contact);
        } else {
          this.hwInvoicingContactForm.sameAsObject = this.customer?.contact;
          this.hwInvoicingContactForm.setSameAs(true);
        }
      }

      if (this.registrationAddressVisible && !this.checkout) {
        if (hwInvoicingAddress) {
          this.sameAs(hwInvoicingAddress, this.hwInvoicingAddressForm, null, this.customer?.address);
        } else {
          this.hwInvoicingAddressForm.sameAsObject = this.customer?.address;
          this.hwInvoicingAddressForm.setSameAs(true);
        }
      }
      this.fillContactAndAddress();
    }
  }

  deliveryFormsGenerated() {
    if (this.contactForm.formGenerated && this.addressForm.formGenerated) {
      const deliveryAddress = JSON.parse(
        OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.DELIVERY_ADDRESS),
      );
      const deliveryContact = JSON.parse(
        OrderUtils.getOrderAttributeValue(this.wcOrderingService.currentOrder, DeliveryComponent.DELIVERY_CONTACT),
      );

      if (this.primaryContactVisible && !this.checkout && !this.hwInvoicingContactVisible) {
        if (deliveryContact) {
          this.sameAs(deliveryContact, this.contactForm, null, this.customer?.contact);
        } else {
          this.contactForm.sameAsObject = this.customer?.contact;
          this.contactForm.setSameAs(true);
        }
      }
      if (this.customer?.address && !this.checkout && !this.hwInvoicingContactVisible) {
        if (deliveryAddress) {
          this.sameAs(deliveryAddress, this.addressForm, null, this.customer?.address);
        } else {
          this.addressForm.sameAsObject = this.customer?.address;
          this.addressForm.setSameAs(true);
        }
      }
    }
  }

  checkOutFormGenerated() {
    if (this.hwInvoicingContactVisible) {
      this.fillHwInvoicingContactAndAddress();
    } else {
      this.fillContactAndAddress();
    }
  }
}
