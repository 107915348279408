<div class="modal-header">
  <h4 class="modal-title">
    {{ 'wc.ecare.quoteDetails.title' | translate }}
  </h4>
  <div class="modal-close" (click)="dialogReference.dismiss()">
    <i [inlineSVG]="'/assets/svg/close-icon.svg'"></i>
  </div>
</div>

<div class="modal-body" [formGroup]="form">
  <div class="generated-form">
    <div class="generated-input-container col4">
      <label>
        {{ 'wc.ecare.quoteDetails.identification' | translate }}
      </label>
      <div class="text">
        {{ quote.id }}
      </div>
    </div>

    <div class="generated-input-container col4">
      <label>
        {{ 'wc.ecare.quoteDetails.state' | translate }}
      </label>
      <div class="text quote-state -state-{{ getQuoteState(quote) }}">
        <i class="icon-state" [inlineSVG]="'/assets/svg/' + getQuoteState(quote) + '.svg'"></i>
        {{ 'wc.ecare.opportunities.quote.state.' + quote.quoteState | translate }}
      </div>
    </div>

    <div class="generated-input-container col4">
      <label>
        {{ 'wc.ecare.quoteDetails.version' | translate }}
      </label>
      <div class="text">
        {{ 'wc.ecare.quoteDetails.version' | translate }} {{ getQuoteAttributeValue('quoteVersion') }}
      </div>
    </div>

    <div class="generated-input-container col12">
      <label>
        {{ 'wc.ecare.quoteDetails.quoteName' | translate }}
      </label>
      <div class="text">
        <ng-container
          *ngIf="isQuoteStateIn([quoteStateDtoEnum.Pending, quoteStateDtoEnum.InProgress]); else nameReadOnly">
          <input formControlName="quoteName" />
        </ng-container>
        <ng-template #nameReadOnly>
          <div class="-attr-value read-only">
            {{ getQuoteAttributeValue('description') }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="generated-input-container col4">
      <label>
        {{ 'wc.ecare.quoteDetails.category' | translate }}
      </label>
      <div class="input full">
        <ng-container
          *ngIf="isQuoteStateIn([quoteStateDtoEnum.Pending, quoteStateDtoEnum.InProgress]); else categoryReadOnly">
          <app-form-select-input
            class="full-width"
            [showEmptyOption]="false"
            selectClass="ecare-dropdown"
            controlName="category"
            codebookType="QUOTE_CATEGORY"
            inputType="codebook"
            translationPrefix="wc.ecare.quoteDetails.category"
            [parentGroup]="form">
          </app-form-select-input>
        </ng-container>
        <ng-template #categoryReadOnly>
          <div class="-attr-value read-only">
            <span *ngIf="getQuoteAttributeValue('quoteCategory')">
              {{ 'wc.ecare.quoteDetails.category.' + getQuoteAttributeValue('quoteCategory') | translate }}
            </span>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="generated-input-container col4">
      <label>
        {{ 'wc.ecare.quoteDetails.validTo' | translate }}
      </label>

      <ng-container
        *ngIf="
          isQuoteStateIn([quoteStateDtoEnum.Pending, quoteStateDtoEnum.InProgress, quoteStateDtoEnum.Approved]);
          else validToReadOnly
        ">
        <div class="input">
          <app-date-picker
            class="full date-picker input date-generated-form"
            formControlName="validTo"
            style="display: flex"
            [showTime]="false"
            placeholder="{{ 'wc.ecare.quoteDetails.validTo.placeholder' | translate }}">
          </app-date-picker>
        </div>
      </ng-container>
      <ng-template #validToReadOnly>
        <div class="text">
          <span *ngIf="getQuoteValidTo()">{{ getQuoteValidTo() | date : 'dd.MM.yyyy HH:mm' }}</span>
        </div>
      </ng-template>
    </div>

    <div class="generated-input-container col4">
      <label>
        {{ 'wc.ecare.quoteDetails.created' | translate }}
      </label>
      <div class="text">
        {{ quote.created | date : 'dd.MM.yyyy HH:mm' }}
      </div>
    </div>

    <div class="generated-input-container col4">
      <label>
        {{ 'wc.ecare.quoteDetails.completed' | translate }}
      </label>
      <div class="text">
        <ng-container *ngIf="getQuoteCompletedDate(); else complitedEmpty">
          <span>{{ getQuoteCompletedDate() | date : 'dd.MM.yyyy HH:mm' }}</span>
        </ng-container>
        <ng-template #complitedEmpty>
          <span>-</span>
        </ng-template>
      </div>
    </div>

    <div class="generated-input-container col12">
      <label>
        {{ 'wc.ecare.quoteDetails.note' | translate }}
      </label>

      <ng-container
        *ngIf="isQuoteStateIn([quoteStateDtoEnum.Pending, quoteStateDtoEnum.InProgress]); else noteReadOnly">
        <div class="input">
          <textarea formControlName="note"></textarea>
        </div>
      </ng-container>
      <ng-template #noteReadOnly>
        <div class="text">
          {{ getQuoteAttributeValue('quoteNotes') ? getQuoteAttributeValue('quoteNotes') : '-' }}
        </div>
      </ng-template>
    </div>
  </div>
</div>
<div class="modal-footer">
  <app-button
    size="shrink"
    variant="secondary"
    label="{{ 'wc.common.cancel.button' | translate }}"
    (click)="dialogReference.dismiss()"></app-button>
  <app-button
    *ngIf="isQuoteStateIn([quoteStateDtoEnum.InProgress, quoteStateDtoEnum.Pending, quoteStateDtoEnum.Approved])"
    size="shrink"
    variant="primary"
    iconPath="/assets/svg/save.svg"
    label="{{ 'wc.ecare.quoteDetails.saveChanges' | translate }}"
    (click)="saveChanges()">
  </app-button>
</div>
