import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import _ from 'lodash';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { DmsFileDto, PagedDmsFilesDto } from '@btl/order-bff';
import { CustomerLocalStorageService } from '@service/customer-local-storage.service';
import {
  AbstractPageComponent,
  BlockTemplateComponent,
  CurrentLocaleService,
  DmsService,
  EnableDynamicLoading,
  Search,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { FilesAddModalComponent } from '../../files-add-modal/files-add-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AttachmentType, FormAttachmentsComponent } from '../../form-attachments/form-attachments.component';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';

@Component({
  selector: 'app-ecare-documents',
  templateUrl: './ecare-documents.component.html',
})
@EnableDynamicLoading({ customName: EcareDocumentsComponent.PAGE_ID })
export class EcareDocumentsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'EcareDocumentsComponent';

  pageId(): string {
    return EcareDocumentsComponent.PAGE_ID;
  }

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  @Input() entityType: string = null;
  @Input() entityId: string = null;
  @Input() simpleView: boolean = false;
  @Input() enableDownload: boolean = true;
  @Input() enableUpload: boolean = true;
  @Input() enableDelete: boolean = true;
  @Input() pageSize: number = 20;
  @Input() allowedNotifSending = false;
  @Input() defaultEmailList: string;
  @Input() notificationTypeCode: string;
  @Input() notificationContextMap: {
    [key: string]: any;
  };

  @Output() filesLength = new EventEmitter<number>();

  files: DmsFileDto[] = [];
  pagedDto: PagedDmsFilesDto;
  canDisplayNoDocumentsText = false;
  disableUploadByProperty = false;
  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 20,
    },
  };

  selectedAttachments = [];

  selectForm: FormGroup = this.formBuilder.group({
    selected: new FormArray([]),
  });

  get selectedFormArray() {
    return this.selectForm.get('selected') as FormArray;
  }

  constructor(
    private renderer: Renderer2,
    private readonly dmsService: DmsService,
    public readonly customerLocalStorageService: CustomerLocalStorageService,
    private ngbModal: NgbModal,
    private stickyMessageService: StickyMessageService,
    private formBuilder: FormBuilder,
    private ngModal: NgbModal,
    private httpClient: HttpClient,
    private currentLocaleService: CurrentLocaleService,
    protected router: Router,
    protected route: ActivatedRoute,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
  ) {
    super(router, route);
    this.renderer.addClass(document.body, 'ecare');
    this.propertyAccessorLocalService
      .isModuleBffCrmEnabled()
      .subscribe(bffCrmEnabled => (this.disableUploadByProperty = !bffCrmEnabled));
  }

  ngOnInit(): void {
    if (!this.entityType) {
      if (this.customerLocalStorageService.getCurrentCustomerAccount()) {
        this.entityType = 'com.emeldi.ecc.be.crm.dto.CustomerAccount';
        this.entityId = this.customerLocalStorageService.getCurrentCustomerAccount().id;
      } else if (this.customerLocalStorageService.getCurrentCustomer()) {
        this.entityType = 'com.emeldi.ecc.be.crm.dto.Customer';
        this.entityId = this.customerLocalStorageService.getCurrentCustomer().id;
      }
    }
    this.search.paging.pageSize = this.pageSize;
    this.search.filtering.push({
      column: 'references',
      compareType: null,
      value: [
        {
          entityType: this.entityType,
          entityId: this.entityId,
        },
      ],
    });
    if (this.simpleView) {
      this.search.paging.pageSize = -1;
      this.loadDocuments();
    }
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadDocuments();
  }

  private loadDocuments() {
    this.blockUIElement.start();
    this.dmsService
      .filterFiles(this.search, null, false)
      .pipe(
        finalize(() => {
          this.canDisplayNoDocumentsText = _.isEmpty(this.files);
          this.blockUIElement.stop();
        })
      )
      .subscribe(result => {
        this.pagedDto = result;
        this.files = result.data;
        this.filesLength.emit(this.files.length);
        this.selectedFormArray.clear();
        this.files.forEach(item => this.selectedFormArray.push(new FormControl()));
      });
  }

  onPrevPage() {
    this.search.paging.page--;
    this.loadDocuments();
  }

  onNextPage() {
    this.search.paging.page++;
    this.loadDocuments();
  }

  onPageSizeChanged(pageSize: number) {
    this.search.paging.page = 1;
    this.search.paging.pageSize = pageSize;
    this.loadDocuments();
  }

  onSpecificPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.loadDocuments();
  }

  add() {
    const modalRef = this.ngbModal.open(FilesAddModalComponent);
    const pictureAddModalComponent = <FilesAddModalComponent>modalRef.componentInstance;
    pictureAddModalComponent.dialogRef = modalRef;
    pictureAddModalComponent.uploadStep = false;
    pictureAddModalComponent.multiple = true;
    pictureAddModalComponent.uploadedHandler = (newFile: DmsFileDto) => {
      newFile.type = 'CRM_DOCUMENT';
      newFile.contentHref = null;
      newFile.size = null;
      newFile.extId = `CRM_DOCUMENT${ServiceUtils.getRandomId()}`;
      newFile.params = {
        language: this.currentLocaleService.getCurrentLanguage(),
      };
      newFile.references = [
        {
          entityType: this.entityType,
          entityId: this.entityId,
          refType: 'GENERAL',
        },
      ];

      this.dmsService.createFile(newFile).subscribe(file => {
        modalRef.close();
        this.loadDocuments();
        this.stickyMessageService.addStickySuccessMessage('wc.ecare.documents.documentUploaded');
      });
    };
  }

  deleteFile(id: string) {
    const dialogReference = this.ngModal.open(ConfirmationDialogComponent, {
      windowClass: 'dialog dialog-confirmation',
    });

    const confirmationDialogComponent = <ConfirmationDialogComponent>dialogReference.componentInstance;
    confirmationDialogComponent.heading = 'wc.ecare.document.delete';
    confirmationDialogComponent.texts.push('wc.ecare.document.delete.confirm');
    confirmationDialogComponent.dialogReference = dialogReference;
    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      dialogReference.dismiss();

      this.dmsService.deleteFile(id).subscribe(result => {
        this.stickyMessageService.addStickySuccessMessage('wc.ecare.documents.documentDeleted');
        this.loadDocuments();
      });
    };
  }

  getSelectedFiles() {
    const selected = [];
    for (let i = 0; i < this.selectedFormArray.length; i++) {
      if (this.selectedFormArray.getRawValue()[i]) {
        selected.push(this.files[i]);
      }
    }
    return selected;
  }

  deleteFiles() {
    const dialogReference = this.ngModal.open(ConfirmationDialogComponent, {
      windowClass: 'dialog dialog-confirmation',
    });

    const confirmationDialogComponent = <ConfirmationDialogComponent>dialogReference.componentInstance;
    confirmationDialogComponent.heading = 'wc.ecare.documents.delete';
    confirmationDialogComponent.texts.push('wc.ecare.documents.delete.confirm');
    confirmationDialogComponent.dialogReference = dialogReference;
    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      dialogReference.dismiss();
      const calls = [];
      this.getSelectedFiles().forEach(file => {
        calls.push(this.dmsService.deleteFile(file.id));
      });
      forkJoin(calls).subscribe(result => {
        this.stickyMessageService.addStickySuccessMessage('wc.ecare.documents.documentsDeleted');
        this.loadDocuments();
      });
    };
  }

  getTypeIcon(file) {
    return file.name?.split('.')[1];
  }

  formatFileName(fileName: string) {
    return FormAttachmentsComponent.formatFileName(fileName);
  }

  shouldShortenFileName(fileName: string) {
    return FormAttachmentsComponent.shouldShortenFileName(fileName);
  }

  download(file) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    this.httpClient.get(file.contentHref, httpOptions).subscribe((data: any) => {
      const blob = new Blob([data]);

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = file.name;
      link.click();
    });
  }

  selectUnselectAttachment(value: any) {
    if (this.selectedAttachments.find(attach => attach === value)) {
      this.selectedAttachments = this.selectedAttachments.filter(attach => attach !== value);
    } else {
      this.selectedAttachments.push(value);
    }
  }

  sendNotification() {
    this.router.navigate(['/ecare/notifications/newNotification'], {
      queryParams: {
        returnUrl: this.router.url,
        defaultEmailList: this.defaultEmailList,
        notificationTypeCode: this.notificationTypeCode,
        contextMap: JSON.stringify(this.notificationContextMap),
        filesExtIds: this.selectedAttachments.map(attachment => attachment.extId),
      },
    });
  }

  protected readonly AttachmentType = AttachmentType;
}
