<ng-container
  *ngIf="socket && socket.contentQty.max > socketChildren.length && socketChildrenProductOfferings && socketChildrenProductOfferings.length > 0"
  [formGroup]="addProductForm">
  <div class="generated-form padding-bottom-10">
    <app-input-select-form-field
      valueOption="value"
      labelOption="label"
      formControlName="product"
      [noLabel]="true"
      [options]="socketChildrenProductOfferings"
      [doNotShowInternalErrors]="true"
      [showEmptyOption]="false" />

    <app-button
      label="{{ 'wc.shopping.cart.addToService.text' | translate }}"
      (click)="addProduct()" />
  </div>
</ng-container>
