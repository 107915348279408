import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AccountContactsService, BlockTemplateComponent, FormUtils } from '@btl/btl-fe-wc-common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs/internal/Subject';
import { FormBuilder } from '@angular/forms';
import { AddressFormComponent } from '../../../../page/address-form/address-form.component';
import { AddressDto } from '@btl/order-bff';

@Component({
  selector: 'app-address-edit-popup',
  templateUrl: './address-edit-popup.component.html',
})
export class AddressEditPopupComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @ViewChild('addressForm', { static: true }) addressForm: AddressFormComponent;

  @BlockUI('blockUIElement') blockUIElement: NgBlockUI;
  blockTemplate = BlockTemplateComponent;

  @Input() dialogRef;
  @Input() handler = (address: AddressDto) => {};
  @Input() accountId;
  @Input() address: AddressDto;
  @Input() addressType;
  @Input() preferredAddressWithoutNone = false;

  form = this.formBuilder.group({
    id: [null],
    type: [null],
  });

  constructor(private formBuilder: FormBuilder, private accountContactsService: AccountContactsService) {}

  ngOnInit(): void {
    if (!this.addressType && this.address?.type) {
      this.addressType = this.address.type;
    }
    this.addressForm.generateForm(this.address);
  }

  closePopup() {
    this.dialogRef.dismiss();
  }

  save() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      const address = this.form.getRawValue();
      if (address.id) {
        address.id = null;
        this.handler(address);
      } else {
        this.handler(address);
      }
    }
  }

  addressFormGenerated() {
    if (!this.address) {
      this.form.get('type').patchValue(this.addressType);
    }
  }
}
