<app-ecare-page-layout
  *ngIf="notification"
  columnTemplate="1fr"
  showBackButton="true"
  title="{{ 'wc.ecare.notifications.newHeadline' | translate }}">
  <ng-container page-actions>
    <app-button variant="secondary" label="{{ 'wc.common.cancel.button' | translate }}" (click)="cancel()"></app-button>
    <app-button
      label="{{
        (form.get('toBeSent')?.value ? 'wc.ecare.notifications.scheduleSend' : 'wc.ecare.notifications.send')
          | translate
      }}"
      [iconPath]="
        '/assets/img/notifications/' + (form.get('toBeSent')?.value ? 'schedule_send' : 'send_notification') + '.svg'
      "
      (click)="send()"></app-button>
  </ng-container>
  <div>
    <div *blockUI="blockUIElement.name; template: blockTemplate" class="ecare-detail-page" [formGroup]="form">
      <div class="left-panel">
        <div class="section-wrapper">
          <div class="selects">
            <div class="select">
              <label>{{ 'wc.ecare.notifications.notificationType' | translate }}</label>
              <div class="extended-input select">
                <app-input-select-form-field
                  id="001_notificationType"
                  formControlName="notificationType"
                  translationPrefix="wc.ecare.notifications.notificationTypes"
                  labelOption="notificationType"
                  valueOption="notificationType"
                  [doNotShowInternalErrors]="true"
                  [options]="notificationTypes"
                  [noLabel]="true"
                  (selectionChangeEmit)="notificationTypeChanged($event)" />
              </div>
              <app-input-errors
              [control]="form.controls['notificationType']"
              [translationPrefix]="'wc.ecare.notifications.notificationType'">
            </app-input-errors>
            </div>

            <div class="select" [hidden]="!gatewayTypes || gatewayTypes.length === 0">
              <label>{{ 'wc.ecare.notifications.gateway' | translate }}</label>
              <div class="extended-input select">
                <app-input-select-form-field
                  id="001_gateway"
                  formControlName="gateway"
                  translationPrefix="wc.ecare.notifications.gateways"
                  labelOption="gateway"
                  valueOption="gateway"
                  [options]="gatewayTypes"
                  [noLabel]="true"
                  (selectionChangeEmit)="gatewayTypeChanged($event)" />
              </div>
            </div>

            <div class="select">
              <label>{{ 'wc.ecare.notifications.scheduleDate' | translate }}</label>
              <app-date-picker
                class="date-picker"
                formControlName="toBeSent"
                [showTime]="true"
                placeholder="{{ 'wc.ecare.notifications.toBeSent.placeholder' | translate }}">
              </app-date-picker>
            </div>
          </div>
        </div>
        <div class="section-wrapper">
          <app-input-row fieldName="subject" translationPrefix="wc.ecare.notifications" [parentGroup]="form" [mandatory]="true">
          </app-input-row>
        </div>
        <div class="section-wrapper">
          <app-form-recipients
            formControlName="recipients"
            [control]="form.get('recipients')"
            [types]="['TO', 'CC', 'BCC']">
          </app-form-recipients>
        </div>

        <div class="section-wrapper">
          <div class="form-group-row">
            <label>{{ 'wc.ecare.notifications.body' | translate }}</label><label class="mandatory">*</label>
            <angular-editor formControlName="body" [config]="editorConfig"> </angular-editor>
            <app-input-errors translationPrefix="wc.ecare.notifications.body" [control]="form.controls['body']">
            </app-input-errors>
          </div>
        </div>
        <div *ngIf="form.get('bodyAlternative').value" class="section-wrapper">
          <div class="form-group-row">
            <label>{{ 'wc.ecare.notifications.bodyAlternative' | translate }}</label>
            <textarea formControlName="bodyAlternative" class="border" style="width: 100%" rows="4"> </textarea>
            <app-input-errors
              translationPrefix="wc.ecare.notifications.bodyAlternative"
              [control]="form.controls['bodyAlternative']">
            </app-input-errors>
          </div>
        </div>
        <app-ecare-section sectionTitle="{{ 'wc.ecare.ticket.attachments' | translate }}">
          <div class="section-wrapper">
            <div>
              <div *ngIf="!getAttachmentsFormArray()?.value?.length" [ngTemplateOutlet]="noDataMessage"></div>
              <app-form-attachments
                extIdPrefix="NOTIF_"
                dmsType="GENERAL"
                formControlName="attachments"
                [control]="form.get('attachments')"
                [types]="['GENERAL']"
                [attachmentType]="attachmentType">
              </app-form-attachments>
            </div>
          </div>
        </app-ecare-section>
        <div class="section-wrapper">
          <app-input-row fieldName="note" translationPrefix="wc.ecare.notifications" [parentGroup]="form">
          </app-input-row>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noDataMessage>
    <div class="no-data">{{ 'wc.ecare.ticket.noData' | translate }}</div>
  </ng-template>
</app-ecare-page-layout>
