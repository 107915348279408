import { Component, Input, OnInit } from '@angular/core';
import { EcareContactHistoryComponent } from './ecare-contact-history.component';
import { CodebookService } from '@btl/btl-fe-wc-common';
import { TicketTypeParamDto } from '@btl/order-bff';
import { ContactParamNames } from './contact-history-details/contact-history-details.component';

@Component({
  selector: 'app-ecare-contact-history-mobile, [app-ecare-contact-history-mobile]',
  templateUrl: './ecare-contact-history-mobile.component.html',
})
export class EcareContactHistoryMobileComponent implements OnInit {

  @Input() contactHistory;
  @Input() visibleParameters = new Map<string, TicketTypeParamDto>();

  contactParamNames = ContactParamNames;

  constructor(
    public ecareContactHistoryComponent: EcareContactHistoryComponent,
    public codebookService: CodebookService
  ) {}

  ngOnInit(): void {}
}
