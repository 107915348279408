import { ProductInShoppingCart } from './product-in-shopping-cart';
import { OrderDto, OrderItemDto, ProductDetailDto, ProductOfferingDto, SocketService } from '@btl/order-bff';
import { CategoryTypeEnum } from './product-filter';
import { ProductService } from '@btl/btl-fe-wc-common';
import _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { ProductUtils } from '../helpers/product-utils';
import { AssetReplacement } from './asset-replacement';
import { OrderUtils } from '../helpers/order-utils';
import { KeyValue } from '@angular/common';

/**
 * PrecalculatedShoppingCart represents a view on shopping cart having some precalculated values (products in the shopping cart, total
 * prices, etc.).
 */
export class PrecalculatedShoppingCart {
  /**
   * A list of categories that are not grouped by quantity. That means, if an order item is for a product of one of these categories, it is
   * displayed as a single row in the shopping cart (regardless how many product of the same category the shopping cart contains). If an
   * order item is for a product without its category in this list, shopping cart displays only one row for all order items  of the same
   * category.
   */
  static categoriesNotGroupedByQuantity: CategoryTypeEnum[] = [
    CategoryTypeEnum.PRODC_SU_TARIFF,
    CategoryTypeEnum.PRODC_GE_DELIVERY,
    CategoryTypeEnum.PRODC_GE_PAYMENT,
  ];

  static canDisplayProductInShoppingCart(productInShoppingCart: ProductInShoppingCart): boolean {
    return !productInShoppingCart.parent || productInShoppingCart.productDetail.parametersStatic['showInShoppingCart'] === 'true';
  }
  //region Data:

  /**
   * The products in the shopping cart as a map. List of products is mapped to the product ID.
   */
  products: PrecalculatedShoppingCartProducts = new Map<string, ProductInShoppingCart[]>();

  promotionConfiguration = new Map<string, ProductOfferingDto[]>();

  /**
   * The total number of order items in the shopping cart - regardless how the items are structured.
   */
  quantityOfItemsInShoppingCart = 0;

  /**
   * The total OC price of order items in the shopping cart.
   */
  totalOcPrice = 0;

  /**
   * The total RC price of order items in the shopping cart.
   */
  totalRcPrice = 0;

  /**
   * The total tax for OC prices of order items in the shopping cart.
   */
  totalOcPriceTax = 0;

  /**
   * Total tax for RC prices of order items in the shopping cart.
   */
  totalRcPriceTax = 0;

  discountProducts: Array<ProductInShoppingCart> = [];
  deliveryProduct: ProductInShoppingCart;
  paymentProduct: ProductInShoppingCart;

  constructor(public order: OrderDto, private productService: ProductService, private socketService: SocketService) {}

  /**
   * Return orderItem of product identified by the given ID if product exists in the precalculated shopping cart.
   *
   * @param productId The ID of the product to check existence of.
   */
  public getOrderItemByProductId(productId: String, notUsed?): OrderItemDto {
    let orderItem = undefined;
    if (!this.order) {
      return orderItem;
    }

    this.products.forEach((productGroup: ProductInShoppingCart[]) => {
      productGroup.forEach((product: ProductInShoppingCart) => {
        if (product.productDetail && product.productDetail.id === productId) {
          if (product.orderItems.length > 0) {
            if (notUsed) {
              orderItem = product.orderItems.find(
                orderItem =>
                  !product.children.find(child =>
                    child.orderItems.find(childOrderItem => childOrderItem.parentOrderItemId === orderItem.id)
                  )
              );
            } else {
              orderItem = product.orderItems[0];
            }
          }
        }
      });
    });
    return orderItem;
  }

  /**
   * Return orderItem identified by the given orderItemId if exists in the precalculated shopping cart.
   * @param orderItemId
   */
  public getOrderItemById(orderItemId: String): OrderItemDto {
    let orderItem = undefined;
    if (!this.order) {
      return orderItem;
    }

    this.products.forEach((productGroup: ProductInShoppingCart[]) => {
      productGroup.forEach((product: ProductInShoppingCart) => {
        product.orderItems.forEach(productOrderItem => {
          if (productOrderItem.id === orderItemId) {
            orderItem = productOrderItem;
          }
        });
      });
    });
    return orderItem;
  }

  /**
   * Return productInShoppingCart of tariff with categoryId.
   * @param tariffProductId
   * @param categoryId
   */
  public getTariffProductInCartByCategoryId(
    tariffProductId: string,
    categoryId: CategoryTypeEnum
  ): ProductInShoppingCart | undefined {
    const tariffProductsInShoppingCart = this.products.get(tariffProductId);
    return _.isNil(tariffProductsInShoppingCart)
      ? undefined
      : this.getProductInCartByCategoryId(tariffProductsInShoppingCart, categoryId);
  }

  /**
   * Return productInShoppingCart from array of productsInShoppingCart and their children's  by categoryId
   * @param productsInShoppingCart
   * @param categoryId
   */
  public getProductInCartByCategoryId(
    productsInShoppingCart: ProductInShoppingCart[],
    categoryId: CategoryTypeEnum
  ): ProductInShoppingCart {
    let retProductInShoppingCart = undefined;

    productsInShoppingCart.forEach(loopProductInShoppingCart => {
      let productInShoppingCart;
      if (loopProductInShoppingCart.productDetail.categoryId === categoryId) {
        productInShoppingCart = loopProductInShoppingCart;
      } else {
        productInShoppingCart = this.getProductInCartByCategoryId(loopProductInShoppingCart.children, categoryId);
      }
      if (productInShoppingCart) {
        retProductInShoppingCart = productInShoppingCart;
      }
    });
    return retProductInShoppingCart;
  }

  public getProductDetailByCategory(category: CategoryTypeEnum): ProductDetailDto {
    let productDetail;
    const productsInCart: ProductInShoppingCart[] = [].concat(...this.products.values());
    productsInCart.forEach(product => {
      if (ProductUtils.isOfCategory(product.productDetail, category)) {
        productDetail = product.productDetail;
      }
    });
    return productDetail;
  }

  public getMainOderCategoryId(): ProductInShoppingCart {
    let mainOderCategoryId;
    this.products.forEach((productInShoppingCartGroup: ProductInShoppingCart[]) => {
      for (const productInShoppingCart of productInShoppingCartGroup) {
        const productCategoryId = productInShoppingCart.productDetail.categoryId;
        if (
          productCategoryId === CategoryTypeEnum.PRODC_SU_TARIFF ||
          productCategoryId === CategoryTypeEnum.PRODC_CA_VAS_SS
        ) {
          mainOderCategoryId = productCategoryId;
        }
      }
    });

    return mainOderCategoryId;
  }

  /**
   * Check if at least one product has set static attribute "upfrontPayment" with value "true".
   */
  public checkUpfrontPayment(): boolean {
    if (!this.products) {
      return false;
    }

    let upfrontPayment = false;
    this.products.forEach((productInShoppingCartGroup: ProductInShoppingCart[]) => {
      for (const productInShoppingCart of productInShoppingCartGroup) {
        if (!upfrontPayment) {
          if (this.checkProductUpfrontPayment(productInShoppingCart)) {
            upfrontPayment = true;
          }
        }
      }
    });
    return upfrontPayment;
  }

  /**
   * Check if at least one orderItem has attribute "servicePartners" with value containing "shipment".
   */
  public checkShipment(): boolean {
    if (!this.products) {
      return false;
    }
    let shipment = false;
    this.products.forEach((productGroup: ProductInShoppingCart[]) => {
      productGroup.forEach((productInShoppingCart: ProductInShoppingCart) => {
        if (!shipment) {
          shipment = PrecalculatedShoppingCart.isProductWithShipment(productInShoppingCart);
        }
      });
    });
    return shipment;
  }

  public static isProductWithShipment(productInShoppingCart: ProductInShoppingCart) {
    let shipment = false;
    productInShoppingCart.orderItems.forEach(productOrderItem => {
      const servicePartners = productOrderItem.attributes.find(attr => attr.name === 'servicePartners');
      if (productOrderItem.action === 'ADD' && servicePartners && servicePartners.value?.includes('shipment')) {
        shipment = true;
      }
    });
    return shipment;
  }

  public static isOrderItemWithShipment(orderItem: OrderItemDto) {
    let shipment = false;
    const servicePartners = orderItem.attributes?.find(attr => attr.name === 'servicePartners');
    if (orderItem.action === 'ADD' && servicePartners && servicePartners.value?.includes('shipment')) {
      shipment = true;
    }
    return shipment;
  }

  public containsService() {
    if (!this.products) {
      return false;
    }

    let containsService = false;
    this.products.forEach((productInShoppingCartGroup: ProductInShoppingCart[]) => {
      for (const productInShoppingCart of productInShoppingCartGroup) {
        if (!containsService) {
          if (ProductUtils.isTariff(productInShoppingCart.productDetail)) {
            containsService = true;
          }
        }
      }
    });
    return containsService;
  }

  public isDeliveryNeeded(): boolean {
    let displayDelivery = false;
    this.products.forEach((productInShoppingCartGroup: ProductInShoppingCart[]) => {
      for (const productInShoppingCart of productInShoppingCartGroup) {
        if (!displayDelivery) {
          if (this.checkShipment()) {
            displayDelivery = true;
          }
        }
      }
    });
    return displayDelivery;
  }

  public isVerifiedCustomerRequired(): boolean {
    if (!this.products) {
      return false;
    }

    let verifiedCustomerRequired = false;
    this.products.forEach((productInShoppingCartGroup: ProductInShoppingCart[]) => {
      for (const productInShoppingCart of productInShoppingCartGroup) {
        if (!verifiedCustomerRequired) {
          if (this.checkVerifiedCustomerRequired(productInShoppingCart)) {
            verifiedCustomerRequired = true;
          }
        }
      }
    });
    return verifiedCustomerRequired;
  }

  public isDeliveryOrUpFrontPaymentNeeded(): boolean {
    if (this.isDeliveryNeeded() || this.checkUpfrontPayment()) {
      return true;
    }
    return false;
  }

  // region Helpers:

  allRootOrderItemsAdding(): boolean {
    let allAdd = true;
    this.order?.orderItems.forEach(orderItem => {
      if (!orderItem.parentOrderItemId && orderItem.action !== 'ADD') {
        allAdd = false;
      }
    });

    return allAdd;
  }

  /**
   * Load products from the order.
   */
  loadProducts(): Observable<ProductDetailDto[]> {
    if (_.isEmpty(this.order) || _.isEmpty(this.order.orderItems)) {
      return of([]);
    }

    return new Observable<ProductDetailDto[]>(observer => {
      this.getProductDetailDtos(_.map(this.order.orderItems, orderItem => orderItem.productId)).subscribe(
        productDetailDtos => {
          this.loadProductsFromProductDetailDtos(productDetailDtos, this.order.orderItems);
          this.calculateTotals();
          const promotionProductsSet = new Set<string>();
          Array.from(this.products.entries())
            .filter(([key, value]) => value && value.length > 0 && value.find(productInShoppingCart => productInShoppingCart.children.length > 0))
            .map(([key, value]) => value[0].productDetail.productCode)
            .forEach(productCode => promotionProductsSet.add(productCode));
          const promotionProducts = Array.from(promotionProductsSet);
          if (promotionProducts.length > 0) {
            this.getProductSockets(null, promotionProducts).subscribe(result => {
              for (let i = 0; i < result.length; i++) {
                this.promotionConfiguration.set(promotionProducts[i], result[i]);
              }
              Array.from(this.products.values()).forEach((productsInShoppingCart: ProductInShoppingCart[]) => {
                const productInShoppingCart = productsInShoppingCart[0];
                const promotionSockets = this.promotionConfiguration.get(productInShoppingCart.productDetail.productCode);
                if (promotionSockets) {
                  this.setChildSockets(productInShoppingCart, promotionSockets);
                }
              });
              //console.log(this.promotionConfiguration);
              observer.next(productDetailDtos);
            });
          } else {
            observer.next(productDetailDtos);
          }
        }
      );
    });
  }

  setChildSockets(productInShoppingCart: ProductInShoppingCart, promotionSockets: ProductOfferingDto[]) {
    productInShoppingCart.children.forEach(child => {
      child.socket = promotionSockets.find(productOffering => {
        return (
          productOffering.product.productCode === child.productDetail.productCode &&
          productOffering.parentProductCode === child.parent.productDetail.productCode &&
          ((!productInShoppingCart.socket && !productOffering.socket.parentCode) ||
            productInShoppingCart.socket?.code === productOffering.socket?.parentCode)
        );
      })?.socket;
      this.setChildSockets(child, promotionSockets);
    });
  }

  private getProductSockets(orderId: string, productCodes: string[]): Observable<ProductOfferingDto[][]> {
    return forkJoin(
      _.map(productCodes, productCode =>
        this.socketService.getProductChildren(productCode, null, null, null, productCode, false, orderId, null)
      )
    );
  }

  private loadProductsFromProductDetailDtos(productDetailDtos: ProductDetailDto[], orderItems: OrderItemDto[]): void {
    const orderItemsWithoutParent = _.filter(orderItems, orderItemDto => _.isNil(orderItemDto.parentOrderItemId));
    const replacedTariffs: AssetReplacement[] = [];

    for (const orderItem of orderItemsWithoutParent) {
      const productDetail = _.find(productDetailDtos, productDetailDto => productDetailDto.id === orderItem.productId);

      if (
        !(ProductUtils.isOfCategory(productDetail, CategoryTypeEnum.PRODC_SU_TARIFF) && orderItem.action === 'DELETE')
      ) {
        this.addProductToShoppingCart(orderItem, orderItems, productDetail, productDetailDtos);
      } else {
        if (this.checkTariffChange(orderItem, orderItems, productDetail, productDetailDtos)) {
          const removedTariff: AssetReplacement = {
            orderItem: orderItem,
            productDetail: productDetail,
          };
          replacedTariffs.push(removedTariff);
        } else {
          this.addProductToShoppingCart(orderItem, orderItems, productDetail, productDetailDtos);
        }
      }
    }

    if (replacedTariffs.length) {
      this.products.forEach((productInShoppingCartGroup: ProductInShoppingCart[]) => {
        productInShoppingCartGroup.forEach((productInShoppingCart: ProductInShoppingCart) => {
          if (ProductUtils.isOfCategory(productInShoppingCart.productDetail, CategoryTypeEnum.PRODC_SU_TARIFF)) {
            const replacedTariff = _.find(
              replacedTariffs,
              replacedTariff => replacedTariff.orderItem.partyRefNo === productInShoppingCart.orderItems[0].partyRefNo
            );
            productInShoppingCart.replacementFor = replacedTariff;
            // TODO: The following code adds order items of the removed tariff to the new one.
            //this.addChildProductsToProductInShoppingCart(replacedTariff.orderItem, productInShoppingCart, orderItems, productDetailDtos);
          }
        });
      });
    }
  }

  /**
   * Check if the given tariff is part of tariff change.
   *
   * @param tariff1OrderItem The tariff's order item.
   * @param orderItems All order items from the order.
   * @param tariff1ProductDetail The tariff's product detail.
   * @param productDetails The product details for the order items.
   */
  private checkTariffChange(
    tariff1OrderItem: OrderItemDto,
    orderItems: OrderItemDto[],
    tariff1ProductDetail: ProductDetailDto,
    productDetails: ProductDetailDto[]
  ): boolean {
    const tariff1PartyId = tariff1OrderItem.partyRefNo;
    const tariff1Action = tariff1OrderItem.action;

    if (tariff1Action !== 'ADD' && tariff1Action !== 'DELETE') {
      return undefined;
    }

    let tariff2Action;
    if (tariff1OrderItem.action === 'ADD') {
      tariff2Action = 'DELETE';
    } else {
      tariff2Action = 'ADD';
    }

    let tariff2ProductDetail;
    const tariff2OrderItem = _.find(orderItems, orderItem => {
      if (orderItem.partyRefNo === tariff1PartyId && orderItem.action === tariff2Action) {
        tariff2ProductDetail = _.find(productDetails, productDetailDto => productDetailDto.id === orderItem.productId);
        if (tariff2ProductDetail.categoryId === tariff1ProductDetail.categoryId) {
          return true;
        }
      }

      return false;
    });

    if (tariff2OrderItem) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Add the given order item and its children to the shopping cart.
   *
   * @param orderItem The order item to add.
   * @param orderItems All order items from the order.
   * @param productDetail The product detail of the order item to add.
   * @param productDetails The product details for the order items.
   */
  private addProductToShoppingCart(
    orderItem: OrderItemDto,
    orderItems: OrderItemDto[],
    productDetail: ProductDetailDto,
    productDetails: ProductDetailDto[]
  ): void {
    this.addRootProductToShoppingCart(orderItem, productDetail);

    const parentProductInShoppingCartGroup: ProductInShoppingCart[] = this.products.get(
      _.find(orderItems, orderItemDto => orderItemDto.id === orderItem.id).productId
    );
    const parentProductInShoppingCart = _.find(parentProductInShoppingCartGroup, productInShoppingCart =>
      productInShoppingCart.orderItems.includes(orderItem)
    );
    this.addChildProductsToProductInShoppingCart(
      orderItem,
      parentProductInShoppingCart,
      orderItems,
      productDetails,
      productDetail.productCode
    );
  }

  trackEntryByKey(index: number, mapEntry: any): any {
    return mapEntry ? mapEntry.key : null;
  }

  private addRootProductToShoppingCart(orderItem: OrderItemDto, productDetail: ProductDetailDto): void {
    const productId = orderItem.productId;

    if (!this.products.has(productId)) {
      this.products.set(productId, []);
    }
    const productsInShoppingCart: ProductInShoppingCart[] = this.products.get(productId);

    if (
      productsInShoppingCart.length < 1 ||
      PrecalculatedShoppingCart.categoriesNotGroupedByQuantity.includes(CategoryTypeEnum[productDetail.categoryId])
    ) {
      const newProductInShoppingCart = new ProductInShoppingCart(productDetail);
      newProductInShoppingCart.addOrderItem(orderItem);
      productsInShoppingCart.push(newProductInShoppingCart);
    } else {
      const existingProductInShoppingCart = productsInShoppingCart[0];
      existingProductInShoppingCart.addOrderItem(orderItem);
    }
  }

  private getProductDetailDtos(productIds: string[]): Observable<ProductDetailDto[]> {
    return forkJoin(_.map(productIds, productId => this.productService.getProductById(productId)));
  }

  getPriceFromOrder(priceType: string): number {
    return parseFloat(OrderUtils.getOrderAttributeValue(this.order, priceType));
  }

  /**
   * Calculate totals for the precalculated shopping cart.
   */
  private calculateTotals(): void {
    this.products.forEach((productGroup: ProductInShoppingCart[]) => {
      productGroup.forEach((productInShoppingCart: ProductInShoppingCart) => {
        this.calculateQuantityOfItemsInShoppingCart(productInShoppingCart);
        this.discountProducts.push(this.checkIfDiscount(productInShoppingCart));
        if (!this.deliveryProduct) {
          this.deliveryProduct = this.getIfDelivery(productInShoppingCart);
        }
        if (!this.paymentProduct) {
          this.paymentProduct = this.getIfPayment(productInShoppingCart);
        }
      });
    });
    this.totalOcPrice = this.getPriceFromOrder('totalPriceOC');
    this.totalOcPriceTax = this.getPriceFromOrder('totalPriceWithoutTaxOC');
    this.totalRcPrice = this.getPriceFromOrder('totalPriceRC');
    this.totalRcPriceTax = this.getPriceFromOrder('totalPriceWithoutTaxRC');
  }

  private calculateQuantityOfItemsInShoppingCart(productInShoppingCart: ProductInShoppingCart) {
    if (PrecalculatedShoppingCart.canDisplayProductInShoppingCart(productInShoppingCart)) {
      this.quantityOfItemsInShoppingCart += productInShoppingCart.visibleOrderItemsQuantity;
    }
  }

  /**
   * Check if the given product or any of its children have set static attribute "upfrontPayment" with value "true".
   *
   * @param productInShoppingCart The product to check.
   */
  private checkProductUpfrontPayment(productInShoppingCart: ProductInShoppingCart): boolean {
    if (productInShoppingCart.productDetail.parametersStatic['upFrontPayment'] === 'true') {
      return true;
    }

    if (productInShoppingCart.children) {
      for (const productInShoppingCartChild of productInShoppingCart.children) {
        if (this.checkProductUpfrontPayment(productInShoppingCartChild)) {
          return true;
        }
      }
    }
    return false;
  }

  private checkIfDiscount(product) {
    if (product.productDetail?.parametersStatic['isDiscount'] === 'true') {
      return product;
    }

    if (product.children) {
      for (const productInShoppingCartChild of product.children) {
        if (this.checkIfDiscount(productInShoppingCartChild)) {
          return productInShoppingCartChild;
        }
      }
    }
    return false;
  }

  private getIfDelivery(productInShoppingCart) {
    if (ProductUtils.isOfCategory(productInShoppingCart.productDetail, CategoryTypeEnum.PRODC_GE_DELIVERY)) {
      return productInShoppingCart;
    }

    return false;
  }

  private getIfPayment(productInShoppingCart) {
    if (ProductUtils.isOfCategory(productInShoppingCart.productDetail, CategoryTypeEnum.PRODC_GE_PAYMENT)) {
      return productInShoppingCart;
    }

    return false;
  }

  public getHwProductCodes() {
    const productCodes: Array<string> = [];
    if (!this.products) {
      return productCodes;
    }

    this.products.forEach((productInShoppingCartGroup: ProductInShoppingCart[]) => {
      for (const productInShoppingCart of productInShoppingCartGroup) {
        if (PrecalculatedShoppingCart.isProductWithShipment(productInShoppingCart)) {
          productCodes.push(productInShoppingCart.productDetail.productCode);
        }
      }
    });
    return productCodes;
  }

  private checkVerifiedCustomerRequired(productInShoppingCart: ProductInShoppingCart): boolean {
    if (productInShoppingCart.productDetail.parametersStatic['verifiedCustomerRequired'] === 'true') {
      return true;
    }

    return false;
  }

  trackProductById(index: number, product: ProductInShoppingCart): any {
    return product ? product.productDetail.id : null;
  }

  mainProductsIsHwAscOrder = (
    a: KeyValue<string, ProductInShoppingCart[]>,
    b: KeyValue<string, ProductInShoppingCart[]>
  ): number => {
    const aProductInShoppingCart = a.value.find(
      productInShoppingCart => productInShoppingCart.productDetail.id === a.key
    );
    const bProductInShoppingCart = b.value.find(
      productInShoppingCart => productInShoppingCart.productDetail.id === b.key
    );
    return (
      this.compareByIsHw(aProductInShoppingCart, bProductInShoppingCart) ||
      aProductInShoppingCart.getPriorityWithName().localeCompare(bProductInShoppingCart.getPriorityWithName())
    );
  };

  private compareByIsHw(a: ProductInShoppingCart, b: ProductInShoppingCart) {
    const isAProductHw = PrecalculatedShoppingCart.isProductWithShipment(a);
    const isBProductHw = PrecalculatedShoppingCart.isProductWithShipment(b);
    return isAProductHw === isBProductHw ? 0 : isAProductHw ? -1 : 1;
  }

  private addChildProductsToProductInShoppingCart(
    parentOrderItem: OrderItemDto,
    parentProductInShoppingCart: ProductInShoppingCart,
    orderItems: OrderItemDto[],
    productDetailDtos: ProductDetailDto[],
    promotion: string
  ): void {
    const childOrderItems = _.filter(orderItems, orderItem => orderItem.parentOrderItemId === parentOrderItem.id);

    for (const childOrderItem of childOrderItems) {
      const childProduct = _.find(
        productDetailDtos,
        productDetailDto => productDetailDto.id === childOrderItem.productId
      );
      const newProductInShoppingCart = new ProductInShoppingCart(childProduct);

      newProductInShoppingCart.addOrderItem(childOrderItem);
      newProductInShoppingCart.parent = parentProductInShoppingCart;
      parentProductInShoppingCart.children.push(newProductInShoppingCart);
      parentProductInShoppingCart.children.sort((a, b) =>
        this.compareByIsHw(a, b) || a.getPriorityWithName() > b.getPriorityWithName() ? 1 : -1
      );
      this.addChildProductsToProductInShoppingCart(
        childOrderItem,
        newProductInShoppingCart,
        orderItems,
        productDetailDtos,
        promotion
      );
    }
  }

  /**
   * Get ProductInShoppingCart order item attribute value if exists if not return null
   * @param product
   * @param attrName
   */
  public getProductOrderItemAttr(product: ProductInShoppingCart, attrName: string): string {
    const attr = null;

    if (product) {
      const productOrderItem = product.orderItems.find(orderItem => orderItem.productId === product.productDetail.id);
      if (productOrderItem) {
        const attr = productOrderItem.attributes.find(attr => attr.name === attrName);
        if (attr) {
          return attr.value;
        }
      }
    }

    return attr;
  }
}

export type PrecalculatedShoppingCartProducts = Map<string, ProductInShoppingCart[]>;
