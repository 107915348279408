<ng-container *ngIf="simpleMode; else mainPage">
  <app-ecare-section
    sectionTitle="{{
      (cuRefNo ? 'wc.ecare.homepage.customerTicket.header' : 'wc.ecare.homepage.myTasks.header') | translate
    }}">
    <ng-content></ng-content>
    <div sectionHeaderContent>
      <app-button
        variant="whiteWithBorder"
        size="small"
        label="{{ 'wc.ecare.tasks.newTask' | translate }}"
        (click)="newTask(); $event.stopPropagation()">
      </app-button>
    </div>
    <div class="homepage_tasks">
      <mat-calendar [(selected)]="selectedDate" (selectedChange)="dateChanged($event)"></mat-calendar>
      <div class="tasks">
        <div
          *ngIf="tasksOfSelectedDay && tasksOfSelectedDay.length && tasksOfSelectedDay.length > 0"
          class="tasks-group">
          <div class="task_group_header">
            {{
              isTodaySelected() ? ('wc.ecare.tasks.todays' | translate) : (selectedDateAsDate() | date : 'dd.MM.yyyy')
            }}
            {{ 'wc.ecare.tasks.tasks' | translate }}
          </div>
          <app-ecare-tasks-table [ticketDtos]="tasksOfSelectedDay" [simpleMode]="true" (editTaskEmitter)="edit($event)">
          </app-ecare-tasks-table>
        </div>
        <div class="tasks-group">
          <div class="task_group_header">
            {{ 'wc.ecare.tasks.upNext' | translate }}
          </div>
          <ng-container
            *ngIf="tasksOfOtherDays && tasksOfOtherDays.length && tasksOfOtherDays.length > 0; else noTasks">
            <app-ecare-tasks-table [ticketDtos]="tasksOfOtherDays" [simpleMode]="true" (editTaskEmitter)="edit($event)">
            </app-ecare-tasks-table>
          </ng-container>
          <ng-template #noTasks>
            <div class="-entity-label-text only-text-low-opacity">
              <span>{{ 'wc.ecare.homepage.tasks.empty.text' | translate }}</span>
              &nbsp;<a (click)="newTask()">{{ 'wc.ecare.homepage.tasks.empty.text.action' | translate }}</a>
            </div>
          </ng-template>
        </div>
        <app-button
          *ngIf="
            (tasksOfSelectedDay && tasksOfOtherDays.length && tasksOfSelectedDay.length > 0) ||
            (tasksOfOtherDays && tasksOfOtherDays.length && tasksOfOtherDays.length > 0)
          "
          variant="secondary"
          label="{{ 'wc.ecare.tasks.showAllTasks' | translate }}"
          (click)="showAllTasks()"></app-button>
      </div>
    </div>
  </app-ecare-section>
</ng-container>
<ng-template #mainPage>
  <app-ecare-page-layout
    columnTemplate="1fr"
    [showBreadcrumbs]="!onlyTable"
    title="{{
      onlyTable
        ? null
        : (caRefNo
            ? customerLocalStorageService.getCurrentCustomerAccount().displayName +
              ' ' +
              ('wc.ecare.dashboard.account.header' | translate) +
              ' '
            : (cuRefNo ? customerLocalStorageService.getCurrentCustomer().displayName + ' ' : '') + ' ') +
          ('wc.ecare.tasks.tasks' | translate)
    }}">
    <ng-container *ngIf="!onlyTable" page-actions>
      <app-button
        variant="primary"
        iconPath="/assets/svg/add.svg"
        label="{{ 'wc.ecare.tasks.newTask' | translate }}"
        (click)="newTask()">
      </app-button>
      <app-button
        *ngIf="!simpleMode && getSelectedTickets().length > 0"
        variant="secondary"
        iconPath="/assets/svg/delete.svg"
        label="{{ 'wc.ecare.ticket.delete.button' | translate }}"
        (click)="deleteTasks()">
      </app-button>
    </ng-container>
    <div>
      <ng-container *ngIf="!simpleMode && tickets">

        <div class="ecare-filtering-wrapper">
          <app-section-tabs *ngIf="!externalSearchMode && !onlyTable" #nav="ngbNav" ngbNav>
            <li [ngbNavItem]="1">
              <a ngbNavLink (click)="sectionTabsChange(ticketFilterComponent.userLogin)">
                {{ 'wc.ecare.tasks.myTasks' | translate }}
              </a>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink (click)="sectionTabsChange(ticketFilterComponent.userRoles)">
                {{ 'wc.ecare.tasks.teamTasks' | translate }}
              </a>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink (click)="sectionTabsChange(null)">
                {{ 'wc.ecare.tasks.allTasks' | translate }}
              </a>
            </li>
          </app-section-tabs>
          <div>
            <app-ticket-filter
              *ngIf="!onlyTable"
              #ticketFilterComponent
              [filtering]="search.filtering"
              [cuRefNo]="cuRefNo"
              (filterChanged)="handleFilterChange()">
            </app-ticket-filter>
          </div>
        </div>


        <!-- TODO: Following 3 divs needs to be removed after refactoring ecare-dashboard.scss -->
        <div class="dashboard">
          <div class="dashboard_wrapper">
            <div class="dashboard_search">
              <ng-container *ngIf="tickets?.length; else no_results">
                <div class="ecare-responsive-table">
                  <app-ecare-tasks-table
                    [ticketDtos]="tickets"
                    [cuRefNo]="cuRefNo"
                    [search]="search"
                    [selectFormArray]="onlyTable ? null : selectedFormArray"
                    [allTicketsSelected]="allTicketsSelected"
                    [anyTicketsSelected]="anyTicketsSelected"
                    (duplicateTicketEvent)="duplicate($event)"
                    (selectDeselectAllEvent)="selectDeselectAll()"
                    (sortChangedEmitter)="onSortChange()"
                    (editTaskEmitter)="edit($event)"
                    (deleteTaskEmitter)="deleteTask($event)">
                  </app-ecare-tasks-table>
                  <app-ecare-tasks-table-mobile
                    class="ecare-responsive-mobile tasks-table"
                    [ticketDtos]="tickets"
                    [cuRefNo]="cuRefNo"
                    [selectFormArray]="onlyTable ? null : selectedFormArray"
                    (editTaskEmitter)="edit($event)"
                    (deleteTaskEmitter)="deleteTask($event)">
                  </app-ecare-tasks-table-mobile>
                </div>
              </ng-container>

              <ng-template #no_results>
                <app-ecare-listing-no-results></app-ecare-listing-no-results>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </app-ecare-page-layout>
</ng-template>
