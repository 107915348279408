<app-ecare-page-layout
  showBackButton="true"
  columnTemplate="2fr 1fr"
  [hidden]="!order"
  title="{{ 'wc.ecare.orderDetail.headline' | translate }} #{{ order?.id }}">
  <ng-container page-actions>
    <!-- todo add action and button when exists
      <app-button variant="secondary" label="Download Invoice" iconPath="/assets/svg/payment-invoice-sign-alt-o.svg">
      </app-button>-->
    <!-- todo add action and button when exists
      <app-button variant="primary" label="Track order" iconPath="/assets/svg/track-icon.svg"> </app-button>-->
    <app-button
      *ngIf="order && order.orderStateType === orderStateTypeEnum.CREATED && order.orderItems.length > 0"
      variant="primary"
      label="Resume order"
      iconPathAfter="/assets/svg/arrow-dropright-icon.svg"
      (click)="resumeOrder(order)" />

    <app-button
      *ngIf="order && order.orderStateType !== orderStateTypeEnum.CANCELED"
      variant="whiteWithBorder"
      [privilege]="
        order && order.orderStateType === orderStateTypeEnum.CONFIRMED && isBlocked
          ? 'ORDER_CANCEL_PROCESSING'
          : 'ORDER_CANCEL'
      "
      label="{{ 'wc.ecare.orderDetail.cancel' | translate }}"
      noPrivilegeToolTip="{{ 'wc.ecare.orderDetail.noPrivilegeToCancel' | translate }}"
      (click)="cancelOrder()" />

    <app-button variant="secondary" [label]="'wc.common.cancel.button' | translate" (click)="backToListing()" />
  </ng-container>

  <!-- LEFT SIDE -->
  <div left-side>
    <div *ngIf="order && order.orderStateType === orderStateTypeEnum.CONFIRMED && isBlocked" class="block-info">
      <div class="main">
        <div class="icon">
          <i [inlineSVG]="'/assets/svg/warning-icon.svg'"></i>
        </div>
        <div class="info">
          <div class="header">
            {{ 'wc.ecare.orderDetail.blockingInfo' | translate }}
          </div>
          <div class="message">
            {{ 'wc.ecare.orderDetail.blockingReason.info.' + blockingReason | translate }}
          </div>
        </div>
        <div class="button">
          <app-acl-evaluate privilege="ORDER_UNBLOCK">
            <app-button
              variant="whiteWithBorder"
              size="small"
              iconPath="/assets/svg/feather-unlock.svg"
              label="{{ 'wc.ecare.orderDetail.unblock' | translate }}"
              (click)="unblockOrder()" />
          </app-acl-evaluate>
          <app-button
            *ngIf="!isInternal && blockingReason === 'PAYMENT' && order.orderStateType !== orderStateTypeEnum.CANCELED"
            variant="whiteWithBorder"
            size="small"
            iconPath="/assets/svg/refresh-icon.svg"
            label="{{ 'wc.shopping.payAgain.button' | translate }}"
            (click)="payOrder()" />
        </div>
      </div>
    </div>

    <div
      *ngIf="order && order.orderStateType === orderStateTypeEnum.CONFIRMED && isProcessingError"
      class="block-info info-alert">
      <div class="main">
        <div class="icon">
          <i [inlineSVG]="'/assets/svg/warning-icon.svg'"></i>
        </div>
        <div class="info info-alert">
          <div class="header">
            {{ 'wc.ecare.orderDetail.processingError' | translate }}
          </div>
          <div class="message">
            {{ 'wc.ecare.orderDetail.processingError.generalErrorText' | translate }}
          </div>
        </div>
        <div class="button">
          <app-acl-evaluate privilege="ORDER_VIEW_PROCESSING_ERROR">
            <app-button
              variant="whiteWithBorder"
              size="small"
              label="{{
                showProcessingLogs
                  ? ('wc.ecare.orderDetail.hideProcessingErrors' | translate)
                  : ('wc.ecare.orderDetail.showProcessingErrors' | translate)
              }}"
              (click)="showHideProcessingLogs()" />
          </app-acl-evaluate>
        </div>
      </div>
      <app-acl-evaluate privilege="ORDER_VIEW_PROCESSING_ERROR">
        <div *ngIf="showProcessingLogs && pagedProcessingLogDto" class="errors-info">
          <div class="main">
            <div class="info">
              <div class="header">
                {{ 'wc.ecare.orderDetail.processingError.errorHistory' | translate }}
              </div>
              <div class="message">
                {{ 'wc.ecare.orderDetail.processingError.errorTotal' | translate }}
                : {{ pagedProcessingLogDto.data.length }}
              </div>
            </div>
            <div class="button">
              <app-button
                variant="whiteWithBorder"
                size="small"
                iconPath="/assets/svg/feather-repeat.svg"
                label="{{ 'wc.ecare.orderDetail.reprocess' | translate }}"
                (click)="reprocess()" />
            </div>
          </div>
          <div class="errors">
            <table class="ecare_listing-table">
              <tbody>
                <tr
                  *ngFor="let error of pagedProcessingLogDto.data; let i = index"
                  class="error-row cursor-pointer"
                  ngbTooltip="{{ 'wc.common.error.detail.button' | translate }}"
                  (click)="showProcessingLogFailures(content)">
                  <td class="error-column no-wrap">
                    <div class="step">
                      <div>{{ error.step }}</div>
                      <div class="status">{{ error.status }}</div>
                    </div>
                    <div class="happened">
                      {{ error.happened | date : 'dd. MM. yyyy' }}
                      <span class="-time">{{ error.happened | date : 'HH:mm' }}</span>
                    </div>
                  </td>
                  <td class="error-column content-sm">
                    <div>{{ error.errorMessage }}</div>
                  </td>
                  <ng-template #content let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title">{{ 'wc.common.errorDetail' | translate }}</h4>
                      <app-button variant="link" size="small" label="x" (click)="modal.dismiss()" />
                    </div>
                    <div class="modal-body" style="word-break: break-word">
                      <div class="form">
                        <div class="form-row">
                          <div class="error__page error-modal container">
                            <ng-container *ngFor="let failure of error.failures">
                              <h3 class="error_page-error-name">{{ failure.code + ' - ' + failure.detail }}</h3>
                              <p class="error_page-error-description">
                                {{ failure.localizedDescription ? failure.localizedDescription : failure.detail }}
                              </p>
                              <p>
                                {{ failure.stackTrace }}
                              </p>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </app-acl-evaluate>
    </div>
    <!-- END BLOCKING INFO - full size -->
    <app-ecare-section sectionTitle="{{ 'wc.ecare.orders.section.customerCart.title' | translate }}">
      <app-shopping-cart-items
        #shoppingCartItems
        [showQuantityChange]="false"
        [readOnlyOrder]="true"
        [showRemoveBtn]="false"
        [showGoToProductListingLink]="false"
        [showDetails]="false"
        [displayMode]="'FULL'"
        [showConfigurationBtn]="false"
        [showQuantity]="true" />
      <app-ordering-wizard-actions
        *ngIf="order"
        [readOnlyOrder]="true"
        [currentOrderDto]="order"
        [showPrice]="true"
        [showDiscount]="false"
        [showBackToShopping]="false"
        [showContinue]="false"
        [showVatPrice]="true"
        [alwaysShowNavigationButtons]="false"
        [displayMode]="'FULL'" />
    </app-ecare-section>
    <app-ecare-section sectionTitle="{{ 'wc.ecare.orders.section.activityLog.title' | translate }}">
      <app-business-events *ngIf="order" entityType="com.emeldi.ecc.be.order.dto.Order" [entityId]="order?.id" />
    </app-ecare-section>
  </div>

  <!-- RIGHT SIDE -->
  <div *ngIf="order" right-side>
    <app-ecare-section sectionTitle="{{ 'wc.ecare.orderDetail.orderInformation.heading' | translate }}">
      <app-orders-detail-side-box>
        <app-sidebox-row
          iconPath="assets/svg/icon-copy.svg"
          clipboardValue="{{ order.id }}"
          label="{{ 'wc.ecare.orderDetail.id' | translate }}" />
        <app-sidebox-row label="{{ 'wc.ecare.orderDetail.creator' | translate }}">{{ order.creator }}</app-sidebox-row>
        <app-sidebox-row label="{{ 'wc.ecare.orderDetail.created' | translate }}">
          {{ order.created | date : 'dd.MM.yyyy HH:mm' }}
        </app-sidebox-row>
        <app-sidebox-row
          iconPath="assets/svg/feather-info-small.svg"
          label="{{ 'wc.ecare.orderDetail.status' | translate }}"
          iconTooltip="{{ order.stateHappened | date : 'dd.MM.yyyy HH:mm' }}">
          <app-sticker [variant]="getStickerType()">
            {{ 'wc.common.orderState.' + order.orderCalcStateType | translate }}
          </app-sticker>
        </app-sidebox-row>
        <app-sidebox-row label="{{ 'wc.ecare.ordersFilter.stateDatePeriod' | translate }}">
          {{ order.stateHappened | date : 'dd.MM.yyyy HH:mm' }}
        </app-sidebox-row>
        <app-sidebox-row label="{{ 'wc.ecare.orderDetail.channelType' | translate }}">
          {{ order.channelType }}
        </app-sidebox-row>
        <app-sidebox-row
          *ngIf="onetimePaymentMethod && paymentMethod"
          label="{{ 'wc.ecare.orderDetail.paymentInformation.heading' | translate }}"
          [generalTooltip]="getPaymentStatusDescription()"
          [iconPath]="
            paymentStatus === paymentStatusEnum.COMPLETED || paymentStatus === paymentStatusEnum.APPROVED
              ? 'assets/svg/icon-check-green.svg'
              : paymentFailedReason
              ? 'assets/svg/circle-cross.svg'
              : undefined
          "
          [iconTooltip]="
            paymentFailedReason
              ? ('wc.shopping.payment.paymentFailedReason.' + paymentFailedReason | translate)
              : undefined
          ">
          <ng-container
            *ngIf="paymentStatus === paymentStatusEnum.CREATED && checkPaymentStatusManually; else defaultPaymentText">
            <a id="003_thank_you_page" class="cursor-pointer" (click)="checkStatusManually()">
              {{ 'wc.shopping.payment.check.status' | translate }}
            </a>
          </ng-container>
          <ng-template #defaultPaymentText>
            {{ paymentMethod.name }}
          </ng-template>
        </app-sidebox-row>
        <app-sidebox-row
          *ngIf="blockingReason === 'PAYMENT' && !isInternal && order.orderStateType !== orderStateTypeEnum.CANCELED"
          label="{{ 'wc.ecare.repeatPayment.title' | translate }}">
          <app-button
            size="small"
            variant="danger"
            iconPath="/assets/svg/refresh-icon.svg"
            label="{{ 'wc.shopping.payAgain.button' | translate }}"
            (click)="payOrder()" />
        </app-sidebox-row>
      </app-orders-detail-side-box>
    </app-ecare-section>
    <app-ecare-section
      [sectionTitle]="('wc.ecare.orderDetail.attachments' | translate) + (filesLength ? ' (' + filesLength + ')' : '')">
      <app-ecare-documents
        entityType="com.emeldi.ecc.be.order.dto.Order"
        [simpleView]="true"
        [enableDownload]="true"
        [enableUpload]="false"
        [enableDelete]="false"
        [entityId]="order.id"
        (filesLength)="this.getFileLength($event)" />
    </app-ecare-section>
    <app-ecare-section
      *ngIf="customer"
      sectionTitle="{{ 'wc.ecare.orderDetail.customerInformation.heading' | translate }}"
      [collapsed]="true">
      <app-sidebox-row label="{{ 'wc.ecare.dashboard.primaryContact.fullName' | translate }}">
        {{ customer?.contact?.firstName }} {{ customer?.contact?.lastName }}
      </app-sidebox-row>
      <app-sidebox-row
        iconPath="assets/svg/icon-mail-small.svg"
        iconBeforeValue="true"
        label="{{ 'wc.ecare.dashboard.primaryContact.email.label' | translate }}">
        {{ customer?.contact?.email ? customer?.contact?.email : '-' }}
      </app-sidebox-row>
      <app-sidebox-row
        label="{{ 'wc.ecare.dashboard.primaryContact.phone.label' | translate }}"
        [iconPath]="
          ['SMS', 'ALL'].includes(customer?.contact?.preferredChannel || '')
            ? 'assets/svg/icon-preferred-channel.svg'
            : undefined
        "
        iconTooltip="{{ 'wc.ecare.customerDetails.preferredChannel' | translate }}">
        {{ customer?.contact?.phone1 ? (customer?.contact?.phone1 | phoneNumber) : '-' }}
      </app-sidebox-row>
      <app-sidebox-row label="{{ 'wc.ecare.dashboard.primaryContact.address.label' | translate }}">
        {{ customer?.address | displayAddress : addressTypes.SUMMARY_PAGE }}
      </app-sidebox-row>
    </app-ecare-section>
    <app-ecare-section
      *ngIf="invoicing && invoicing.contact"
      sectionTitle="{{ 'wc.shopping.confirmation.invoicing.heading' | translate }}"
      [collapsed]="true">
      <app-sidebox-row label="{{ 'wc.ecare.customerDetails.fullName' | translate }}">
        {{ invoicing?.contact.firstName }} {{ invoicing?.contact.lastName }}
      </app-sidebox-row>
      <app-sidebox-row
        iconPath="assets/svg/icon-mail-small.svg"
        iconBeforeValue="true"
        label="{{ 'wc.ecare.dashboard.primaryContact.email.label' | translate }}">
        {{ invoicing?.contact.email ? invoicing?.contact.email : '-' }}
      </app-sidebox-row>
      <app-sidebox-row
        label="{{ 'wc.ecare.dashboard.primaryContact.phone.label' | translate }}"
        [iconPath]="
          ['SMS', 'ALL'].includes(invoicing?.contact.preferredChannel || '')
            ? 'assets/svg/icon-preferred-channel.svg'
            : undefined
        "
        iconTooltip="{{ 'wc.ecare.customerDetails.preferredChannel' | translate }}">
        {{ invoicing.contact?.phone1 ? (invoicing?.contact.phone1 | phoneNumber) : '-' }}
      </app-sidebox-row>
      <app-sidebox-row label="{{ 'wc.ecare.dashboard.customerDetails.address.label' | translate }}">
        {{ invoicing?.address | displayAddress : addressTypes.SUMMARY_PAGE }}
      </app-sidebox-row>
    </app-ecare-section>
    <app-ecare-section
      *ngIf="deliveryContact || deliveryAddress"
      sectionTitle="{{ 'wc.shopping.confirmation.deliveryAddress.heading' | translate }}"
      [collapsed]="true">
      <app-sidebox-row label="{{ 'wc.ecare.customerDetails.fullName' | translate }}">
        {{ deliveryContact?.firstName }} {{ deliveryContact?.lastName }}
      </app-sidebox-row>
      <app-sidebox-row
        iconPath="assets/svg/icon-mail-small.svg"
        iconBeforeValue="true"
        label="{{ 'wc.ecare.customerDetails.deliveryAddress.email' | translate }}">
        {{ deliveryContact?.email ? deliveryContact?.email : '-' }}
      </app-sidebox-row>
      <app-sidebox-row
        label="{{ 'wc.ecare.customerDetails.deliveryAddress.phone' | translate }}"
        [iconPath]="
          ['SMS', 'ALL'].includes(deliveryContact?.preferredChannel || '')
            ? 'assets/svg/icon-preferred-channel.svg'
            : undefined
        "
        iconTooltip="{{ 'wc.ecare.customerDetails.preferredChannel' | translate }}">
        {{ deliveryContact?.phone1 ? (deliveryContact?.phone1 | phoneNumber) : '-' }}
      </app-sidebox-row>
      <app-sidebox-row label="{{ 'wc.ecare.dashboard.customerDetails.address.label' | translate }}">
        {{ deliveryAddress | displayAddress : addressTypes.SUMMARY_PAGE }}
      </app-sidebox-row>
    </app-ecare-section>
    <app-ecare-section
      *ngIf="externalLinks && externalLinks.length"
      sectionTitle="{{ 'wc.ecare.orderDetail.externalLinks' | translate }}"
      [collapsed]="true">
      <div class="sidebox-wrapper">
        <div *ngFor="let externalLink of externalLinks" class="order-detail-external-link">
          <ng-container [ngSwitch]="externalLink.type">
            <a *ngSwitchCase="'OPEN_NEW_TAB'" [routerLink]="" (click)="openInNewTab(externalLink)">
              {{ externalLink.text }}
            </a>
            <a *ngSwitchCase="'DOWNLOAD'" download [href]="externalLink.url">
              {{ externalLink.text }}
            </a>
            <a *ngSwitchDefault [href]="externalLink.url">
              {{ externalLink.text }}
            </a>
          </ng-container>
        </div>
      </div>
    </app-ecare-section>
  </div>
</app-ecare-page-layout>
