<div class="widget--header">
  <div class="widget--header_title">
    <i [inlineSVG]="'/assets/svg/dashboard-widgets/last-invoices.svg'"></i>
    <h6>{{ 'wc.ecare.widgets.lastInvoices.header' | translate }}</h6>
  </div>
  <div class="widget--header_account">
    <a routerLink="/ecare/financial-documents">{{ 'wc.ecare.widgets.lastInvoices.viewAll' | translate }}</a>
  </div>
</div>
<div class="widget--content shrinked-gap">
  <!-- max 4 last invoices -->
  <ng-container *ngFor="let invoice of invoices?.slice(0, 3)">
    <div
      class="widget-single-invoice {{ existsRemainingAmount(invoice) ? '' : 'paid' }}"
      [ngbTooltip]="existsRemainingAmount(invoice) ? ('wc.ecare.invoices.pay' | translate) : ''"
      (click)="existsRemainingAmount(invoice) ? onPay(invoice) : ''">
      <div class="invoice-top">
        <div class="invoice-id">{{ invoice.id }}</div>
        <div style="white-space: nowrap" class="status {{ existsRemainingAmount(invoice) ? 'notPaid' : 'paid' }}">
          {{
          'wc.ecare.widgets.lastInvoices.status.' + (existsRemainingAmount(invoice) ? 'notPaid' : 'paid') | translate
          }}
        </div>
        <ng-container *ngIf='existsRemainingAmount(invoice)'>
          <div class='amount' [innerHTML]='invoice.remainingAmount.amount | price'></div>
        </ng-container>
      </div>
      <div class='infoText'>{{ invoice.billDate | date : 'dd.MM.yyyy' }}</div>
    </div>
  </ng-container>
</div>
<app-button
  class="remove-widget"
  iconPath="/assets/img/ecare/feather-minus.svg"
  variant="whiteWithBorder"
  placement="top"
  ngbTooltip="Remove widget">
</app-button>
