import { OrderItemDto, PriceDto, ProductDetailDto, SocketDto } from "@btl/order-bff";
import { AssetReplacement } from "./asset-replacement";
import { OrderItemPriceDto } from "@btl/order-bff/model/orderItemPriceDto";
import { ProductUtils } from "app/helpers/product-utils";

/**
 * ProductInShoppingCart represents a virtual product in shopping cart. This is a replacement for order item quantities (unsupported in
 * BFF/BE). It allows to wrap some order items and handle them like one object.
 */
export class ProductInShoppingCart {
  orderItems: OrderItemDto[] = [];
  ocPrice = null;
  ocPriceTax = null;
  rcPrice = null;
  rcPriceTax = null;
  discountOCPrice = null;
  socket: SocketDto = null;
  parent: ProductInShoppingCart = null;
  children: ProductInShoppingCart[] = [];
  display = false;
  isDiscount = false;
  hasSIMAttribute = false;
  hasProductPrice = false;
  priceMode = 'single';
  replacementFor: AssetReplacement;

  constructor(public productDetail: ProductDetailDto) {
    this.calculatePrices();
  }

  /**
   * Add an order item to this product in shopping cart. It also recalculates prices.
   *
   * @param orderItem An order item to add.
   */
  addOrderItem(orderItem: OrderItemDto): void {
    this.orderItems.push(orderItem);
    this.calculatePrices();
  }

  /**
   * Get true if at least one of the wrapped order items is non-NO_CHANGE.
   */
  get visibleActions(): boolean {
    for (const orderItem of this.orderItems) {
      if (orderItem.action !== 'NO_CHANGE') {
        return true;
      }
    }
    return false;
  }

  /**
   * Get true if at least one of the wrapped order items is MODIFY.
   */
  get modifyAction(): boolean {
    for (const orderItem of this.orderItems) {
      if (orderItem.action === 'MODIFY') {
        return true;
      }
    }
    return false;
  }

  /**
   * Get true if at least one of the wrapped order items is DELETE.
   */
  get deleteAction(): boolean {
    for (const orderItem of this.orderItems) {
      if (orderItem.action === 'DELETE') {
        return true;
      }
    }
    return false;
  }

  /**
   * Get quantity of wrapped non-NO_CHANGE order items.
   */
  get visibleOrderItemsQuantity(): number {
    let quantity = 0;
    for (const orderItem of this.orderItems) {
      if (orderItem.action !== 'NO_CHANGE') {
        quantity++;
      }
    }
    return quantity;
  }

  /**
   * Calculate prices for the product detail and the wrapped order items.
   */
  private calculatePrices(): void {
    if (this.productDetail && this.orderItems.length > 0) {
      const visibleOrderItemsQuantity = this.visibleOrderItemsQuantity;

      const ocPrice = this.orderItems[0].prices.find(price => price.priceType === 'OC');
      if (ocPrice) {
        this.ocPrice = ocPrice.priceWithTax * visibleOrderItemsQuantity;
        this.ocPriceTax = ocPrice.priceWithoutTax * visibleOrderItemsQuantity;
      }

      const rcPrice = this.orderItems[0].prices.find(price => price.priceType === 'RC');
      if (rcPrice) {
        this.rcPrice = rcPrice.priceWithTax * visibleOrderItemsQuantity;
        this.rcPriceTax = rcPrice.priceWithoutTax * visibleOrderItemsQuantity;
      }
    } else if (this.productDetail) {
      const ocPrice = this.productDetail.prices['OC'];
      if (ocPrice) {
        this.ocPrice = ocPrice.price;
        this.ocPriceTax = ocPrice.price / (1 + ocPrice.tax / 100);
      }

      const rcPrice = this.productDetail.prices['RC'];
      if (rcPrice) {
        this.rcPrice = rcPrice.price;
        this.rcPriceTax = rcPrice.price / (1 + rcPrice.tax / 100);
      }
    }

    this.checkIfDiscount();
    this.checkIfSIMAttribute();
    this.checkIfProductHavePrice();
    this.setPriceMode();
  }

  private getPriceAndTaxPrice(ocPriceWrapper, visibleOrderItemsQuantity): { price: number; priceWithTax: number } {
    const price = this.getOrderItemPrice(ocPriceWrapper);
    const totalPrice = price * visibleOrderItemsQuantity;
    const priceTax = (price / (1 + ocPriceWrapper.tax / 100)) * visibleOrderItemsQuantity;
    return { price: totalPrice, priceWithTax: priceTax };
  }

  private getOrderItemPrice(priceWrapper: PriceDto) {
    const allOverrides: Array<OrderItemPriceDto> = [];
    this.orderItems.forEach(oi => {
      if (oi.prices && oi.prices.length) {
        allOverrides.push(...oi.prices);
      }
    });
    const priceOverride = allOverrides.find(po => po.priceId === priceWrapper.id);
    if (priceOverride && priceOverride.overridingPrice) {
      return priceOverride.overridingPrice;
    } else {
      return priceWrapper.price;
    }
  }

  getPriorityWithName() {
    return this.productDetail.priority + this.productDetail.name;
  }

  setPriceMode() {
    if (this.ocPrice > 0 && this.rcPrice > 0) {
      this.priceMode = 'multi';
    } else {
      this.priceMode = 'single';
    }
  }

  checkIfProductHavePrice() {
    this.hasProductPrice = this.rcPrice != null || this.ocPrice != null;
  }

  checkIfSIMAttribute() {
    if (ProductUtils.isOfCategory(this.productDetail, 'PRODB_TP_GSM_SIM')) {
      this.hasSIMAttribute =  true;
    }
  }

  checkIfDiscount() {
    if (this.productDetail?.parametersStatic && this.productDetail?.parametersStatic['isDiscount'] === 'true') {
      const discountPrice = this.orderItems[0].prices[0];
      if (discountPrice.priceAppType === 'REL') {
        this.discountOCPrice = (this.ocPrice / 100) * discountPrice.priceWithTax;
      } else if (discountPrice.priceAppType === 'ABS') {
        this.discountOCPrice = discountPrice.priceWithTax;
      }
      this.isDiscount = true;
    } else {
      this.isDiscount = false;
    }
  }
}
