import {
  AbstractService,
  DefaultErrorHandler,
  ElasticsearchService,
  Search,
  ServiceUtils
} from '@btl/btl-fe-wc-common';
import { Injectable } from '@angular/core';
import {
  BillCycleSpecificationsDto,
  BillingFrontendService,
  CheckCreditResultDto,
  CustomerAccountDto,
  CustomerDto,
  CustomerRelationshipManagerFrontendService,
  FinancialDocumentsDto,
  InvoiceDto,
  InvoicesAmountResultDto,
  PagedCustomerAccountsDto,
  PagedCustomersDto,
  SearchFrontendService,
  SearchOptionsDto,
  TariffSpaceConsumptionInfoDto
} from '@btl/order-bff';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { PartyHits } from '../models/party-holder';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';

/**
 * {@code CustomerService} is a service encapsulating operations related to customers.
 */
@Injectable()
export class CustomerService extends AbstractService {
  constructor(
    private bffCustomerService: CustomerRelationshipManagerFrontendService,
    private billingFrontendService: BillingFrontendService,
    protected errorHandler: DefaultErrorHandler,
    private searchFrontendService: SearchFrontendService,
    private readonly propertyAccessorLocalService: PropertyAccessorLocalService
  ) {
    super(errorHandler);
  }

  /**
   * Get a customer by the given id.
   *
   * @param customerId
   * @returns Observable<CustomerDto>
   */
  public getCustomer(customerId: string, propagateNotFound?: boolean): Observable<CustomerDto> {
    if (!customerId) {
      throw new Error('CustomerId must be configured.');
    }

    const errorMessage = `Getting customer '${customerId}' failed.`;
    return this.bffCustomerService.getCustomerById(customerId).pipe(
      catchError(error => {
        if (propagateNotFound && error instanceof HttpErrorResponse && error.status === 404) {
          return throwError(error);
        }
        this.errorHandler.handleError(errorMessage, error);
      })
    );
  }

  /**
   * Create the given customer and get it updated by BFF.
   *
   * @param {CustomerDto} customer The customer to create.
   * @returns {Observable<CustomerDto>} The updated customer's observable.
   */
  public createCustomer(customer: CustomerDto): Observable<CustomerDto> {
    if (!customer) {
      throw new Error('Customer must be configured.');
    }

    const errorMessage = `Customer creation failed.`;
    return this.bffCustomerService.createCustomer(customer).pipe(catchError(this.handleError(errorMessage, null)));
  }

  /**
   * Create the given customer account and get it updated by BFF.
   *
   * @param {CustomerAccountDto} customerAccount The customer account to create.
   * @returns {Observable<CustomerAccountDto>} The updated CA's observable.
   */
  public createCustomerAccount(customerAccount: CustomerAccountDto): Observable<CustomerAccountDto> {
    if (!customerAccount) {
      throw new Error('CustomerAccount must be configured.');
    }

    const errorMessage = `CustomerAccount creation failed.`;
    return this.bffCustomerService
      .createCustomerAccount(customerAccount)
      .pipe(catchError(this.handleError(errorMessage, null)));
  }

  /**
   * Update the given customer account and get it updated by BFF.
   *
   * @param {CustomerAccountDto} customerAccount The customer account to update.
   * @param {string} id The id identifying the customer's item.
   * @returns {Observable<CustomerAccountDto>} The updated customer account observable.
   */
  public updateCustomerAccount(id: string, customerAccount: CustomerAccountDto): Observable<CustomerDto> {
    if (!id) {
      throw new Error('Customer account id be configured.');
    }
    if (!customerAccount) {
      throw new Error('Customer account must be configured.');
    }

    const customerForUpdate: CustomerDto = Object.assign({}, customerAccount);
    customerForUpdate.id = null;
    const errorMessage = `Customer account update failed.`;
    return this.bffCustomerService
      .updateCustomerAccount(id, customerAccount)
      .pipe(catchError(this.handleError(errorMessage, null)));
  }

  public patchCustomerAccount(id: string, customerAccountAsMap: any): Observable<CustomerAccountDto> {
    if (!id) {
      throw new Error('Customer account id must be configured.');
    }

    if (!customerAccountAsMap) {
      throw new Error('CustomerAccountAsMap id must be configured.');
    }

    const errorMessage = `Patching customer account by id '${id}' failed.`;

    return this.bffCustomerService
      .patchCustomerAccount(id, customerAccountAsMap)
      .pipe(catchError(this.handleError(errorMessage, null)));
  }

  /**
   * Filter available customer accounts
   */
  public filterCustomerAccounts(search: Search): Observable<PagedCustomerAccountsDto> {
    if (!search) {
      throw new Error('Search must be configured.');
    }

    const serializedFilter = JSON.stringify(search.filtering);
    const serializedSorting = ServiceUtils.getSerializedSorting(search.sorting);

    const errorMessage = `Getting customer accounts by criteria failed.`;
    return this.bffCustomerService
      .filterCustomerAccounts(null, serializedFilter, serializedSorting, search.paging.page, search.paging.pageSize)
      .pipe(catchError(this.handleError(errorMessage, null)));
  }

  /**
   * Update the given customer and get it updated by BFF.
   *
   * @param {CustomerDto} customer The customer to update.
   * @param {string} id The id identifying the customer's item.
   * @returns {Observable<CustomerDto>} The updated customers's observable.
   */
  public updateCustomer(id: string, customer: CustomerDto): Observable<CustomerDto> {
    if (!id) {
      throw new Error('Customer id be configured.');
    }
    if (!customer) {
      throw new Error('Customer must be configured.');
    }

    const customerForUpdate: CustomerDto = Object.assign({}, customer);
    customerForUpdate.id = null;
    const errorMessage = `Customer update failed.`;
    return this.bffCustomerService.updateCustomer(id, customer).pipe(catchError(this.handleError(errorMessage, null)));
  }

  public patchCustomer(id: string, customerAsMap: any): Observable<CustomerDto> {
    if (!id) {
      throw new Error('Customer id must be configured.');
    }

    if (!customerAsMap) {
      throw new Error('CustomerAsMap id must be configured.');
    }

    const errorMessage = `Patching customer by id '${id}' failed.`;

    return this.bffCustomerService
      .patchCustomer(id, customerAsMap)
      .pipe(catchError(this.handleError(errorMessage, null)));
  }

  public getCustomers(search: Search): Observable<PagedCustomersDto> {
    if (!search) {
      throw new Error('Search must be configured.');
    }

    const serializedFilter = JSON.stringify(search.filtering);
    const serializedSorting = ServiceUtils.getSerializedSorting(search.sorting);

    const errorMessage = `Getting customers by criteria failed.`;
    return this.bffCustomerService
      .getCustomers(null, serializedFilter, serializedSorting, search.paging.page, search.paging.pageSize)
      .pipe(catchError(this.handleError(errorMessage, null)));
  }

  /**
   * Check credit for msisdn
   * @param msisdn
   */
  public checkCredit(msisdn: string): Observable<CheckCreditResultDto> {
    if (!msisdn) {
      throw new Error('Msisdn must be configured.');
    }
    return of<CheckCreditResultDto>({
      credit: 100,
      creditExparyDate: new Date(),
      accountExparyDate: new Date(),
    });
  }

  /**
   * Get invoices amount for customer account
   * @param customerAccountExtId
   */
  public getInvoicesAmount(customerAccountExtId: string): Observable<InvoicesAmountResultDto> {
    if (!customerAccountExtId) {
      //throw new Error('CustomerAccountExtId must be configured.');
      return of(null);
    }
    return of<InvoicesAmountResultDto>({
      openAmount: 50,
      dueAmount: 40,
      overdueAmount: 35,
    });
  }

  public getInvoices(customerAccountExtId: string): Observable<{} | InvoiceDto[]> {
    if (!customerAccountExtId) {
      const errorMessage = 'CustomerAccountExtId must be configured';
      const errDetails = {
        status: '',
        error: { failures: [{ code: 'Service', detail: 'validation error', localizedDescription: errorMessage }] },
      };
      return this.handleError(errorMessage, null)(errDetails);
    }
    return of<InvoiceDto>({
      referenceNumber: '50',
      referenceDate: new Date(),
      dueDate: new Date(),
      totalAmount: 63,
      openAmount: 25,
      documentType: 'invoicedocument',
      linkToFile: 'invoiceink',
    });
  }

  /**
   * Get tariff space consumption for msisdn
   * @param isPrepaid
   * @param msisdn
   */
  public getTariffSpaceConsumptionInfo(msisdn: string, isPrepaid: boolean): Observable<TariffSpaceConsumptionInfoDto> {
    if (!msisdn) {
      throw new Error('Msisdn must be configured.');
    }
    return of<TariffSpaceConsumptionInfoDto>({
      voiceBundles: [
        {
          name: 'voicename',
          bundleID: 56,
          sequenceTypeID: 42,
          amountTotal: 'voicetotal',
          expiryDateTime: new Date(),
          isFullSize: 22,
          type: 'voicetype',
          amountRemaining: 'voiceremaining',
          status: 'voicestatus',
        },
      ],
      dataBundles: [
        {
          name: 'dataname',
          bundleID: 57,
          sequenceTypeID: 43,
          amountTotal: 'datatotal',
          expiryDateTime: new Date(),
          isFullSize: 23,
          type: 'datatype',
          amountRemaining: 'dataremaining',
          status: 'datastatus',
        },
      ],
      smsmmsBundles: [
        {
          name: 'smsmmsname',
          bundleID: 58,
          sequenceTypeID: 44,
          amountTotal: 'smsmmstotal',
          expiryDateTime: new Date(),
          isFullSize: 24,
          type: 'smsmmstype',
          amountRemaining: 'smsmmsremaining',
          status: 'smsmmsstatus',
        },
      ],
    });
  }

  /**
   * Get open balance amount for customer, customer account
   * @param cuRefNo Customer reference number
   * @param caRefNo Customer Account reference number
   */
  public getOpenBalance(cuRefNo: string, caRefNo: string): Observable<InvoicesAmountResultDto> {
    if (!cuRefNo) {
      throw new Error('cuRefNo is missing.');
    }
    if (!caRefNo) {
      throw new Error('caRefNo is missing.');
    }
    const errorMessage = `Getting web client channel failed.`;

    return new Observable<InvoicesAmountResultDto>(observable => {
      this.propertyAccessorLocalService
        .getWebClientChannel()
        .pipe(catchError(this.handleError(errorMessage, {})))
        .subscribe((property: string) => {
          let webClientChannel = property;
          if (!webClientChannel) {
            webClientChannel = 'WEB';
          }
          const errorMessage = `Getting open balance failed.`;
          this.billingFrontendService
            .getBalancesAmount(webClientChannel, cuRefNo, caRefNo)
            .pipe(catchError(this.handleError(errorMessage, {})))
            .subscribe(result => observable.next(result));
        });
    });
  }

  /**
   * Get FinancialDocumentsDto with list of CustomerBillDto by the given filter. If no CustomerBillDto matches the filter, an empty list is returned instead.
   * @param search
   */
  public getFinancialDocumentsByFilter(search: Search): Observable<FinancialDocumentsDto> {
    if (!search) {
      throw new Error('Search must be configured.');
    }

    const serializedFilter = JSON.stringify(search.filtering);
    const serializedSorting = ServiceUtils.getSerializedSorting(search.sorting);

    const errorMessage = `Getting web client channel failed.`;

    return new Observable<FinancialDocumentsDto>(observable => {
      this.propertyAccessorLocalService
        .getWebClientChannel()
        .pipe(catchError(this.handleError(errorMessage, {})))
        .subscribe((property: string) => {
          let webClientChannel = property;
          if (!webClientChannel) {
            webClientChannel = 'WEB';
          }
          const errorHandler = this.handleError('Getting financial documents by criteria failed.');
          return this.billingFrontendService
            .filterFinancialDocuments(
              webClientChannel,
              serializedFilter,
              serializedSorting,
              search.paging.page,
              search.paging.pageSize
            )
            .pipe(
              catchError(error => {
                const serviceNotAvailableFailure = error.error.failures.find(
                  failure => failure.code === 503 && failure.detail === '503 Service is not available.'
                );
                if (serviceNotAvailableFailure) {
                  return of(null);
                }
                throw error;
              })
            )
            .pipe(catchError(this.handleError(errorMessage, null)))
            .subscribe(result => observable.next(result));
        });
    });
  }

  /**
   * Get BillCycleSpecifications by the given filter. If no BillCycleSpecification matches the filter, an empty list is returned instead.
   * @param search
   */
  public getBillCycles(search: Search): Observable<BillCycleSpecificationsDto> {
    if (!search) {
      throw new Error('Search must be configured.');
    }

    const serializedFilter = JSON.stringify(search.filtering);
    const serializedSorting = ServiceUtils.getSerializedSorting(search.sorting);

    const errorMessage = `Getting web client channel failed.`;

    return new Observable<BillCycleSpecificationsDto>(observable => {
      this.propertyAccessorLocalService
        .getWebClientChannel()
        .pipe(catchError(this.handleError(errorMessage, {})))
        .subscribe((property: string) => {
          let webClientChannel = property;
          if (!webClientChannel) {
            webClientChannel = 'WEB';
          }
          const errorHandler = this.handleError('Getting bill cycles by criteria failed.');
          return this.billingFrontendService
            .getBillCycles(webClientChannel, serializedFilter, serializedSorting)
            .pipe(
              catchError(error => {
                const serviceNotAvailableFailure = error.error.failures.find(
                  failure => failure.code === 503 && failure.detail === '503 Service is not available.'
                );
                if (serviceNotAvailableFailure) {
                  return of(null);
                }
                throw error;
              })
            )
            .pipe(catchError(this.handleError(errorMessage, null)))
            .subscribe(result => observable.next(result));
        });
    });
  }

  /**
   * Search elasticsearch for products by given text
   * @param term Text to search by
   * @param size
   * @param partyRole
   */
  public searchByText(term: string, size: number, from?: number, partyRole?: string): Observable<PartyHits> {
    const properties: Map<string, any> = new Map<string, any>();
    properties.set('partyRole', partyRole ? partyRole : 'Customer');
    const searchOptions: SearchOptionsDto = {
      criteria: [
        { property: 'searchText', value: term },
        { property: 'partyRole', value: partyRole ? partyRole : 'Customer' },
      ],
      size: size,
      from: from,
    };
    return this.searchFrontendService
      .search(ElasticsearchService.PARTIES_INDEX, searchOptions)
      .pipe(map(oph => JSON.parse(oph).hits));
  }

  /**
   * Search elasticsearch for products by given search options
   * @param search Search object to search by
   * @param size
   * @param partyRole
   */
  public searchByOptions(search: Search, size: number, from?: number, partyRole?: string): Observable<PartyHits> {
    const searchOptions: SearchOptionsDto = {
      criteria: [],
      sort: [],
      size: size,
      from: from,
    };
    searchOptions.criteria.push(ServiceUtils.getSearchCriteria('partyRole', partyRole ? partyRole : 'Customer'));
    search.filtering.forEach(filter => {
      if (filter.column != 'type') {
        searchOptions.criteria.push(ServiceUtils.getSearchCriteria(filter.column, filter.value));
      }
    });

    search.sorting.forEach(sort => {
      searchOptions.sort.push(ServiceUtils.getSearchSort(sort.column, sort.sortOrder));
    });

    return this.searchFrontendService
      .search(ElasticsearchService.PARTIES_INDEX, searchOptions)
      .pipe(map(oph => JSON.parse(oph).hits));
  }
}
