import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProductsHolder } from '../../../models/products-holder';
import { ProductElasticFilter, SortTypeEnum } from '../../../models/product-elastic-filter';
import { FilterService } from '@service/filter.service';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { ProductCustomService } from '@service/product-custom.service';
import { CurrentLocaleService, ProductService } from '@btl/btl-fe-wc-common';
import { NgImageSliderComponent } from 'ng-image-slider';
import { Router } from '@angular/router';

import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { ProductListingService } from '@service/product-listing.service';
import { Product } from '../../../models/product';
import { GroupDto } from '@btl/order-bff';
import { WcOrderingService } from '@service/wc-ordering.service';
import { ScenarioTypeEnum } from 'app/helpers/order-utils';

SwiperCore.use([Scrollbar, Navigation, Pagination]);

@Component({
  selector: 'app-homepage-listing, [app-homepage-listing]',
  templateUrl: './homepage-listing.component.html',
})
export class HomepageListingComponent implements OnInit, OnDestroy {
  @ViewChild('nav', { static: false }) ds: NgImageSliderComponent;
  showSlider = true;

  productsHolders: Array<ProductsHolder>;
  productsLoadingError: boolean = false;
  productHeaderHolder: string;

  @Input()
  swiperId: number;

  @Input()
  count: number;

  @Input()
  availability: [string];

  @Input()
  groupCode: string;

  @Input()
  sorting?: SortTypeEnum = null;

  @Input()
  withCTA?: boolean = false;

  @Input()
  header: string;

  // Carousel Setting
  sliderWidth: Number = 960;
  sliderImageWidth: Number = 250;
  sliderImageHeight: Number = 200;
  sliderArrowShow: Boolean = true;
  sliderInfinite: Boolean = true;
  sliderImagePopup: Boolean = true;
  sliderAutoSlide: Boolean = false;
  sliderSlideImage: Number = 1;
  sliderAnimationSpeed: any = 0.6;
  imageObject: Array<object> = [];

  isQuoteOrder: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private currentLocaleService: CurrentLocaleService,
    private productService: ProductService,
    private productCustomService: ProductCustomService,
    private wcOrderingService: WcOrderingService,
    private filterService: FilterService,
    public router: Router,
    private productListingService: ProductListingService
  ) {}

  ngOnInit(): void {
    this.initProduct();
    this.wcOrderingService.getCurrentOrder().subscribe((currentOrder) => {
      if (currentOrder) {
        this.isQuoteOrder = currentOrder.scenarioType === ScenarioTypeEnum.QUOTE_MANAGEMENT;
      } else {
        throw new Error('There is no current order!');
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  setupFilter(
    page: number,
    pageSize: number,
    availability: [string],
    sorting?: SortTypeEnum,
    groupCode?: string,
    groupId?: string
  ): ProductElasticFilter {
    const filter = this.filterService.getDefaultProductFilter();
    filter.paging.page = page;
    filter.paging.pageSize = pageSize;
    filter.attributes.availability = availability;
    filter.attributes.groupCode = groupCode;
    filter.attributes.groupId = groupId;
    filter.sorting = sorting;
    return filter;
  }

  private initProduct() {
    this.productService.getProductGroupByGroupCode(this.groupCode)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((group: GroupDto) => {
        // get product group (group name is used for header)
        // if group localized text not defined, 'header' parameter value is used
        this.productService
          .getProductGroupByGroupCode(this.groupCode)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((groupDto: GroupDto | {}) => {
            const text = (groupDto as GroupDto)?.texts?.find(
              x => x !== undefined && x.locale === this.currentLocaleService.getCurrentLanguage()
            )?.message;
            this.productHeaderHolder = text ? text : this.header;
          });

        // get products from Elasticsearch
        this.filterService
          .getAggregationJson()
          .pipe(takeUntil(this.onDestroy$))
          .pipe(
            switchMap(aggregationJson => {
              return this.productCustomService.getProductsByFilter(
                this.setupFilter(1, this.count, this.availability, this.sorting, this.groupCode, group.id),
                aggregationJson
              );
            }),
            tap(response => {
              this.productsHolders = response.hits.hits;
              this.productsLoadingError = false;
            }),
            catchError(error => {
              console.error(error);
              this.productsLoadingError = true;
              return of([]);
            })
          )
          .subscribe();
      });
  }

  onChangeHandler() {
    this.showSlider = false;
    setTimeout(() => {
      this.showSlider = true;
    }, 10);
  }

  imageOnClick(index) {
    this.router.navigate(['eshop/product-detail', this.productsHolders[index]._source.seoUrl]);
  }

  addToCart(product: Product) {
    this.productListingService.handleProductAddingToCart(
      ProductListingService.asProductAddingToCart(product),
      null,
      null,
      null,
      null,
      true
    );
  }

  /**
   * Set swiper width for SSR only
   */
  getSwiperWidth() {
    // @ts-ignore
    if (typeof process !== 'undefined' && typeof process.versions.node !== 'undefined') {
      return 250;
    }
    return null;
  }
}
