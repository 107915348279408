<app-page-header
  title="{{
    wcOrderingService.currentOrder?.scenarioType === scenarioTypeEnum.QUOTE_MANAGEMENT
      ? ('wc.shopping.quote.label' | translate)
      : ('wc.shopping.cart.label' | translate)
  }}">
  <app-button
    variant="secondary"
    size="shrink"
    label="{{ 'wc.shopping.cart.backToShopping' | translate }}"
    [routerLink]="['/']"></app-button>
  <div ngbDropdown placement="bottom-right">
    <div
      app-button
      id="shoppingCartDropdown"
      ngbDropdownToggle
      [variant]="'secondary'"
      [iconPath]="'/assets/svg/three-dots.svg'"></div>
    <div ngbDropdownMenu aria-labelledby="shoppingCartDropdown">
      <button ngbDropdownItem style="border-bottom: 1px solid #e5e5e5" (click)="suspendOrderPopup()">
        <i [inlineSVG]="'/assets/svg/pause.svg'"></i>{{ 'wc.shopping.cart.suspend.label' | translate }}
      </button>
      <button ngbDropdownItem style="min-width: 185px" (click)="cancelOrderPopup()">
        <i [inlineSVG]="'/assets/svg/remove.svg'"></i>{{ 'wc.shopping.cart.removeAll' | translate }}
      </button>
    </div>
  </div>
</app-page-header>
<app-shopping-cart-items
  #shoppingCartItems
  [showImg]="true"
  [showGoToProductListingLink]="false"
  [showQuantity]="false"
  [showSpecialOffer]="true"
  [showAddons]="true"
  [showDetails]="true"
  [displayMode]="'FULL'">
</app-shopping-cart-items>
<app-ordering-wizard-actions
  #orderingWizardActions
  [showSuspendButton]="wcOrderingService.currentOrder?.scenarioType !== scenarioTypeEnum.QUOTE_MANAGEMENT"
  [showPrice]="true"
  [showVatPrice]="true"
  [displayMode]="'FULL'"
  (checkForm)="continue()">
</app-ordering-wizard-actions>
<app-suspend-order
  *ngIf="suspendOrder"
  [suspendOrderPopup]="suspendOrder"
  (close)="suspendOrderPopup()">
</app-suspend-order>
