<div *ngIf="preCalculatedShoppingCart" class="summary-page-prices">
  <div *ngIf="preCalculatedShoppingCart.totalOcPrice" class="prices one-time-price">
    <div class="prices-label">
      {{ 'wc.shopping.confirmation.totalTodayPrice.label' | translate }}
    </div>
    <ng-container *ngIf="showVat; else noVatOc">
      <div class="prices-unit">
        <one-time-price [price]="preCalculatedShoppingCart.totalOcPrice" />
        <div *ngIf="preCalculatedShoppingCart.totalOcPriceTax !== 0 && displayMode" class="taxPrice">
          <one-time-price [price]="preCalculatedShoppingCart.totalOcPriceTax" />
          {{ 'wc.shopping.gui_productDetail.general.withoutVat.label' | translate }}
        </div>
      </div>
    </ng-container>
    <ng-template #noVatOc>
      <div class="prices-unit">
        <one-time-price [price]="preCalculatedShoppingCart.totalOcPriceTax" />
        <div *ngIf="preCalculatedShoppingCart.totalOcPriceTax !== 0" class="taxPrice">
          ({{ 'wc.shopping.confirmation.taxes.without' | translate }})
        </div>
      </div>
    </ng-template>
  </div>
  <div *ngIf="preCalculatedShoppingCart.totalRcPrice" class="prices monthly-price">
    <div class="prices-label">
      {{ 'wc.shopping.confirmation.monthlyPrice.label' | translate }}
    </div>
    <ng-container *ngIf="showVat; else noVatRc">
      <div class="prices-unit">
        <one-time-price [price]="preCalculatedShoppingCart.totalRcPrice" />
        <div *ngIf="preCalculatedShoppingCart.totalRcPriceTax !== 0 && displayMode" class="taxPrice">
          (<one-time-price
            [price]="preCalculatedShoppingCart.totalRcPrice - preCalculatedShoppingCart.totalRcPriceTax" />
          {{ 'wc.shopping.confirmation.taxes.included' | translate }})
        </div>
      </div>
    </ng-container>
    <ng-template #noVatRc>
      <div class="prices-unit">
        <one-time-price [price]="preCalculatedShoppingCart.totalRcPriceTax" />
        <div *ngIf="preCalculatedShoppingCart.totalRcPriceTax !== 0" class="taxPrice">
          ({{ 'wc.shopping.confirmation.taxes.without' | translate }})
        </div>
      </div>
    </ng-template>
  </div>
  <div
    *ngFor="let shoppingCartParam of preCalculatedShoppingCart.shoppingCartTotalParameters | keyvalue"
    class="prices monthly-price">
    <div class="prices-label">
      {{ shoppingCartParam.value.label }}
    </div>
    <div class="prices-unit">
      <one-time-price [price]="shoppingCartParam.value.value" />
    </div>
  </div>
</div>
<div *ngIf="displayMode !== 'COMPACT'" class="taxes" [hidden]="displayMode === 'ROLLOUT'">
  <div *ngIf="showVatPrice" class="button-radio-container">
    <div *ngIf="showVatButton" class="tax-switch-button">
      <div class="label">{{ 'wc.shopping.confirmation.taxes.label' | translate }}</div>
      <app-toggle-switch checked (change)="changeCheckbox()" />
    </div>
  </div>
  <p *ngIf="!showVatPrice">
    {{ 'wc.shopping.confirmation.taxes.info' | translate }}
  </p>
</div>
