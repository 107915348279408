<app-ecare-page-layout
  *ngIf="ticket && form"
  showBackButton="true"
  [formGroup]="form"
  [columnTemplate]="'2fr 1fr'"
  [title]="
    (!editMode
      ? ('wc.ecare.opportunityDetail.headline' | translate)
      : ((newTicket ? 'wc.ecare.opportunity.new.header' : 'wc.ecare.opportunity.edit.header') | translate)) +
    ' ' +
    (!editMode ? '(' + ticket.subject + ')' : '')
  ">
  <ng-container page-actions>
    <ng-container *ngIf="!editMode; else editActions">
      <app-button
        variant="primary"
        iconPath="assets/svg/edit.svg"
        [label]="'wc.common.edit.button' | translate"
        (click)="edit()" />
      <app-button
        *ngIf="ticket.recordVersion"
        variant="secondary"
        iconPath="assets/img/ecare/copy_icon.svg"
        [label]="'wc.common.duplicate.button' | translate"
        (click)="duplicate()" />
      <app-button
        variant="secondary"
        iconPath="assets/svg/delete.svg"
        [label]="'wc.common.delete.button' | translate"
        (click)="deleteTicket()" />
    </ng-container>
    <ng-template #editActions>
      <app-button
        variant="primary"
        iconPath="assets/svg/save.svg"
        [label]="'wc.ecare.opportunity.saveChanges' | translate"
        (click)="saveChanges()" />
      <app-button variant="secondary" [label]="'wc.common.cancel.button' | translate" (click)="cancel()" />
      <app-button
        *ngIf="!newTicket"
        variant="secondary"
        iconPath="assets/svg/delete.svg"
        [label]="'wc.ecare.opportunity.discardChanges' | translate"
        (click)="discardChanges()" />
    </ng-template>
  </ng-container>
  <div *blockUI="blockUIElement.name; template: blockTemplate" left-side>
    <div class="left-panel">
      <app-ecare-section [sectionTitle]="'wc.ecare.opportunity.heading.opportunityInfo.text' | translate">
        <div class="left-panel generated-form">
          <div class="generated-input-container col12">
            <app-custom-input
              [parentGroup]="form"
              [controlName]="'subject'"
              [inputType]="'textarea'"
              [rows]="1"
              [maxRows]="2"
              [disabled]="!editMode"
              [translationPrefix]="'wc.ecare.ticket'" />
          </div>
          <div class="generated-input-container col12">
            <app-custom-input
              [parentGroup]="form"
              [controlName]="'description'"
              [rows]="5"
              [maxRows]="10"
              [inputType]="'textarea'"
              [disabled]="!editMode"
              [translationPrefix]="'wc.ecare.ticket'" />
          </div>
        </div>

        <div *ngIf="!editMode && getOpportunityParamValue('VISITED_PAGE_URL')" class="section-wrapper">
          <label>{{ 'wc.ecare.opportunities.visitedPage' | translate }}</label>
          <div>
            <a [href]="getOpportunityParamValue('VISITED_PAGE_URL')">
              {{ getOpportunityParamValue('VISITED_PAGE_URL') }}
            </a>
          </div>
        </div>
      </app-ecare-section>

      <app-ecare-section [sectionTitle]="'wc.ecare.ticket.notes' | translate">
        <app-ticket-notes-form [ticket]="ticket" (notesChanged)="updateIfNotEdit()" />
      </app-ecare-section>

      <app-ecare-section [sectionTitle]="'wc.ecare.ticket.attachments' | translate">
        <div class="section-wrapper">
          <div>
            <div *ngIf="!form.get('attachments').value?.length" [ngTemplateOutlet]="noDataMessage"></div>
            <app-form-attachments
              extIdPrefix="TICKET_"
              dmsType="TICKET_GENERAL"
              formControlName="attachments"
              [control]="form.get('attachments')"
              [types]="['GENERAL']"
              [allowedNotifSending]="!newTicket"
              [defaultEmailList]="getDefaultEmailList()"
              (onChange)="fieldChanged('attachments', $event)" />
          </div>
        </div>
      </app-ecare-section>

      <ng-container *ngIf="!newTicket">
        <ng-container *ngIf="relatedTicketsByCode.size === 0; else relatedTaskByCodeMode">
          <app-ecare-section [sectionTitle]="'wc.ecare.contactHistory.relatedTasks' | translate">
            <div *ngIf="relatedTasks?.length; else noDataMessage">
              <app-ecare-tasks
                [backUrl]="router.url"
                [tickets]="relatedTasks"
                [editCallback]="goToRelatedTicket"
                [deleteCallback]="deleteTicketRelation"
                (tasksDeletedEmitter)="tasksDeleted()" />
            </div>
            <div class="buttons">
              <app-button
                variant="secondary"
                iconPath="/assets/svg/add.svg"
                [label]="'wc.ecare.contactHistory.addRelatedTask' | translate"
                (click)="addRelatedTask()" />
            </div>
          </app-ecare-section>
        </ng-container>
        <ng-template #relatedTaskByCodeMode>
          <app-ecare-section
            *ngFor="let entry of relatedTicketsByCode | keyvalue"
            [sectionTitle]="'wc.ecare.ticket.related.' + entry.key | translate">
            <div *ngIf="entry.value?.length; else noDataMessage">
              <ng-container *ngIf="entry.key === ContactHistoryDetailsComponent.INTERACTION_TICKET_TYPE">
                <app-contact-history-component
                  [inputTickets]="entry.value"
                  [externalSearchMode]="true"
                  [editCallback]="goToRelatedTicket"
                  [removeRelationCallback]="deleteTicketRelation"
                  [showDeleteBtn]="false"
                  [showDuplicateBtn]="false"
                  [smallTable]="true"/>
              </ng-container>
            </div>
            <div class="buttons">
              <app-button
                variant="secondary"
                iconPath="/assets/svg/add.svg"
                [label]="'wc.ecare.ticket.add.related.' + entry.key | translate"
                (click)="addRelatedTask(entry.key)" />
            </div>
          </app-ecare-section>
        </ng-template>
      </ng-container>

      <app-ecare-section *ngIf="ticket.recordVersion" [sectionTitle]="'wc.ecare.ticket.linkedOrders' | translate">
        <div class="section-wrapper overflow-y-auto">
          <app-ecare-order-table
            *ngIf="relatedOrders.length; else noDataMessage"
            [maxThumbnailsToShow]="2"
            [opportunity]="ticket"
            [orders]="relatedOrders" />
        </div>
      </app-ecare-section>

      <app-ecare-section *ngIf="ticket.recordVersion" [sectionTitle]="'wc.ecare.ticket.quotes' | translate">
        <div class="section-wrapper">
          <div *ngIf="relatedQuotes.length; else noDataMessage" class="card_grid">
            <div
              *ngFor="let quote of relatedQuotes"
              class="card quote-card -state-{{ getQuoteState(quote) }}"
              [ngClass]="{ 'cursor-pointer': isQuoteClickable(quote) }"
              (click)="continueQuote(quote)">
              <div>
                <div ngbDropdown placement="bottom-right" class="quotes-actions" (click)="$event.stopPropagation()">
                  <span class="fa-stack cursor-pointer action-icon-wrapper" ngbDropdownToggle>
                    <i class="action-button" [inlineSVG]="'/assets/svg/action-button.svg'"></i>
                  </span>

                  <div ngbDropdownMenu class="action-list-dropdown-menu" id="popover-content_{{ ticket.id }}">
                    <div>
                      <button
                        class="btn btn-link action-list-btn-link"
                        type="button"
                        (click)="showQuoteDetailsPopup(quote)">
                        {{ 'wc.ecare.opportunity.showDetails.button' | translate }}
                      </button>
                      <button
                        class="btn btn-link action-list-btn-link"
                        type="button"
                        (click)="generateQuoteDocument(quote)">
                        {{ 'wc.ecare.opportunities.generateQuoteDocument' | translate }}
                      </button>
                      <button
                        *ngIf="quote.quoteState === quoteStateDtoEnum.InProgress"
                        class="btn btn-link action-list-btn-link"
                        type="button"
                        (click)="approveQuote(quote)">
                        {{ 'wc.ecare.opportunities.approveQuote' | translate }}
                      </button>
                      <button
                        *ngIf="quote.quoteState === quoteStateDtoEnum.Approved"
                        class="btn btn-link action-list-btn-link"
                        type="button"
                        (click)="rejectQuote(quote)">
                        {{ 'wc.ecare.opportunities.rejectQuote' | translate }}
                      </button>
                      <button
                        *ngIf="quote.quoteState === 'rejected'"
                        class="btn btn-link action-list-btn-link"
                        type="button"
                        (click)="reviseQuote(quote)">
                        {{ 'wc.ecare.opportunities.reviseQuote' | translate }}
                      </button>
                      <button
                        *ngIf="
                          quote.quoteState === quoteStateDtoEnum.InProgress ||
                          quote.quoteState === quoteStateDtoEnum.Pending
                        "
                        class="btn btn-link action-list-btn-link"
                        type="button"
                        (click)="cancelQuote(quote)">
                        {{ 'wc.ecare.opportunities.cancelQuote' | translate }}
                      </button>
                      <button
                        *ngIf="isQuoteToOrderVisible(quote)"
                        class="btn btn-link action-list-btn-link"
                        type="button"
                        (click)="quoteToOrder(quote)">
                        {{ 'wc.ecare.opportunities.quoteToOrder' | translate }}
                      </button>
                    </div>
                  </div>
                </div>

                <h3 class="card_label">
                  <span *ngIf="quote['description']">{{
                      quote['description']
                  }}</span>
                  <span *ngIf="!quote['description']">
                    {{ 'wc.ecare.opportunities.quote.defaultNamePrefix' | translate }}{{ quote.id }}
                  </span>
                  <i
                    placement="bottom"
                    class="icon-state -state-{{ quote['state'] }}"
                    [ngbTooltip]="
                      'wc.ecare.opportunities.quote.state.' + (quote['isExpired'] ? 'expired' : quote.quoteState)
                        | translate
                    "
                    [inlineSVG]="'/assets/svg/' + quote['stateIcon'] + '.svg'"></i>
                </h3>
                <p class="card_description">
                  {{ quote['label'] }}
                </p>
                <p *ngIf="quote['params']" class="card_description">
                  <span class="no-wrap">{{ quote['params']['subsidy'] ? quote['params']['subsidy'].label+': '+ quote['params']['subsidy'].value : '' }}</span>
                  <span class="no-wrap">{{ quote['params']['subsidyPercentageDiscount'] ? '|'+quote['params']['subsidyPercentageDiscount'].label+': '+ quote['params']['subsidyPercentageDiscount'].value : '' }}</span>
                  <span class="no-wrap">{{ quote['params']['committedPeriod'] ? '|'+quote['params']['committedPeriod'].label+': '+ quote['params']['committedPeriod'].value : '' }}</span>
                </p>
                <div class="card_footer">
                  <p class="created-date">
                    <img height="auto" width="auto" [src]="'/assets/svg/note-timestamp.svg'" />
                    {{ quote.created | date : 'dd.MM.yyyy HH:mm' }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <app-button
              size="large"
              variant="secondary"
              [iconPath]="'/assets/svg/add.svg'"
              [label]="'wc.ecare.contactHistory.addQuote' | translate"
              (click)="addQuote()" />
          </div>
        </div>
      </app-ecare-section>

      <app-ecare-section
        *ngFor="let formParameter of formParameters"
        [sectionTitle]="formParameter.localizedNames[currentLocaleService.getCurrentLanguage()]"
        [formGroup]="form">
        <app-configurable-form [parameter]="formParameter" [formControlName]="formParameter.name" />
      </app-ecare-section>
    </div>
  </div>

  <ng-template #noDataMessage>
    <div class="no-data">{{ 'wc.ecare.ticket.noData' | translate }}</div>
  </ng-template>

  <div right-side>
    <app-ecare-section [sectionTitle]="'wc.ecare.opportunities.opportunityDetails.label' | translate">
      <div>
        <app-sidebox-row [label]="'wc.ecare.opportunities.opportunityType.label' | translate" [require]="true">
          <app-sticker
            *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_OPPORTUNITY_TYPE') | async as codebook"
            [variant]="'inform'">
            {{ codebook[getOpportunityParamValue('OPPORTUNITY_TYPE')] }}
          </app-sticker>
          <div [hidden]="!editMode">
            <div *ngIf="form" [formGroup]="form">
              <app-input-select-form-field
                class="full-width"
                inputType="codebook"
                formControlName="opportunityType"
                codebookType="TICKET_OPPORTUNITY_TYPE"
                translationPrefix="wc.ecare.opportunity"
                [sortOptions]="false"
                [noLabel]="true" />
            </div>
          </div>
        </app-sidebox-row>
        <app-sidebox-row [label]="'wc.ecare.ticket.state' | translate" [require]="true">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('TICKET_OPPORTUNITY_STATE') | async as codebook">
            {{ codebook[getOpportunityParamValue('OPPORTUNITY_STATE')] }}
          </div>
          <div [hidden]="!editMode">
            <div>
              <app-input-select-form-field
                class="full-width"
                inputType="codebook"
                formControlName="status"
                codebookType="TICKET_OPPORTUNITY_STATE"
                translationPrefix="wc.ecare.opportunity"
                [sortOptions]="false"
                [noLabel]="true"
                [disabled]="!editMode"
                (selectionChangeEmit)="fieldChanged('OPPORTUNITY_STATE', $event)" />
            </div>
          </div>
        </app-sidebox-row>
        <app-sidebox-row [label]="'wc.ecare.ticket.assignedTo' | translate">
          <ng-container *ngIf="!editMode">
            {{ ticket.assignedTo }}
          </ng-container>
          <div [hidden]="!editMode">
            <div>
              <app-form-field-modal-picker
                valueField="login"
                labelField="login"
                controlName="assignedTo"
                [disabled]="!editMode"
                [parentGroup]="form"
                [customerId]="form.controls.cuRefNo.value?.id"
                [pickerType]="pickerInputType.USER"
                (preSelectionDone)="accountChanged($event)"
                (selectionChanged)="accountChanged($event)" />
            </div>
          </div>
        </app-sidebox-row>
        <app-sidebox-row [label]="'wc.ecare.ticket.salesTeam' | translate">
          <ng-container *ngIf="!editMode">
            {{ ticket.salesTeam ? ticket.salesTeam : '-' }}
          </ng-container>
          <div [hidden]="!editMode">
            <div>
              <app-input-select-form-field
                class="full-width"
                formControlName="salesTeam"
                valueOption="id"
                labelOption="id"
                translationPrefix="wc.ecare.opportunity"
                [options]="userSalesTeamRoles"
                [noLabel]="true"
                [disabled]="!editMode" />
            </div>
          </div>
        </app-sidebox-row>
        <app-sidebox-row [label]="'wc.ecare.ticket.currentOperator' | translate">
          <div *ngIf="!editMode && codebookService.getCodebookTexts('NETWORK_OPERATOR') | async as codebook">
            {{
              getOpportunityParamValue('CURRENT_OPERATOR')
                ? codebook[getOpportunityParamValue('CURRENT_OPERATOR')]
                : '-'
            }}
          </div>
          <div [hidden]="!editMode">
            <div>
              <app-input-select-form-field
                class="full-width"
                inputType="codebook"
                formControlName="currentOperator"
                codebookType="NETWORK_OPERATOR"
                translationPrefix="wc.ecare.opportunity"
                [noLabel]="true"
                [disabled]="!editMode"
                (selectionChangeEmit)="handleCurrentOperatorChange($event)" />
            </div>
          </div>
        </app-sidebox-row>
        <app-sidebox-row [label]="'wc.ecare.ticket.probability' | translate" [require]="true">
          <ng-container *ngIf="!editMode"> {{ getOpportunityParamValue('OPPORTUNITY_PROBABILITY') }} %</ng-container>
          <div [hidden]="!editMode">
            <app-custom-input
              controlName="probability"
              inputType="number"
              suffix="%"
              [parentGroup]="form"
              [noLabel]="true"
              [translationPrefix]="'wc.ecare.opportunity'" />
          </div>
        </app-sidebox-row>
        <app-sidebox-row [label]="'wc.ecare.ticket.revenue' | translate">
          <ng-container *ngIf="!editMode && getOpportunityParamValue('EXPECTED_REVENUE')">
            {{ getOpportunityParamValue('EXPECTED_REVENUE') }} {{ appCurrency }}</ng-container
          >
          <div [hidden]="!editMode">
            <app-custom-input
              controlName="expectedRevenue"
              inputType="number"
              [suffix]="appCurrency"
              [parentGroup]="form"
              [noLabel]="true"
              [translationPrefix]="'wc.ecare.ticket'" />
          </div>
        </app-sidebox-row>
        <app-sidebox-row [label]="'wc.ecare.ticket.priority' | translate">
          <div *ngIf="!editMode" class="flex-5">
            <i class="task-priority" [inlineSVG]="'/assets/svg/task-' + ticket.priority + '.svg'"></i>
            <span>{{ 'wc.ecare.ticket.task.priorities.' + ticket.priority | translate }}</span>
          </div>
          <div [hidden]="!editMode">
            <div>
              <ng-select
                formControlName="priority"
                [clearable]="false"
                [clearOnBackspace]="false"
                [searchable]="false"
                [readonly]="!editMode"
                (change)="fieldChanged('priority', $event)">
                <ng-option *ngFor="let priority of getOpportunityPriorities()" [value]="priority">
                  <div class="flex-5">
                    <i class="task-priority" [inlineSVG]="'/assets/svg/task-' + priority + '.svg'"></i>
                    <span>{{ 'wc.ecare.ticket.task.priorities.' + priority | translate }}</span>
                  </div>
                </ng-option>
              </ng-select>
            </div>
          </div>
        </app-sidebox-row>
        <app-sidebox-row [label]="'wc.ecare.ticket.slaDueDate' | translate">
          <ng-container *ngIf="!editMode">
            {{ ticket.slaDueDate | date : 'dd.MM.yyyy HH:mm' }}
          </ng-container>
          <div [hidden]="!editMode">
            <div>
              <app-date-picker class="date-picker" formControlName="slaDueDate" [showTime]="true" />
            </div>
          </div>
        </app-sidebox-row>
        <app-sidebox-row
          *ngIf="
            ticket.businessState === 'CLOSED_LOST' ||
            ticket.businessState === 'CLOSED_LOST' ||
            ticket.businessState === 'CANCELED'
          "
          [label]="'wc.ecare.ticket.closed' | translate"
          >{{ ticket.created | date : 'dd.MM.yyyy HH:mm' }}
        </app-sidebox-row>
        <app-sidebox-row
          *ngIf="getOpportunityParamValue('REJECT_REASON')"
          [label]="'wc.ecare.ticket.rejectionReason' | translate"
          >{{ getOpportunityParamValue('REJECT_REASON') }}</app-sidebox-row
        >
        <app-sidebox-row *ngIf="ticket.recordVersion" [label]="'wc.ecare.ticket.created' | translate"
          >{{ ticket.createdBy }}
          <span class="-ticket-date">({{ ticket.created | date : 'dd.MM.yyyy HH:mm' }})</span>
        </app-sidebox-row>
        <app-sidebox-row *ngIf="ticket.recordVersion" [label]="'wc.ecare.ticket.modified' | translate"
          >{{ ticket.modifiedBy }}
          <span class="-ticket-date">({{ ticket.modified | date : 'dd.MM.yyyy HH:mm' }})</span>
        </app-sidebox-row>
      </div>
    </app-ecare-section>

    <app-ecare-section [sectionTitle]="'wc.ecare.ticket.customer' | translate">
      <div class="section-wrapper">
        <div>
          <div class="contacts-customer">
            <div class="generated-form">
              <div class="generated-input-container col12">
                <app-button
                  *ngIf="editMode && !customerLocalStorageService.getCurrentCustomer()"
                  variant="whiteWithBorder"
                  size="small"
                  [label]="'wc.ecare.homepage.createCustomer' | translate"
                  (click)="createCustomerPopup()" />
              </div>
              <div class="generated-input-container col12">
                <div>
                  <app-form-field-modal-picker
                    labelField="displayName"
                    controlName="cuRefNo"
                    [disabled]="!editMode || (newTicket && customerLocalStorageService.getCurrentCustomer())"
                    [parentGroup]="form"
                    [translationPrefix]="'wc.ecare.ticket'"
                    [pickerType]="pickerInputType.CUSTOMER"
                    (selectionChanged)="customerChanged($event)" />

                  <div *ngIf="form.controls.cuRefNo.value" class="generated-input-container col4">
                    <label>
                      {{ 'wc.ecare.ticket.id' | translate }}
                    </label>
                    <input disabled [value]="form.controls.cuRefNo.value?.id" />
                  </div>
                  <div *ngIf="form.controls.cuRefNo.value" class="generated-input-container col8">
                    <label>
                      {{ 'wc.ecare.dashboard.subjectType.label' | translate }}
                    </label>
                    <input disabled [value]="form.controls.cuRefNo.value?.subject.subjectType" />
                  </div>
                  <div *ngIf="form.controls.cuRefNo.value" class="generated-input-container col12">
                    <label>
                      {{ 'wc.ecare.dashboard.primaryContact.label' | translate }}
                    </label>
                    <input disabled [value]="form.controls.cuRefNo.value?.contact | displayContact" />
                  </div>
                  <div *ngIf="form.controls.cuRefNo.value" class="generated-input-container col12">
                    <label>
                      {{ 'wc.ecare.dashboard.customerAccount.header' | translate }}
                    </label>
                    <app-form-field-modal-picker
                      labelField="displayName"
                      controlName="caRefNo"
                      [disabled]="!editMode"
                      [parentGroup]="form"
                      [customerId]="form.controls.cuRefNo.value?.id"
                      [pickerType]="pickerInputType.CUSTOMER_ACCOUNT"
                      (selectionChanged)="setCustomerContact()" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-ecare-section>

    <app-ecare-section [sectionTitle]="'wc.ecare.opportunities.contact' | translate">
      <div *ngIf="!newCustomerForm" class="generated-form">
        <div *ngIf="contacts?.length" class="generated-input-container col12">
          <ng-container *ngFor="let contact of contacts; let i = index">
            <div
              *ngIf="(!editMode && i === selectedContactIndex) || editMode"
              class="{{ editMode ? 'section-widget' : '' }} contact-card full-width {{
                !editMode ? 'notEditable' : ''
              }} {{ i === selectedContactIndex ? 'selected' : '' }}"
              (click)="editMode ? setPreferredContact(contact, i) : null">
              <div class="radio">
                <input type="radio" name="contact-radio" [checked]="i === selectedContactIndex" />
                <div class="radio-label">
                  <div class="contact-info">
                    <div class="-name">{{ contact?.firstName }} {{ contact.middleName }} {{ contact.lastName }}</div>
                    <div class="-email">{{ contact?.email }}</div>
                    <div class="-phone-no">{{ contact?.phone1 }}</div>
                    <!-- todo: use phone number pipe <div class="-phone-no">{{contact.phone1 | phoneNumber}}</div>-->
                  </div>
                  <div
                    *ngIf="editMode && contact.type === contactTypesEnum.OPPORTUNITY"
                    ngbDropdown
                    class="edit-button-wrapper"
                    (click)="$event.stopPropagation()">
                    <button class="edit-button cursor-pointer" ngbDropdownToggle>
                      <i [inlineSVG]="'/assets/svg/feather-vertical-dots.svg'"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="shoppingCartDropdown">
                      <button
                        class="dropdown-item filled"
                        ngbTooltip="'wc.common.edit' | translate"
                        (click)="editContact(contact, i)">
                        <i [inlineSVG]="'/assets/svg/feather-edit.svg'"></i>{{ 'wc.common.edit' | translate }}
                      </button>
                      <button
                        class="dropdown-item"
                        ngbTooltip="'wc.common.delete.button' | translate"
                        (click)="removeContact(contact)">
                        <i [inlineSVG]="'/assets/svg/remove.svg'"></i>{{ 'wc.common.delete.button' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="editMode && form.controls.cuRefNo.value">
          <div
            class="btn-sm"
            app-button
            iconPath="/assets/svg/feather-plus-circle.svg"
            [label]="'wc.ecare.opportunity.newContact.text' | translate"
            (click)="addContact()"></div>
        </ng-container>
      </div>

      <ng-container *ngIf="newCustomerForm || !form.controls.cuRefNo.value">
        <div *ngIf="form.controls['newContact']" class="generated-form" [formGroup]="form.controls['newContact']">
          <div class="generated-input-container">
            <label>{{ 'wc.ecare.contact.firstName' | translate }}</label>
            <app-custom-input
              controlName="firstName"
              inputType="text"
              [disabled]="!editMode"
              [parentGroup]="form.controls['newContact']"
              [noLabel]="true"
              [translationPrefix]="'wc.ecare.opportunity'" />
          </div>
          <div class="generated-input-container">
            <label>{{ 'wc.ecare.contact.lastName' | translate }}</label>
            <app-custom-input
              controlName="lastName"
              inputType="text"
              [disabled]="!editMode"
              [parentGroup]="form.controls['newContact']"
              [noLabel]="true"
              [translationPrefix]="'wc.ecare.opportunity'" />
          </div>
          <div class="generated-input-container">
            <label>
              {{ 'wc.ecare.contact.email' | translate }}
              <span class="required">
                <i
                  [inlineSVG]="'/assets/svg/help.svg'"
                  ngbTooltip="{{ 'wc.ecare.opportunity.phoneOrEmailRequires' | translate }}"></i>
              </span>
            </label>
            <app-custom-input
              controlName="email"
              inputType="text"
              [disabled]="!editMode"
              [parentGroup]="form.controls['newContact']"
              [noLabel]="true"
              [translationPrefix]="'wc.ecare.opportunity'" />
          </div>
          <div class="generated-input-container">
            <label>
              {{ 'wc.ecare.contact.phone' | translate }}
              <span class="required">
                <i
                  [inlineSVG]="'/assets/svg/help.svg'"
                  ngbTooltip="{{ 'wc.ecare.opportunity.phoneOrEmailRequires' | translate }}"></i>
              </span>
            </label>
            <app-custom-input
              controlName="phone1"
              inputType="text"
              [disabled]="!editMode"
              [parentGroup]="form.controls['newContact']"
              [noLabel]="true"
              [translationPrefix]="'wc.ecare.opportunity'" />
          </div>
        </div>
        <div
          *ngIf="
            form.controls.newContact?.hasError('phoneOrEmailRequires') &&
            form.controls.newContact?.controls['email'].touched &&
            form.controls.newContact?.controls['phone1'].touched
          "
          class="alert alert-danger">
          <div>
            {{ 'wc.ecare.opportunity.phoneOrEmailRequires' | translate }}
          </div>
        </div>
      </ng-container>
    </app-ecare-section>

    <app-ecare-section *ngIf="showAddressForm" [sectionTitle]="'wc.ecare.opportunities.address' | translate">
      <div class="section-wrapper">
        <div class="opportunities-technologyCheck">
          <div class="generated-form full-width">
            <div *ngIf="form" class="generated-input-container col12">
              <app-form-address-search-input
                #formAddressSearchInputComponent
                formControlName="address"
                [control]="form.get('address')"
                [disabled]="!editMode" />
            </div>
          </div>
        </div>
      </div>
    </app-ecare-section>
  </div>
</app-ecare-page-layout>
